import { PlayCircleIcon } from '@heroicons/react/20/solid';
import { useTranslation } from 'react-i18next';

import { SkeletonBox } from 'components/skeleton';

import AmplifyMedia from 'views/components/media/StorageImageRenderer';

interface IContentCard extends IJobContent {
  hideCreator?: boolean;
}

interface IJobContentCard extends IContentCard {
  fetchJobContents?: () => void;
}

export default function JobContentCard(props: IJobContentCard) {
  const { t } = useTranslation('translation', { keyPrefix: 'brand.contents' });

  return (
    <div
      key={props.id}
      className='cursor-pointer group relative overflow-hidden h-fit'
    >
      <div className='absolute top-3 left-0 w-full pr-6 z-10 mx-3 opacity-30 group-hover:opacity-100 transition-all duration-150'>
        <div className='flex justify-between items-center w-full bg-dark-theme-200 rounded-full p-1 text-white'>
          <div className='flex items-center gap-2 capitalize'>
            <AmplifyMedia
              sourceKey={props.Creator?.profile_picture}
              className='w-8 h-8 rounded-full'
              isUserAvatar
            />
            {`${props.Creator?.firstName} ${props.Creator?.lastName}`}
          </div>
        </div>
      </div>

      {props.contentType === 'video' && (
        <div className='w-full h-full absolute left-0 top-0 flex'>
          <PlayCircleIcon className='w-16 h-16 m-auto opacity-50 group-hover:opacity-80 transition-all duration-150' />
        </div>
      )}

      <div className='w-full aspect-square rounded-lg bg-gray-300 dark:bg-dark-theme-900'>
        <AmplifyMedia
          mediaType={props.contentType}
          sourceKey={props.contentUrl}
          className='w-full aspect-square'
          isContentMedia
        />
      </div>
    </div>
  );
}

export function SkeletonJobContentCard() {
  return (
    <div className='relative'>
      <SkeletonBox className='w-full h-full' darkMode />
    </div>
  );
}
