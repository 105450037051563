import { DragEvent, useState, ChangeEvent } from "react";
import { toast } from "react-toastify";
import { Storage } from "aws-amplify";
import { useTranslation } from "react-i18next";

export default function useFileDropzone(
  fileType: Array<string>,
  onFileUpload: (urls: string[], files: File[]) => void,
) {
  const { t } = useTranslation("translation", {
    keyPrefix: "notification",
  });

  const [files, setFiles] = useState<File[]>([]);
  const [currentUpload, setCurrentUpload] = useState<number>();
  const [isUploading, setIsUploading] = useState<boolean>(false);

  const changeHandler = (e: ChangeEvent<HTMLInputElement>) => {
    if (!!e.target.files) {
      //@ts-ignore
      const validFiles: File[] = [...e.target.files].filter((file) =>
        fileType.includes(file.type.slice(0, 5)),
      );
      e.target.files.length !== validFiles.length &&
        toast.warn(t("Only images and video formats are supported."));
      setFiles([...files, ...validFiles]);
    }
    if (isUploading) {
      return;
    }
  };

  const dragOverHandler = (e: DragEvent<HTMLLabelElement>) => {
    e.preventDefault();
    if (isUploading) {
      return;
    }
  };

  const dropHandler = (e: DragEvent<HTMLLabelElement>) => {
    // Prevent default behavior (Prevent file from being opened)
    e.preventDefault();
    if (isUploading) {
      return;
    }

    if (e.dataTransfer.items) {
      // Use DataTransferItemList interface to access the file(s)
      let dataFiles: File[] = [];
      // @ts-ignore
      [...e.dataTransfer.items].forEach((item) => {
        // If dropped items aren't files, reject them
        if (item.kind === "file" && fileType.includes(item.type.slice(0, 5))) {
          const file = item.getAsFile();

          dataFiles.push(file);
        }
      });
      dataFiles.length !== e.dataTransfer.items.length &&
        toast.warn(t("Files with unsupported formats are automatically excluded."));
      setFiles([...files, ...dataFiles]);
    } else {
      // Use DataTransfer interface to access the file(s)
      let dataFiles: File[] = [];

      // @ts-ignore
      [...e.dataTransfer.files].forEach((file, i) => {
        if (fileType.includes(file.type.slice(0, 5))) {
          dataFiles.push(file);
        }

        dataFiles.length !== e.dataTransfer.items.length &&
          toast.warn(t("Only images and video formats are supported."));
      });
      setFiles([...files, ...dataFiles]);
    }
  };

  const removeFileHandler = (index: number) => {
    if (isUploading) {
      return;
    }
    const newFiles = files.filter((file, fileIndex) => fileIndex !== index);
    setFiles(newFiles);
  };

  const emptyFilesHandler = () => {
    setFiles([]);
  };

  const uploadAllHandler = async () => {
    if (isUploading) {
      return;
    }

    if (files.length <= 0) return;
    let imageURLs: string[] = [];
    setIsUploading(true);
    for (let i = 0; i < files.length; i++) {
      let response;
      setCurrentUpload(i);
      try {
        const fileName = files[i].name.split(".")[0];
        const ext = files[i].name.split(".")[1];
        response = await Storage.put(fileName + Date.now() + "." + ext, files[i]);
        imageURLs = [...imageURLs, response.key];
      } catch (error) {
        console.log("Error uploading file: ", error);
      }
    }
    setIsUploading(false);
    setCurrentUpload(undefined);
    setFiles([]);
    onFileUpload(imageURLs, files);
  };

  return {
    isUploading,
    files,
    currentUpload,
    emptyFilesHandler,
    removeFileHandler,
    dropHandler,
    dragOverHandler,
    changeHandler,
    uploadAllHandler,
  };
}
