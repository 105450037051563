const products = {
  'My Products': 'My Products',
  'Active campaigns': 'Active campaigns',

  // product modal
  'Add a physical product': 'Add a physical product',
  'Edit product': 'Edit product',
  'Product name (visible to creators)': 'Product name (visible to creators)',
  'Product price': 'Product price',
  'Product category': 'Product category',
  'External product link': 'External product link',
  'Please describe your product': 'Please describe your product and delivery method',
  'Add a digital product': 'Add a digital product',

  // Action
  Cancel: 'Cancel',
  'Create product': 'Create product',
  'Save changes': 'Save changes',

  Product: 'Product',
  Brand: 'Brand',
  'My content': 'My content',
  'Add new product': 'Add new product',

  'No products': 'No products',
  'See details': 'See details',
  'Remove product': 'Remove product',
  'Open campaigns': 'Open campaigns',
  'Hidden campaigns': 'Hidden campaigns',
  'Closed campaigns': 'Closed campaigns',
  'Create campaign': 'Create campaign',
  'Product information': 'Product information',
  'Product link': 'Product link',
  'Product access instructions': 'Product access instructions',

  'digital product': 'Digital product',
  'physical product': 'Physical product',
};

export default products;
