import { getCampaignByID } from 'apis/brand/campaign';
import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { applyJobAction } from 'redux/influencer_slices/creatorSlice';
import { AppDispatch, RootState } from 'redux/store';
import useSafeCampaign from './useSafeCampaign';
import { useTranslation } from 'react-i18next';

export default function useFetchedCampaignData() {
  const { t } = useTranslation('translation', {
    keyPrefix: 'notification',
  });

  const dispatch = useDispatch<AppDispatch>();

  const userID = useSelector((root: RootState) => root.user.profile.id);
  const pending = useSelector((root: RootState) => root.creator.pending);
  const userProfile = useSelector((root: RootState) => root.user.profile);

  const { campaignID } = useParams();

  const [visibility, setVisibility] = useState<boolean>(false);
  const [error, setError] = useState<boolean>(false);
  const [fetching, setFetching] = useState<boolean>(false);
  const [campaign, setCampaign] = useState<ICampaignData>();

  const { safety } = useSafeCampaign(campaign);

  useEffect(() => {
    if (campaignID === undefined) return;
    (async () => {
      setFetching(true);
      try {
        const response = await getCampaignByID(campaignID);
        setCampaign(response.data.getCampaign);
        setError(false);
      } catch (e) {
        setError(true);
      }
      setFetching(false);
    })();

    return () => {};
  }, [campaignID]);

  async function applyJob() {
    if (
      campaign?.id === undefined ||
      userID === undefined ||
      campaign?.userID === undefined
    ) {
      closeConfirmModal();
      toast.error(
        t('There is an issue with this campaign, please try another.')
      );
      return;
    }
    if (pending || fetching) {
      return;
    }

    if (!safety) {
      toast.info(t('You already have an active job with this campaign.'));
      return;
    }

    const job: IJobData = {
      id: 'UNIQUE ID WILL BE IGNORED',
      buyerID: campaign?.userID,
      productID: campaign?.productID,
      campaignID: campaign?.id,
      brandID: campaign?.brandID,
      creatorID: userID,
      deliveryAddressLine1: '',
      deliveryAddressLine2: '',
      deliveryCity: '',
      deliveryCountry: '',
      deliveryName: '',
      deliveryPostalCode: '',
      deliveryState: '',
      deliveryTrackingInfo: '',
      invoiceLink: '',
      jobApplicationFee: 0,
      jobStartedDate: new Date(),
      jobEndedDate: new Date(),
      jobPrice: 0,
      jobStatus: 'open',
    };
    await dispatch(applyJobAction(job));
    closeConfirmModal();
  }

  function openConfirmModal() {
    const {
      deliveryAddressLine1,
      deliveryCity,
      deliveryCountry,
      deliveryPostalCode,
      deliveryState,
    } = userProfile;

    if (
      !deliveryAddressLine1 ||
      !deliveryCity ||
      !deliveryCountry ||
      !deliveryPostalCode ||
      !deliveryState
    ) {
      toast.info(
        t('You should complete your deliver address before applying for a job')
      );
      return;
    }

    setVisibility(true);
  }

  function closeConfirmModal() {
    setVisibility(false);
  }

  return {
    safety,
    campaign,
    fetching,
    error,
    pending,
    visibility,
    openConfirmModal,
    closeConfirmModal,
    applyJob,
  };
}
