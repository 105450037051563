import { ReactElement } from 'react';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { FolderIcon } from '@heroicons/react/20/solid';

import TableRow from 'components/table/TableRow';
import TableBody from 'components/table/TableBody';
import TableCell from 'components/table/TableCell';
import TableHeader from 'components/table/TableHeader';
import TableWrapper from 'components/table/TableWrapper';
import AmplifyMedia from 'views/components/media/StorageImageRenderer';
import { useSelector } from 'react-redux';
import { RootState } from 'redux/store';

interface IJobDataAsDBRecord extends IJobData {
  createdAt: string;
  updatedAt: string;
}

export default function JobTable({ jobs }: { jobs: IJobData[] }): ReactElement {
  const { t } = useTranslation('translation', {
    keyPrefix: 'creator.job',
  });

  const role = useSelector((root: RootState) => root.user.profile.role);
  const isAdmin = role === 'ADMIN';

  const navigate = useNavigate();
  const headerArray = [
    t('Product'),
    t('Content type'),
    t('Job info'),
    t('Created at'),
  ];

  const adminHeaderArray = [
    'Product',
    'Buyer',
    'Creator',
    'Content type',
    'Status',
    'Created at',
  ];

  const navigateJobDetail = (jobID: string, campaignID: string) => {
    console.log('navigating...', role);
    if (role === 'ADMIN') {
      navigate(`/admin/job/${jobID}`);
    } else {
      navigate(`/influencer/campaigns/${campaignID}/job-detail/${jobID}`);
    }
  };

  if (jobs.length === 0) {
    return (
      <div className='h-[300px] w-full flex flex-col gap-y-2 justify-center items-center pb-10'>
        <FolderIcon className='text-white w-8 h-8' />
        {t('No jobs')}
      </div>
    );
  }
  return (
    <TableWrapper>
      <TableHeader headerArray={isAdmin ? adminHeaderArray : headerArray} />
      <TableBody>
        {(jobs as IJobDataAsDBRecord[]).map((job) => (
          <TableRow
            key={job.id}
            onClick={() => navigateJobDetail(job.id, job.campaignID)}
          >
            <TableCell>
              <div className='flex gap-3 items-center'>
                <AmplifyMedia
                  sourceKey={job.Product?.productImage}
                  className='w-14 h-14 rounded-lg'
                  width={56}
                  height={56}
                />
                <div className='flex flex-col justify-between'>
                  <span>{job.Product?.productName}</span>
                  <span>{job.Brand?.brandName}</span>
                </div>
              </div>
            </TableCell>
            {isAdmin && (
              <>
                <TableCell>
                  <span className='capitalize'>
                    {job.Buyer?.firstName + ' ' + job.Buyer?.lastName}
                  </span>
                </TableCell>
                <TableCell>
                  <span className='capitalize'>
                    {job.Creator?.firstName + ' ' + job.Creator?.lastName}
                  </span>
                </TableCell>
              </>
            )}
            <TableCell>
              <div className='flex flex-col'>
                <span className='capitalize'>
                  {t(job.Campaign?.contentType ?? '')}
                </span>
                <span>
                  {(() => {
                    const format = job.Campaign?.contentFormat;
                    const aspects: Record<string, string> = {
                      any: t('Any'),
                      portrait: '3:4',
                      landscape: '16:9',
                      square: '1:1',
                    };
                    return format ? aspects[format] : '';
                  })()}{' '}
                  {t('aspect ratio')}
                </span>
              </div>
            </TableCell>
            <TableCell>
              <span className='capitalize'>{job.jobStatus}</span>
            </TableCell>
            <TableCell>
              {new Date(job.createdAt).toLocaleDateString()}{' '}
              {new Date(job.createdAt).toLocaleTimeString()}
            </TableCell>
          </TableRow>
        ))}
      </TableBody>
    </TableWrapper>
  );
}
