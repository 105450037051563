import { ReactElement } from 'react';
import { MagnifyingGlassCircleIcon } from '@heroicons/react/24/solid';

import DebouncedInputBox from 'components/form/InputBox.Debounce';

import { useTranslation } from 'react-i18next';

export default function SearchInput(props: ISearchInput): ReactElement {
  const { t } = useTranslation('translation', { keyPrefix: 'brand.campaigns' });

  return (
    <div className={props.className ?? ''}>
      <DebouncedInputBox
        onQueryChange={props.onQueryChange}
        icon={
          <MagnifyingGlassCircleIcon className='w-9 h-9 text-gray-400 dark:text-white' />
        }
        addClassName='w-full !rounded-full'
        placeholder={t(props.placeholder ?? 'Search...') ?? ''}
      />
    </div>
  );
}
