import { useTranslation } from 'react-i18next';

import Card from 'components/card';
import { SkeletonBox } from 'components/skeleton';
import AmplifyMedia from 'views/components/media/StorageImageRenderer';
import { ArrowTopRightOnSquareIcon } from '@heroicons/react/24/outline';

type PropsType = {
  fetching: boolean;
  product?: IProductData;
  campaign?: ICampaignData;
};

export default function ProductInfoCard({
  fetching,
  product,
  campaign,
}: PropsType) {
  const { t } = useTranslation('translation', {
    keyPrefix: 'creator.marketplace',
  });

  return (
    <Card addClassName='p-3 md:p-8 mt-10 lg:min-w-[700px] !w-fit mx-auto'>
      <div className='flex flex-row items-center md:items-stretch gap-x-4 md:gap-x-10'>
        <div className='lg:min-w-[250px] w-[120px] md:w-[160px] lg:w-[250px] aspect-square rounded-lg overflow-hidden'>
          {(() => {
            if (fetching) {
              return <SkeletonBox className='w-full h-full' darkMode />;
            }

            return (
              <AmplifyMedia
                className='w-full h-full object-cover'
                sourceKey={product?.productImage}
              />
            );
          })()}
        </div>

        <div className='flex flex-col justify-between md:py-2'>
          <div className='flex w-full justify-between mb-4 md:mb-0'>
            <div>
              {fetching ? (
                <SkeletonBox className='w-32 h-5 my-1' darkMode />
              ) : (
                <h5 className='max-sm:text-sm font-semibold'>
                  {product?.productName}
                </h5>
              )}

              <div className='flex items-center gap-2 font-medium max-sm:text-sm'>
                {(() => {
                  if (fetching) {
                    return (
                      <>
                        <SkeletonBox className='w-12 h-4 my-1' darkMode />
                        <SkeletonBox className='w-[112px] h-4 my-1' darkMode />
                      </>
                    );
                  }

                  return (
                    <>
                      ${product?.productPrice}{' '}
                      <a
                        rel="noreferrer"
                        target="_blank"                      
                        href={product?.productExternalLink ?? '#'}
                        className='flex gap-2 text-xs sm:text-sm items-end sm:items-center text-blue-600 dark:text-blue-300'
                      >
                        {t('Product link')}
                        <ArrowTopRightOnSquareIcon className='w-4 h-4' />
                      </a>
                    </>
                  );
                })()}
              </div>
              <div className='mt-2 md:mt-6 text-xs sm:text-sm'>
                {fetching ? (
                  <>
                    <SkeletonBox
                      className='w-[100px] h-[14px] my-[2px]'
                      darkMode
                    />
                    <SkeletonBox className='w-[160px] h-4' darkMode />
                  </>
                ) : (
                  <>
                    <span className='font-medium capitalize'>
                      {t(product?.productType?.toLowerCase() ?? '')}{' '}
                      {t('product')}
                    </span>
                    <span className='block text-xs text-gray-500'>
                      {t(campaign?.deliveryType ?? '')}
                    </span>
                  </>
                )}
              </div>
            </div>
            {/* <div className='flex flex-col items-end'>
              <span className='text-sm font-medium'>{t('Earnings')}</span>
              {fetching ? (
                <SkeletonBox className='h-6 my-1 w-16' darkMode />
              ) : (
                <span className='text-xl font-semibold'>$100</span>
              )}
            </div> */}
          </div>
        </div>
      </div>
    </Card>
  );
}
