import { ChangeEvent, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { updateUserProfileAction } from "redux/common_slices/userSlice";
import { AppDispatch, RootState } from "redux/store";

import useUpload from "hooks/common/useUpload";
import { toast } from "react-toastify";

export default function useEditCreatorProfile(
  editing: IProfileEdit,
  setEditing: (val: IProfileEdit) => void,
) {
  const dispatch = useDispatch<AppDispatch>();

  const user = useSelector((root: RootState) => root.user.profile);
  const { isUploading, progress, uploadToS3Bucket } = useUpload();
  const pending = useSelector((root: RootState) => root.user.pending);

  const [editedUser, setEditedUser] = useState<IUserProfile>(user);
  const [profileImageFile, setProfileImageFile] = useState<File>();
  const [previewImage, setPreviewImage] = useState<string | null>(user.profile_picture);
  const [isEditing, setIsEditing] = useState<boolean>(false);

  const toEditMode = (mode: TEditSection) => {
    if (editing.editing) {
      toast.warning("Please save your changes first.");
      return;
    }
    setEditing({ editing: true, section: mode });
    setIsEditing(true);
  };

  const editUserHandler = (e: ChangeEvent<HTMLInputElement>) => {
    var { name, value } = e.target;
    setEditedUser({ ...editedUser, [name]: value });
  };

  const editUserGenderHandler = (value: "Female" | "Male" | null) => {
    setEditedUser({ ...editedUser, gender: value });
  };

  const cancelEditHandler = () => {
    setIsEditing(false);
    setEditedUser(user);
    // update global state in the page level
    setEditing({ editing: false, section: null });
    setPreviewImage(null);
  };

  const profileImageFileHandler = (imageFile: File) => {
    setProfileImageFile(imageFile);
    setPreviewImage(URL.createObjectURL(imageFile));
  };

  const updateUserProfileHandler = async () => {
    const userProfile = { ...editedUser };

    [
      "UserReviews",
      "Notifications",
      "Campaigns",
      "Brands",
      "Products",
      "CreatorJobs",
      "BuyerJobs",
      "PortfolioContents",
      "CreatorJobContents",
      "BuyerJobContents",
    ].forEach((keyName) => {
      if (Object.hasOwn(userProfile, keyName)) {
        // @ts-ignore
        delete userProfile[keyName];
      }
    });

    if (profileImageFile) {
      const keyName = await uploadToS3Bucket({
        noFileMessage: "",
        file: profileImageFile,
      });
      try {
        await dispatch(
          updateUserProfileAction({
            ...userProfile,
            profile_picture: keyName || null,
          }),
        );
        setIsEditing(false);
        setPreviewImage(null);
        setProfileImageFile(undefined);
      } catch (e) {}

      return;
    }
    try {
      await dispatch(updateUserProfileAction(userProfile));
      setIsEditing(false);
      // update global state for editing
      setEditing({ editing: false, section: null });
      setPreviewImage(null);
    } catch (e) {
      // catch error in redux
    }
  };

  const isPersonalInfoUpdateEnabled = editedUser.firstName !== "" && editedUser.lastName !== "";

  return {
    isUploading,
    progress,
    disabledForPersonalInfo: !isPersonalInfoUpdateEnabled,
    user,
    pending,
    editedUser,
    isEditing,
    previewImage,
    editUserHandler,
    profileImageFileHandler,
    editUserGenderHandler,
    cancelEditHandler,
    toEditMode,
    updateUserProfileHandler,
  };
}
