import { ReactElement } from 'react';

import Card from 'components/card';
import CardHr from 'components/card/CardHr';

import SearchInput from 'views/brand/_components/Input.SearchBox';
import BrandComboBox from 'views/components/product_brand/Combo.Brand';
import CampaignTable from './Table.Campaign';

import useFilteredCampaign from 'hooks/brand/useFilteredCampaign';

export default function CampaignTableCard({
  view,
}: ICampaignTableCard): ReactElement {
  const { brands, campaigns, selectFilterBrand, queryChangeHandler } =
    useFilteredCampaign(view);

  return (
    <Card addClassName='py-8 px-8'>
      <div className='flex flex-col md:flex-row justify-between gap-4 md:gap-0'>
        <SearchInput
          onQueryChange={queryChangeHandler}
          className='w-full md:w-[280px]'
        />
        <div>
          <BrandComboBox onBrandChanage={selectFilterBrand} />
        </div>
      </div>
      <CardHr />
      <div className='overflow-x-auto pb-1'>
        <CampaignTable
          brands={brands}
          campaigns={campaigns as ICampaignDataResponse[]}
          tableInfo={''}
        />
      </div>
    </Card>
  );
}
