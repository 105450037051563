import { ReactElement, ReactNode } from 'react';
import { useSelector } from 'react-redux';
import { RootState } from 'redux/store';

// navbars
import BrandNav from 'views/brand/_components/BrandNav';
import MobileSidebar from 'views/components/MobileSidebar';
import Notification from 'views/components/notification';
import InfluencerNav from 'views/influencer/_components/InfluencerNav';

// footers
import Footer from './Footer';
import AdminNav from 'views/admin/_components/AdminNav';

export default function AuthLayout(props: IAuthLayout): ReactElement {
  const showBackdrop = useSelector(
    (root: RootState) =>
      root.user.showNotification || root.user.showMobileSidebar
  );

  const navBars: Record<IAuthLayout['userType'], ReactNode> = {
    ADMIN: (
      <AdminNav
        userProfilePicture={props.userProfilePicture}
        auth={props.auth}
        path={props.path}
      />
    ),
    BRAND: (
      <BrandNav
        userProfilePicture={props.userProfilePicture}
        auth={props.auth}
        path={props.path}
      />
    ),
    INFLUENCER: (
      <InfluencerNav
        userProfilePicture={props.userProfilePicture}
        auth={props.auth}
        path={props.path}
      />
    ),
  };

  return (
    <>
      {navBars[props.userType]}

      <main
        className={`${
          props.auth
            ? 'min-h-[calc(100vh-211px)]'
            : 'md:min-h-[calc(100vh-128px)]'
        } overflow-y-scroll bg-gray-50 dark:bg-dark-theme-900`}
      >
        {props.children}
      </main>

      {showBackdrop && (
        <div className='fixed inset-0 w-screen h-screen backdrop-blur-sm' />
      )}

      <Notification />

      <MobileSidebar />

      <Footer />
    </>
  );
}
