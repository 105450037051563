import { ReactElement } from 'react';

export default function CardHr(props: {
  marginClassName?: string;
}): ReactElement {
  return (
    <hr
      className={`border-gray-200 dark:border-dark-theme-900/50 ${
        props.marginClassName ?? 'my-4 md:my-8'
      } `}
    />
  );
}
