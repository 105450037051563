import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { toast } from 'react-toastify';

import { getAllJobs } from 'apis/common/job';
import { applyJob, getAllFilteredPost } from 'apis/influencer/creator';

import { logoutAction } from 'redux/common_slices/authSlice';

const initialState: ICreatorState = {
  error: null,
  pending: false,
  posts: [],
  jobs: [],
};

export const getAllFilteredPostAction = createAsyncThunk(
  'creator/campaign/all/filter',
  async (query: string) => {
    const result = await getAllFilteredPost(query);
    return result;
  }
);

export const getAllJobForCreatorAction = createAsyncThunk(
  'creator/job/all',
  async (userID: string) => {
    const result = await getAllJobs('creator', userID);
    return result;
  }
);

export const applyJobAction = createAsyncThunk(
  'creator/campaign/apply',
  async (jobData: IJobData) => {
    const result = await applyJob(jobData);
    return result;
  }
);

export const creatorSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(applyJobAction.pending, (state: ICreatorState, action) => {
        if (!state.pending) {
          state.pending = true;
          state.currentRequestId = action.meta.requestId;
        }
      })
      .addCase(applyJobAction.fulfilled, (state: ICreatorState, action) => {
        const { requestId } = action.meta;
        if (state.pending && state.currentRequestId === requestId) {
          state.pending = false;
          toast.success('Successfully applied.');
          state.currentRequestId = undefined;
        }
        return state;
      })
      .addCase(applyJobAction.rejected, (state: ICreatorState, action) => {
        const { requestId } = action.meta;
        if (state.pending && state.currentRequestId === requestId) {
          state.pending = false;
          state.error = action.error;
          state.currentRequestId = undefined;
        }
        return state;
      });

    builder
      .addCase(
        getAllFilteredPostAction.pending,
        (state: ICreatorState, action) => {
          state.pending = true;
        }
      )
      .addCase(
        getAllFilteredPostAction.fulfilled,
        (state: ICreatorState, action: any) => {
          state.pending = false;
          state.posts = action.payload.data.listCampaigns.items;
          return state;
        }
      )
      .addCase(
        getAllFilteredPostAction.rejected,
        (state: ICreatorState, action) => {
          state.pending = false;
          state.error = action.error;
          return state;
        }
      );

    builder
      .addCase(
        getAllJobForCreatorAction.pending,
        (state: ICreatorState, action) => {
          if (!state.pending) {
            state.pending = true;
            state.currentRequestId = action.meta.requestId;
          }
        }
      )
      .addCase(
        getAllJobForCreatorAction.fulfilled,
        (state: ICreatorState, action: any) => {
          const { requestId } = action.meta;
          if (state.pending && state.currentRequestId === requestId) {
            state.pending = false;
            state.jobs = action.payload.data.jobsByCreatorID.items;
            state.currentRequestId = undefined;
          }
          return state;
        }
      )
      .addCase(
        getAllJobForCreatorAction.rejected,
        (state: ICreatorState, action) => {
          const { requestId } = action.meta;
          if (state.pending && state.currentRequestId === requestId) {
            state.pending = false;
            state.error = action.error;
            state.currentRequestId = undefined;
          }
          return state;
        }
      );

    builder.addCase(logoutAction.fulfilled, (state: ICreatorState) => {
      state = initialState;
      return state;
    });
  },
});

// export const {} = creatorSlice.actions;

export default creatorSlice.reducer;
