import { ReactElement } from 'react';
import { NavLink } from 'react-router-dom';

export default function DecorationNavlink(
  props: IDecorationNavlink
): ReactElement {
  if (props.external) {
    return (
      <a
        href={props.to}
        target='_blank'
        rel='noreferrer noopener'
        className='decoration-gray-600 decoration-solid underline decoration-from-font hover:no-underline dark:decoration-gray-100'
      >
        {props.children}
      </a>
    );
  }
  return (
    <NavLink
      to={props.to}
      target={props.target}
      className='decoration-gray-600 decoration-solid underline decoration-from-font hover:no-underline dark:decoration-gray-100'
    >
      {props.children}
    </NavLink>
  );
}
