const dashboard = {
  Dashboard: 'Dashboard',

  // account info cards
  'My campaigns': 'My campaigns',
  'My products': 'My products',
  'Create campaign': 'Create campaign',
  'Current jobs': 'Current jobs',
  'My jobs': 'My jobs',

  // job table
  'Jobs Awaiting Approval': 'Jobs Awaiting Approval',
};

export default dashboard;
