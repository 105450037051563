import { ReactElement } from 'react';
import { useTranslation } from 'react-i18next';
import { NavLink } from 'react-router-dom';

import menu from 'utils/constants/brand_menu';

export default function BrandNavMenu(props: { children?: any }): ReactElement {
  const { t } = useTranslation('translation', {
    keyPrefix: 'brand.navbars.Menu',
  });

  return (
    <ul className='flex items-center gap-1 text-sm'>
      {props.children}
      {menu.map((elem: IMenuData) => (
        <li
          key={elem.path}
          className='hidden lg:block capitalize first-of-type:ml-5'
        >
          <NavLink
            className={({ isActive }) =>
              `${
                isActive
                  ? 'text-black dark:text-white bg-dark/10 dark:bg-[rgb(12,12,12)]'
                  : 'text-dark/70 dark:text-white/80'
              } py-3 px-5 rounded-3xl block hover:bg-dark/10 dark:hover:bg-[rgb(18,18,18)]`
            }
            to={elem.path}
          >
            {t(elem.text)}
          </NavLink>
        </li>
      ))}
    </ul>
  );
}
