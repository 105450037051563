const marketplace = {
  // first scene
  'Jobs you may be interested in': 'Jobs you may be interested in',
  digital: 'digital',
  physical: 'physical',
  'Product price': 'Product price',

  //campaign detail - /influencer/campaigns/:campaignID
  'Brand approval required': 'Brand approval required',
  "Apply for this job and we'll let you know if a brand chooses you to create the content":
    "Apply for this job and we'll let you know if a brand chooses you to create the content",
  'Product link': 'Product link',
  product: 'product',
  'Brand will ship directly': 'Brand will ship directly',
  'Creator will purchase': 'Creator will purchase',
  Earnings: 'Earnings',
  'Apply for this job': 'Apply for this job',

  'Job details': 'Job details',
  Brand: 'Brand',

  // content format
  any: 'any',
  portrait: 'portrait',
  landscape: 'landscape',
  square: 'square',
  'aspect ratio': 'aspect ratio',
  'All aspect ratio': 'All aspect ratio',

  'Content description': 'Content description',
  Description: 'Description',
  'No description': 'No description',
  'Are you sure?': 'Are you sure?',

  Cancel: 'Cancel',
  Apply: 'Apply',

  // job detail
  'Back to my jobs': 'Back to my jobs',
  'Someone invited you to a job, you can start by accepting the invitation.':
    'Someone invited you to a job, you can start by accepting the invitation.',

  Accept: 'Accept',
  Decline: 'Decline',

  'You have applied for this job, please wait until clients accept your application.':
    'You have applied for this job, please wait until clients accept your application.',

  'Delivery info': 'Delivery info',
  'Job info': 'Job info',
  Chat: 'Chat',
  Content: 'Content',

  // chat box
  'Chat with': 'Chat with',
  'Go to my jobs': 'Go to my jobs',
  Send: 'Send',

  // job contents
  'No contents': 'No contents',

  // Dropzone component
  'Choose a file or drag it here': 'Choose a file or drag it here',
  'Remove all': 'Remove all',
  'Upload all': 'Upload all',

  'Required content': 'Required content',

  'No messages': 'No messages',

  jobStatus: {
    all: 'all',
    invited: 'invited',
    open: 'open',
    'waiting-payment': 'waiting-payment',
    started: 'started',
    'product-sent': 'product-sent',
    'product-delivered': 'product-delivered',
    'content-uploaded': 'content-uploaded',
    completed: 'completed',
    rejected: 'rejected',
  },

  'Write message...': 'Write message...',
  'You can upload contents after job has started':
    'You can upload contents after job has started',

    Product: 'Product',  
    'Created at': 'Created at',  
};

export default marketplace;
