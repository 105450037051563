import { ReactElement, useState } from "react";
import { useTranslation } from "react-i18next";

import AuthedHeading from "components/typhography/AuthedHeading";

import AccountProfile from "views/influencer/settings/components/Account.Profile";
import AccountCredentials from "views/influencer/settings/components/Account.Accounts";

export default function BrandAccountSetting(): ReactElement {
  const { t } = useTranslation("translation", {
    keyPrefix: "brand.settings.Account",
  });

  const [profileEditing, setProfileEditing] = useState<IProfileEdit>({
    editing: false,
    section: null,
  });

  return (
    <>
      <AuthedHeading addClassName="mt-0">{t("Account")}</AuthedHeading>

      <AccountProfile editing={profileEditing} setEditing={setProfileEditing} />

      <AccountCredentials editing={profileEditing} setEditing={setProfileEditing} />
    </>
  );
}
