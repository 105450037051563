import { useSelector } from 'react-redux';
import { RootState } from 'redux/store';

export default function useMyDataCount() {
  const products = useSelector((root: RootState) => root.product.products);
  const campaigns = useSelector((root: RootState) => root.campaign.campaigns);
  const jobs = useSelector((root: RootState) => root.job.jobs);

  return {
    productLength: products.length,
    campaignLength: campaigns.length,
    jobLength: jobs.length,
  };
}
