import { useState } from 'react';
import { useSelector } from 'react-redux';
import { RootState } from 'redux/store';

export default function useFilteredBrand() {
  const [query, setQuery] = useState<string>('');
  const brands = useSelector((root: RootState) => root.brand.brands).filter(
    (brand) =>
      !!query
        ? brand.brandName.toLowerCase().includes(query.toLowerCase())
        : true
  );

  return { query, setQuery, brands };
}
