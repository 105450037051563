import { ReactElement, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector, useDispatch } from "react-redux";
import { AppDispatch, RootState } from "redux/store";

import Card from "components/card/Card";
import CardTextLabel from "components/card/CardLabelText";
import AuthedHeading from "components/typhography/AuthedHeading";
import InputBox from "components/form/InputBox";
import Button from "components/button/Button";
import CardText from "components/card/CardText";
import { updateUserProfileAction } from "redux/common_slices/userSlice";

export default function PaymentMethodSetting(): ReactElement {
  const { t } = useTranslation("translation", {
    keyPrefix: "creator.setting.Payment",
  });

  const dispatch = useDispatch<AppDispatch>();
  const profile = useSelector((root: RootState) => root.user.profile);

  const [paypalName, setPaypalName] = useState<string>(profile.paypalUserName ?? "");

  const [pending, setPending] = useState<boolean>(false);
  const [editMode, setEditMode] = useState<boolean>(false);

  const savePaypalUserNameHandler = async () => {
    setPending(true);
    const userProfile = { ...profile };
    [
      "UserReviews",
      "Notifications",
      "Campaigns",
      "Brands",
      "Products",
      "CreatorJobs",
      "BuyerJobs",
      "PortfolioContents",
      "CreatorJobContents",
      "BuyerJobContents",
    ].forEach((keyName) => {
      if (Object.hasOwn(userProfile, keyName)) {
        delete (userProfile as any)[keyName];
      }
    });

    await dispatch(updateUserProfileAction({ ...userProfile, paypalUserName: paypalName }));
    setPending(false);
    setEditMode(false);
  };

  return (
    <>
      <AuthedHeading>{t("Payout method")}</AuthedHeading>
      <Card addClassName="p-4 md:p-8 mt-8">
        <CardTextLabel>
          {t("Your payouts will be sent directly to your PayPal account.")}
        </CardTextLabel>
        <div className="flex flex-col gap-y-4 mt-10">
          <CardTextLabel>{t("Paypal Username:")}</CardTextLabel>
          {!editMode && (
            <div className="flex justify-between items-center">
              <CardText>{profile.paypalUserName ?? "No username"}</CardText>
              <button className="text-sm" onClick={() => setEditMode(!editMode)}>
                {t("Edit")}
              </button>
            </div>
          )}

          {editMode && (
            <>
              <InputBox
                placeholder="Paypal username"
                className="h-10"
                value={paypalName}
                onChange={(e) => setPaypalName(e.target.value)}
              />
              <div className="mt-5 sm:ml-auto w-full sm:w-fit flex max-sm:flex-col gap-x-10 gap-y-4">
                <Button
                  variant="outline"
                  className="w-full sm:w-[200px] h-10"
                  onClick={() => {
                    setEditMode(false);
                    setPaypalName("");
                  }}
                >
                  {t("Cancel")}
                </Button>
                <Button
                  pending={pending}
                  className="w-full sm:w-[200px] h-10"
                  onClick={savePaypalUserNameHandler}
                >
                  {t("Save")}
                </Button>
              </div>
            </>
          )}
        </div>
      </Card>
    </>
  );
}
