import { ReactElement } from 'react';
import { Dialog } from '@headlessui/react';
import { useTranslation } from 'react-i18next';

import Button from 'components/button';
import InputBox from 'components/form/InputBox';
import ModalCloseButton from 'components/button/Button.ModalClose';

import { CampaignFormContainer } from './Container.CreateCampaign';

import useEditBrandInfo from 'hooks/brand/useEditBrandInfo';

import ImageUploadBox from 'views/components/upload/ImageUpload';

export default function BrandModal(props: IBrandModalPanel): ReactElement {
  const { t } = useTranslation('translation', { keyPrefix: 'brand.brands' });

  const {
    disabled,
    pending,
    imageURL,
    brandData,
    isUploading,
    progress,
    submitHandler,
    inputChangeHandler,
    handleImageFileChange,
  } = useEditBrandInfo(props);

  return (
    <Dialog.Panel className='w-[75vw] sm:w-[500px] md:w-[700px] transform overflow-hidden rounded-xl bg-white dark:bg-dark-theme-200 shadow-card transition-all'>
      <Dialog.Title
        as='h3'
        className='text-lg font-medium leading-6 text-gray-900 dark:text-gray-100 mx-5'
      >
        <span className='flex justify-between items-center py-5 border-b-gray-300 dark:border-b-dark-theme-900/70 border-b-[1px]'>
          {props.modalType === 'CREATE' ? t('Create brand') : t('Edit brand')}

          <ModalCloseButton onClick={props.closeModal} />
        </span>
      </Dialog.Title>
      <div className='modal-body p-5 pb-0 '>
        <form onSubmit={submitHandler} noValidate autoComplete='off'>
          <div className='flex flex-col items-center md:flex-row md:items-start md:gap-6'>
            <ImageUploadBox
              pending={isUploading}
              imageURL={imageURL}
              progress={{ total: progress.total, loaded: progress.loaded }}
              onImageChange={handleImageFileChange}
            />
            <div className='w-full flex-auto flex flex-col justify-around'>
              <CampaignFormContainer
                addClassName='first:md:pt-0'
                labelText={t('Brand name')}
                inputElement={
                  <InputBox
                    value={brandData.brandName}
                    name='brandName'
                    onChange={inputChangeHandler}
                    placeholder='Brand name'
                  />
                }
              />
              <CampaignFormContainer
                labelText={t('Website (optional)')}
                inputElement={
                  <InputBox
                    value={brandData.externalLink}
                    name='externalLink'
                    onChange={inputChangeHandler}
                    placeholder='Website link'
                  />
                }
              />
            </div>
          </div>

          <div className='modal-footer sticky border-t-[1px] border-t-gray-300 dark:border-t-dark-theme-900/70 z-10 mt-5 flex pt-5 bottom-0 pb-5'>
            <div className='grid md:grid-cols-2 w-full gap-5'>
              <Button
                variant='outline'
                addClassName='md:order-first order-last'
              >
                {t('Cancel')}
              </Button>
              <Button disabled={disabled} pending={pending || isUploading}>
                {props.modalType === 'CREATE'
                  ? t('Add brand')
                  : t('Save changes')}
              </Button>
            </div>
          </div>
        </form>
      </div>
    </Dialog.Panel>
  );
}
