import { ReactElement, ReactNode } from 'react';
import { NavLink } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import Button from 'components/button';

import useMyDataCount from 'hooks/brand/useMyDataCount';

function InfoCard({
  count,
  text,
  href,
}: {
  count: number;
  text: string;
  href?: string;
}): ReactElement {
  return (
    <div className='stat first:border-l-0 sm:border-l-[1px] border-l-gray-300 dark:border-l-dark-theme-900/80 first:pl-0 sm:pl-12 pr-12 text-center sm:text-left'>
      <span className='font-semibold text-gray-900 block dark:text-gray-100'>
        {count}
      </span>
      {href ? (
        <NavLink to={href} className='text-gray-500 text-sm dark:text-gray-300'>
          {text}
        </NavLink>
      ) : (
        <span className='text-gray-500 text-sm dark:text-gray-300'>{text}</span>
      )}
    </div>
  );
}

export default function BrandAccountInfo(props: {
  children?: ReactNode;
}): ReactElement {
  const { t } = useTranslation('translation', { keyPrefix: 'brand.dashboard' });

  const { campaignLength, productLength } = useMyDataCount();

  return (
    <div className='dashboard-account-info mt-6 p-8 bg-white dark:bg-dark-theme-200 rounded-lg shadow-card'>
      <div className='card-dashboard-stats flex flex-col lg:flex-row gap-y-8 justify-between items-center'>
        <div className='dashboard-stats flex justify-center flex-wrap gap-y-2'>
          <InfoCard
            count={campaignLength}
            text={t('My campaigns')}
            href='/brand/campaigns'
          />
          <InfoCard
            count={productLength}
            text={t('My products')}
            href='/brand/products'
          />
        </div>
        <div className='dashboard-stats-buttons flex'>
          {props.children}
          <NavLink
            to='/brand/campaign/create'
            className='btn btn-black btn-lg w-full'
          >
            <Button addClassName='px-5 font-medium min-w-fit'>
              {t('Create campaign')}
            </Button>
          </NavLink>
        </div>
      </div>
    </div>
  );
}
