import { ReactElement, useState } from 'react';
import { useTranslation } from 'react-i18next';

import InputBox from 'components/form/InputBox';
import Button from 'components/button';
import FormContainer from 'views/brand/auth/components/Wrapper.FormContainer';

import useCredentials from 'hooks/auth/useCredentials';

export default function SigninForm(props: IBrandAuthForm): ReactElement {
  const { t } = useTranslation('translation', { keyPrefix: 'auth' });

  const { credentials, errorMessages, hasError, handleInputChange } =
    useCredentials('SIGNIN');

  const [errorMsgShow, setErrorMesgShow] = useState<boolean>(false);

  const submitHandler = (e: any) => {
    e.preventDefault();
    if (hasError) {
      setErrorMesgShow(true);
      return;
    }
    props.onSubmit && !hasError && props.onSubmit(credentials);
  };

  const blurHandler = () => {
    errorMsgShow === false && setErrorMesgShow(true);
  };

  return (
    <form onSubmit={submitHandler} noValidate>
      <FormContainer
        labelText={t('Email address')}
        errorMessage={
          errorMessages.email?.show ? errorMessages.email.message : undefined
        }
      >
        <InputBox
          name='email'
          value={credentials.email}
          placeholder='email@domain.tld'
          autoComplete='username'
          onChange={handleInputChange}
          error={errorMessages.email?.show}
          onBlur={blurHandler}
          disabled={props.pending}
        />
      </FormContainer>
      <FormContainer
        labelText={t('Password')}
        errorMessage={
          errorMessages.password?.show
            ? errorMessages.password.message
            : undefined
        }
      >
        <InputBox
          name='password'
          value={credentials.password}
          type='password'
          autoComplete='current-password'
          placeholder={t('8 characters minimum') || ''}
          onChange={handleInputChange}
          error={errorMessages.password?.show}
          onBlur={blurHandler}
          disabled={props.pending}
        />
      </FormContainer>
      <Button
        disabled={hasError}
        pending={props.pending}
        className='w-full h-11 mt-10 font-semibold'
      >
        {t('Sign in')}
      </Button>
    </form>
  );
}
