import { ReactElement, ReactNode } from 'react';
import { useTranslation } from 'react-i18next';

function ContentWrapper({ children }: { children: ReactNode }) {
  return (
    <div className='content-orientation-item flex items-center'>
      <div className='relative flex items-center group'>{children}</div>
    </div>
  );
}

function getActiveClassName(active: boolean | undefined) {
  return `flex flex-col justify-center items-center rounded-xl ${
    active
      ? 'border-[2px] border-theme/80 bg-[#d1e1fa] dark:bg-[#1d345a]'
      : 'cursor-pointer border-[1px] border-gray-200 bg-white dark:bg-dark-theme-200 dark:text-gray-100'
  }`;
}

export function ContentFormatAny({ active }: IContentFormatItem): ReactElement {
  const { t } = useTranslation('translation', { keyPrefix: 'brand.campaigns' });

  return (
    <ContentWrapper>
      <div className={`z-10 w-[88px] h-[132px] ${getActiveClassName(active)}`}>
        <p className='font-medium'>{t('Any')}</p>
        <p className='font-medium'></p>
      </div>
      <div
        className={`shape shape-any absolute ml-3 h-[72px] w-[100px] z-0 ${getActiveClassName(
          active
        )}`}
      ></div>
    </ContentWrapper>
  );
}

export function ContentFormatPortrait({
  active,
}: IContentFormatItem): ReactElement {
  const { t } = useTranslation('translation', { keyPrefix: 'brand.campaigns' });

  return (
    <ContentWrapper>
      <div
        className={`shape z-10 w-[88px] h-[132px] ml-6 ${getActiveClassName(
          active
        )}`}
      >
        <p className='font-medium'>{t('Portrait')}</p>
        <p className='font-medium'>9:16</p>
      </div>
    </ContentWrapper>
  );
}

export function ContentFormatLandscape({
  active,
}: IContentFormatItem): ReactElement {
  const { t } = useTranslation('translation', { keyPrefix: 'brand.campaigns' });

  return (
    <ContentWrapper>
      <div className={`shape z-10 h-[88px] w-36 ${getActiveClassName(active)}`}>
        <p className='font-medium'>{t('Landscape')}</p>
        <p className='font-medium'>16:9</p>
      </div>
    </ContentWrapper>
  );
}

export function ContentFormatSquare({
  active,
}: IContentFormatItem): ReactElement {
  const { t } = useTranslation('translation', { keyPrefix: 'brand.campaigns' });

  return (
    <ContentWrapper>
      <div className={`shape z-10 h-24 w-24 ${getActiveClassName(active)}`}>
        <p className='font-medium'>{t('Square')}</p>
        <p className='font-medium'>1:1</p>
      </div>
    </ContentWrapper>
  );
}
