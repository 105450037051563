import { useState } from 'react';
import { NavLink } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import {
  ArrowLongLeftIcon,
  ArrowLongRightIcon,
} from '@heroicons/react/24/outline';

import UnauthedLayout from 'components/layout/UnauthedLayout';

export default function Terms() {
  


  return (
    <UnauthedLayout>
      <div className='2xl:container md:mx-auto' >
        <iframe title="terms" width="100%" height="3000px" src="https://docs.google.com/document/d/e/2PACX-1vRiVb1SbT9MywzZ5b7oa3fq2VWk6oLUEzFli_yO8wlBbWJDho_aA28JD0PJS1bNXidPAMFB4IZZ9xIp/pub?embedded=true"></iframe>
      </div>
    </UnauthedLayout>
  );
}
