import { MouseEvent, ReactElement } from "react";
import { PlayCircleIcon, XCircleIcon, XMarkIcon } from "@heroicons/react/20/solid";

import { ArrowDownTrayIcon, ArrowUpOnSquareIcon } from "@heroicons/react/24/outline";
import { useTranslation } from "react-i18next";

import useFileDropzone from "./useFileDropzone";

export default function FileDropaone({
  fileType,
  ...props
}: {
  pending?: boolean;
  blocked?: boolean;
  className?: string;
  fileType: Array<string>; // can be ['image'], ['video'], ['image', 'video']
  onFileUpload: (imageURLs: string[], files: File[]) => void;
}): ReactElement {
  const { t } = useTranslation("translation", {
    keyPrefix: "creator.marketplace",
  });

  const {
    isUploading,
    files,
    currentUpload,
    emptyFilesHandler,
    changeHandler,
    dragOverHandler,
    dropHandler,
    removeFileHandler,
    uploadAllHandler,
  } = useFileDropzone(fileType, props.onFileUpload);

  return (
    <label
      onDrop={dropHandler}
      onDragOver={dragOverHandler}
      className={`${
        props.className ?? "w-full h-[240px]"
      } relative border-[2px] rounded-lg border-theme dark:border-theme-variant border-dashed cursor-pointer flex flex-col justify-center gap-4 bg-white dark:bg-dark-theme-200`}
    >
      {files.length > 0 && (
        <div className="absolute top-4 left-4 flex flex-wrap gap-2">
          {files.map((file, index) => (
            <div
              key={"preview" + index}
              onClick={(e) => e.preventDefault()}
              className={`w-16 h-16 bg-dark-theme-900 rounded-lg relative group transition-all duration-150 ${
                currentUpload === index ? "animate-bounce" : ""
              }`}
            >
              {/* {file.type} */}
              {file.type.includes("image") && (
                <img
                  src={URL.createObjectURL(file)}
                  alt="preshow"
                  className="w-full h-full object-cover rounded-lg opacity-60 dark:opacity-40 group-hover:opacity-100"
                />
              )}
              {file.type.includes("video") && (
                <div className="w-full h-full relative">
                  <video
                    src={URL.createObjectURL(file)}
                    className="w-full h-full object-cover rounded-lg opacity-60 dark:opacity-40 group-hover:opacity-100"
                  />
                  <PlayCircleIcon className="bg-white dark:bg-dark-theme-900 rounded-full w-8 h-8 absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 opacity-60 dark:opacity-40 group-hover:opacity-100" />
                </div>
              )}
              <XCircleIcon
                onClick={(e: MouseEvent<SVGElement>) => {
                  e.preventDefault();
                  removeFileHandler(index);
                }}
                className="w-8 h-8 stroke-white absolute -top-2 -right-2 scale-0 group-hover:scale-100 transition-all duration-150 bg-theme-variant text-theme-variant dark:text-white dark:bg-dark-theme-200 rounded-full"
              />
            </div>
          ))}
        </div>
      )}

      {!isUploading && (
        <ArrowDownTrayIcon className="w-10 h-10 stroke-theme dark:stroke-theme-variant mx-auto" />
      )}

      <span className="mx-auto text-sm text-theme dark:text-theme-variant">
        {isUploading ? "Uploading..." : t("Choose a file or drag it here")}
      </span>
      {!isUploading && <input multiple hidden type={"file"} onChange={changeHandler} />}

      {/* Upload button when drag and drop files */}
      {!isUploading && files.length > 0 && (
        <div className="absolute bottom-4 right-4 flex gap-2">
          <button
            onClick={(e) => {
              e.preventDefault();
              emptyFilesHandler();
            }}
            className="py-2 rounded-full bg-gray-400 hover:bg-gray-600 hover:text-white dark:bg-dark-theme-900 dark:hover:bg-dark-theme-900/80 flex px-4 justify-between items-center text-sm font-medium transition-all duration-150"
          >
            {t("Remove all")}
            <XMarkIcon className="w-6 h-6" />
          </button>

          <button
            onClick={uploadAllHandler}
            className="py-2 rounded-full bg-gray-400 hover:bg-gray-600 hover:text-white dark:bg-dark-theme-900 dark:hover:bg-dark-theme-900/80 flex px-4 justify-between items-center text-sm font-medium transition-all duration-150"
          >
            {t("Upload all")}
            <ArrowUpOnSquareIcon className="w-6 h-6 -translate-y-[2px]" />
          </button>
        </div>
      )}
    </label>
  );
}
