import { ReactElement } from 'react';
import { NavLink } from 'react-router-dom';
import { Bars3Icon, BellAlertIcon } from '@heroicons/react/24/outline';
import { useDispatch } from 'react-redux';
import { AppDispatch, RootState } from 'redux/store';
import {
  setMobileSidebarVisibility,
  setNotificationSidebarVisibility,
} from 'redux/common_slices/userSlice';

import DarkModeButton from 'components/button/DarkModeButton';
import LocaleButton from 'components/button/LocaleButton';
import Logo from 'components/logo';

import AmplifyMedia from 'views/components/media/StorageImageRenderer';
import NavWrapper from 'views/components/navbar/NavWrapper';

import AccountSettings from './AccountSettings';
import InfluencerNavMenu from './InfluencerNavMenu';
import { useSelector } from 'react-redux';
import UnauthedMobileMenu from 'components/layout/UnauthedMobileMenu';
import { useTranslation } from 'react-i18next';

export default function InfluencerNav({
  auth,
  path,
  userProfilePicture,
}: ICreatorNav): ReactElement {
  const dispatch = useDispatch<AppDispatch>();
  const notifications = useSelector((root: RootState) =>
    root.user.notifications.filter((noti) => !noti.isRead)
  );

  const { t } = useTranslation('translation', {
    keyPrefix: 'creator.navbar',
  });

  const authPath =
    ['signup', 'signin'].includes(
      path.split('/')[path.split('/').length - 1]
    ) && !auth;

  return (
    <NavWrapper>
      {auth ? (
        <InfluencerNavMenu>
          <Logo />
        </InfluencerNavMenu>
      ) : (
        <Logo />
      )}
      <div className='flex items-center gap-2 md:gap-6'>
        <DarkModeButton />
        <LocaleButton />

        {auth && (
          <>
            <div
              onClick={() => {
                dispatch(setNotificationSidebarVisibility(true));
              }}
              className='p-2'
            >
              <div className='relative'>
                <BellAlertIcon className='cursor-pointer w-5 h-5' />
                {notifications.length > 0 && (
                  <div className='w-2 h-2 bg-error rounded-full absolute top-0 right-0'></div>
                )}
              </div>
            </div>

            {/* Account for desktop */}
            <AccountSettings
              buttonElem={
                <AmplifyMedia
                  className='w-8 h-8 !rounded-full'
                  height={64}
                  width={64}
                  isUserAvatar
                  sourceKey={userProfilePicture}
                />
              }
            />

            {/* Hamburger for mobile */}
            <div
              onClick={() => {
                dispatch(setMobileSidebarVisibility(true));
              }}
              className='lg:hidden p-2 cursor-pointer'
            >
              <Bars3Icon className='h-5 w-5' />
            </div>
          </>
        )}

        {authPath && (
          <>
            <NavLink
              to='/brand/signup'
              className='text-theme font-semibold text-sm max-sm:hidden'
            >
              {t('Register as an Brand')}
            </NavLink>
            <span className='text-gray-500 text-sm max-sm:hidden'>
              {t('Already have an account?')}
            </span>
            <NavLink
              to='/influencer/signin'
              className='font-semibold max-sm:hidden'
            >
              {t('Sign In')}
            </NavLink>

            {/* Mobile hamburger */}
            <UnauthedMobileMenu />
          </>
        )}
      </div>
    </NavWrapper>
  );
}
