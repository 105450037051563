import { ReactElement } from 'react';
import { Helmet } from 'react-helmet';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { SignupAction } from 'redux/common_slices/authSlice';
import { AppDispatch, RootState } from 'redux/store';

import ImageSidePanel from 'views/brand/auth/components/Image.SidePanel';
import DecorationNavlink from 'views/brand/auth/components/Link.Decoration';
import SignupForm from './components/Form.Signup';

export default function Signup(): ReactElement {
  const { t } = useTranslation('translation', { keyPrefix: 'auth' });

  const dispatch = useDispatch<AppDispatch>();
  const navigate = useNavigate();

  const auth = useSelector((root: RootState) => root.authentication);

  async function submitHandler(data: ICredentials) {
    dispatch(SignupAction({ credential: data, type: 'CREATOR', navigate }));
  }

  return (
    <>
      <Helmet>
        <title>Creator - Sign up</title>
      </Helmet>
      <main className='w-full flex-col relative'>
        <div className='w-full mx-auto sm:w-3/5 2xl:w-2/5 py-14 md:py-20'>
          <div className='flex flex-col lg:flex-row rounded-2xl overflow-hidden shadow-none md:shadow-card'>
            <ImageSidePanel />
            <div className='shadow-card md:shadow-none bg-white dark:bg-dark-theme-200 m-3 sm:m-0 p-4 md:p-6 sm:p-8 xl:p-10 lg:w-2/3 rounded-lg md:rounded-none'>
              <h1 className='text-2xl sm:text-3xl font-bold text-left mb-4 lg:mb-6'>
                <span>{t('Sign up')}</span>
                <span className='text-theme'> {t('as a Creator')}</span>
              </h1>
              <SignupForm pending={auth.pending} onSubmit={submitHandler} />
              <p className='text-sm md:text-base text-left text-gray-500 mt-6 dark:text-gray-200'>
                {'By signing up, you agree to the '}
                <DecorationNavlink target='_blank' to='/terms'>
                  Terms &amp; Conditions
                </DecorationNavlink>
                {' and '}
                <DecorationNavlink target='_blank' to='/privacy'>
                  Privacy Policy
                </DecorationNavlink>
              </p>
            </div>
          </div>
        </div>
      </main>
    </>
  );
}
