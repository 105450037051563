import i18n from 'locale/i18n';
import { useEffect, useMemo, useState, useTransition } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import { RootState } from 'redux/store';

export default function useProductBrand(currentBrandProp?: IValueWithIndex) {
  const campaign: ICampaignData = useSelector(
    (root: RootState) => root.campaign.newCampaign
  );

  const { t } = useTranslation('translation', { keyPrefix: 'brand.campaigns' });

  const brands = useSelector((root: RootState) => root.brand.brands);
  const products = useSelector((root: RootState) => root.product.products);

  const brandObjects: IValueWithIndex[] = [
    { index: 'NaN', value: 'All brands', displayValue: t('All brands') },
    ...brands.map((brand) => {
      return {
        index: brand.id,
        value: brand.brandName,
        displayValue: brand.brandName,
      };
    }),
  ];

  const [currentBrand, setCurrentBrand] = useState<IValueWithIndex>(
    currentBrandProp ?? brandObjects[0]
  );

  const productObjects: IValueWithIndex[] = useMemo(
    () => [
      { index: 'NaN', value: 'All products', displayValue: t('All products') },
      ...products
        .filter((product) => product.brandID === currentBrand.index)
        .map((product) => {
          return {
            index: product.id,
            value: product.productName,
            displayValue: product.productName,
          };
        }),
    ],
    [currentBrand.index, products]
  );

  const [currentProduct, setCurrentProduct] = useState<IValueWithIndex>(
    productObjects[0]
  );

  const handleSelectBrand = (elem: IValueWithIndex) => {
    setCurrentBrand(elem);
  };

  useEffect(() => {
    setCurrentProduct(productObjects[0]);
  }, [currentBrand, productObjects]);

  const handleSelectProduct = (elem: IValueWithIndex) => {
    setCurrentProduct(elem);
  };

  useEffect(() => {
    currentBrand.index === 'NaN' &&
      setCurrentBrand({ ...currentBrand, displayValue: t(currentBrand.value) });
  }, [i18n.language]);

  return {
    campaign,
    brandObjects,
    productObjects,
    currentBrand,
    currentProduct,
    handleSelectBrand,
    handleSelectProduct,
  };
}
