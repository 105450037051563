const settings = {
  sidebar: {
    'Member since': '成为会员从',
    Account: '账户',
    Wallet: '钱包',
    Billing: '账单',
    'Payment methods': '付款方式',
    Brands: '品牌',
    Notifications: '提醒',
    'Withdrawal method': '提款方式',
  },
  brand: {
    Brands: '品牌',
    'Add brand': '添加品牌',
    Brand: '品牌',
    Campaigns: '招募活动',
    'Submitted content': '提交过的内容',
    Products: '产品',
    Open: '可见',
    Hidden: '隐藏',
    Closed: '关闭的',
    Picture: '图片',
    Video: '视频',
    'Edit brand': '修改品牌',
  },
  Account: {
    Account: '账户',
  },
  Notification: {
    Notifications: '提醒',
    'Mark as read': '标记为已读',
  },
};

export default settings;
