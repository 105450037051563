import { ReactElement } from "react";
import { PencilSquareIcon } from "@heroicons/react/20/solid";
import { useTranslation } from "react-i18next";

import Card from "components/card";
import CardHr from "components/card/CardHr";
import Button from "components/button";
import CardText from "components/card/CardText";
import CardTitle from "components/card/CardTitle";
import InputBox from "components/form/InputBox";
import CardTextLabel from "components/card/CardLabelText";

import useEditCreatorProfile from "hooks/influencer/useEditCreatorProfile";

export default function AccountAddress({
  editing,
  setEditing,
}: {
  editing: IProfileEdit;
  setEditing: (value: IProfileEdit) => void;
}): ReactElement {
  const { t } = useTranslation("translation", {
    keyPrefix: "creator.setting.AddressPage",
  });

  const {
    user,
    pending,
    editedUser,
    isEditing,
    toEditMode,
    editUserHandler,
    cancelEditHandler,
    updateUserProfileHandler,
  } = useEditCreatorProfile(editing, setEditing);

  return (
    <Card addClassName="p-4 md:p-8 mt-8">
      <CardTitle title={t("Delivery Address")} />
      <CardHr />
      <div className={`${isEditing ? "hidden" : "block"} relative`}>
        <PencilSquareIcon
          onClick={() => toEditMode("ADDRESS")}
          className="cursor-pointer w-6 h-6 absolute top-0 right-2"
        />

        <CardTextLabel>{t("Country")}</CardTextLabel>
        <CardText addClassName="mb-2">{user.deliveryCountry ?? t("Not provided")}</CardText>
        <CardTextLabel>{t("Address line 1")}</CardTextLabel>
        <CardText addClassName="mb-2">{user.deliveryAddressLine1 ?? t("Not provided")}</CardText>
        <CardTextLabel>{t("City/Town")}</CardTextLabel>
        <CardText addClassName="mb-2">{user.deliveryCity ?? t("Not provided")}</CardText>
        <CardTextLabel>{t("State")}</CardTextLabel>
        <CardText addClassName="mb-2">{user.deliveryState ?? t("Not provided")}</CardText>
        <CardTextLabel>{t("Zip code")}</CardTextLabel>
        <CardText addClassName="mb-2">{user.deliveryPostalCode ?? t("Not provided")}</CardText>
      </div>

      <div className={`${!isEditing ? "hidden" : "block"}`}>
        <div className="grid grid-cols-1 sm:grid-cols-2 gap-x-5 gap-2">
          <div className="flex flex-col gap-2">
            <CardTextLabel>{t("Country")}</CardTextLabel>
            <InputBox
              name="deliveryCountry"
              value={editedUser.deliveryCountry ?? ""}
              placeholder="Country"
              onChange={editUserHandler}
            />
          </div>
          <div className="flex flex-col gap-2">
            <CardTextLabel>{t("Address line 1")}</CardTextLabel>
            <InputBox
              name="deliveryAddressLine1"
              value={editedUser.deliveryAddressLine1 ?? ""}
              placeholder="Address line 1"
              onChange={editUserHandler}
            />
          </div>
          <div className="flex flex-col gap-2">
            <CardTextLabel>{t("City/Town")}</CardTextLabel>
            <InputBox
              name="deliveryCity"
              value={editedUser.deliveryCity ?? ""}
              placeholder="Delivery city"
              onChange={editUserHandler}
            />
          </div>
          <div className="flex flex-col gap-2">
            <CardTextLabel>{t("State")}</CardTextLabel>
            <InputBox
              name="deliveryState"
              value={editedUser.deliveryState ?? ""}
              placeholder="Delivery state"
              onChange={editUserHandler}
            />
          </div>
          <div className="flex flex-col gap-2">
            <CardTextLabel>{t("Zip code")}</CardTextLabel>
            <InputBox
              name="deliveryPostalCode"
              value={editedUser.deliveryPostalCode ?? ""}
              placeholder="Zipcode"
              onChange={editUserHandler}
            />
          </div>
        </div>
        <div className="mt-4 flex flex-col sm:flex-row gap-x-5 gap-y-2">
          <Button onClick={cancelEditHandler} variant="outline">
            {t("Cancel")}
          </Button>
          <Button pending={pending} onClick={updateUserProfileHandler}>
            {t("Save changes")}
          </Button>
        </div>
      </div>
    </Card>
  );
}
