import { UserIcon, StarIcon } from '@heroicons/react/20/solid';
import { ReactElement } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import AmplifyMedia from 'views/components/media/StorageImageRenderer';
import CustomButton from './Button.CustomInCard';

import helperGetAverateRating from 'helpers/rating';

import useInviteCreator from 'hooks/brand/useInviteCreator';
import { RootState } from 'redux/store';

export default function CreatorTable(props: {
  creators: IUserProfile[];
  campaign?: ICampaignData;
}): ReactElement {
  const { t } = useTranslation('translation', { keyPrefix: 'brand.campaigns' });
  const navigate = useNavigate();

  const jobs = useSelector((root: RootState) => root.job.jobs);

  const { inviteCreatorHandler } = useInviteCreator();

  return (
    <div className='grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 2xl:grid-cols-5 gap-4 2xl:gap-8 min-h-[300px]'>
      {props.creators.map((creator) => (
        <div
          key={creator.id}
          className='cursor-pointer w-full aspect-square rounded-lg bg-gray-200 dark:bg-dark-theme-900 text-[100px] font-bold relative group overflow-hidden'
        >
          {/* Creator profile picture*/}
          {!!creator.profile_picture ? (
            <AmplifyMedia
              sourceKey={creator.profile_picture}
              className='w-full h-full'
              isUserAvatar
            />
          ) : (
            <div className='w-full h-full flex items-center justify-center'>
              <UserIcon className='text-gray-500 dark:text-gray-500 w-3/5 h-3/5' />
            </div>
          )}

          {/* Creator name & rating */}
          <div className='text-sm absolute top-4 left-4'>
            <span className='transition-all duration-150 opacity-50 group-hover:opacity-100 capitalize'>{`${creator.firstName} ${creator.lastName}`}</span>
            <div className='flex font-medium gap-2 pt-1 opacity-0 group-hover:opacity-100 translate-x-8 group-hover:translate-x-0 transition-all duration-150'>
              {t('Reviews')}:
              <div className='flex items-center gap-1'>
                {(() => {
                  const avgRating = helperGetAverateRating(
                    creator.UserReviews?.items
                  );

                  return (
                    <>
                      <StarIcon
                        className={`w-5 h-5 text-yellow-500 ${
                          creator.UserReviews?.items.length !== 0 &&
                          avgRating === 0
                            ? 'opacity-20'
                            : ''
                        }`}
                      />
                      {avgRating.toFixed(1)}
                      <span className='text-gray-500'>
                        ({creator.UserReviews?.items.length})
                      </span>
                    </>
                  );
                })()}
              </div>
            </div>
          </div>

          {/* Invite button */}
          <div className='absolute opacity-0 group-hover:opacity-100 -bottom-6 group-hover:bottom-4 w-full flex flex-col gap-y-1 justify-center items-center transition-all duration-150'>
            {(() => {
              const activeCreator: boolean =
                jobs.filter(
                  (job) =>
                    job.creatorID === creator.id &&
                    job.campaignID === props.campaign?.id
                ).length > 0;
              return (
                <>
                  <CustomButton
                    onClick={() => navigate(`/brand/creator/${creator.id}`)}
                    disabled={activeCreator}
                  >
                    {t('View profile')}
                  </CustomButton>
                  <CustomButton
                    onClick={() =>
                      inviteCreatorHandler(creator, props.campaign)
                    }
                    disabled={activeCreator}
                  >
                    {activeCreator ? t('In progress') : t('Invite')}
                  </CustomButton>
                </>
              );
            })()}
          </div>
        </div>
      ))}
    </div>
  );
}
