import { ReactElement, ReactNode } from 'react';

export default function TableWrapper(props: {
  children?: ReactNode;
  addClassName?: string;
}): ReactElement {
  return (
    <table className={`w-full ${props.addClassName ?? ''}`}>
      {props.children}
    </table>
  );
}
