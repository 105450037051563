import { ReactElement } from 'react';

export default function FormContainer(props: ILabelWrapper): ReactElement {
  return (
    <div className='label-wrapper py-3 relative'>
      <label
        htmlFor={props.label}
        className='mb-2 block text-base font-medium text-theme'
      >
        {props.labelText}
      </label>
      {props.children}
      {props.errorMessage && (
        <p
          role='alert'
          className={` ${
            props.staticErrorMsg ? '' : 'absolute top-[86px] left-0'
          } mt-2 text-xs text-error dark:text-error/70 min-h-[16px]`}
        >
          {props.errorMessage}
        </p>
      )}
    </div>
  );
}
