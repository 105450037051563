import { ReactElement } from 'react';

export default function TableHeader(props: ITableHeader): ReactElement {
  return (
    <thead className='bg-gray-50 first:border-b first:border-gray-200 dark:bg-dark-theme-900 dark:border-dark-theme-900'>
      <tr>
        {props.headerArray.map((header, index) => (
          <th
            key={header + index}
            className='text-sm font-semibold text-gray-600 p-4 text-left dark:text-gray-200'
          >
            {header}
          </th>
        ))}
      </tr>
    </thead>
  );
}
