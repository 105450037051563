import { ReactElement } from 'react';
import { Helmet } from 'react-helmet';
import { Trans } from 'react-i18next';

// common components
import FlatTab from 'components/tab';
import { SkeletonBox } from 'components/skeleton';

import ProductCampaignBar from './components/ProductCampaignBar';
import ProductDetailCard from './components/ProductDetailCard';
import ContentViewForProduct from './components/Card.ContentViewForProduct';
import BrandAuthedContainer from '../_components/Container.AuthedBrand';

import useFetchedProductDetail from 'hooks/brand/useFetchedProductDetail';

export default function BrandProducDetailView(): ReactElement {
  const { fetching, jobContents } = useFetchedProductDetail();

  return (
    <>
      <Helmet>
        <title>Brands - Products</title>
      </Helmet>
      <BrandAuthedContainer>
        <div className='px-3 md:px-4 xl:px-6 pt-6 w-full xl:w-[1008px] xl:mx-auto'>
          <ProductCampaignBar />
          <ProductDetailCard />
        </div>
        <div className='mt-4'>
          <FlatTab
            pending={fetching}
            pendingBody={<SkeletonBox className='w-full h-[300px]' />}
            tabs={[
              <Trans
                i18nKey='brand.contents.MyContent'
                values={{ count: jobContents.length }}
              />,
            ]}
            tabBarAddClassName='mb-6'
            views={[
              <ContentViewForProduct
                contents={jobContents}
                fetching={fetching}
              />,
            ]}
          />
        </div>
      </BrandAuthedContainer>
    </>
  );
}
