const navbars = {
  'Account Settings': {
    Account: 'Account',
    Wallet: 'Wallet',
    Billing: 'Billing',
    'Payment methods': 'Payment methods',
    Brands: 'Brands',
    'Log out': 'Log out',
  },
  Menu: {
    dashboard: 'dashboard',
    campaigns: 'campaigns',
    products: 'products',
    content: 'content',
  },
  'Register as an Influencer': 'Register as an Influencer',
  'Already have an account?': 'Already have an account?',
  'Sign In': 'Sign In',
};

export default navbars;
