const dashboard = {
  Dashboard: '控制面板',

  // account info cards
  'My campaigns': '我的招募活动列表 （每个招募活动中可以有多个任务）',
  'My products': '我的产品',
  'Create campaign': '创建招募活动',
  'Current jobs': '目前的任务',
  'My jobs': '我的任务列表',

  // job table
  'Jobs Awaiting Approval': '有任务等待您的确认',
};

export default dashboard;
