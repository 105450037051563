import { API } from 'aws-amplify';

import * as customQueries from 'graphql_custom/custom_queries';
import * as mutations from 'graphql/mutations';

export async function getJobContentsByJobID(jobID: string) {
  const response = (await API.graphql({
    query: customQueries.jobContentsByJobID,
    variables: { jobID },
  })) as any;

  return response;
}

export async function getJobContentsByCampaignID(campaignID: string) {
  const response: any = await API.graphql({
    query: customQueries.listJobContents,
    variables: {
      filter: {
        campaignID: {
          eq: campaignID,
        },
      },
    },
  });

  return response;
}

export async function getJobContentsByBuyerID(buyerID: string) {
  const response: any = await API.graphql({
    query: customQueries.jobContentsByBuyerID,
    variables: { buyerID },
  });

  return response;
}

export async function createJobContent({ id, ...data }: IJobContent) {
  const response = (await API.graphql({
    query: mutations.createJobContent,
    variables: { input: data },
  })) as any;

  return response;
}

// brand only update job contents

export async function updatejobContent(data: IJobContent) {
  const response: any = await API.graphql({
    query: mutations.updateJobContent,
    variables: { input: data },
  });

  return response;
}
