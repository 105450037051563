import { ReactElement } from 'react';
import { useTranslation } from 'react-i18next';

import useNewCampaignNavigation from 'hooks/brand/useNewCampaignNavigation';

export default function CreateCampaignProgressBar(): ReactElement {
  const { t } = useTranslation('translation', { keyPrefix: 'brand.campaigns' });

  const { pathname, disabled, navigateHandler } = useNewCampaignNavigation();

  const campaignCreationMenu = [
    {
      index: 'campaign_create',
      href: 'create',
      text: 'Campaign creation',
    },
    {
      index: 'campaign_create_product',
      href: 'product',
      text: 'Product information',
    },
    {
      index: 'campaign_create_content_setting',
      href: 'settings',
      text: 'Content setting',
    },
    {
      index: 'campaign_create_summary',
      href: 'summary',
      text: 'Summary',
    },
  ];

  return (
    <aside className='border-r border-gray-200 dark:border-dark-theme-200 xl:w-[264px] hidden xl:block'>
      <ul className='fixed mt-6 mx-6'>
        {campaignCreationMenu.map((menu) => {
          return (
            <li
              onClick={() => navigateHandler(menu.href)}
              key={menu.index}
              className={`py-1 text-sm ${disabled ? '' : 'hover:text-theme'} ${
                menu.href.includes(pathname) ? 'text-theme' : ''
              } transition-all duration-150 group cursor-pointer flex items-center gap-3`}
            >
              <span className='flex h-[10px] w-[10px]'>
                <span
                  className={`relative inline-flex rounded-full h-full w-full ${
                    menu.href.includes(pathname) ? 'bg-theme' : 'bg-theme/30'
                  } `}
                >
                  <span
                    className={`group-hover:animate-ping ${
                      menu.href.includes(pathname)
                        ? 'animate-ping bg-theme'
                        : 'bg-theme/30'
                    } absolute inline-flex h-full w-full rounded-full opacity-75`}
                  ></span>
                </span>
              </span>
              {t(menu.text)}
            </li>
          );
        })}
      </ul>
    </aside>
  );
}
