import { ReactElement } from 'react';
import { Helmet } from 'react-helmet';
import { NavLink } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { RootState } from 'redux/store';

import Card from 'components/card/Card';
import Button from 'components/button/Button';
import AuthedHeading from 'components/typhography/AuthedHeading';
import ReviewCard from '../components/Card.Review';

export default function CreatorReview(): ReactElement {
  const reviews = useSelector((root: RootState) => root.user.reviews);

  const { t } = useTranslation('translation', {
    keyPrefix: 'creator.setting.ReviewPage',
  });

  return (
    <>
      <Helmet>
        <title>Creator - Review</title>
      </Helmet>
      <AuthedHeading addClassName='mt-0'>{t('My Reviews')}</AuthedHeading>
      {reviews.length === 0 && (
        <Card addClassName='p-4 md:p-8 mt-8 flex flex-col items-center select-none'>
          <>
            <img
              src='/assets/images/creator/illu-review.png'
              alt='review'
              width={240}
              className='w-[240px] aspect-auto pointer-events-none'
            />
            <h3 className='font-semibold text-xl'>{t('No reviews')}</h3>
            <p className='text-sm text-gray-500'>
              {t("Looks like you don't have any reviews")}
            </p>
            <NavLink to='/influencer/marketplace' className='mt-6'>
              <Button variant='outline' className='px-5 h-10 font-medium'>
                {t('Go to marketplace')}
              </Button>
            </NavLink>
          </>
        </Card>
      )}
      {reviews.length > 0 && (
        <Card addClassName='p-4 md:p-8 mt-8'>
          <div className='flex flex-wrap gap-2'>
            {reviews.map((review) => (
              <ReviewCard key={review.id} {...review} />
            ))}
          </div>
        </Card>
      )}
    </>
  );
}
