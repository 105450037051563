import { ReactElement } from 'react';
import { useTranslation } from 'react-i18next';

import Card from 'components/card';
import CardTitle from 'components/card/CardTitle';
import NavigateButtonGroup from './components/Navigate.ButtonGroup';

import {
  ContentFormatAny,
  ContentFormatLandscape,
  ContentFormatPortrait,
  ContentFormatSquare,
} from './components/Item.ContentFormats';
import Textarea from 'components/form/Textarea/Textarea';

import useNewCampaign from 'hooks/brand/useNewCampaign';

export default function CampaignSettings(): ReactElement {
  const { t } = useTranslation('translation', { keyPrefix: 'brand.campaigns' });

  const { campaign, changeHandler } = useNewCampaign();

  return (
    <>
      <div className='min-h-[calc(100vh_-_150px)] px-3 md:px-4 xl:px-6 pb-10'>
        <h2 className='text-xl font-semibold text-gray-900 dark:text-gray-100 py-6'>
          {t('New campaign')}:{' '}
          <span className='sm:ml-1 sm:text-gray-800 dark:text-gray-200'>
            {campaign.campaignName}
          </span>
        </h2>
        <div className='flex flex-col space-y-2'>
          <Card addClassName='content-format space-y-2 p-8'>
            <CardTitle title={t('Content format')} />
            <div className='content-format-list flex flex-wrap justify-around gap-14'>
              <button onClick={() => changeHandler('contentFormat', 'any')}>
                <ContentFormatAny active={campaign.contentFormat === 'any'} />
              </button>
              <button
                onClick={() => changeHandler('contentFormat', 'portrait')}
              >
                <ContentFormatPortrait
                  active={campaign.contentFormat === 'portrait'}
                />
              </button>
              <button
                onClick={() => changeHandler('contentFormat', 'landscape')}
              >
                <ContentFormatLandscape
                  active={campaign.contentFormat === 'landscape'}
                />
              </button>
              <button onClick={() => changeHandler('contentFormat', 'square')}>
                <ContentFormatSquare
                  active={campaign.contentFormat === 'square'}
                />
              </button>
            </div>
          </Card>

          <Card addClassName='space-y-2 p-8'>
            <CardTitle title={t('Content description')} />
            <div className='!-mt-4'>
              <Textarea
                placeholder={t('Description') || ''}
                className='text-sm h-[160px]'
                value={campaign.description}
                maxLength={1200}
                onChange={(e) =>
                  changeHandler('description', e.target.value.slice(0, 1200))
                }
              />
              <p className='mb-4 text-sm font-normal text-gray-500 dark:text-gray-300'>
                {t('Characters')}: {campaign.description.length} / 1200
              </p>
            </div>
            <div className='text-sm font-normal text-gray-500 dark:text-gray-300'>
              <p>
                {t('Recommendations on what to mention in this description')}:
              </p>
              <ol className='list-decimal list-inside'>
                <li>
                  {t('Specify what exactly you want to see')}
                  {': '}
                  {t('face, hands, etc.')}
                </li>
                <li>
                  {t(
                    'Specify the location in which the content should be filmed'
                  )}
                  {': '}
                  {t('outside, in the kitchen, in the house, etc.')}
                </li>
              </ol>
            </div>
          </Card>
        </div>
        <NavigateButtonGroup
          required='description'
          next='../summary'
          back='../product'
        />
      </div>
    </>
  );
}
