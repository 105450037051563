import { ReactElement } from 'react';
import { useSelector } from 'react-redux';
import { NavLink } from 'react-router-dom';
import { RootState } from 'redux/store';

import {
  // BanknotesIcon,
  // BellAlertIcon,
  // CreditCardIcon,
  // CurrencyDollarIcon,
  SparklesIcon,
  UserCircleIcon,
  // WalletIcon,
} from '@heroicons/react/20/solid';
import { useTranslation } from 'react-i18next';

import AmplifyMedia from 'views/components/media/StorageImageRenderer';

const optionIconClassName = 'min-w-[20px] w-5 h-5';

const navLinks: { text: string; link?: string; icon: any }[] = [
  {
    text: 'Account',
    link: 'account',
    icon: <UserCircleIcon className={optionIconClassName} />,
  },
  // {
  //   text: 'Wallet',
  //   link: 'wallet',
  //   icon: <WalletIcon className={optionIconClassName} />,
  // },
  // {
  //   text: 'Billing',
  //   link: 'billing',
  //   icon: <CurrencyDollarIcon className={optionIconClassName} />,
  // },
  // {
  //   text: 'Payment methods',
  //   link: 'payment-methods',
  //   icon: <CreditCardIcon className={optionIconClassName} />,
  // },
  {
    text: 'Brands',
    link: 'brands',
    icon: <SparklesIcon className={optionIconClassName} />,
  },
  // {
  //   text: 'Notifications',
  //   link: 'notifications',
  //   icon: <BellAlertIcon className={optionIconClassName} />,
  // },
  // {
  //   text: 'Withdrawal method',
  //   link: 'withdrawal-method',
  //   icon: <BanknotesIcon className={optionIconClassName} />,
  // },
];
export default function SettingAsideBar(): ReactElement {
  const { t } = useTranslation('translation', {
    keyPrefix: 'brand.settings.sidebar',
  });

  const profile = useSelector((root: RootState) => root.user.profile);

  return (
    <div className='hidden xl:block border-r border-gray-200 dark:border-dark-theme-200 xl:w-[264px]'>
      <aside className='xl:flex flex-col w-full xl:w-[264px] h-full xl:p-6 flex-shrink-0 z-0 xl:h-[calc(100vh-212px)]'>
        <div className='flex flex-col flex-grow overflow-y-auto'>
          <div className='flex flex-col pb-6 mb-6 border-gray-200 dark:border-dark-theme-200 border-b'>
            <AmplifyMedia
              isUserAvatar
              height={64}
              width={64}
              className='w-16 h-16'
              sourceKey={profile.profile_picture}
            />
            <div className='text-lg font-semibold text-gray-900 dark:text-gray-100 capitalize'>
              {profile.firstName} {profile.lastName}
            </div>
            <div className='text-sm text-gray-500 dark:text-gray-400'>
              {t('Member since')}:{' '}
              {profile?.approvedDate &&
                new Date(profile.approvedDate).toLocaleDateString()}
            </div>
          </div>
          <nav className='flex-grow space-y-6 flex flex-col'>
            {navLinks.map((navItem, index) =>
              navItem.link ? (
                <NavLink
                  key={navItem.link + index}
                  className={({ isActive }) =>
                    `${
                      isActive
                        ? 'text-dark-black dark:text-gray-200'
                        : 'text-gray-400 dark:text-gray-500'
                    } pl-1 text-sm font-medium flex items-center gap-4`
                  }
                  to={navItem.link}
                >
                  {navItem.icon}
                  {t(navItem.text)}
                </NavLink>
              ) : (
                <div
                  key={navItem.text + index}
                  className='pl-1 text-gray-400 text-sm font-medium flex items-center gap-4'
                >
                  {navItem.icon}
                  {t(navItem.text)}
                </div>
              )
            )}
          </nav>
        </div>
      </aside>
    </div>
  );
}
