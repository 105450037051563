const landing = {
  'Pick content type': 'Pick content type',
  'Browse our library of jobs from cross-industrial brands, from health suppleements to household items. Select one (or many!) brands that piqued your interest.':
    'Browse our library of jobs from cross-industrial brands, from health suppleements to household items. Select one (or many!) brands that piqued your interest.',
  'Create Content': 'Create Content',
  "Have fun taking video and photos of the product. Don't hold back, let your creativity soar and show off your eloquent charm!":
    "Have fun taking video and photos of the product. Don't hold back, let your creativity soar and show off your eloquent charm!",
  'Submmit. Ka-ching!': 'Submmit. Ka-ching!',
  'Upload your content and a payment will be deposited instantly.':
    'Upload your content and a payment will be deposited instantly.',
  'Get Paid for Doing What You Love.': 'Get Paid for Doing What You Love.',
  'Pick a brand you love. Receive a free product. And get paid for doing one short video clip and shooting three photos.':
    'Pick a brand you love. Receive a free product. And get paid for doing one short video clip and shooting three photos.',
  "Let's get started!": "Let's get started!",
  'How it works': 'How it works',
  'Ready to get started?': 'Ready to get started?',
  'Sign Me Up Now!': 'Sign Me Up Now!',
  '1. Pick A Job': '1. Pick A Job',
  '2. Create Content': '2. Create Content',
  '3. Submit and Earn': '3. Submit and Earn',
};

export default landing;
