const campaign = {
  'My campaigns': 'My campaigns',

  // tabs with count
  All: 'All({{count}})',
  Open: 'Open({{count}})',
  Closed: 'Closed({{count}})',
  Hidden: 'Hidden({{count}})',

  // campaign table header array
  Campaign: 'Campaign',
  'Content type': 'Content type',
  Creators: 'Creators',
  Status: 'Status',
  'Created at': 'Created at',

  'In progress': 'In progress',
  'Waiting payment': 'Waiting payment',
  Completed: 'Completed',
  'See details': 'See details',

  // campaign status
  OPEN: 'OPEN',
  CLOSED: 'CLOSED',
  HIDDEN: 'HIDDEN',

  // campaign content type
  photo: 'photo',
  video: 'video',

  // campaign job card
  'Delivery type': 'Delivery type',
  'Brand will ship directly': 'Brand will ship directly',
  'Creator will purchase': 'Creator will purchase',

  'Product review': 'Product review',

  'Job price': 'Job price',

  // campaign status card
  'Campaign status': 'Campaign status',
  Applicants: 'Applicants',
  Invited: 'Invited',
  'creator(s)': 'creator(s)',
  'job(s)': 'job(s)',
  'Change campaign status': 'Change campaign status',

  // campaign detail table tabs
  Jobs: 'Jobs',
  Content: 'Content',
  'Campaign details': 'Campaign details',
  Delivery: 'Delivery',
  'Cost breakdown': 'Cost breakdown',

  // creator table
  Reviews: 'Reviews',
  Invite: 'Invite',
  'Pro tip': 'Pro tip',
  'Here goes the pro tip like you can select more creators...':
    'Here goes the pro tip like you can select more creators...',

  // campaign delivery table
  Creator: 'Creator',
  Address: 'Address',

  // job status enum
  open: 'open',
  invited: 'invited',
  started: 'started',
  'product-sent': 'product-sent',
  'product-delivered': 'product-delivered',
  'content-uploaded': 'content-uploaded',
  completed: 'completed',
  rejected: 'rejected',

  'Price per': 'Price per',
  Total: 'Total',

  // job detail page
  'Back to campagin': 'Back to campagin',

  loading: 'Loading...',
  NoJobMessage:
    'Thanks for creating your campaign. Creators will soon see your listing and apply for your campaign. You can also invite creators from the invite tab',

  // chat
  'Chat with': 'Chat with',
  'Go to campaign': 'Go to campaign',

  // new campaign
  'Campaign creation': 'Campaign creation',
  'Product information': 'Product information',
  'Content setting': 'Content setting',
  Summary: 'Summary',

  'New campaign': 'New campaign',
  'Add a new brand': 'Add a new brand',
  Brand: 'Brand',
  Product: 'Product',
  'Add a new product': 'Add a new product',
  'Campaign name': 'Campaign name',
  'Required content': 'Required content',
  'Edit product': 'Edit product',
  'Product link': 'Product link',
  'Product access instructions': 'Product access instructions',

  'Content format': 'Content format',
  'Content description': 'Content description',
  Characters: 'Characters',
  'Recommendations on what to mention in this description':
    'Recommendations on what to mention in this description',
  'Specify what exactly you want to see':
    'Specify what exactly you want to see',
  'face, hands, etc.': 'face, hands, etc.',
  'Specify the location in which the content should be filmed':
    'Specify the location in which the content should be filmed',
  'outside, in the kitchen, in the house, etc.':
    'outside, in the kitchen, in the house, etc.',

  'Content details': 'Content details',
  'Content type and format': 'Content type and format',
  Description: 'Description',
  'Job Cost': 'Job Cost',

  'digital product': 'Digital product',
  'physical product': 'Physical product',

  // Tips
  NewJobMsg:
    'The creator applied for this job, you can start by accepting the application.',

  // navigation & edit
  Edit: 'Edit',
  Back: 'Back',
  Cancel: 'Cancel',
  Next: 'Next',
  Create: 'Create',

  Accept: 'Accept',
  Decline: 'Decline',

  // tip
  'You will NOT be charged full amount upfront.':
    'You will NOT be charged full amount upfront.',
  'You will be charged for each creator job individually, as soon as creators accept the job.':
    'You will be charged for each creator job individually, as soon as creators accept the job.',

  // job detail

  'Delivery info': 'Delivery info',
  'Job info': 'Job info',
  Chat: 'Chat',

  // delivery info panel
  'You can see the delivery info after the job has started':
    'You can see the delivery info after the job has started',
  'Delivery Country': 'Delivery Country',
  'Delivery State': 'Delivery State',
  'Delivery City': 'Delivery City',
  'Delivery AddressLine1': 'Delivery AddressLine1',
  'Delivery Postal Code': 'Delivery Postal Code',

  'Send payment': 'Send payment',
  'View Receipt': 'View Receipt',
  Invoice: 'Invoice',

  'No messages': 'No messages',
  'No contents': 'No contents',

  // product ratio
  Any: 'Any',
  Portrait: 'Portrait',
  Landscape: 'Landscape',
  Square: 'Square',
  any: 'Any',
  portrait: 'Portrait',
  landscape: 'Landscape',
  square: 'Square',

  // job card texts
  'Job details': 'Job details',

  'All brands': 'All brands',
  'All products': 'All products',

  // inside components
  'Select product': 'Select product',
  'Nothing found': 'Nothing found',

  'Search...': 'Search...',
  'View profile': 'View profile',
  'No active jobs': 'No active jobs',
  'Complete the job': 'Complete the job',
  'Leave reviews to the creator to complete the job.':
    'Leave reviews to the creator to complete the job.',
  Complete: 'Complete',
  'Write message...': 'Write message...',
};

export default campaign;
