import { StarIcon } from '@heroicons/react/20/solid';
import { StarIcon as StarEmptyIcon } from '@heroicons/react/24/outline';
import { useState } from 'react';

interface IRatingInput {
  value?: 1 | 2 | 3 | 4 | 5;
  onChange: (rating: number) => void;
}

export default function RatingInput(props: IRatingInput) {
  const className =
    'w-7 h-7 stroke-yellow-500 text-yellow-500 cursor-pointer transition-all duration-150';

  const [valueIndex, setValueIndex] = useState<number | undefined>(props.value);

  return (
    <div className='flex gap-2'>
      {new Array(5).fill(true).map((_rating, index) => {
        return valueIndex === undefined || index > valueIndex - 1 ? (
          <StarEmptyIcon
            key={'rating' + index}
            onMouseEnter={() => setValueIndex(index + 1)}
            onMouseLeave={() => setValueIndex(props.value)}
            onClick={() => {
              props.onChange(index + 1);
            }}
            className={className}
          />
        ) : (
          <StarIcon
            key={'rating' + index}
            onMouseEnter={() => setValueIndex(index + 1)}
            onMouseLeave={() => setValueIndex(props.value)}
            onClick={() => {
              props.onChange(index + 1);
            }}
            className={className}
          />
        );
      })}
    </div>
  );
}
