import { ReactElement } from "react";
import { PencilSquareIcon } from "@heroicons/react/24/outline";
import { useTranslation } from "react-i18next";

import Card from "components/card";
import Button from "components/button";
import CardHr from "components/card/CardHr";
import CardText from "components/card/CardText";
import CardTitle from "components/card/CardTitle";
import InputBox from "components/form/InputBox";
import ComboBox from "components/form/Combobox/Combobox";
import CardTextLabel from "components/card/CardLabelText";

import ImageUploadBox from "views/components/upload/ImageUpload";
import AmplifyMedia from "views/components/media/StorageImageRenderer";

import useEditCreatorProfile from "hooks/influencer/useEditCreatorProfile";

export default function AccountProfile({
  editing,
  setEditing,
}: {
  editing: IProfileEdit;
  setEditing: (value: IProfileEdit) => void;
}): ReactElement {
  const { t } = useTranslation("translation", {
    keyPrefix: "creator.setting.AccountPage",
  });

  const {
    pending,
    isUploading,
    progress,
    user,
    editedUser,
    disabledForPersonalInfo,
    isEditing,
    previewImage,
    toEditMode,
    editUserHandler,
    editUserGenderHandler,
    profileImageFileHandler,
    cancelEditHandler,
    updateUserProfileHandler,
  } = useEditCreatorProfile(editing, setEditing);

  const genderList: IValueWithIndex[] = [
    { index: "NaN", value: "Not selected", displayValue: "Not selected" },
    { index: "0", value: "Male", displayValue: "Male" },
    { index: "1", value: "Female", displayValue: "Female" },
  ];

  return (
    <Card addClassName="p-4 md:p-8 mt-8 relative">
      <CardTitle title={t("Personal information")} />
      <CardHr marginClassName="mt-10" />
      <div
        className={`my-5 flex flex-row items-start sm:items-center gap-x-6 sm:gap-x-10 relative ${
          isEditing ? "hidden" : "block"
        }`}
      >
        <PencilSquareIcon
          onClick={() => {
            toEditMode("PERSONAL");
          }}
          className="cursor-pointer w-6 h-6 absolute top-0 sm:top-5 right-2"
        />
        <AmplifyMedia
          isUserAvatar
          width={260}
          height={260}
          sourceKey={user.profile_picture}
          className="max-sm:mx-auto w-[220px] sm:w-[260px] aspect-square rounded-lg"
        />
        <div className="w-full sm:w-fit">
          <CardTextLabel>{t("First name")}</CardTextLabel>
          <CardText addClassName="mb-2 capitalize">{user.firstName}</CardText>
          <CardTextLabel>{t("Last name")}</CardTextLabel>
          <CardText addClassName="mb-2 capitalize">{user.lastName}</CardText>
          <CardTextLabel>{t("Email")}</CardTextLabel>
          <CardText addClassName="mb-2 capitalize">{user.email}</CardText>

          <CardTextLabel>{t("Day of birth")}</CardTextLabel>
          <CardText addClassName="mb-2">
            {user.date_of_birth
              ? new Date(user.date_of_birth).toLocaleString().split(",")[0]
              : t("Not provided")}
          </CardText>
          <CardTextLabel>{t("Gender")}</CardTextLabel>
          <CardText addClassName="mb-2">
            {user.gender ? t(user.gender) : t("Not provided")}
          </CardText>
        </div>
      </div>

      <div
        className={`flex flex-col sm:flex-row items-start sm:items-center mt-10 gap-10 ${
          !isEditing ? "hidden" : "block"
        }`}
      >
        <ImageUploadBox
          pending={isUploading}
          originalURL={user.profile_picture ?? undefined}
          imageURL={previewImage ?? undefined}
          onImageChange={profileImageFileHandler}
          progress={progress}
        />

        <div className="grid grid-cols-1 sm:grid-cols-2 gap-x-5 gap-y-4">
          <div className="flex flex-col gap-2">
            <CardTextLabel>{t("First name")}</CardTextLabel>
            <InputBox
              name="firstName"
              value={editedUser.firstName}
              placeholder="First name"
              onChange={editUserHandler}
              addClassName="capitalize"
            />
          </div>
          <div className="flex flex-col gap-2">
            <CardTextLabel>{t("Last name")}</CardTextLabel>
            <InputBox
              name="lastName"
              value={editedUser.lastName}
              placeholder="Last name"
              onChange={editUserHandler}
              addClassName="capitalize"
            />
          </div>
          <div className="flex flex-col gap-2">
            <CardTextLabel>{t("Day of birth")}</CardTextLabel>
            <InputBox
              type="date"
              name="date_of_birth"
              value={editedUser.date_of_birth ? editedUser.date_of_birth : undefined}
              onChange={editUserHandler}
            />
          </div>
          <div className="flex flex-col gap-2">
            <CardTextLabel>{t("Gender")}</CardTextLabel>
            <ComboBox
              data={genderList}
              setSelectedData={(gender: { index: string; value: "Male" | "Female" }) =>
                editUserGenderHandler(gender.index === "NaN" ? null : gender.value)
              }
              selectedData={
                genderList.find((gender) => gender.value === editedUser.gender) ?? genderList[0]
              }
              placeholder="Gender"
            />
          </div>

          <Button onClick={cancelEditHandler} variant="outline">
            {t("Cancel")}
          </Button>
          <Button
            pending={pending || isUploading}
            disabled={disabledForPersonalInfo}
            onClick={updateUserProfileHandler}
          >
            {t("Save changes")}
          </Button>
        </div>
      </div>
    </Card>
  );
}
