const landing = {
  'Quality UGC Package for A Fraction of the Price!':
    '轻松直连美国百姓网红，超低价丰富产品内容!',
  "Let's get started!": '开始尝试!',
  'How it works': '使用简介',
  'Ready to get started?': '准备好了么?',
  'Sign Me Up Now!': '马上注册使用!',
  'Post A Job': '发布任务',
  'Add pictures of your brand and your products. Share somande cool facts about your brand. Describe your content requirements. Creators will start applying in minutes.':
    '添加品牌信息，上传品牌Logo和产品图片，添加产品信息简介，描述内容创作要求，完成后网红内容制作者会陆续申请与您合作。',
  'Pick content type': '选择内容模式',
  'Select creators that best represent your brand':
    '选择您最喜爱的美国网红创作者',
  'As creators apply to your job, their portfolio will become accessible for you to review. Browse through their profiles and pick the ones that best represent your brand.':
    '当网红开始申请时，您可以查看他们的信息。浏览后选择您最想合作的网红。',
  'Approve Content': '确认收到产品内容',
  'You will receive a notification once the selected creator delivers the content. Chat with them for any editing requests or simply just to let them know how happy you are with their creative work.':
    '当内容完成后，您会收到信息提醒，你可以与他们交流反馈。',
  '1. Post A Job': '1. 发布任务',
  '2. Select Creators': '2. 选择创作者',
  '3. Approve Content': '3. 内容确认',
};

export default landing;
