import {
  ArrowPathIcon,
  PhotoIcon,
  PlusIcon,
} from '@heroicons/react/24/outline';
import { ChangeEvent, ReactElement } from 'react';
import { useTranslation } from 'react-i18next';

export default function ImageUploadBox({
  onImageChange,
  imageURL,
  originalURL,
  pending,
  progress,
}: IImageUploadBox): ReactElement {
  const { t } = useTranslation('translation', { keyPrefix: 'brand.brands' });

  const changeHandler = (e: ChangeEvent<HTMLInputElement>) => {
    if (!!e.target.files) {
      const file = e.target.files[0];
      onImageChange(file);
    }
  };

  return (
    <label className='group block w-[200px] h-[200px] sm:w-[260px] sm:h-[260px] bg-gray-50 dark:bg-dark-theme-900 aspect-square rounded-2xl cursor-pointer'>
      {!(!!imageURL || !!originalURL) ? (
        <div className='border-dashed border-[2px] border-dark/30 hover:border-dark/70 dark:border-gray-500 dark:hover:border-gray-400 transition-all duration-150 w-full h-full flex flex-col justify-center items-center text-gray-400 group-hover:text-gray-500 rounded-2xl'>
          <span className='w-2/3 relative'>
            <PhotoIcon className='w-full h-fit aspect-video stroke-[1px]' />
            <span className='p-2 rounded-2xl absolute top-0 right-5 bg-gray-50 dark:bg-dark-theme-900'>
              <PlusIcon className='w-8 h-8 stroke-[4px]' />
            </span>
          </span>
          <p className='font-medium text-gray-500 group-hover:text-gray-900 dark:text-gray-100 dark:group-hover:text-gray-500 transition-all duration-150'>
            {t('Upload an image')}
          </p>
          <span className='text-sm text-center'>{t('PNG, JPG upto 50MB')}</span>
        </div>
      ) : imageURL || originalURL ? (
        <div className='group w-full h-full rounded-2xl overflow-hidden border-[2px] border-gray-400 dark:border-dark-theme-900 relative'>
          <img
            src={imageURL || originalURL}
            alt='To upload'
            className='w-full h-full object-cover rotate-0 group-hover:rotate-12 group-hover:scale-150 transition-all duration-150'
            draggable={false}
          />
          {!pending && (
            <div className='absolute w-full -bottom-10 left-1/2 -translate-x-1/2 flex group-hover:bottom-5 transition-all duration-150'>
              <span className='opacity-60 group-hover:opacity-90 bg-dark-theme-900 transition-all duration-150 flex items-center gap-4 text-white w-fit px-5 py-1 mx-auto rounded-full'>
                <ArrowPathIcon className='w-6 h-6 stroke-[2px] animate-spin' />
                {t('Change image')}
              </span>
            </div>
          )}
          <div
            className={`w-1/2 aspect-square absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 rounded-full flex justify-center items-center bg-dark-black/70 text-white text-lg transition-all duration-200 ${
              pending
                ? 'opacity-100 scale-100 animate-pulse'
                : 'opacity-0 scale-50'
            }`}
          >
            {Math.floor(
              (Number(progress.loaded) * 100) / Number(progress.total)
            )}
            %
          </div>
        </div>
      ) : undefined}

      <input
        disabled={pending}
        type='file'
        hidden
        onChange={changeHandler}
        accept='image/png, image/jpeg'
      />
    </label>
  );
}
