import { getJobByCampaignID } from 'apis/common/job';
import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';

export default function useFetchedJobDataByCampaignID() {
  const { campaignID } = useParams();

  const [fetching, setFetching] = useState<boolean>(false);
  const [error, setError] = useState<boolean>(false);
  const [jobs, setJobs] = useState<IJobData[]>([]);

  useEffect(() => {
    if (campaignID === undefined) return;
    (async () => {
      setFetching(true);

      try {
        debugger;
        const response = await getJobByCampaignID(campaignID);
        console.log('responseresponse', response);
        setJobs(response.data.jobsByCampaignID.items);
        setError(false);
      } catch (e) {
        console.log('error', error);
        setError(true);
      }

      setFetching(false);
    })();

    return () => {};
  }, []);

  return { fetching, error, campaignID, jobs };
}
