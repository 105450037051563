import { configureStore } from '@reduxjs/toolkit';

import userSlice from './common_slices/userSlice';
import authSlice from './common_slices/authSlice';
import protoTypeSlice from './common_slices/protoTypeSlice';

// brand
import campaignSlice from './brand_slices/campaignSlice';
import productSlice from './brand_slices/productSlice';
import brandSlice from './brand_slices/brandSlice';
import jobSlice from './brand_slices/jobSlice';
import creatorSlice from './influencer_slices/creatorSlice';
import adminSlice from './adminSlice';

export const store = configureStore({
  reducer: {
    protoType: protoTypeSlice,
    user: userSlice,
    authentication: authSlice,

    // brand reducers
    brand: brandSlice,
    product: productSlice,
    campaign: campaignSlice,
    job: jobSlice,

    // creator reducers
    creator: creatorSlice,

    // admin
    admin: adminSlice,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
    }),
});

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>;

// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch;
