import { ReactElement } from 'react';
import { useTranslation } from 'react-i18next';

import TableWrapper from 'components/table/TableWrapper';
import TableHeader from 'components/table/TableHeader';
import TableBody from 'components/table/TableBody';
import TableCell from 'components/table/TableCell';
import TableRow from 'components/table/TableRow';
import Button from 'components/button';

import AmplifyMedia from 'views/components/media/StorageImageRenderer';

export default function BrandTable({
  brands,
  campaigns,
  products,
  ...props
}: IBrandTable): ReactElement {
  const { t } = useTranslation('translation', {
    keyPrefix: 'brand.settings.brand',
  });

  const headerArray = [t('Brand'), t('Campaigns'), t('Submitted content'), ''];

  return (
    <TableWrapper addClassName='min-w-[700px]'>
      <TableHeader headerArray={headerArray} />
      <TableBody>
        {brands.map((brand) => (
          <TableRow key={brand.id}>
            <TableCell>
              <div className='flex space-x-3'>
                <AmplifyMedia
                  sourceKey={brand.imageUrl}
                  className='min-w-[64px] w-16 h-16 rounded-lg'
                  width={64}
                  height={64}
                />

                <div className='flex flex-col justify-center w-full'>
                  <p className='text-sm line-clamp-2 break-all text-gray-700 dark:text-gray-500'>
                    {brand.brandName}
                  </p>
                  <div className='flex items-center space-x-1'>
                    <p className='text-sm truncate text-gray-500 dark:text-gray-300'>
                      {t('Products')}:{' '}
                    </p>
                    <span className='font-semibold text-gray-700 dark:text-gray-100'>
                      {
                        products.filter(
                          (product) => product.brandID === brand.id
                        ).length
                      }
                    </span>
                  </div>
                </div>
              </div>
            </TableCell>
            <TableCell>
              {(() => {
                let openCount = 0;
                let hiddenCount = 0;
                let closedCount = 0;
                campaigns.forEach((campaign) => {
                  if (
                    campaign.campaignStatus === 'CLOSED' &&
                    campaign.brandID === brand.id
                  ) {
                    closedCount++;
                  } else if (
                    campaign.campaignStatus === 'HIDDEN' &&
                    campaign.brandID === brand.id
                  ) {
                    hiddenCount++;
                  } else if (
                    campaign.campaignStatus === 'OPEN' &&
                    campaign.brandID === brand.id
                  ) {
                    openCount++;
                  }
                });

                return (
                  <div className='flex flex-col justify-start'>
                    <div className='flex items-center space-x-1'>
                      <p className='text-gray-500 text-sm'>
                        {t('Open')}:{' '}
                        <span className='text-gray-700 dark:text-gray-100 font-semibold'>
                          {openCount}
                        </span>
                      </p>
                    </div>
                    <div className='flex items-center space-x-1'>
                      <p className='text-gray-500 text-sm'>
                        {t('Hidden')}:{' '}
                        <span className='text-gray-700 dark:text-gray-100 font-semibold'>
                          {hiddenCount}
                        </span>
                      </p>
                    </div>
                    <div className='flex items-center space-x-1'>
                      <p className='text-gray-500 text-sm'>
                        {t('Closed')}:{' '}
                        <span className='text-gray-700 dark:text-gray-100 font-semibold'>
                          {closedCount}
                        </span>
                      </p>
                    </div>
                  </div>
                );
              })()}
            </TableCell>
            <TableCell>
              <div className='flex flex-col justify-start'>
                <div className='flex items-center space-x-1'>
                  <p className='text-gray-500 text-sm'>
                    {t('Picture')}:{' '}
                    <span className='text-gray-700 dark:text-gray-100 font-semibold'>
                      0
                    </span>
                  </p>
                </div>
                <div className='flex items-center space-x-1'>
                  <p className='text-gray-500 text-sm'>
                    {t('Video')}:{' '}
                    <span className='text-gray-700 dark:text-gray-100 font-semibold'>
                      0
                    </span>
                  </p>
                </div>
              </div>
            </TableCell>
            <TableCell>
              <Button
                onClick={() => props.onEditBrand(brand.id)}
                className='mx-4 text-sm font-medium px-6 h-11'
              >
                {t('Edit brand')}
              </Button>
            </TableCell>
          </TableRow>
        ))}
      </TableBody>
    </TableWrapper>
  );
}
