import { useDispatch } from 'react-redux';
import { useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import {
  initCampaignData,
  setNewCampaign,
} from 'redux/brand_slices/campaignSlice';
import { AppDispatch, RootState } from 'redux/store';

export default function useNewCampaignNavigation() {
  const navigate = useNavigate();
  const dispatch = useDispatch<AppDispatch>();

  const pathname = useLocation().pathname.split('/').at(-1) ?? '';
  const newCampaign = useSelector(
    (root: RootState) => root.campaign.newCampaign
  );

  let nextEnabled =
    !!newCampaign &&
    newCampaign.brandID !== 'NaN' &&
    !!newCampaign.brandID &&
    !!newCampaign.productID &&
    !!newCampaign.campaignName;

  const required = pathname === 'settings' ? 'description' : undefined;

  if (required) {
    nextEnabled &&= newCampaign[required] !== '';
  }

  const navigateHandler = (href: string) => {
    if (href === 'summary' && newCampaign.description === '') {
      return;
    }
    if (nextEnabled) {
      navigate(href);
    }
  };

  const cancelNewCampaignHandler = () => {
    dispatch(setNewCampaign(initCampaignData));
    navigate('/brand/campaigns');
  };

  return {
    pathname,
    disabled: !nextEnabled,
    navigateHandler,
    cancelNewCampaignHandler,
  };
}
