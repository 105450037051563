import { ReactElement, useEffect, useCallback, useState, useRef } from 'react';
import { Dialog } from '@headlessui/react';
import { UserCircleIcon } from '@heroicons/react/20/solid';
import { Storage } from 'aws-amplify';
import { useTranslation } from 'react-i18next';

import { SkeletonBox } from 'components/skeleton';
import CardHr from 'components/card/CardHr';
import Modal from 'components/modal/Modal';
import { XMarkIcon } from '@heroicons/react/24/outline';
import Button from 'components/button/Button';

export default function AmplifyMedia({
  mediaType = 'image',
  sourceKey,
  className,
  width,
  height,
  isUserAvatar,
  isContentMedia,
  actionButtons,
  downloadable,
}: IAmplifyMedia): ReactElement {
  const { t } = useTranslation('translation', { keyPrefix: 'brand.contents' });

  const videoRef = useRef<HTMLVideoElement>(null);

  const [visibility, setVisibility] = useState<boolean>(false);
  const [sourceURL, setSourceURL] = useState<string>();
  const getSource = useCallback(async (key?: string) => {
    if (key) {
      const source = await Storage.get(key, { expires: 512 });

      setSourceURL(source);
    }
  }, []);

  useEffect(() => {
    if (sourceKey) {
      getSource(sourceKey);
    }
    return () => {};
  }, [sourceKey, getSource]);

  const playVideoHandler = () => {
    videoRef.current?.play();
  };

  const pauseVideoHandler = () => {
    videoRef.current?.pause();
  };

  if (
    (sourceURL === null ||
      sourceURL === undefined ||
      sourceKey === undefined) &&
    isUserAvatar
  )
    return (
      <div className={`${className} text-gray-700 dark:text-white`}>
        <UserCircleIcon />
      </div>
    );

  if (sourceURL && sourceURL !== null) {
    return (
      <>
        {mediaType === 'video' ? (
          <video
            ref={videoRef}
            width={width}
            height={height}
            className={`${
              className ?? ''
            } rounded-lg object-cover bg-gray-200 dark:bg-dark-black group-hover:scale-105 transition-all duration-200`}
            src={sourceURL}
            onMouseEnter={playVideoHandler}
            onMouseLeave={pauseVideoHandler}
            onClick={() => isContentMedia && setVisibility(true)}
          />
        ) : (
          <img
            src={sourceURL}
            alt={sourceKey ?? 'null-image'}
            width={width}
            height={height}
            className={`${
              className ?? ''
            } rounded-lg object-cover bg-gray-200 dark:bg-dark-black group-hover:scale-105 transition-all duration-200`}
            draggable={false}
            onClick={() => isContentMedia && setVisibility(true)}
          />
        )}
        <Modal isOpen={visibility} closeModal={() => setVisibility(false)}>
          <Dialog.Panel className='w-[80vw] md:w-[700px] lg:w-[900px] xl:w-[1100px] transform overflow-hidden rounded-xl bg-white dark:bg-dark-theme-200 shadow-card transition-all'>
            <Dialog.Title
              as='h3'
              className='text-lg font-medium leading-6 text-gray-900 mx-5 dark:text-gray-200 py-4 flex items-center justify-between'
            >
              {t('Content preview')}
              <XMarkIcon
                onClick={() => setVisibility(false)}
                className='w-5 h-5 cursor-pointer'
              />
            </Dialog.Title>
            <CardHr marginClassName='mb-8' />
            <div className='flex gap-2 pb-8'>
              <div className='px-6 w-full'>
                {mediaType === 'video' ? (
                  <video
                    className='w-full aspect-video rounded-lg'
                    src={sourceURL}
                    controls
                  />
                ) : (
                  <img
                    src={sourceURL}
                    alt={sourceKey ?? 'null-image'}
                    className='w-full aspect-video object-cover rounded-lg'
                    draggable={false}
                  />
                )}
              </div>
              <div className='w-fit flex flex-col gap-2 pr-6'>
                <a href={sourceURL} target='_blank' rel='noreferrer'>
                  <Button className='w-[200px] h-10'>{t('Download')}</Button>
                </a>
                {actionButtons ?? <>{actionButtons}</>}
              </div>
            </div>
          </Dialog.Panel>
        </Modal>
      </>
    );
  }

  return <SkeletonBox darkMode className={className} />;
}
