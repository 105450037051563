import { ReactElement } from 'react';
import { NavLink } from 'react-router-dom';

import { useTranslation } from 'react-i18next';

import LocaleButton from 'components/button/LocaleButton';
import UnauthedMobileMenu from 'components/layout/UnauthedMobileMenu';
import Logo from 'components/logo';
import NavWrapper from './NavWrapper';

export default function UnauthenticatedNav(): ReactElement {
  const { t } = useTranslation('translation', { keyPrefix: 'landing' });

  return (
    <NavWrapper>
      <div className='flex gap-8 items-center'>
        <Logo />
        <NavLink to='/for-creators' className='max-md:hidden'>
          {t('For creators')}
        </NavLink>
        <NavLink to='/' className='max-md:hidden'>
          {t('For brands')}
        </NavLink>
      </div>

      <div className='flex items-center gap-6'>
        <LocaleButton />

        <NavLink to='/brand/signin' className='max-md:hidden'>
          {t('Sign in as Brand')}
        </NavLink>
        <NavLink
          to='/influencer/signin'
          className='py-2 px-4 rounded-full bg-theme hover:bg-theme/90 active:bg-theme/80 transition-all duration-200 text-white max-md:hidden'
        >
          {t('Sign in as Creator')}
        </NavLink>

        {/* For mobile */}
        <UnauthedMobileMenu />
      </div>
    </NavWrapper>
  );
}
