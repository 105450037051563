const navbar = {
  marketplace: '广场集市',
  'my jobs': '我的任务',

  // account settings
  Account: '账户',
  Portfolio: '内容库',
  Reviews: '评价',
  Wallet: '钱包',
  'Payment methods': '付款方式',
  'Log out': '退出',
  'Register as an Brand': '以品牌身份注册',
  'Already have an account?': '已有账户？',
  'Sign In': '请登陆',
};

export default navbar;
