import { ReactElement } from 'react';

export default function InputBox({
  error,
  className,
  addClassName,
  icon,
  ...props
}: IInput): ReactElement {
  return (
    <div className='relative w-full'>
      <input
        {...props}
        className={`border-[1px] outline-none ${
          className ?? 'w-full h-11 text-base'
        } ${addClassName ?? ''} ${
          error
            ? props.errorClassName ??
              'border-error/100 dark:border-error/50 hover:border-error/100 dark:hover:border-error/50 bg-error/10 placeholder:text-error dark:placeholder:text-error/60 placeholder:font-medium'
            : props.successClassName ??
              ' border-dark/40 hover:border-dark/60 focus:border-dark/90'
        } ${
          icon ? 'pl-11' : ''
        } bg-white dark:bg-dark-theme-900 px-4 rounded-lg`}
      />
      {icon && (
        <span className='absolute top-1/2 left-[6px] -translate-y-1/2'>
          {icon}
        </span>
      )}
    </div>
  );
}
