import { ReactElement } from 'react';
import { useTranslation } from 'react-i18next';

import { CampaignFormContainer } from './components/Container.CreateCampaign';
import SelectProductTypeModal from './components/Modal.SelectProductType';
import PhysicalProductModal from './components/Modal.PhysicalProduct';
import DigitalProductModal from './components/Modal.DigitalProduct';
import BrandModal from './components/Modal.Brand';

import Card from 'components/card';
import Modal from 'components/modal';
import Button from 'components/button';
import InputBox from 'components/form/InputBox';
import ComboBox from 'components/form/Combobox/Combobox';
import CardTitle from 'components/card/CardTitle';

import BrandComboBox from 'views/components/product_brand/Combo.Brand';

import useVisibleModal from 'hooks/common/useVisibleModal';
import useCampaignCreation from 'hooks/brand/useCampaignCreation';
import useNewCampaignNavigation from 'hooks/brand/useNewCampaignNavigation';

export default function CampaignCreation(): ReactElement {
  const { t } = useTranslation('translation', { keyPrefix: 'brand.campaigns' });

  const { type, visibility, closeModal, openModal } = useVisibleModal({
    types: ['BRAND', 'PRODUCT TYPE', 'DIGITAL PRODUCT', 'PHYSICAL PRODUCT'],
  });

  const {
    disabled,
    campaign,
    newProduct,
    newProductDisabled,
    productObjects,
    currentProduct,
    currentBrand,
    submitHandler,
    inputChangeHandler,
    handleSelectBrand,
    handleSelectProduct,
    manualChangeHandler,
  } = useCampaignCreation(type);

  const { cancelNewCampaignHandler } = useNewCampaignNavigation();

  return (
    <>
      <form
        onSubmit={submitHandler}
        noValidate
        autoComplete='off'
        className='min-h-[calc(100vh_-_64px)] px-3 md:px-4 xl:px-6'
      >
        <h2 className='text-xl font-semibold text-gray-900 dark:text-gray-100 py-6'>
          {t('New campaign')}
        </h2>
        <Card addClassName='sm:mb-2 p-8'>
          <CardTitle title={t('Campaign creation')} />
          <CampaignFormContainer
            key={Math.random() * 100}
            labelText={t('Brand')}
            actionButton={
              <button
                onClick={() => openModal('BRAND')}
                className={'link link-blue link-no-underline mb-2'}
                type='button'
              >
                {t('Add a new brand')}
              </button>
            }
            inputElement={
              <BrandComboBox
                currentBrand={currentBrand}
                onBrandChanage={handleSelectBrand}
              />
            }
          />
          <CampaignFormContainer
            key={Math.random() * 100}
            labelText={t('Product')}
            actionButton={
              <button
                disabled={newProductDisabled}
                onClick={() => openModal('PRODUCT TYPE')}
                className={`mb-2 ${newProductDisabled ? 'text-gray-400' : ''}`}
                type='button'
              >
                {t('Add a new product')}
              </button>
            }
            inputElement={
              <ComboBox
                data={productObjects}
                selectedData={currentProduct}
                setSelectedData={handleSelectProduct}
                placeholder={t('Select product') || ''}
              />
            }
          />

          {(() => {
            const typeArray: IValueWithIndex[] = [
              {
                index: '1',
                value: 'Brand will ship directly',
                displayValue: t('Brand will ship directly') ?? '',
              },
              // {
              //   index: '2',
              //   value: 'Creator will purchase',
              // },
            ];

            return (
              <CampaignFormContainer
                labelText={t('Delivery type')}
                actionButton={undefined}
                inputElement={
                  <ComboBox
                    data={typeArray}
                    setSelectedData={(type: IValueWithIndex) =>
                      manualChangeHandler('deliveryType', type.value)
                    }
                    selectedData={typeArray.find(
                      (type) => type.value === campaign.deliveryType
                    )}
                  />
                }
              />
            );
          })()}

          <CampaignFormContainer
            labelText={t('Campaign name')}
            actionButton={undefined}
            inputElement={
              <InputBox
                name='campaignName'
                value={campaign.campaignName}
                onChange={inputChangeHandler}
              />
            }
          />
        </Card>

        <Card addClassName='p-4 md:p-8 mt-5 flex justify-between'>
          <Button
            onClick={cancelNewCampaignHandler}
            variant='outline'
            addClassName='w-[150px] px-10 font-medium'
          >
            {t('Cancel')}
          </Button>
          <Button
            disabled={disabled}
            addClassName='!w-fit min-w-[150px] font-medium'
          >
            {t('Next')}
          </Button>
        </Card>
      </form>

      <Modal closeModal={closeModal} isOpen={visibility}>
        {type === 'BRAND' && (
          <BrandModal
            modalType='CREATE'
            closeModal={closeModal}
            hook={(brandItem: IValueWithIndex) => handleSelectBrand(brandItem)}
          />
        )}
        {type === 'PRODUCT TYPE' && (
          <SelectProductTypeModal
            openModal={openModal}
            closeModal={closeModal}
          />
        )}
        {type === 'PHYSICAL PRODUCT' && (
          <PhysicalProductModal
            modalType='CREATE'
            data={newProduct}
            openModal={openModal}
            closeModal={closeModal}
            hook={(productItem: IValueWithIndex) =>
              handleSelectProduct(productItem)
            }
          />
        )}
        {type === 'DIGITAL PRODUCT' && (
          <DigitalProductModal
            modalType='CREATE'
            data={newProduct}
            openModal={openModal}
            closeModal={closeModal}
            hook={(productItem: IValueWithIndex) =>
              handleSelectProduct(productItem)
            }
          />
        )}
      </Modal>
    </>
  );
}
