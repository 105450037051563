// INFLUENCER APP ROUTE PATHS

const INFLUENCER_APP_PATH = '/influencer/*';

const SIGNIN = '/signin';
const SIGNUP = '/signup';
const VERIFY_EMAIL = '/verify-email/*';
const FORGOT_PASSWORD = '/forgot-password';
const MARKETPLACE = '/marketplace/*';
const CAMPAIGN = '/campaigns/:campaignID/*';
const MY_JOBS = '/my-jobs';
const SETTINGS = '/settings/*';
const CREATOR_PROFILE = '/creator/:creatorID';

const INFLUENCER_PATH = {
  // INFLUENCER
  INFLUENCER_APP_PATH,
  SIGNIN,
  SIGNUP,
  VERIFY_EMAIL,
  FORGOT_PASSWORD,
  MARKETPLACE,
  CAMPAIGN,
  MY_JOBS,
  SETTINGS,
  CREATOR_PROFILE,
};

export default INFLUENCER_PATH;
