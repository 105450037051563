import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useDispatch } from 'react-redux';
import { getAllAdminJobAction } from 'redux/adminSlice';
import { AppDispatch, RootState } from 'redux/store';

export default function useAdminJobs() {
  const dispatch = useDispatch<AppDispatch>();
  const jobs = useSelector((root: RootState) => root.admin.jobs);

  useEffect(() => {
    dispatch(getAllAdminJobAction());
    return () => {};
  }, []);

  return { jobs };
}
