const data: IMenuData[] = [
  {
    text: 'marketplace',
    path: '/influencer/marketplace',
  },
  {
    text: 'my jobs',
    path: '/influencer/my-jobs',
  },
];

export default data;
