import { ReactElement } from 'react';
import { NavLink } from 'react-router-dom';
import {
  ArrowTopRightOnSquareIcon,
  PhotoIcon,
} from '@heroicons/react/24/outline';
import { CurrencyDollarIcon } from '@heroicons/react/24/solid';
import {
  ArrowsPointingOutIcon,
  VideoCameraIcon,
} from '@heroicons/react/20/solid';
import { useTranslation } from 'react-i18next';

import CardTitle from 'components/card/CardTitle';
import Button from 'components/button';
import Card from 'components/card';
import Tip from 'components/tip';

import AmplifyImage from 'views/components/media/StorageImageRenderer';

import useNewCampaignNavigation from 'hooks/brand/useNewCampaignNavigation';
import useNewCampaign from 'hooks/brand/useNewCampaign';

const accounting = require('accounting');

export default function CampaignSummary(): ReactElement {
  const { t } = useTranslation('translation', { keyPrefix: 'brand.campaigns' });

  const { pending, campaign, brand, product, createCampaignHandler } =
    useNewCampaign();
  const { cancelNewCampaignHandler } = useNewCampaignNavigation();

  return (
    <>
      <div className='min-h-[calc(100vh_-_64px)] px-3 md:px-4 xl:px-6 pb-10'>
        <h2 className='text-xl font-semibold text-gray-900 dark:text-gray-100 py-6'>
          {t('New campaign')}:{' '}
          <span className='sm:ml-1 sm:text-gray-800 dark:text-gray-200'>
            {campaign.campaignName}
          </span>
        </h2>
        <div className='flex flex-col space-y-2'>
          <Card addClassName='p-4 md:p-8'>
            <CardTitle title={t('Product information')} />
            <div className='flex flex-row gap-8 mt-10'>
              <div className='flex justify-self-start sm:justify-center'>
                <div className='image-container object-center w-[72px] h-[72px] sm:w-[120px] sm:h-[120px] rounded-lg overflow-hidden bg-dark-black/20 flex items-center justify-center text-6xl capitalize'>
                  <AmplifyImage
                    sourceKey={product?.productImage}
                    className='w-full h-full'
                  />
                </div>
              </div>
              <div className='flex flex-col'>
                <span className='text-sm text-gray-500 dark:text-gray-300 break-words line-clamp-3 sm:mb-1'>
                  {brand?.brandName}
                </span>
                <span className='text-sm sm:text-base text-gray-900 dark:text-gray-100 break-words line-clamp-3'>
                  {product?.productName}
                </span>
                <div className='flex gap-x-3 mt-1 sm:mt-0'>
                  <span className='text-gray-900 dark:text-gray-100 font-semibold'>
                    ${product?.productPrice.toFixed(2)}
                  </span>
                  <a
                    href={product?.productName}
                    rel='noreferrer'
                    target='_blank'
                    className='text-blue-700 dark:text-blue-300 inline-block link-no-underline product-link'
                  >
                    <span className='flex items-center gap-1'>
                      {t('Product link')}
                      <ArrowTopRightOnSquareIcon className='w-5 h-5 stroke-2' />
                    </span>
                  </a>
                </div>
                <div className='product-type-info pt-1'>
                  <span className='product-type-info-title text-sm font-semibold capitalize'>
                    {t(`${product?.productType.toLowerCase()} product`)}
                  </span>
                </div>
              </div>
            </div>
          </Card>

          <Card addClassName='p-4 md:p-8 space-y-2'>
            <CardTitle title={t('Content details')} />
            <div className='divide-y divide-gray-200 space-y-6'>
              <div className='delivery-type'>
                <div className='flex justify-between items-start'>
                  <div className='text-sm font-normal text-gray-500 dark:text-gray-400'>
                    {t('Delivery type')}
                  </div>
                  <NavLink to='/brand/campaign/create' className='text-sm'>
                    {t('Edit')}
                  </NavLink>
                </div>
                <p className='pt-2'>{t(campaign.deliveryType)}</p>
              </div>
              <div className='pt-6 ugc-type-format'>
                <div className='flex justify-between items-start'>
                  <div className='text-sm font-normal text-gray-500 dark:text-gray-400'>
                    {t('Content type and format')}
                  </div>
                  <NavLink to='/brand/campaign/settings' className='text-sm'>
                    {t('Edit')}
                  </NavLink>
                </div>
                <div className='flex space-x-6 mt-2'>
                  <div className='flex space-x-2'>
                    {campaign.contentType === 'photo' ? (
                      <PhotoIcon className='w-6 h-6' />
                    ) : (
                      <VideoCameraIcon className='w-6 h-6' />
                    )}

                    <span className='text-gray-900 dark:text-gray-200 capitalize'>
                      {t('video')}
                    </span>
                  </div>
                  <div className='flex space-x-2'>
                    <ArrowsPointingOutIcon className='w-6 h-6' />
                    <span className='text-gray-900 dark:text-gray-200 capitalize'>
                      {t(campaign.contentFormat)}
                    </span>
                  </div>
                </div>
              </div>
              <div className='flex flex-col space-y-2 pt-6'>
                <div className='flex justify-between items-start'>
                  <div className='text-sm text-gray-500 dark:text-gray-400'>
                    {t('Description')}
                  </div>
                  <NavLink to='/brand/campaign/settings' className='text-sm'>
                    {t('Edit')}
                  </NavLink>
                </div>
                <pre className='director-notes font-inter whitespace-pre-wrap'>
                  {campaign.description}
                </pre>
              </div>
              <div className='price-calculator flex flex-col space-y-2 pt-6'>
                <div className='flex justify-between text-lg font-semibold text-gray-900 dark:text-gray-100'>
                  <p>{t('Job Cost')}:</p>
                  <p>{accounting.formatMoney(campaign.campaignPrice, '¥')}</p>
                </div>
              </div>
            </div>
            <Tip
              wrapperClassName='!mt-6'
              icon={<CurrencyDollarIcon className='w-6 h-6' />}
              tipType={'info'}
              title={t('You will NOT be charged full amount upfront.')}
              description={t(
                'You will be charged for each creator job individually, as soon as creators accept the job.'
              )}
            />
          </Card>
        </div>
        <Card addClassName='p-4 md:p-8 mt-5 flex flex-wrap justify-between'>
          <Button
            onClick={cancelNewCampaignHandler}
            variant='outline'
            addClassName='w-full md:w-[150px] px-10'
          >
            {t('Cancel')}
          </Button>
          <div className='max-md:mt-4 flex max-md:w-full max-md:flex-col gap-4'>
            <NavLink to='../settings'>
              <Button
                variant='outline'
                addClassName='w-full md:w-[150px] font-medium'
              >
                {t('Back')}
              </Button>
            </NavLink>

            <Button
              pending={pending}
              onClick={createCampaignHandler}
              addClassName='w-full md:w-[150px] font-medium'
            >
              {t('Create')}
            </Button>
          </div>
        </Card>
      </div>
    </>
  );
}
