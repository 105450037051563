import { ReactElement } from 'react';
import { useTranslation } from 'react-i18next';

import Button from 'components/button';

import SearchInput from 'views/brand/_components/Input.SearchBox';
import BrandComboBox from 'views/components/product_brand/Combo.Brand';

export default function ProductTableActions(props: {
  createProductHandler: () => void;
  queryChange: (value: string) => void;
  brandChange: (elem: IValueWithIndex) => void;
}): ReactElement {
  const { t } = useTranslation('translation', { keyPrefix: 'brand.products' });

  return (
    <div className='flex flex-col sm:flex-row gap-y-4 justify-between'>
      <SearchInput onQueryChange={props.queryChange} />
      <div className='flex flex-col sm:flex-row gap-y-4 gap-8'>
        <BrandComboBox onBrandChanage={props.brandChange} />
        <Button
          onClick={props.createProductHandler}
          className='min-w-fit px-6 font-medium h-10 sm:h-auto'
        >
          {t('Add new product')}
        </Button>
      </div>
    </div>
  );
}
