/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const createUserReview = /* GraphQL */ `
  mutation CreateUserReview(
    $input: CreateUserReviewInput!
    $condition: ModelUserReviewConditionInput
  ) {
    createUserReview(input: $input, condition: $condition) {
      id
      FromUserID
      review
      rating
      userID
      User {
        id
        email
        firstName
        lastName
        isActive
        approvedDate
        role
        phone
        deliveryAddressLine1
        deliveryAddressLine2
        deliveryCity
        deliveryState
        deliveryCountry
        deliveryPostalCode
        youtube
        instagram
        tiktok
        facebook
        date_of_birth
        gender
        phone_number
        paypalUserName
        stripeCustomer
        profile_picture
        createdAt
        updatedAt
      }
      jobID
      Job {
        id
        jobStatus
        jobStartedDate
        jobEndedDate
        jobPrice
        jobApplicationFee
        invoiceLink
        deliveryTrackingInfo
        deliveryName
        deliveryAddressLine1
        deliveryAddressLine2
        deliveryCity
        deliveryState
        deliveryCountry
        deliveryPostalCode
        campaignID
        creatorID
        buyerID
        brandID
        productID
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
    }
  }
`;
export const updateUserReview = /* GraphQL */ `
  mutation UpdateUserReview(
    $input: UpdateUserReviewInput!
    $condition: ModelUserReviewConditionInput
  ) {
    updateUserReview(input: $input, condition: $condition) {
      id
      FromUserID
      review
      rating
      userID
      User {
        id
        email
        firstName
        lastName
        isActive
        approvedDate
        role
        phone
        deliveryAddressLine1
        deliveryAddressLine2
        deliveryCity
        deliveryState
        deliveryCountry
        deliveryPostalCode
        youtube
        instagram
        tiktok
        facebook
        date_of_birth
        gender
        phone_number
        paypalUserName
        stripeCustomer
        profile_picture
        createdAt
        updatedAt
      }
      jobID
      Job {
        id
        jobStatus
        jobStartedDate
        jobEndedDate
        jobPrice
        jobApplicationFee
        invoiceLink
        deliveryTrackingInfo
        deliveryName
        deliveryAddressLine1
        deliveryAddressLine2
        deliveryCity
        deliveryState
        deliveryCountry
        deliveryPostalCode
        campaignID
        creatorID
        buyerID
        brandID
        productID
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
    }
  }
`;
export const deleteUserReview = /* GraphQL */ `
  mutation DeleteUserReview(
    $input: DeleteUserReviewInput!
    $condition: ModelUserReviewConditionInput
  ) {
    deleteUserReview(input: $input, condition: $condition) {
      id
      FromUserID
      review
      rating
      userID
      User {
        id
        email
        firstName
        lastName
        isActive
        approvedDate
        role
        phone
        deliveryAddressLine1
        deliveryAddressLine2
        deliveryCity
        deliveryState
        deliveryCountry
        deliveryPostalCode
        youtube
        instagram
        tiktok
        facebook
        date_of_birth
        gender
        phone_number
        paypalUserName
        stripeCustomer
        profile_picture
        createdAt
        updatedAt
      }
      jobID
      Job {
        id
        jobStatus
        jobStartedDate
        jobEndedDate
        jobPrice
        jobApplicationFee
        invoiceLink
        deliveryTrackingInfo
        deliveryName
        deliveryAddressLine1
        deliveryAddressLine2
        deliveryCity
        deliveryState
        deliveryCountry
        deliveryPostalCode
        campaignID
        creatorID
        buyerID
        brandID
        productID
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
    }
  }
`;
export const createNotifications = /* GraphQL */ `
  mutation CreateNotifications(
    $input: CreateNotificationsInput!
    $condition: ModelNotificationsConditionInput
  ) {
    createNotifications(input: $input, condition: $condition) {
      id
      notification_type
      message
      link
      isRead
      userID
      User {
        id
        email
        firstName
        lastName
        isActive
        approvedDate
        role
        phone
        deliveryAddressLine1
        deliveryAddressLine2
        deliveryCity
        deliveryState
        deliveryCountry
        deliveryPostalCode
        youtube
        instagram
        tiktok
        facebook
        date_of_birth
        gender
        phone_number
        paypalUserName
        stripeCustomer
        profile_picture
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
    }
  }
`;
export const updateNotifications = /* GraphQL */ `
  mutation UpdateNotifications(
    $input: UpdateNotificationsInput!
    $condition: ModelNotificationsConditionInput
  ) {
    updateNotifications(input: $input, condition: $condition) {
      id
      notification_type
      message
      link
      isRead
      userID
      User {
        id
        email
        firstName
        lastName
        isActive
        approvedDate
        role
        phone
        deliveryAddressLine1
        deliveryAddressLine2
        deliveryCity
        deliveryState
        deliveryCountry
        deliveryPostalCode
        youtube
        instagram
        tiktok
        facebook
        date_of_birth
        gender
        phone_number
        paypalUserName
        stripeCustomer
        profile_picture
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
    }
  }
`;
export const deleteNotifications = /* GraphQL */ `
  mutation DeleteNotifications(
    $input: DeleteNotificationsInput!
    $condition: ModelNotificationsConditionInput
  ) {
    deleteNotifications(input: $input, condition: $condition) {
      id
      notification_type
      message
      link
      isRead
      userID
      User {
        id
        email
        firstName
        lastName
        isActive
        approvedDate
        role
        phone
        deliveryAddressLine1
        deliveryAddressLine2
        deliveryCity
        deliveryState
        deliveryCountry
        deliveryPostalCode
        youtube
        instagram
        tiktok
        facebook
        date_of_birth
        gender
        phone_number
        paypalUserName
        stripeCustomer
        profile_picture
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
    }
  }
`;
export const createMessage = /* GraphQL */ `
  mutation CreateMessage(
    $input: CreateMessageInput!
    $condition: ModelMessageConditionInput
  ) {
    createMessage(input: $input, condition: $condition) {
      id
      fromUserID
      toUserID
      message
      jobID
      Job {
        id
        jobStatus
        jobStartedDate
        jobEndedDate
        jobPrice
        jobApplicationFee
        invoiceLink
        deliveryTrackingInfo
        deliveryName
        deliveryAddressLine1
        deliveryAddressLine2
        deliveryCity
        deliveryState
        deliveryCountry
        deliveryPostalCode
        campaignID
        creatorID
        buyerID
        brandID
        productID
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
    }
  }
`;
export const updateMessage = /* GraphQL */ `
  mutation UpdateMessage(
    $input: UpdateMessageInput!
    $condition: ModelMessageConditionInput
  ) {
    updateMessage(input: $input, condition: $condition) {
      id
      fromUserID
      toUserID
      message
      jobID
      Job {
        id
        jobStatus
        jobStartedDate
        jobEndedDate
        jobPrice
        jobApplicationFee
        invoiceLink
        deliveryTrackingInfo
        deliveryName
        deliveryAddressLine1
        deliveryAddressLine2
        deliveryCity
        deliveryState
        deliveryCountry
        deliveryPostalCode
        campaignID
        creatorID
        buyerID
        brandID
        productID
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
    }
  }
`;
export const deleteMessage = /* GraphQL */ `
  mutation DeleteMessage(
    $input: DeleteMessageInput!
    $condition: ModelMessageConditionInput
  ) {
    deleteMessage(input: $input, condition: $condition) {
      id
      fromUserID
      toUserID
      message
      jobID
      Job {
        id
        jobStatus
        jobStartedDate
        jobEndedDate
        jobPrice
        jobApplicationFee
        invoiceLink
        deliveryTrackingInfo
        deliveryName
        deliveryAddressLine1
        deliveryAddressLine2
        deliveryCity
        deliveryState
        deliveryCountry
        deliveryPostalCode
        campaignID
        creatorID
        buyerID
        brandID
        productID
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
    }
  }
`;
export const createJobContent = /* GraphQL */ `
  mutation CreateJobContent(
    $input: CreateJobContentInput!
    $condition: ModelJobContentConditionInput
  ) {
    createJobContent(input: $input, condition: $condition) {
      id
      contentType
      contentUrl
      jobID
      Job {
        id
        jobStatus
        jobStartedDate
        jobEndedDate
        jobPrice
        jobApplicationFee
        invoiceLink
        deliveryTrackingInfo
        deliveryName
        deliveryAddressLine1
        deliveryAddressLine2
        deliveryCity
        deliveryState
        deliveryCountry
        deliveryPostalCode
        campaignID
        creatorID
        buyerID
        brandID
        productID
        createdAt
        updatedAt
      }
      campaignID
      picked
      creatorID
      buyerID
      Creator {
        id
        email
        firstName
        lastName
        isActive
        approvedDate
        role
        phone
        deliveryAddressLine1
        deliveryAddressLine2
        deliveryCity
        deliveryState
        deliveryCountry
        deliveryPostalCode
        youtube
        instagram
        tiktok
        facebook
        date_of_birth
        gender
        phone_number
        paypalUserName
        stripeCustomer
        profile_picture
        createdAt
        updatedAt
      }
      Buyer {
        id
        email
        firstName
        lastName
        isActive
        approvedDate
        role
        phone
        deliveryAddressLine1
        deliveryAddressLine2
        deliveryCity
        deliveryState
        deliveryCountry
        deliveryPostalCode
        youtube
        instagram
        tiktok
        facebook
        date_of_birth
        gender
        phone_number
        paypalUserName
        stripeCustomer
        profile_picture
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
    }
  }
`;
export const updateJobContent = /* GraphQL */ `
  mutation UpdateJobContent(
    $input: UpdateJobContentInput!
    $condition: ModelJobContentConditionInput
  ) {
    updateJobContent(input: $input, condition: $condition) {
      id
      contentType
      contentUrl
      jobID
      Job {
        id
        jobStatus
        jobStartedDate
        jobEndedDate
        jobPrice
        jobApplicationFee
        invoiceLink
        deliveryTrackingInfo
        deliveryName
        deliveryAddressLine1
        deliveryAddressLine2
        deliveryCity
        deliveryState
        deliveryCountry
        deliveryPostalCode
        campaignID
        creatorID
        buyerID
        brandID
        productID
        createdAt
        updatedAt
      }
      campaignID
      picked
      creatorID
      buyerID
      Creator {
        id
        email
        firstName
        lastName
        isActive
        approvedDate
        role
        phone
        deliveryAddressLine1
        deliveryAddressLine2
        deliveryCity
        deliveryState
        deliveryCountry
        deliveryPostalCode
        youtube
        instagram
        tiktok
        facebook
        date_of_birth
        gender
        phone_number
        paypalUserName
        stripeCustomer
        profile_picture
        createdAt
        updatedAt
      }
      Buyer {
        id
        email
        firstName
        lastName
        isActive
        approvedDate
        role
        phone
        deliveryAddressLine1
        deliveryAddressLine2
        deliveryCity
        deliveryState
        deliveryCountry
        deliveryPostalCode
        youtube
        instagram
        tiktok
        facebook
        date_of_birth
        gender
        phone_number
        paypalUserName
        stripeCustomer
        profile_picture
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
    }
  }
`;
export const deleteJobContent = /* GraphQL */ `
  mutation DeleteJobContent(
    $input: DeleteJobContentInput!
    $condition: ModelJobContentConditionInput
  ) {
    deleteJobContent(input: $input, condition: $condition) {
      id
      contentType
      contentUrl
      jobID
      Job {
        id
        jobStatus
        jobStartedDate
        jobEndedDate
        jobPrice
        jobApplicationFee
        invoiceLink
        deliveryTrackingInfo
        deliveryName
        deliveryAddressLine1
        deliveryAddressLine2
        deliveryCity
        deliveryState
        deliveryCountry
        deliveryPostalCode
        campaignID
        creatorID
        buyerID
        brandID
        productID
        createdAt
        updatedAt
      }
      campaignID
      picked
      creatorID
      buyerID
      Creator {
        id
        email
        firstName
        lastName
        isActive
        approvedDate
        role
        phone
        deliveryAddressLine1
        deliveryAddressLine2
        deliveryCity
        deliveryState
        deliveryCountry
        deliveryPostalCode
        youtube
        instagram
        tiktok
        facebook
        date_of_birth
        gender
        phone_number
        paypalUserName
        stripeCustomer
        profile_picture
        createdAt
        updatedAt
      }
      Buyer {
        id
        email
        firstName
        lastName
        isActive
        approvedDate
        role
        phone
        deliveryAddressLine1
        deliveryAddressLine2
        deliveryCity
        deliveryState
        deliveryCountry
        deliveryPostalCode
        youtube
        instagram
        tiktok
        facebook
        date_of_birth
        gender
        phone_number
        paypalUserName
        stripeCustomer
        profile_picture
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
    }
  }
`;
export const createJob = /* GraphQL */ `
  mutation CreateJob(
    $input: CreateJobInput!
    $condition: ModelJobConditionInput
  ) {
    createJob(input: $input, condition: $condition) {
      id
      jobStatus
      jobStartedDate
      jobEndedDate
      jobPrice
      jobApplicationFee
      invoiceLink
      deliveryTrackingInfo
      deliveryName
      deliveryAddressLine1
      deliveryAddressLine2
      deliveryCity
      deliveryState
      deliveryCountry
      deliveryPostalCode
      campaignID
      JobContents {
        nextToken
      }
      Campaign {
        id
        campaignName
        campaignStatus
        approvedDate
        campaignPrice
        description
        contentType
        contentFormat
        deliveryType
        userID
        brandID
        productID
        createdAt
        updatedAt
      }
      creatorID
      buyerID
      brandID
      Brand {
        id
        brandName
        externalLink
        imageUrl
        userID
        createdAt
        updatedAt
      }
      Creator {
        id
        email
        firstName
        lastName
        isActive
        approvedDate
        role
        phone
        deliveryAddressLine1
        deliveryAddressLine2
        deliveryCity
        deliveryState
        deliveryCountry
        deliveryPostalCode
        youtube
        instagram
        tiktok
        facebook
        date_of_birth
        gender
        phone_number
        paypalUserName
        stripeCustomer
        profile_picture
        createdAt
        updatedAt
      }
      Buyer {
        id
        email
        firstName
        lastName
        isActive
        approvedDate
        role
        phone
        deliveryAddressLine1
        deliveryAddressLine2
        deliveryCity
        deliveryState
        deliveryCountry
        deliveryPostalCode
        youtube
        instagram
        tiktok
        facebook
        date_of_birth
        gender
        phone_number
        paypalUserName
        stripeCustomer
        profile_picture
        createdAt
        updatedAt
      }
      Messages {
        nextToken
      }
      UserReviews {
        nextToken
      }
      productID
      Product {
        id
        productType
        productPrice
        productCategory
        productExternalLink
        productImage
        productDescription
        brandID
        userID
        productName
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
    }
  }
`;
export const updateJob = /* GraphQL */ `
  mutation UpdateJob(
    $input: UpdateJobInput!
    $condition: ModelJobConditionInput
  ) {
    updateJob(input: $input, condition: $condition) {
      id
      jobStatus
      jobStartedDate
      jobEndedDate
      jobPrice
      jobApplicationFee
      invoiceLink
      deliveryTrackingInfo
      deliveryName
      deliveryAddressLine1
      deliveryAddressLine2
      deliveryCity
      deliveryState
      deliveryCountry
      deliveryPostalCode
      campaignID
      JobContents {
        nextToken
      }
      Campaign {
        id
        campaignName
        campaignStatus
        approvedDate
        campaignPrice
        description
        contentType
        contentFormat
        deliveryType
        userID
        brandID
        productID
        createdAt
        updatedAt
      }
      creatorID
      buyerID
      brandID
      Brand {
        id
        brandName
        externalLink
        imageUrl
        userID
        createdAt
        updatedAt
      }
      Creator {
        id
        email
        firstName
        lastName
        isActive
        approvedDate
        role
        phone
        deliveryAddressLine1
        deliveryAddressLine2
        deliveryCity
        deliveryState
        deliveryCountry
        deliveryPostalCode
        youtube
        instagram
        tiktok
        facebook
        date_of_birth
        gender
        phone_number
        paypalUserName
        stripeCustomer
        profile_picture
        createdAt
        updatedAt
      }
      Buyer {
        id
        email
        firstName
        lastName
        isActive
        approvedDate
        role
        phone
        deliveryAddressLine1
        deliveryAddressLine2
        deliveryCity
        deliveryState
        deliveryCountry
        deliveryPostalCode
        youtube
        instagram
        tiktok
        facebook
        date_of_birth
        gender
        phone_number
        paypalUserName
        stripeCustomer
        profile_picture
        createdAt
        updatedAt
      }
      Messages {
        nextToken
      }
      UserReviews {
        nextToken
      }
      productID
      Product {
        id
        productType
        productPrice
        productCategory
        productExternalLink
        productImage
        productDescription
        brandID
        userID
        productName
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
    }
  }
`;
export const deleteJob = /* GraphQL */ `
  mutation DeleteJob(
    $input: DeleteJobInput!
    $condition: ModelJobConditionInput
  ) {
    deleteJob(input: $input, condition: $condition) {
      id
      jobStatus
      jobStartedDate
      jobEndedDate
      jobPrice
      jobApplicationFee
      invoiceLink
      deliveryTrackingInfo
      deliveryName
      deliveryAddressLine1
      deliveryAddressLine2
      deliveryCity
      deliveryState
      deliveryCountry
      deliveryPostalCode
      campaignID
      JobContents {
        nextToken
      }
      Campaign {
        id
        campaignName
        campaignStatus
        approvedDate
        campaignPrice
        description
        contentType
        contentFormat
        deliveryType
        userID
        brandID
        productID
        createdAt
        updatedAt
      }
      creatorID
      buyerID
      brandID
      Brand {
        id
        brandName
        externalLink
        imageUrl
        userID
        createdAt
        updatedAt
      }
      Creator {
        id
        email
        firstName
        lastName
        isActive
        approvedDate
        role
        phone
        deliveryAddressLine1
        deliveryAddressLine2
        deliveryCity
        deliveryState
        deliveryCountry
        deliveryPostalCode
        youtube
        instagram
        tiktok
        facebook
        date_of_birth
        gender
        phone_number
        paypalUserName
        stripeCustomer
        profile_picture
        createdAt
        updatedAt
      }
      Buyer {
        id
        email
        firstName
        lastName
        isActive
        approvedDate
        role
        phone
        deliveryAddressLine1
        deliveryAddressLine2
        deliveryCity
        deliveryState
        deliveryCountry
        deliveryPostalCode
        youtube
        instagram
        tiktok
        facebook
        date_of_birth
        gender
        phone_number
        paypalUserName
        stripeCustomer
        profile_picture
        createdAt
        updatedAt
      }
      Messages {
        nextToken
      }
      UserReviews {
        nextToken
      }
      productID
      Product {
        id
        productType
        productPrice
        productCategory
        productExternalLink
        productImage
        productDescription
        brandID
        userID
        productName
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
    }
  }
`;
export const createCampaign = /* GraphQL */ `
  mutation CreateCampaign(
    $input: CreateCampaignInput!
    $condition: ModelCampaignConditionInput
  ) {
    createCampaign(input: $input, condition: $condition) {
      id
      campaignName
      campaignStatus
      approvedDate
      campaignPrice
      description
      contentType
      contentFormat
      deliveryType
      userID
      Jobs {
        nextToken
      }
      User {
        id
        email
        firstName
        lastName
        isActive
        approvedDate
        role
        phone
        deliveryAddressLine1
        deliveryAddressLine2
        deliveryCity
        deliveryState
        deliveryCountry
        deliveryPostalCode
        youtube
        instagram
        tiktok
        facebook
        date_of_birth
        gender
        phone_number
        paypalUserName
        stripeCustomer
        profile_picture
        createdAt
        updatedAt
      }
      brandID
      productID
      Product {
        id
        productType
        productPrice
        productCategory
        productExternalLink
        productImage
        productDescription
        brandID
        userID
        productName
        createdAt
        updatedAt
      }
      Brand {
        id
        brandName
        externalLink
        imageUrl
        userID
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
    }
  }
`;
export const updateCampaign = /* GraphQL */ `
  mutation UpdateCampaign(
    $input: UpdateCampaignInput!
    $condition: ModelCampaignConditionInput
  ) {
    updateCampaign(input: $input, condition: $condition) {
      id
      campaignName
      campaignStatus
      approvedDate
      campaignPrice
      description
      contentType
      contentFormat
      deliveryType
      userID
      Jobs {
        nextToken
      }
      User {
        id
        email
        firstName
        lastName
        isActive
        approvedDate
        role
        phone
        deliveryAddressLine1
        deliveryAddressLine2
        deliveryCity
        deliveryState
        deliveryCountry
        deliveryPostalCode
        youtube
        instagram
        tiktok
        facebook
        date_of_birth
        gender
        phone_number
        paypalUserName
        stripeCustomer
        profile_picture
        createdAt
        updatedAt
      }
      brandID
      productID
      Product {
        id
        productType
        productPrice
        productCategory
        productExternalLink
        productImage
        productDescription
        brandID
        userID
        productName
        createdAt
        updatedAt
      }
      Brand {
        id
        brandName
        externalLink
        imageUrl
        userID
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
    }
  }
`;
export const deleteCampaign = /* GraphQL */ `
  mutation DeleteCampaign(
    $input: DeleteCampaignInput!
    $condition: ModelCampaignConditionInput
  ) {
    deleteCampaign(input: $input, condition: $condition) {
      id
      campaignName
      campaignStatus
      approvedDate
      campaignPrice
      description
      contentType
      contentFormat
      deliveryType
      userID
      Jobs {
        nextToken
      }
      User {
        id
        email
        firstName
        lastName
        isActive
        approvedDate
        role
        phone
        deliveryAddressLine1
        deliveryAddressLine2
        deliveryCity
        deliveryState
        deliveryCountry
        deliveryPostalCode
        youtube
        instagram
        tiktok
        facebook
        date_of_birth
        gender
        phone_number
        paypalUserName
        stripeCustomer
        profile_picture
        createdAt
        updatedAt
      }
      brandID
      productID
      Product {
        id
        productType
        productPrice
        productCategory
        productExternalLink
        productImage
        productDescription
        brandID
        userID
        productName
        createdAt
        updatedAt
      }
      Brand {
        id
        brandName
        externalLink
        imageUrl
        userID
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
    }
  }
`;
export const createProduct = /* GraphQL */ `
  mutation CreateProduct(
    $input: CreateProductInput!
    $condition: ModelProductConditionInput
  ) {
    createProduct(input: $input, condition: $condition) {
      id
      productType
      productPrice
      productCategory
      productExternalLink
      productImage
      productDescription
      brandID
      Brand {
        id
        brandName
        externalLink
        imageUrl
        userID
        createdAt
        updatedAt
      }
      userID
      productName
      Campaigns {
        nextToken
      }
      Jobs {
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const updateProduct = /* GraphQL */ `
  mutation UpdateProduct(
    $input: UpdateProductInput!
    $condition: ModelProductConditionInput
  ) {
    updateProduct(input: $input, condition: $condition) {
      id
      productType
      productPrice
      productCategory
      productExternalLink
      productImage
      productDescription
      brandID
      Brand {
        id
        brandName
        externalLink
        imageUrl
        userID
        createdAt
        updatedAt
      }
      userID
      productName
      Campaigns {
        nextToken
      }
      Jobs {
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const deleteProduct = /* GraphQL */ `
  mutation DeleteProduct(
    $input: DeleteProductInput!
    $condition: ModelProductConditionInput
  ) {
    deleteProduct(input: $input, condition: $condition) {
      id
      productType
      productPrice
      productCategory
      productExternalLink
      productImage
      productDescription
      brandID
      Brand {
        id
        brandName
        externalLink
        imageUrl
        userID
        createdAt
        updatedAt
      }
      userID
      productName
      Campaigns {
        nextToken
      }
      Jobs {
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const createBrand = /* GraphQL */ `
  mutation CreateBrand(
    $input: CreateBrandInput!
    $condition: ModelBrandConditionInput
  ) {
    createBrand(input: $input, condition: $condition) {
      id
      brandName
      externalLink
      imageUrl
      userID
      Products {
        nextToken
      }
      User {
        id
        email
        firstName
        lastName
        isActive
        approvedDate
        role
        phone
        deliveryAddressLine1
        deliveryAddressLine2
        deliveryCity
        deliveryState
        deliveryCountry
        deliveryPostalCode
        youtube
        instagram
        tiktok
        facebook
        date_of_birth
        gender
        phone_number
        paypalUserName
        stripeCustomer
        profile_picture
        createdAt
        updatedAt
      }
      Campaigns {
        nextToken
      }
      Jobs {
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const updateBrand = /* GraphQL */ `
  mutation UpdateBrand(
    $input: UpdateBrandInput!
    $condition: ModelBrandConditionInput
  ) {
    updateBrand(input: $input, condition: $condition) {
      id
      brandName
      externalLink
      imageUrl
      userID
      Products {
        nextToken
      }
      User {
        id
        email
        firstName
        lastName
        isActive
        approvedDate
        role
        phone
        deliveryAddressLine1
        deliveryAddressLine2
        deliveryCity
        deliveryState
        deliveryCountry
        deliveryPostalCode
        youtube
        instagram
        tiktok
        facebook
        date_of_birth
        gender
        phone_number
        paypalUserName
        stripeCustomer
        profile_picture
        createdAt
        updatedAt
      }
      Campaigns {
        nextToken
      }
      Jobs {
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const deleteBrand = /* GraphQL */ `
  mutation DeleteBrand(
    $input: DeleteBrandInput!
    $condition: ModelBrandConditionInput
  ) {
    deleteBrand(input: $input, condition: $condition) {
      id
      brandName
      externalLink
      imageUrl
      userID
      Products {
        nextToken
      }
      User {
        id
        email
        firstName
        lastName
        isActive
        approvedDate
        role
        phone
        deliveryAddressLine1
        deliveryAddressLine2
        deliveryCity
        deliveryState
        deliveryCountry
        deliveryPostalCode
        youtube
        instagram
        tiktok
        facebook
        date_of_birth
        gender
        phone_number
        paypalUserName
        stripeCustomer
        profile_picture
        createdAt
        updatedAt
      }
      Campaigns {
        nextToken
      }
      Jobs {
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const createPortfolioContent = /* GraphQL */ `
  mutation CreatePortfolioContent(
    $input: CreatePortfolioContentInput!
    $condition: ModelPortfolioContentConditionInput
  ) {
    createPortfolioContent(input: $input, condition: $condition) {
      id
      userID
      User {
        id
        email
        firstName
        lastName
        isActive
        approvedDate
        role
        phone
        deliveryAddressLine1
        deliveryAddressLine2
        deliveryCity
        deliveryState
        deliveryCountry
        deliveryPostalCode
        youtube
        instagram
        tiktok
        facebook
        date_of_birth
        gender
        phone_number
        paypalUserName
        stripeCustomer
        profile_picture
        createdAt
        updatedAt
      }
      contentType
      contentUrl
      createdAt
      updatedAt
    }
  }
`;
export const updatePortfolioContent = /* GraphQL */ `
  mutation UpdatePortfolioContent(
    $input: UpdatePortfolioContentInput!
    $condition: ModelPortfolioContentConditionInput
  ) {
    updatePortfolioContent(input: $input, condition: $condition) {
      id
      userID
      User {
        id
        email
        firstName
        lastName
        isActive
        approvedDate
        role
        phone
        deliveryAddressLine1
        deliveryAddressLine2
        deliveryCity
        deliveryState
        deliveryCountry
        deliveryPostalCode
        youtube
        instagram
        tiktok
        facebook
        date_of_birth
        gender
        phone_number
        paypalUserName
        stripeCustomer
        profile_picture
        createdAt
        updatedAt
      }
      contentType
      contentUrl
      createdAt
      updatedAt
    }
  }
`;
export const deletePortfolioContent = /* GraphQL */ `
  mutation DeletePortfolioContent(
    $input: DeletePortfolioContentInput!
    $condition: ModelPortfolioContentConditionInput
  ) {
    deletePortfolioContent(input: $input, condition: $condition) {
      id
      userID
      User {
        id
        email
        firstName
        lastName
        isActive
        approvedDate
        role
        phone
        deliveryAddressLine1
        deliveryAddressLine2
        deliveryCity
        deliveryState
        deliveryCountry
        deliveryPostalCode
        youtube
        instagram
        tiktok
        facebook
        date_of_birth
        gender
        phone_number
        paypalUserName
        stripeCustomer
        profile_picture
        createdAt
        updatedAt
      }
      contentType
      contentUrl
      createdAt
      updatedAt
    }
  }
`;
export const createUser = /* GraphQL */ `
  mutation CreateUser(
    $input: CreateUserInput!
    $condition: ModelUserConditionInput
  ) {
    createUser(input: $input, condition: $condition) {
      id
      email
      firstName
      lastName
      isActive
      approvedDate
      role
      phone
      deliveryAddressLine1
      deliveryAddressLine2
      deliveryCity
      deliveryState
      deliveryCountry
      deliveryPostalCode
      youtube
      instagram
      tiktok
      facebook
      date_of_birth
      gender
      phone_number
      paypalUserName
      stripeCustomer
      UserReviews {
        nextToken
      }
      Notifications {
        nextToken
      }
      Campaigns {
        nextToken
      }
      Brands {
        nextToken
      }
      Products {
        nextToken
      }
      CreatorJobs {
        nextToken
      }
      BuyerJobs {
        nextToken
      }
      PortfolioContents {
        nextToken
      }
      profile_picture
      CreatorJobContents {
        nextToken
      }
      BuyerJobContents {
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const updateUser = /* GraphQL */ `
  mutation UpdateUser(
    $input: UpdateUserInput!
    $condition: ModelUserConditionInput
  ) {
    updateUser(input: $input, condition: $condition) {
      id
      email
      firstName
      lastName
      isActive
      approvedDate
      role
      phone
      deliveryAddressLine1
      deliveryAddressLine2
      deliveryCity
      deliveryState
      deliveryCountry
      deliveryPostalCode
      youtube
      instagram
      tiktok
      facebook
      date_of_birth
      gender
      phone_number
      paypalUserName
      stripeCustomer
      UserReviews {
        nextToken
      }
      Notifications {
        nextToken
      }
      Campaigns {
        nextToken
      }
      Brands {
        nextToken
      }
      Products {
        nextToken
      }
      CreatorJobs {
        nextToken
      }
      BuyerJobs {
        nextToken
      }
      PortfolioContents {
        nextToken
      }
      profile_picture
      CreatorJobContents {
        nextToken
      }
      BuyerJobContents {
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const deleteUser = /* GraphQL */ `
  mutation DeleteUser(
    $input: DeleteUserInput!
    $condition: ModelUserConditionInput
  ) {
    deleteUser(input: $input, condition: $condition) {
      id
      email
      firstName
      lastName
      isActive
      approvedDate
      role
      phone
      deliveryAddressLine1
      deliveryAddressLine2
      deliveryCity
      deliveryState
      deliveryCountry
      deliveryPostalCode
      youtube
      instagram
      tiktok
      facebook
      date_of_birth
      gender
      phone_number
      paypalUserName
      stripeCustomer
      UserReviews {
        nextToken
      }
      Notifications {
        nextToken
      }
      Campaigns {
        nextToken
      }
      Brands {
        nextToken
      }
      Products {
        nextToken
      }
      CreatorJobs {
        nextToken
      }
      BuyerJobs {
        nextToken
      }
      PortfolioContents {
        nextToken
      }
      profile_picture
      CreatorJobContents {
        nextToken
      }
      BuyerJobContents {
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const createInvoice = /* GraphQL */ `
  mutation CreateInvoice(
    $input: CreateInvoiceInput!
    $condition: ModelInvoiceConditionInput
  ) {
    createInvoice(input: $input, condition: $condition) {
      id
      date
      stripeInvoiceID
      amount_due
      amount_paid
      amount_remaining
      total
      customer
      status
      buyerID
      creatorID
      campaignID
      jobID
      createdAt
      updatedAt
    }
  }
`;
export const updateInvoice = /* GraphQL */ `
  mutation UpdateInvoice(
    $input: UpdateInvoiceInput!
    $condition: ModelInvoiceConditionInput
  ) {
    updateInvoice(input: $input, condition: $condition) {
      id
      date
      stripeInvoiceID
      amount_due
      amount_paid
      amount_remaining
      total
      customer
      status
      buyerID
      creatorID
      campaignID
      jobID
      createdAt
      updatedAt
    }
  }
`;
export const deleteInvoice = /* GraphQL */ `
  mutation DeleteInvoice(
    $input: DeleteInvoiceInput!
    $condition: ModelInvoiceConditionInput
  ) {
    deleteInvoice(input: $input, condition: $condition) {
      id
      date
      stripeInvoiceID
      amount_due
      amount_paid
      amount_remaining
      total
      customer
      status
      buyerID
      creatorID
      campaignID
      jobID
      createdAt
      updatedAt
    }
  }
`;
