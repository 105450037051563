import { ChangeEvent, SyntheticEvent, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import {
  setNewCampaign,
  setNewCampaignElement,
} from 'redux/brand_slices/campaignSlice';
import { AppDispatch, RootState } from 'redux/store';

import hashID from 'helpers/hashID';

export default function useCampaignCreation(type?: string) {
  const navigate = useNavigate();
  const dispatch = useDispatch<AppDispatch>();
  const campaign: ICampaignData = useSelector(
    (root: RootState) => root.campaign.newCampaign
  );

  const { t } = useTranslation('translation', { keyPrefix: 'brand.campaigns' });

  const userID = useSelector((root: RootState) => root.user.profile.id);
  const brands = useSelector((root: RootState) => root.brand.brands);
  const products = useSelector((root: RootState) => root.product.products);

  const brandObjects: IValueWithIndex[] = [
    { index: 'NaN', value: 'All brands', displayValue: t('All brands') },
    ...brands.map((brand) => {
      return {
        index: brand.id,
        value: brand.brandName,
        displayValue: brand.brandName,
      };
    }),
  ];

  const [currentBrand, setCurrentBrand] = useState<IValueWithIndex>(
    brandObjects.find((brand) => brand.index === campaign.brandID) ??
      brandObjects[0]
  );

  const productObjects: IValueWithIndex[] = products
    .filter((product) => product.brandID === currentBrand?.index)
    .map((product) => {
      return {
        index: product.id,
        value: product.productName,
        displayValue: product.productName,
      };
    });

  const [currentProduct, setCurrentProduct] = useState<IValueWithIndex>(
    productObjects.find((product) => product.index === campaign.productID) ??
      productObjects[0]
  );

  const newProduct: IProductData = {
    id: hashID(8),
    brandID: currentBrand?.index || '',
    productCategory: -100,
    productDescription: '',
    productExternalLink: '',
    productImage: '',
    productName: '',
    productPrice: 0,
    productType: type === 'DIGITAL PRODUCT' ? 'DIGITAL' : 'PHYSICAL',
    userID: userID,
  };

  const handleSelectBrand = (elem: IValueWithIndex) => {
    setCurrentBrand(elem);
    dispatch(setNewCampaignElement({ name: 'brandID', value: elem.index }));
  };

  useEffect(() => {
    setCurrentProduct(
      productObjects.find((product) => product.index === campaign.productID) ??
        productObjects[0]
    );
  }, [currentBrand]);

  useEffect(() => {
    dispatch(
      setNewCampaignElement({
        name: 'productID',
        value: productObjects[0]?.index ?? -100,
      })
    );
  }, [campaign.brandID]);

  const handleSelectProduct = (elem: IValueWithIndex) => {
    setCurrentProduct(elem);
    dispatch(
      setNewCampaignElement({
        name: 'productID',
        value: elem.index,
      })
    );
  };

  useEffect(() => {
    dispatch(
      setNewCampaign({
        ...campaign,
        userID: userID,
        brandID: campaign.brandID ?? brandObjects[0]?.index,
      })
    );
  }, [userID]);

  const inputChangeHandler = (e: ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    dispatch(
      setNewCampaignElement({
        name,
        value,
      })
    );
  };

  const manualChangeHandler = (name: string, value: string) => {
    dispatch(
      setNewCampaignElement({
        name,
        value,
      })
    );
  };

  const submitHandler = (e: SyntheticEvent) => {
    e.preventDefault();
    navigate('/brand/campaign/product');
  };

  const nextEnabled =
    !!currentBrand &&
    currentBrand.index !== 'NaN' &&
    !!currentProduct &&
    !!campaign.campaignName;

  return {
    disabled: !nextEnabled,
    campaign,
    newProduct,
    newProductDisabled:
      currentBrand === undefined || currentBrand.index === 'NaN',
    brandObjects,
    productObjects,
    currentBrand,
    currentProduct,
    submitHandler,
    manualChangeHandler,
    inputChangeHandler,
    handleSelectBrand,
    handleSelectProduct,
  };
}
