import { LanguageIcon } from '@heroicons/react/20/solid';
import { useTranslation } from 'react-i18next';

export const languages = ['en', 'cn'];

export default function LocaleButton() {
  const { i18n } = useTranslation();

  const changeLanguageHandler = () => {
    const index = languages.indexOf(i18n.language) + 1;
    i18n.changeLanguage(languages[index % languages.length]);
    localStorage.setItem(
      'reeffo-language',
      languages[index % languages.length]
    );
  };

  return (
    <button
      onClick={changeLanguageHandler}
      className='uppercase min-w-[40px] flex justify-center rounded-full p-2 hover:bg-gray-100 active:bg-gray-200 dark:hover:bg-dark-theme-900/80 dark:active:bg-dark-theme-900'
    >
      <LanguageIcon className='w-5 h-5' />
    </button>
  );
}
