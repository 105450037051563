import { ReactElement } from 'react';

export function CampaignFormContainer(
  props: ICampaignFormContainer
): ReactElement {
  return (
    <div className={`form-container py-2 ${props.addClassName ?? ''}`}>
      <div className='flex justify-between'>
        <label
          htmlFor={props.htmlFor}
          className='mb-2 block text-base font-medium'
        >
          {props.labelText}
        </label>

        {props?.actionButton}
      </div>
      <div className='flex'>{props.inputElement}</div>
    </div>
  );
}
