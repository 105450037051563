import { ReactElement } from 'react';

import useAdminBrands from 'hooks/admin/useAdminBrands';
import Tab from 'components/tab';
import UserTable from '../_components/UserTable';

export default function UserListPage(): ReactElement {
  const { fetching, users, brands, creators } = useAdminBrands();

  return (
    <div className='container mx-auto px-4 lg:px-8 pb-16 lg:pb-32'>
      <Tab
        tabs={['All Users', 'Brand Users', 'Creator Users']}
        views={[
          <UserTable users={users} text='All Users' />,
          <UserTable users={brands} text='Brand Users' />,
          <UserTable users={creators} text='Creator Users' />,
        ]}
        tabBarAddClassName='mt-14'
      />
    </div>
  );
}
