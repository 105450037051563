import { ReactElement } from 'react';
import { Helmet } from 'react-helmet';
import { Navigate, Route, Routes } from 'react-router-dom';

import CreateCampaignProgressBar from './Bar.CreateCampaignProgress';

import CampaignCreation from './CampaignCreation';
import CampaignDetails from './CampaignDetails';
import CampaignSettings from './CampaignSettings';
import CampaignSummary from './CampaignSummary';

const routes: { path: string; element: ReactElement }[] = [
  {
    path: '/',
    element: <Navigate to='/create' replace />,
  },
  {
    path: 'create',
    element: <CampaignCreation />,
  },
  {
    path: 'product',
    element: <CampaignDetails />,
  },
  {
    path: 'settings',
    element: <CampaignSettings />,
  },
  {
    path: 'summary',
    element: <CampaignSummary />,
  },
];

export default function BrandNewCampaign(): ReactElement {
  window?.scrollTo({ top: 0 });

  return (
    <>
      <Helmet>
        <title>Brand - New Campaign</title>
      </Helmet>
      <div className='xl:container flex mx-auto'>
        <CreateCampaignProgressBar />
        <div className='w-full xl:w-[752px] 2xl:w-[1008px] relative'>
          <Routes>
            {routes.map((route, index) => (
              <Route key={route.path + index} {...route} />
            ))}
          </Routes>
        </div>
      </div>
    </>
  );
}
