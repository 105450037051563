const setting = {
  // aside bar
  Account: 'Account',
  Portfolio: 'Portfolio',
  Reviews: 'Reviews',
  Wallet: 'Wallet',
  'Payment methods': 'Payment methods',

  'Member since': 'Member since',
  'Active jobs': 'Active jobs',

  AccountPage: {
    'Personal information': 'Personal information',
    'First name': 'First name',
    'Last name': 'Last name',
    'Day of birth': 'Day of birth',
    'Not provided': 'Not provided',
    'Email': 'Email',
    Gender: 'Gender',
    Male: 'Male',
    Female: 'Female',

    // Buttons
    Cancel: 'Cancel',
    'Save changes': 'Save changes',
  },

  AddressPage: {
    'Delivery Address': 'Delivery Address',
    Country: 'Country',
    'Address line 1': 'Address line 1',
    'City/Town': 'City/Town',
    State: 'State',
    'Zip code': 'Zip code',
    'Not provided': 'Not provided',

    // Buttons
    Cancel: 'Cancel',
    'Save changes': 'Save changes',
  },

  SocialPage: {
    'Not provided': 'Not provided',
    Instagram: 'Instagram',
    Tiktok: 'Tiktok',
    Youtube: 'Youtube',
    'Social media links': 'Social media links',
    // Buttons
    Cancel: 'Cancel',
    'Save changes': 'Save changes',
  },

  CredentialPage: {
    'Your password': 'Your password',
    'Phone number': 'Phone number',
    'No phone number': 'No phone number',

    Credentials: 'Credentials',
    Password: 'Password',

    // Buttons
    Cancel: 'Cancel',
    'Save changes': 'Save changes',
  },

  PortfolioPage: {
    'My portfolio': 'My portfolio',
    'See public portfolio': 'See public portfolio',
    'Upload new content': 'Upload new content',
    'Submit content': 'Submit content',
    published: 'published',
  },

  ReviewPage: {
    'My Reviews': 'My Reviews',
    'No reviews': 'No reviews',
    "Looks like you don't have any reviews":
      "Looks like you don't have any reviews",
    'Go to marketplace': 'Go to marketplace',
  },

  Payment: {
    'Payout method': 'Payout method',
    'Your payouts will be sent directly to your PayPal account.':
      'Your payouts will be sent directly to your PayPal account.',
    'Paypal Username:': 'Paypal Username:',
    Edit: 'Edit',
    Cancel: 'Cancel',
    Save: 'Save',
  },
};

export default setting;
