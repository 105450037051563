import { ReactElement } from "react";
import { PencilSquareIcon } from "@heroicons/react/20/solid";
import { useTranslation } from "react-i18next";

import Card from "components/card";
import CardHr from "components/card/CardHr";
import Button from "components/button";
import CardText from "components/card/CardText";
import InputBox from "components/form/InputBox";
import CardTitle from "components/card/CardTitle";
import CardTextLabel from "components/card/CardLabelText";

import useEditCreatorProfile from "hooks/influencer/useEditCreatorProfile";

export default function AccountSocials({
  editing,
  setEditing,
}: {
  editing: IProfileEdit;
  setEditing: (value: IProfileEdit) => void;
}): ReactElement {
  const { t } = useTranslation("translation", {
    keyPrefix: "creator.setting.SocialPage",
  });

  const {
    pending,
    user,
    editedUser,
    isEditing,
    toEditMode,
    editUserHandler,
    cancelEditHandler,
    updateUserProfileHandler,
  } = useEditCreatorProfile(editing, setEditing);

  return (
    <Card addClassName="p-4 md:p-8 mt-8">
      <CardTitle title={t("Social media links")} />
      <CardHr />
      <div className={`${isEditing ? "hidden" : "block"} relative`}>
        <PencilSquareIcon
          onClick={() => toEditMode("SOCIAL")}
          className="cursor-pointer w-6 h-6 absolute top-0 right-2"
        />
        <CardTextLabel>{t("Instagram")}</CardTextLabel>
        <CardText addClassName="mb-2">{user.instagram ?? t("Not provided")}</CardText>
        <CardTextLabel>{t("Tiktok")}</CardTextLabel>
        <CardText addClassName="mb-2">{user.tiktok ?? t("Not provided")}</CardText>
        <CardTextLabel>{t("Youtube")}</CardTextLabel>
        <CardText addClassName="mb-2">{user.youtube ?? t("Not provided")}</CardText>
      </div>

      <div className={`${!isEditing ? "hidden" : "block"}`}>
        <div className="grid grid-cols-1 sm:grid-cols-2 gap-x-5 gap-2">
          <div className="flex flex-col gap-2">
            <CardTextLabel>{t("Instagram")}</CardTextLabel>
            <InputBox
              name="instagram"
              value={editedUser.instagram ?? ""}
              placeholder="Instagram link"
              onChange={editUserHandler}
            />
          </div>
          <div className="flex flex-col gap-2">
            <CardTextLabel>{t("Tiktok")}</CardTextLabel>
            <InputBox
              name="tiktok"
              value={editedUser.tiktok ?? ""}
              placeholder="Tiktok link"
              onChange={editUserHandler}
            />
          </div>
          <div className="flex flex-col gap-2">
            <CardTextLabel>{t("Youtube")}</CardTextLabel>
            <InputBox
              name="youtube"
              value={editedUser.youtube ?? ""}
              placeholder="Youtube link"
              onChange={editUserHandler}
            />
          </div>
        </div>
        <div className="mt-4 flex flex-col sm:flex-row gap-x-5 gap-y-2">
          <Button onClick={cancelEditHandler} variant="outline">
            {t("Cancel")}
          </Button>
          <Button pending={pending} onClick={updateUserProfileHandler}>
            {t("Save changes")}
          </Button>
        </div>
      </div>
    </Card>
  );
}
