import { ReactElement } from 'react';

export default function TableRow(props: ITableRow): ReactElement {
  return (
    <tr
      className={`${
        props.onClick ? 'cursor-pointer' : ''
      } border-b-[1px] border-b-gray-200 even:bg-gray-50 hover:bg-gray-100 dark:even:bg-dark-theme-900 dark:hover:bg-dark-theme-900/20 dark:even:hover:bg-dark-theme-900 dark:border-b-dark-theme-200 dark:bg-dark-theme-900/20`}
      onClick={props.onClick}
    >
      {props.children}
    </tr>
  );
}
