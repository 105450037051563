import { ReactElement } from 'react';
import {
  PencilSquareIcon,
  EyeIcon,
  TrashIcon,
} from '@heroicons/react/24/solid';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import TableRow from 'components/table/TableRow';
import TableCell from 'components/table/TableCell';
import TableBody from 'components/table/TableBody';
import TableHeader from 'components/table/TableHeader';
import TableWrapper from 'components/table/TableWrapper';
import EllipsisVerticalButton from 'components/button/Button.EllipsisVertical';

import AmplifyImage from 'views/components/media/StorageImageRenderer';

export default function ProductTable({
  brands,
  products,
  campaigns,
  contents,
  ...props
}: IProductTable): ReactElement {
  const { t } = useTranslation('translation', { keyPrefix: 'brand.products' });
  const navigate = useNavigate();

  const navigateToDetail = (productIndex: string) => {
    navigate('/brand/products/' + productIndex);
  };

  const headerArray = [t('Product'), t('Brand'), t('My content'), ''];

  const optionClickHandler = (
    productIndex: string,
    optionIndex: number,
    productType: 'DIGITAL' | 'PHYSICAL'
  ) => {
    if (optionIndex === 0) {
      // see details
      navigateToDetail(productIndex);
      return;
    }

    if (optionIndex === 1) {
      // edit product
      props.onProductEdit(productIndex, productType);
      return;
    }
  };

  return (
    <TableWrapper>
      <TableHeader headerArray={headerArray} />

      <TableBody>
        {products.length === 0 && (
          <TableRow>
            <td colSpan={5} className='text-center h-[200px]'>
              {t('No products')}
            </td>
          </TableRow>
        )}
        {products.length > 0 &&
          products.map((product, index) => (
            <TableRow
              key={product.id + index}
              onClick={() => navigateToDetail(product.id)}
            >
              <TableCell>
                <div className='flex'>
                  <AmplifyImage
                    className='min-w-[60px] w-[60px] h-[60px] rounded-lg object-cover'
                    sourceKey={product?.productImage}
                    width={60}
                    height={60}
                  />

                  <div className='ml-4 flex flex-col justify-around w-full'>
                    <span className='text-sm text-gray-700 dark:text-gray-100 max-w-[500px] truncate'>
                      {product.productName}
                    </span>
                    <span className='text-sm truncate text-gray-500 dark:text-gray-400'>
                      {t('Active campaigns')}:{' '}
                      {
                        campaigns.filter(
                          (campaign) => campaign.productID === product.id
                        ).length
                      }
                    </span>
                  </div>
                </div>
              </TableCell>
              <TableCell>{product?.Brand?.brandName}</TableCell>
              <TableCell>
                {(() => {
                  const campaignIDsFromProduct = campaigns
                    .filter((campaign) => campaign.productID === product.id)
                    .map((campaign) => campaign.id);
                  let count = 0;
                  contents.forEach((content) => {
                    if (campaignIDsFromProduct.includes(content.campaignID)) {
                      count++;
                    }
                  });
                  return count;
                })()}
              </TableCell>
              <TableCell addClassName='w-20'>
                <EllipsisVerticalButton
                  optionPosition={'bottomLeft'}
                  optionLists={[
                    [
                      <>
                        <EyeIcon className='w-4 h-4' />
                        {t('See details')}
                      </>,
                      <>
                        <PencilSquareIcon className='w-4 h-4' />
                        {t('Edit product')}
                      </>,
                    ],
                    [
                      <>
                        <TrashIcon className='w-4 h-4' />
                        {t('Remove product')}
                      </>,
                    ],
                  ]}
                  onOptionClick={(optionIndex: number) =>
                    optionClickHandler(
                      product.id,
                      optionIndex,
                      product.productType
                    )
                  }
                />
              </TableCell>
            </TableRow>
          ))}
      </TableBody>

      {props.tableInfo && (
        <tfoot>
          <tr>
            <td colSpan={5}>
              <p className='pt-9'>{props.tableInfo}</p>
            </td>
          </tr>
        </tfoot>
      )}
    </TableWrapper>
  );
}
