import { ReactElement, useState } from 'react';

export default function FlatTab({
  initActiveIndex,
  ...props
}: IFlatTab): ReactElement {
  const [activeIndex, setActiveIndex] = useState<number>(initActiveIndex || 0);

  const tabChangeHandler = (index: number) => {
    setActiveIndex(index);
    props.onTabChange && props.onTabChange(index);
  };

  return (
    <>
      <div
        className={`${
          props.tabBarAddClassName ?? ''
        } border-b border-gray-200 dark:border-dark-theme-200`}
      >
        <ul className='flex flex-wrap -mb-px font-medium text-center text-gray-500 dark:text-gray-200'>
          {props.tabs.map((tab, index) => (
            <li key={tab + index} className='mr-2'>
              <button
                onClick={() => tabChangeHandler(index)}
                className={`inline-flex py-4 px-6 rounded-t-lg border-b-2 active group hover:bg-gray-50 active:bg-gray-100 dark:hover:bg-dark-theme-200 ${
                  index === activeIndex
                    ? 'text-theme border-theme dark:text-theme-variant dark:border-theme-variant dark:bg-dark-theme-200'
                    : 'hover:text-gray-600 hover:border-gray-300 dark:hover:text-gray-100'
                }`}
              >
                {props.tabIcons && props.tabIcons[index]}
                {tab}
              </button>
            </li>
          ))}
        </ul>
      </div>
      {props.pending && props.pendingBody}
      {props.pending !== true && props.views[activeIndex % props.views.length]}
    </>
  );
}
