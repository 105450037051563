import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import { createProduct, editProduct, getAllProducts } from 'apis/brand/product';
import { removeDatabaseDates } from 'helpers/object';
import { toast } from 'react-toastify';
import { logoutAction } from 'redux/common_slices/authSlice';

export const getAllProductAction = createAsyncThunk(
  'brand/product/get/all',
  async (userID: string) => {
    const result = await getAllProducts(userID);
    return result;
  }
);

export const createProductAction = createAsyncThunk(
  'brand/product/create',
  async (data: IProductData) => {
    const result = await createProduct(data);
    return result;
  }
);

export const updateProductAction = createAsyncThunk(
  'brand/product/update',
  async (data: IProductData) => {
    const result = await editProduct(removeDatabaseDates(data));
    return result;
  }
);

const initialState: IProductState = {
  pending: false,
  error: null,
  products: [],
};

export const campaignSlice = createSlice({
  name: 'product',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getAllProductAction.pending, (state: IProductState, action) => {
        if (!state.pending) {
          state.pending = true;
          state.currentRequestId = action.meta.requestId;
        }
      })
      .addCase(
        getAllProductAction.fulfilled,
        (state: IProductState, action) => {
          const { requestId } = action.meta;
          if (state.pending && state.currentRequestId === requestId) {
            state.pending = false;
            state.products = action.payload.data;
            state.currentRequestId = undefined;
          }
          return state;
        }
      )
      .addCase(getAllProductAction.rejected, (state: IProductState, action) => {
        const { requestId } = action.meta;
        if (state.pending && state.currentRequestId === requestId) {
          state.pending = false;
          state.error = action.error;
          state.currentRequestId = undefined;
        }
      });

    builder
      .addCase(createProductAction.pending, (state: IProductState, action) => {
        if (!state.pending) {
          state.pending = true;
          state.currentRequestId = action.meta.requestId;
        }
      })
      .addCase(
        createProductAction.fulfilled,
        (state: IProductState, action) => {
          const { requestId } = action.meta;
          if (state.pending && state.currentRequestId === requestId) {
            toast.success('Successfully created a product.');
            state.pending = false;
            state.products = [...state.products, action.payload.data];
            state.currentRequestId = undefined;
          }
          return state;
        }
      )
      .addCase(createProductAction.rejected, (state: IProductState, action) => {
        const { requestId } = action.meta;
        if (state.pending && state.currentRequestId === requestId) {
          toast.error(
            'There was an error while creating a product, try again.'
          );
          state.pending = false;
          state.error = action.error;
          state.currentRequestId = undefined;
        }
      });

    builder
      .addCase(updateProductAction.pending, (state: IProductState, action) => {
        if (!state.pending) {
          state.pending = true;
          state.currentRequestId = action.meta.requestId;
        }
      })
      .addCase(
        updateProductAction.fulfilled,
        (state: IProductState, action) => {
          const { requestId } = action.meta;
          if (state.pending && state.currentRequestId === requestId) {
            toast.success('Successfully updated a product.');
            state.pending = false;
            state.products = [...state.products].map((product) =>
              product.id === action.payload.data.id
                ? action.payload.data
                : product
            );
            state.currentRequestId = undefined;
          }
          return state;
        }
      )
      .addCase(updateProductAction.rejected, (state: IProductState, action) => {
        const { requestId } = action.meta;
        if (state.pending && state.currentRequestId === requestId) {
          toast.error('There was an error while updating product, try again.');
          state.pending = false;
          state.error = action.error;
          state.currentRequestId = undefined;
        }
      });

    // set remove redux data when they logout
    builder.addCase(logoutAction.fulfilled, (state: IProductState) => {
      state = initialState;
      return state;
    });
  },
});

// export const {} = campaignSlice.actions;

export default campaignSlice.reducer;
