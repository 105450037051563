import { ReactElement } from 'react';

import Card from 'components/card';
import CardHr from 'components/card/CardHr';

import SearchInput from 'views/brand/_components/Input.SearchBox';

import JobTable from './Table.Job';

import useFetchedJobDataByCampaignID from 'hooks/common/useFetchedJobDataByCampaignID';

export default function CampaignJobTableCard(): ReactElement {
  const { jobs, fetching } = useFetchedJobDataByCampaignID();

  return (
    <Card addClassName='p-4 md:p-8'>
      <div className='flex gap-20'>
        <SearchInput />
      </div>
      <CardHr />
      <JobTable jobs={jobs} fetching={fetching} />
    </Card>
  );
}
