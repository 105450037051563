import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getAllFilteredPostAction } from 'redux/influencer_slices/creatorSlice';
import { AppDispatch, RootState } from 'redux/store';

export default function useFilteredJobPosts() {
  const dispatch = useDispatch<AppDispatch>();
  const campaigns = useSelector((root: RootState) => root.creator.posts as ICampaignDataResponse[]);
  const pending = useSelector((root: RootState) => root.creator.pending);

  const changeQueryHandler = (query: string) => {
    dispatch(getAllFilteredPostAction(query));
  };

  useEffect(() => {
    dispatch(getAllFilteredPostAction(''));

    return () => {};
  }, []);

  const sortedCampaigns = [...campaigns].sort((c1, c2) => {
    try {
      const diff = Date.parse(c2.createdAt) - Date.parse(c1.createdAt);
      return diff;
    } catch (err) {
      return 0;
    }
  })

  return { pending, campaigns: sortedCampaigns, changeQueryHandler };
}
