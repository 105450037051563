import { ReactElement } from 'react';
import { NavLink } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import Rating from 'components/rating/Rating';
import helperGetAverateRating from 'helpers/rating';
import AmplifyMedia from 'views/components/media/StorageImageRenderer';

export default function JobTable(props: {
  jobs: IJobData[];
  fetching: boolean;
}): ReactElement {
  const { t } = useTranslation('translation', { keyPrefix: 'brand.campaigns' });
  const { t: transJobStatus } = useTranslation('translation', {
    keyPrefix: 'creator.marketplace.jobStatus',
  });

  return (
    <div className='grid sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 2xl:grid-cols-5 gap-x-8 gap-y-4'>
      {props.jobs.length > 0 &&
        props.jobs.map((jobItem) => (
          <div
            key={jobItem.id}
            className='bg-white dark:bg-dark-theme-900 rounded-lg pt-5 pb-3 shadow-card flex flex-col'
          >
            <div className='w-full px-5'>
              <AmplifyMedia
                className='w-full aspect-square object-cover'
                sourceKey={jobItem.Product?.productImage}
              />
            </div>
            <p className='text-center capitalize pt-3 px-5'>
              {transJobStatus(jobItem.jobStatus)}
            </p>
            <div className='mt-2 pl-5 pr-2'>
              <NavLink to={`/brand/creator/${jobItem.creatorID}`}>
                <div className='flex items-center gap-2 font-medium w-full'>
                  <AmplifyMedia
                    className='min-w-[40px] sm:min-w-[48px] w-10 h-10 sm:w-12 sm:h-12 !rounded-full'
                    width={12}
                    height={12}
                    sourceKey={jobItem.Creator?.profile_picture}
                  />
                  <div className='capitalize mr-auto truncate'>
                    {`${jobItem.Creator?.firstName} ${jobItem.Creator?.lastName}`}

                    <Rating
                      mini
                      rating={helperGetAverateRating(
                        jobItem.Creator?.UserReviews?.items
                      )}
                    />
                  </div>
                </div>
              </NavLink>
            </div>
            <div className='mt-2 flex justify-center'>
              <NavLink
                to={`/brand/campaigns/${jobItem.campaignID}/job-detail/${jobItem.id}`}
              >
                <button className='px-4 py-2 rounded hover:bg-theme/20 transition-all duration-200'>
                  {t('Job details')}
                </button>
              </NavLink>
            </div>
          </div>
        ))}

      {props.jobs.length === 0 && (
        <div className='flex justify-center h-44 items-center sm:col-span-2 lg:col-span-3 xl:col-span-4 2xl:col-span-5'>
          {t(props.fetching ? 'loading' : 'NoJobMessage')}
        </div>
      )}
    </div>
  );
}
