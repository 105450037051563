import { inviteCreator } from 'apis/brand/campaign';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import { RootState } from 'redux/store';

export default function useInviteCreator() {
  const { t } = useTranslation('translation', {
    keyPrefix: 'notification',
  });

  const buyerID = useSelector((root: RootState) => root.user.profile.id);

  const jobs = useSelector((root: RootState) => root.job.jobs);

  const inviteCreatorHandler = async (
    creator: IUserProfile,
    campaign?: ICampaignData
  ) => {
    if (campaign === undefined) return;

    const safeInvitation = jobs.filter(
      (job) => job.creatorID === creator.id && job.campaignID === campaign?.id
    );

    if (safeInvitation.length > 0) {
      toast.info(
        t('You have an active job with this creator for this campaign')
      );
    }

    const job: IJobData = {
      id: 'This will be ignored',
      brandID: campaign.brandID,
      buyerID: buyerID,
      creatorID: creator.id,
      campaignID: campaign.id,
      deliveryAddressLine1: creator.deliveryAddressLine1,
      deliveryAddressLine2: creator.deliveryAddressLine2,
      deliveryCity: creator.deliveryCity,
      deliveryCountry: creator.deliveryCountry,
      deliveryName: null,
      deliveryPostalCode: creator.deliveryPostalCode,
      deliveryState: creator.deliveryState,
      deliveryTrackingInfo: null,
      invoiceLink: null,
      jobApplicationFee: 0,
      jobStartedDate: null,
      jobEndedDate: null,
      jobPrice: 0,
      jobStatus: 'invited',
      productID: campaign.productID,
    };
    try {
      await inviteCreator(job);
      toast.success(t('Invitation sent!'));
    } catch (e) {
      toast.error(t('There was an error while inviting a creator.'));
    }
  };
  return { inviteCreatorHandler };
}
