import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { RootState } from 'redux/store';

import {
  createJobContent,
  getJobContentsByJobID,
} from 'apis/common/jobContent';
import { toast } from 'react-toastify';
import { updateJob } from 'apis/common/job';
import { useTranslation } from 'react-i18next';

export default function useFetchedJobContent(hook?: any) {
  const { t } = useTranslation('translation', {
    keyPrefix: 'notification',
  });

  const { jobID } = useParams();
  const userProfile = useSelector((root: RootState) => root.user.profile);

  const [jobContents, setJobContents] = useState<IJobContent[]>([]);
  const [error, setError] = useState<boolean>(false);
  const [fetching, setFetching] = useState<boolean>(false);

  const fetchJobContents = async () => {
    if (jobID === undefined) return;
    setFetching(true);
    try {
      const response = await getJobContentsByJobID(jobID);
      setJobContents(response.data.jobContentsByJobID.items);
      setError(false);
    } catch (e) {
      setJobContents([]);
      setError(true);
    }
    setFetching(false);
  };

  useEffect(() => {
    fetchJobContents();

    return () => {};
  }, [jobID]);

  const uploadContentHandler = async (
    urls: string[],
    files: File[],
    job?: IJobData
  ) => {
    if (
      userProfile.role === 'BRAND' ||
      jobID === undefined ||
      job === undefined
    )
      return;

    // They can't upload in these cases
    if (['open', 'rejected', 'completed', 'invited'].includes(job.jobStatus)) {
      toast.warn(t("You can't upload any contents."));
      return;
    }
    const creatorID = userProfile.id;

    try {
      for (let i = 0; i < urls.length; i++) {
        const jobContent: IJobContent = {
          id: 'Will be ignored',
          jobID: jobID,
          contentType: files[i].type.includes('image') ? 'image' : 'video',
          contentUrl: urls[i],
          campaignID: job.campaignID,
          creatorID,
          buyerID: job.buyerID,
          picked: false,
        };
        const response = await createJobContent(jobContent);
      }
      await updateJob({ id: jobID, jobStatus: 'content-uploaded' });
      toast.success(t('Successfully uploaded contents'));
      hook && hook(new Date().getTime() % 1000);
    } catch (e) {}
    fetchJobContents();
  };

  return { fetching, error, jobContents, uploadContentHandler };
}
