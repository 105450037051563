import {
  doMockResetPassword,
  // doMockSignIn,
  // doMockSignUp,
} from 'mock_backend/mock_api_endpoints/auth';
import { Auth } from 'aws-amplify';

export async function doSignUp(param: {
  credential: ICredentials;
  type: 'BRAND' | 'CREATOR';
}) {
  const signupParams: any = {
    //todo: i dont know typescript, is 'any' correct here?
    username: param.credential.email,
    password: param.credential.password,
    attributes: {
      given_name: param.credential.first_name.toLowerCase(),
      email: param.credential.email,
      family_name: param.credential.last_name.toLowerCase(),
      profile: param.type, // hard coded BRAND or CREATOR
    },
    autoSignIn: {
      // optional - enables auto sign in after user is confirmed
      enabled: true,
    },
  };
  const { user } = await Auth.signUp(signupParams);
  return user;
}

export async function doSignIn(data: ICredentials): Promise<boolean | any> {
  // const result = await doMockSignIn(data.email, data.password);
  const user = await Auth.signIn(data.email, data.password);
  return {
    id: user.attributes.sub,
    email: user.username,
    firstName: user.attributes.given_name,
    lastName: user.attributes.family_name,
    role: user.attributes.profile,
  };
}

export async function resetPassword(email: string) {
  const result = await doMockResetPassword(email);
  return result;
}

export async function confirmSignup(confirmParam: {
  emailAddress: string;
  authCode: string;
}) {
  const result = await Auth.confirmSignUp(
    confirmParam.emailAddress,
    confirmParam.authCode
  );
  return result;
}

export async function resendAuthCode(emailAddress: string) {
  const result = await Auth.resendSignUp(emailAddress);
  return result;
}

export async function logout() {
  const result = await Auth.signOut();
  return result;
}

export async function getSession(): Promise<boolean | any> {
  const session = await Auth.currentSession();
  const userInfo = await Auth.currentUserInfo();
  return userInfo
    ? {
        success: true,
        data: {
          id: userInfo.attributes.sub,
          email: userInfo.username,
          firstName: userInfo.attributes.given_name,
          lastName: userInfo.attributes.family_name,
          profile: userInfo.attributes.profile,
        },
      }
    : { success: false };
}
