import { ReactElement } from 'react';
import useProductCategoryCombo from 'hooks/common/useProductCategoryCombo';

import ComboBox from 'components/form/Combobox/Combobox';

export default function ProductCategoryCombo(
  props: IProductCategoryCombo
): ReactElement {
  const { categoryObjects, selectedCategory, setSelectedCategory } =
    useProductCategoryCombo(props.onCategoryChange);

  return (
    <ComboBox
      data={categoryObjects}
      selectedData={selectedCategory}
      setSelectedData={setSelectedCategory}
    />
  );
}
