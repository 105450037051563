import { ReactComponent as ArrowBack } from 'assets/svg/arrow-back.svg';
import { ReactComponent as Cross } from 'assets/svg/cross.svg';
import { ReactComponent as DeviceTypeAny } from 'assets/svg/device-type-any.svg';
import { ReactComponent as DeviceTypePortrait } from 'assets/svg/device-type-portrait.svg';
import { ReactComponent as DeviceTypeLandscape } from 'assets/svg/device-type-landscape.svg';
import { ReactComponent as DeviceTypeSquare } from 'assets/svg/device-type-square.svg';
import { ReactComponent as Google } from 'assets/svg/google.svg';
import { ReactComponent as FacebookGray } from 'assets/svg/facebook-gray.svg';
import { ReactComponent as InstagramGray } from 'assets/svg/instagram-gray.svg';
import { ReactComponent as PhysicalProduct } from 'assets/svg/physical-product.svg';
import { ReactComponent as DigitalProduct } from 'assets/svg/digital-product.svg';

export default function Icon(props: IIcon) {
  const className = props?.iconClassName ?? '';

  const attribute = { className };

  const icons = {
    'ARROW-BACK': <ArrowBack {...attribute} />,
    CROSS: <Cross {...attribute} />,
    'DEVICE-TYPE-ANY': <DeviceTypeAny {...attribute} />,
    'DEVICE-TYPE-PORTRAIT': <DeviceTypePortrait {...attribute} />,
    'DEVICE-TYPE-LANDSCAPE': <DeviceTypeLandscape {...attribute} />,
    'DEVICE-TYPE-SQUARE': <DeviceTypeSquare {...attribute} />,
    GOOGLE: <Google {...attribute} />,
    'FACEBOOK-GRAY': <FacebookGray {...attribute} />,
    'INSTAGRAM-GRAY': <InstagramGray {...attribute} />,
    'PHYSICAL-PRODUCT': <PhysicalProduct {...attribute} />,
    'DIGITAL-PRODUCT': <DigitalProduct {...attribute} />,
  };

  return icons[props.iconName] ?? icons.GOOGLE;
}
