import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import {
  getAllNotifications,
  getAllUserReviews,
  getUserData,
  shootMessage,
  updateUserProfile,
} from 'apis/common/userAPI';
import { toast } from 'react-toastify';
import { logoutAction } from './authSlice';

const initialState: IUserState = {
  pending: false,
  error: null,

  messages: [],
  showMobileSidebar: false,
  showNotification: false,
  notifications: [],
  reviews: [],
  profile: {
    id: '',
    email: '',
    firstName: '',
    lastName: '',
    profile_picture: null,
    resetHash: null,
    isActive: false,
    approvedDate: null,
    role: 'BRAND',
    date_of_birth: null,
    deliveryAddressLine1: null,
    deliveryAddressLine2: null,
    deliveryCity: null,
    deliveryCountry: null,
    deliveryPostalCode: null,
    deliveryState: null,
    facebook: null,
    instagram: null,
    tiktok: null,
    youtube: null,
    gender: null,
    phone: '',
    stripeCustomer: null,
    paypalUserName: null,
  },
};

export const shootMessageAction = createAsyncThunk(
  'message/shoot',
  async (data: IMessage) => {
    const result = await shootMessage(data);
    return result;
  }
);

export const getAllReviewAction = createAsyncThunk(
  'reviews/all',
  async (userID: string) => {
    const result = await getAllUserReviews(userID);
    return result;
  }
);

export const getAllNotificationAction = createAsyncThunk(
  'notifications/all',
  async (userID: string) => {
    const result = await getAllNotifications(userID);
    return result;
  }
);

export const getUserDataAction = createAsyncThunk(
  'user/getdata',
  async (id: string) => {
    const result = await getUserData(id);
    return result;
  }
);

// creator profile update
export const updateUserProfileAction = createAsyncThunk(
  'user/profile/update',
  async (data: IUserProfile) => {
    const response = await updateUserProfile(data);
    return response;
  }
);

export const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    setAuthedUserProfileData: (state, action: PayloadAction<any>) => {
      state.profile = action.payload;
      return state;
    },
    setNotificationSidebarVisibility: (
      state: IUserState,
      action: PayloadAction<any>
    ) => {
      state.showNotification = action.payload;
      return state;
    },
    setMobileSidebarVisibility: (
      state: IUserState,
      action: PayloadAction<any>
    ) => {
      state.showMobileSidebar = action.payload;
      return state;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getUserDataAction.pending, (state: IUserState) => {
        state.pending = true;
        return state;
      })
      .addCase(
        getUserDataAction.fulfilled,
        (state: IUserState, action: any) => {
          state.pending = false;
          state.profile = {
            ...action.payload.data.getUser,
            role:
              state.profile.role === 'ADMIN'
                ? 'ADMIN'
                : action.payload.data.getUser.role,
          };
          return state;
        }
      )
      .addCase(getUserDataAction.rejected, (state: IUserState) => {
        state.pending = false;
        return state;
      });

    builder
      .addCase(updateUserProfileAction.pending, (state: IUserState, action) => {
        if (!state.pending) {
          state.currentRequestId = action.meta.requestId;
          state.pending = true;
        }
        return state;
      })
      .addCase(
        updateUserProfileAction.fulfilled,
        (state: IUserState, action: any) => {
          const { requestId } = action.meta;
          if (state.pending && state.currentRequestId === requestId) {
            state.pending = false;
            state.profile = action.payload.data.updateUser;
            toast.success('Successfully updated profile.');
            state.currentRequestId = undefined;
          }
          return state;
        }
      )
      .addCase(
        updateUserProfileAction.rejected,
        (state: IUserState, action) => {
          const { requestId } = action.meta;
          if (state.pending && state.currentRequestId === requestId) {
            state.pending = false;
            state.error = action.error;
            toast.error('There wasn an error while updating user profile.');
            state.currentRequestId = undefined;
          }
          return state;
        }
      );

    builder
      .addCase(getAllReviewAction.pending, (state: IUserState, action) => {
        state.pending = true;
        return state;
      })
      .addCase(
        getAllReviewAction.fulfilled,
        (state: IUserState, action: any) => {
          state.pending = false;
          state.reviews = action.payload.data.userReviewsByUserID.items;
          return state;
        }
      )
      .addCase(getAllReviewAction.rejected, (state: IUserState, action) => {
        state.pending = false;
        state.error = action.error;
        return state;
      });

    builder
      .addCase(
        getAllNotificationAction.pending,
        (state: IUserState, action) => {
          state.pending = true;
          return state;
        }
      )
      .addCase(
        getAllNotificationAction.fulfilled,
        (state: IUserState, action: any) => {
          state.pending = false;
          state.notifications = action.payload.data;
          return state;
        }
      )
      .addCase(
        getAllNotificationAction.rejected,
        (state: IUserState, action) => {
          const { requestId } = action.meta;
          if (state.pending && state.currentRequestId === requestId) {
            state.pending = false;
            state.error = action.error;
            state.currentRequestId = undefined;
          }
          return state;
        }
      );

    builder
      .addCase(shootMessageAction.pending, (state: IUserState, action) => {
        if (!state.pending) {
          state.currentRequestId = action.meta.requestId;
          state.pending = true;
        }
        return state;
      })
      .addCase(
        shootMessageAction.fulfilled,
        (state: IUserState, action: any) => {
          const { requestId } = action.meta;
          if (state.pending && state.currentRequestId === requestId) {
            state.pending = false;
            state.messages = [...state.messages, action.payload.data];
            state.currentRequestId = undefined;
          }
          return state;
        }
      )
      .addCase(shootMessageAction.rejected, (state: IUserState, action) => {
        const { requestId } = action.meta;
        if (state.pending && state.currentRequestId === requestId) {
          state.pending = false;
          state.error = action.error;
          state.currentRequestId = undefined;
        }
        return state;
      });

    builder.addCase(logoutAction.fulfilled, (state: IUserState) => {
      state = initialState;
      return state;
    });
  },
});

export const {
  setAuthedUserProfileData,
  setMobileSidebarVisibility,
  setNotificationSidebarVisibility,
} = userSlice.actions;

export default userSlice.reducer;
