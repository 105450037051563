import { ReactElement } from 'react';

export default function TableCell(props: ITableCell): ReactElement {
  return (
    <td
      colSpan={props.colSpan}
      className={`text-sm text-gray-900 dark:text-gray-200 p-4 whitespace-nowrap text-left ${
        props.addClassName ?? ''
      }`}
    >
      {props.children}
    </td>
  );
}
