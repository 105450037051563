import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { toast } from 'react-toastify';

import {
  createCampaign,
  getAllCampaigns,
  removeCampaign,
} from 'apis/brand/campaign';
import hashID from 'helpers/hashID';
import { logoutAction } from 'redux/common_slices/authSlice';

export const getAllCampaignAction = createAsyncThunk(
  'brand/campaigns/get/all',
  async (userID: string) => {
    const result = await getAllCampaigns(userID);
    return result;
  }
);

export const createCampaignAction = createAsyncThunk(
  'brand/campaign/create',
  async (data: ICampaignData) => {
    const result = await createCampaign(data);
    return result;
  }
);

export const removeCampaignAction = createAsyncThunk(
  'brand/campaign/remove',
  async (data: ICampaignData) => {
    const result = await removeCampaign(data);
    return result;
  }
);

export const initCampaignData: ICampaignData = {
  id: hashID(8),
  brandID: '',
  userID: '',
  productID: '',
  campaignName: '',
  campaignPrice: 995,
  deliveryType: 'Brand will ship directly',
  campaignStatus: 'OPEN',
  contentType: 'video',
  contentFormat: 'any',
  description: '',
  approvedDate: null,
};

const initialState: ICampaignState = {
  pending: false,
  error: null,
  newCampaign: initCampaignData,
  campaigns: [],
};

export const campaignSlice = createSlice({
  name: 'campaign',
  initialState,
  reducers: {
    setNewCampaignElement(
      state: ICampaignState,
      action: PayloadAction<{ name: string; value: number | string | null }>
    ) {
      state.newCampaign = {
        ...state.newCampaign,
        [action.payload.name]: action.payload.value,
      };
      return state;
    },
    setNewCampaign(
      state: ICampaignState,
      action: PayloadAction<ICampaignData>
    ) {
      state.newCampaign = action.payload;
      return state;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(
        getAllCampaignAction.pending,
        (state: ICampaignState, action) => {
          if (!state.pending) {
            state.pending = true;
            state.currentRequestId = action.meta.requestId;
          }
        }
      )
      .addCase(
        getAllCampaignAction.fulfilled,
        (state: ICampaignState, action) => {
          const { requestId } = action.meta;
          if (state.pending && state.currentRequestId === requestId) {
            state.pending = false;
            state.campaigns = action.payload.data;
            state.currentRequestId = undefined;
          }
          return state;
        }
      )
      .addCase(
        getAllCampaignAction.rejected,
        (state: ICampaignState, action) => {
          const { requestId } = action.meta;
          if (state.pending && state.currentRequestId === requestId) {
            state.pending = false;
            state.error = action.error;
            state.currentRequestId = undefined;
          }
        }
      );

    builder
      .addCase(
        createCampaignAction.pending,
        (state: ICampaignState, action) => {
          if (!state.pending) {
            state.pending = true;
            state.currentRequestId = action.meta.requestId;
          }
        }
      )
      .addCase(
        createCampaignAction.fulfilled,
        (state: ICampaignState, action) => {
          const { requestId } = action.meta;
          if (state.pending && state.currentRequestId === requestId) {
            toast.success('Successfully created a new campaign.');
            state.pending = false;
            state.campaigns = [
              ...state.campaigns,
              action.payload.data.createCampaign,
            ];
            state.currentRequestId = undefined;
          }
          return state;
        }
      )
      .addCase(
        createCampaignAction.rejected,
        (state: ICampaignState, action) => {
          const { requestId } = action.meta;
          if (state.pending && state.currentRequestId === requestId) {
            toast.error(
              'There was an error while creating a new campaign, please try again.'
            );
            state.pending = false;
            state.error = action.error;
            state.currentRequestId = undefined;
          }
        }
      );

    builder
      .addCase(
        removeCampaignAction.pending,
        (state: ICampaignState, action) => {
          if (!state.pending) {
            state.pending = true;
            state.currentRequestId = action.meta.requestId;
          }
        }
      )
      .addCase(
        removeCampaignAction.fulfilled,
        (state: ICampaignState, action) => {
          const { requestId } = action.meta;
          if (state.pending && state.currentRequestId === requestId) {
            toast.success('Successfully removed campaign.');
            state.pending = false;
            state.campaigns = [...state.campaigns].filter(
              (campaign) =>
                campaign.id !== action.payload.data.deleteCampaign.id
            );
            state.currentRequestId = undefined;
          }
          return state;
        }
      )
      .addCase(
        removeCampaignAction.rejected,
        (state: ICampaignState, action) => {
          const { requestId } = action.meta;
          if (state.pending && state.currentRequestId === requestId) {
            toast.error(
              'There was an error while removing campaign, please try again.'
            );
            state.pending = false;
            state.error = action.error;
            state.currentRequestId = undefined;
          }
        }
      );

    // set remove redux data when they logout
    builder.addCase(logoutAction.fulfilled, (state: ICampaignState) => {
      state = initialState;
      return state;
    });
  },
});

export const { setNewCampaign, setNewCampaignElement } = campaignSlice.actions;

export default campaignSlice.reducer;
