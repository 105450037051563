import { ReactElement } from 'react';
import { Navigate, Route, Routes } from 'react-router-dom';

import AdminJobPage from 'views/admin/jobs';
import JobDetailView from 'views/admin/jobs/detail';
import UserListPage from 'views/admin/users';

export default function AdminRoute({ auth }: { auth: boolean }): ReactElement {
  const routes: IRoutesGroup[] = [
    {
      path: '/',
      element: <Navigate to={'users'} />,
    },
    {
      path: '/job',
      element: <AdminJobPage />,
      authRequired: true,
    },
    {
      path: '/job/:jobID',
      element: <JobDetailView />,
      authRequired: true,
    },
    {
      path: '/users',
      element: <UserListPage />,
      authRequired: true,
    },
  ];

  //fallback when theres no auth
  const fallbackRoute = <Navigate to={`/admin/`} replace />;

  return (
    <Routes>
      {routes
        .filter((route) =>
          route.authRequired === false && auth ? false : true
        )
        .map(({ authRequired, element, ...route }, index) => (
          <Route
            key={'app-admin-route-' + index}
            {...route}
            element={authRequired ? (auth ? element : fallbackRoute) : element}
          />
        ))}
      <Route path='/*' element={<Navigate to={auth ? '/admin' : `/brand`} />} />
    </Routes>
  );
}
