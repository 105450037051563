import { ReactElement } from 'react';

export default function Loader({
  scale = 1,
}: {
  scale?: number;
}): ReactElement {
  const scaleClassNames = new Array(10).map(
    (_elem, index) => `scale-[${index}]`
  );

  return (
    <div
      className={`lds-ripple absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 ${
        scaleClassNames[scale] ?? ''
      }`}
    >
      <div className='border-coral-red border-[4px]'></div>
      <div className='border-coral-red border-[4px]'></div>
    </div>
  );
}
