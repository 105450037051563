// import {
//   doMockCreateBrand,
//   doMockEditBrand,
//   getMockAllBrands,
// } from 'mock_backend/mock_api_endpoints/brands';
import { API } from 'aws-amplify';

import * as queries from 'graphql/queries';
import * as mutations from 'graphql/mutations';

export async function getAllBrands(userID: string) {
  const result = (await API.graphql({
    query: queries.brandsByUserID,
    variables: { userID },
  })) as any; //todo is this right to do any?

  //const result = await getMockAllBrands(userId);
  return { success: true, data: result.data.brandsByUserID.items }; //todo is this how we replace mock items ?
}

export async function createBrand(data: IBrandData) {
  const result = (await API.graphql({
    query: mutations.createBrand,
    variables: { input: data },
  })) as any; //todo is "any" type correct here?
  return { success: true, data: result.data.createBrand };
}

export async function editBrand(data: IBrandData) {
  const result = (await API.graphql({
    query: mutations.updateBrand,
    variables: { input: data },
  })) as any; //todo is this  "any" type correct here?
  return { success: true, data: result.data.updateBrand };
}
