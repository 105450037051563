const landing = {
  'Pick content type': '选择内容类型',
  'Browse our library of jobs from cross-industrial brands, from health suppleements to household items. Select one (or many!) brands that piqued your interest.':
    '多个品牌共您选择！',
  'Create Content': '只做内容',
  "Have fun taking video and photos of the product. Don't hold back, let your creativity soar and show off your eloquent charm!":
    "根据要求自由装作内容！",
  'Submmit. Ka-ching!': '提交，等钱进账!',
  'Upload your content and a payment will be deposited instantly.':
    '内容提交后收款。',
  'Get Paid for Doing What You Love.': '爱创作，轻松赚！',
  'Pick a brand you love. Receive a free product. And get paid for doing one short video clip and shooting three photos.':
    '选择您喜爱的品牌，免费收到产品，根据要求制作一个视频三张图片，提及内容后收款。',
  "Let's get started!": "现在开始!",
  'How it works': '如何操作',
  'Ready to get started?': '准备好了?',
  'Sign Me Up Now!': '现在注册!',
  '1. Pick A Job': '1. 选择任务',
  '2. Create Content': '2. 制作内容',
  '3. Submit and Earn': '3. 提交收款',
};

export default landing;
