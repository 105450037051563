import { ReactElement } from 'react';

import Tab from 'components/tab';
import AuthedHeading from 'components/typhography/AuthedHeading';
import useAdminJobs from 'hooks/admin/useAdminJobs';
import JobTableCard from 'views/influencer/my_jobs/components/Card.JobTable';

export default function AdminJobPage(): ReactElement {
  const { jobs } = useAdminJobs();

  return (
    <div className='container mx-auto px-4 lg:px-8 pb-16 lg:pb-32'>
      <AuthedHeading>All jobs</AuthedHeading>

      {(() => {
        const openJobs = [];
        const invitedJobs = [];
        const startedJobs = [];
        const completedJobs = [];
        const rejectedJobs = [];

        for (let i = 0; i < jobs.length; i++) {
          if (jobs[i].jobStatus === 'open') {
            openJobs.push(jobs[i]);
          } else if (jobs[i].jobStatus === 'invited') {
            invitedJobs.push(jobs[i]);
          } else if (
            [
              'started',
              'product-sent',
              'product-delivered',
              'content-uploaded',
            ].includes(jobs[i].jobStatus)
          ) {
            startedJobs.push(jobs[i]);
          } else if (jobs[i].jobStatus === 'completed') {
            completedJobs.push(jobs[i]);
          } else if (jobs[i].jobStatus === 'rejected') {
            rejectedJobs.push(jobs[i]);
          }
        }

        return (
          <Tab
            tabs={[
              'All',
              'Open',
              'Invited',
              'Started',
              'Completed',
              'Rejected',
            ]}
            views={[
              <JobTableCard jobs={jobs} />,
              <JobTableCard jobs={openJobs} />,
              <JobTableCard jobs={invitedJobs} />,
              <JobTableCard jobs={startedJobs} />,
              <JobTableCard jobs={completedJobs} />,
              <JobTableCard jobs={rejectedJobs} />,
            ]}
            tabBarAddClassName='mt-6 mb-10'
          />
        );
      })()}
    </div>
  );
}
