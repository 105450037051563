import { ReactElement } from 'react';
import { useTranslation } from 'react-i18next';

import SettingHeading from '../components/SettingHeading';
import BrandTable from '../components/Table.Brand';

import Card from 'components/card';
import CardHr from 'components/card/CardHr';
import Button from 'components/button';
import Modal from 'components/modal';

import BrandModal from 'views/brand/new_campaign/components/Modal.Brand';
import SearchInput from 'views/brand/_components/Input.SearchBox';

import useBrandModal from '../hooks/useBrandModal';
import useFilteredBrand from 'hooks/brand/useFilteredBrand';

export default function SettingBrandsCRUD(): ReactElement {
  const { t } = useTranslation('translation', {
    keyPrefix: 'brand.settings.brand',
  });

  const {
    products,
    campaigns,
    modal,
    closeModal,
    openCreateModal,
    openEditModal,
  } = useBrandModal();

  const { brands, setQuery } = useFilteredBrand();

  return (
    <>
      <main className='flex flex-col space-y-6'>
        <SettingHeading>{t('Brands')}</SettingHeading>
        <Card addClassName='p-4 md:p-8 pb-6'>
          <div className='flex flex-col md:flex-row gap-y-4 justify-between items-center'>
            <SearchInput className='w-full md:w-fit' onQueryChange={setQuery} />
            <Button
              onClick={openCreateModal}
              className='w-full md:w-fit px-8 h-11 font-medium'
            >
              {t('Add brand')}
            </Button>
          </div>
          <CardHr />
          <div className='overflow-y-auto pb-2'>
            <BrandTable
              brands={brands}
              products={products}
              campaigns={campaigns}
              onEditBrand={openEditModal}
            />
          </div>
        </Card>
      </main>
      <Modal closeModal={closeModal} isOpen={modal.visibility}>
        <BrandModal
          data={modal.data}
          modalType={modal.modalType}
          closeModal={closeModal}
        />
      </Modal>
    </>
  );
}
