import { getAllJobs } from 'apis/common/job';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { RootState } from 'redux/store';

export default function useFetchedJobDataByBuyerID() {
  const userID = useSelector((root: RootState) => root.user.profile.id);

  const [fetching, setFetching] = useState<boolean>(false);
  const [error, setError] = useState<boolean>(false);
  const [jobs, setJobs] = useState<IJobData[]>([]);

  useEffect(() => {
    (async () => {
      setFetching(true);
      try {
        const response = await getAllJobs('brand', userID);
        console.log('response', response);
        setJobs(response.data.jobsByBuyerID.items);
        setError(false);
      } catch (e) {
        setError(true);
      }
      setFetching(false);
    })();

    return () => {};
  }, [userID]);

  return { fetching, jobs, error };
}
