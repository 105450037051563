import { ReactElement } from 'react';
import { useSelector } from 'react-redux';
import { NavLink } from 'react-router-dom';
import { RootState } from 'redux/store';
import { useTranslation } from 'react-i18next';

import {
  CreditCardIcon,
  GiftIcon,
  StarIcon,
  UserCircleIcon,
  // WalletIcon,
} from '@heroicons/react/20/solid';
import Rating from 'components/rating/Rating';

import AmplifyMedia from 'views/components/media/StorageImageRenderer';

// helper
import helperGetAverageRating from 'helpers/rating';

import useCreatorJobs from 'hooks/influencer/useCreatorJobs';

export default function SettingAsideBar(): ReactElement {
  const { jobs } = useCreatorJobs();
  const { t } = useTranslation('translation', { keyPrefix: 'creator.setting' });

  const optionIconClassName = 'min-w-[20px] w-5 h-5';
  const profile = useSelector((root: RootState) => root.user.profile);
  const reviews = useSelector((root: RootState) => root.user.reviews);

  const navLinks: { text: string; link?: string; icon: any }[] = [
    {
      text: 'Account',
      link: 'account',
      icon: <UserCircleIcon className={optionIconClassName} />,
    },
    {
      text: 'Portfolio',
      link: 'portfolio',
      icon: <GiftIcon className={optionIconClassName} />,
    },
    {
      text: 'Reviews',
      link: 'reviews',
      icon: <StarIcon className={optionIconClassName} />,
    },
    // {
    //   text: 'Wallet',
    //   link: 'wallet',
    //   icon: <WalletIcon className={optionIconClassName} />,
    // },
    {
      text: 'Payment methods',
      link: 'payment-methods',
      icon: <CreditCardIcon className={optionIconClassName} />,
    },
  ];

  return (
    <div className='hidden xl:block border-r border-gray-200 xl:w-[264px] dark:border-dark-theme-200'>
      <aside className='xl:flex flex-col w-full xl:w-[264px] h-full xl:p-6 flex-shrink-0 z-0 xl:h-[calc(100vh-212px)]'>
        <div className='flex flex-col flex-grow overflow-y-auto'>
          <div className='flex flex-col pb-6 mb-6 border-gray-200 dark:border-dark-theme-200 border-b'>
            <AmplifyMedia
              sourceKey={profile.profile_picture}
              isUserAvatar
              className='mb-3 w-16 h-16 rounded-full'
              width={64}
              height={64}
            />
            <div className='text-lg font-semibold text-gray-900 dark:text-gray-200 capitalize'>
              {profile.firstName} {profile.lastName}
            </div>
            <div className='text-sm text-gray-500 dark:text-gray-300'>
              {t('Member since')}:{' '}
              {profile.approvedDate &&
                new Date(profile.approvedDate).toLocaleDateString()}
            </div>
            <div className='mt-3 text-xs'>
              <span className='text-gray-500 pr-2 dark:text-gray-300'>
                {t('Active jobs')}
              </span>
              <span>
                {
                  jobs.filter(
                    (job) =>
                      job.jobStatus !== 'completed' &&
                      job.jobStatus !== 'invited' &&
                      job.jobStatus !== 'rejected'
                  ).length
                }{' '}
                / {jobs.length}
              </span>
            </div>
            <div className='mt-2 flex gap-1 items-center'>
              {(() => {
                const avgRating = helperGetAverageRating(reviews);

                return (
                  <>
                    <Rating rating={avgRating} mini />
                    <span className='text-xs block pl-1'>
                      {avgRating.toFixed(1)}{' '}
                      <span className='text-gray-400'>
                        ({reviews.length || 0})
                      </span>
                    </span>
                  </>
                );
              })()}
            </div>
          </div>
          <nav className='flex-grow space-y-6 flex flex-col'>
            {navLinks.map((navItem, index) =>
              navItem.link ? (
                <NavLink
                  key={navItem.link + index}
                  className={({ isActive }) =>
                    `${
                      isActive
                        ? 'text-dark-black dark:text-gray-200'
                        : 'text-gray-400 dark:text-gray-500'
                    } pl-1 text-sm font-medium flex items-center gap-4`
                  }
                  to={navItem.link}
                >
                  {navItem.icon}
                  {t(navItem.text)}
                </NavLink>
              ) : (
                <div
                  key={navItem.text + index}
                  className='pl-1 text-gray-400 text-sm font-medium flex items-center gap-4'
                >
                  {navItem.icon}
                  {t(navItem.text)}
                </div>
              )
            )}
          </nav>
        </div>
      </aside>
    </div>
  );
}
