import { Fragment, useState } from 'react';
import { Menu, Transition } from '@headlessui/react';
import { CheckIcon, ChevronUpDownIcon } from '@heroicons/react/20/solid';
import { useTranslation } from 'react-i18next';

export default function ComboBox(props: IComboBox) {
  // const [focus, setFocus] = useState<boolean>(false);
  const { t } = useTranslation('translation', { keyPrefix: 'brand.campaigns' });

  const [query, setQuery] = useState<string>('');

  const filteredData =
    query === ''
      ? props.data
      : props.data.filter((elem) =>
          elem.value
            .toLowerCase()
            .replace(/\s+/g, '')
            .includes(query.toLowerCase().replace(/\s+/g, ''))
        );

  return (
    <Menu as='div' className='relative w-full'>
      <Menu.Button
        disabled={props.disabled}
        onClick={(e: any) => e.stopPropagation()}
        className='w-full cursor-default overflow-hidden rounded-lg bg-white border-[1px] border-gray-200 dark:border-dark-theme-200'
      >
        <input
          className={`cursor-pointer w-full outline-none border-none h-11 pl-4 pr-10 text-sm leading-5 text-gray-900 dark:bg-dark-theme-900 dark:text-white ${
            props.valueCapitalize ? 'capitalize' : ''
          }`}
          value={props.selectedData?.displayValue ?? ''}
          onChange={(e) => setQuery(e.target.value)}
          placeholder={props.placeholder}
        />
        <span className='absolute inset-y-0 right-0 flex items-center pr-2'>
          <ChevronUpDownIcon
            className='h-5 w-5 text-gray-400 dark:text-gray-300'
            aria-hidden='true'
          />
        </span>
      </Menu.Button>
      <Transition
        as={Fragment}
        leave='transition ease-in duration-100'
        leaveFrom='opacity-100'
        leaveTo='opacity-0'
        afterLeave={() => setQuery('')}
      >
        <Menu.Items
          as='ul'
          className='absolute mt-1 max-h-80 w-full overflow-auto rounded-md bg-white dark:bg-dark-theme-200 py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm px-1 shadow-card z-50'
        >
          {filteredData.length === 0 ? (
            <div className='relative cursor-default select-none py-10 text-center text-gray-700 dark:text-gray-300'>
              {t('Nothing found')}.
            </div>
          ) : (
            filteredData.map((elem) => {
              const active = elem?.index === props.selectedData?.index;
              return (
                <Menu.Button
                  key={elem.index}
                  className={'w-full'}
                  onClick={(e: any) => {
                    e.stopPropagation();
                    props.setSelectedData(elem);
                  }}
                >
                  <li
                    key={elem?.index}
                    className={`relative group cursor-default select-none py-2 pl-10 pr-4 rounded ${
                      active ? 'bg-gray-100 dark:bg-dark-theme-900/70' : ''
                    } hover:bg-gray-100 dark:hover:bg-dark-theme-900/50 text-gray-900 dark:text-gray-200 dark:hover:text-gray-200`}
                  >
                    {
                      <>
                        <span
                          className={`block truncate ${
                            props.optionCapitalize ? 'capitalize' : ''
                          } text-start ${
                            active ? 'font-medium' : 'font-normal'
                          }`}
                        >
                          {elem?.displayValue}
                        </span>
                        {active ? (
                          <span
                            className={`absolute inset-y-0 left-0 flex items-center pl-3 group-hover:text-gray-900 text-gray-600 dark:text-gray-200 dark:group-hover:text-gray-200`}
                          >
                            <CheckIcon className='h-5 w-5' aria-hidden='true' />
                          </span>
                        ) : null}
                      </>
                    }
                  </li>
                </Menu.Button>
              );
            })
          )}
        </Menu.Items>
      </Transition>
    </Menu>
  );
}
