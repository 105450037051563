import { ChangeEvent, KeyboardEvent, ReactElement, useState } from 'react';
import { useTranslation } from 'react-i18next';

import Button from '../../../components/button';

export default function MessageInput(props: IMessageInput): ReactElement {
  const { t } = useTranslation('translation', {
    keyPrefix: 'creator.marketplace',
  });

  const [message, setMessage] = useState<string>('');

  const shootMessageHandler = () => {
    if (message === '') return;
    props.onShoot(message);
    setMessage('');
  };

  const keydownHandler = (e: KeyboardEvent<HTMLInputElement>) => {
    if (e.key !== 'Enter' || props.pending) {
      return;
    }
    shootMessageHandler();
  };

  return (
    <div className='relative w-full'>
      <input
        onKeyDown={keydownHandler}
        value={message}
        onChange={(e: ChangeEvent<HTMLInputElement>) =>
          setMessage(e.target.value)
        }
        placeholder={t('Write message...') ?? ''}
        className='text-black placeholder:font-medium text-sm h-12 outline-none border-[1px] border-gray-300 rounded-full w-full pl-8 pr-20'
      />

      <div className='absolute right-1 rounded-full overflow-hidden top-1/2 -translate-y-1/2'>
        <Button
          onClick={shootMessageHandler}
          disabled={!message}
          pending={props.pending}
          className='rounded-full w-fit px-6 md:w-[160px] h-10 text-sm font-medium'
        >
          {t('Send')}
        </Button>
      </div>
    </div>
  );
}
