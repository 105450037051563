const landing = {
  'Quality UGC Package for A Fraction of the Price!':
    'Quality UGC Package for A Fraction of the Price!',
  "Let's get started!": "Let's get started!",
  'How it works': 'How it works',
  'Ready to get started?': 'Ready to get started?',
  'Sign Me Up Now!': 'Sign Me Up Now!',
  'Post A Job': 'Post A Job',
  'Add pictures of your brand and your products. Share somande cool facts about your brand. Describe your content requirements. Creators will start applying in minutes.':
    'Add pictures of your brand and your products. Share somande cool facts about your brand. Describe your content requirements. Creators will start applying in minutes.',
  'Pick content type': 'Pick content type',
  'Select creators that best represent your brand':
    'Select creators that best represent your brand',
  'As creators apply to your job, their portfolio will become accessible for you to review. Browse through their profiles and pick the ones that best represent your brand.':
    'As creators apply to your job, their portfolio will become accessible for you to review. Browse through their profiles and pick the ones that best represent your brand.',
  'Approve Content': 'Approve Content',
  'You will receive a notification once the selected creator delivers the content. Chat with them for any editing requests or simply just to let them know how happy you are with their creative work.':
    'You will receive a notification once the selected creator delivers the content. Chat with them for any editing requests or simply just to let them know how happy you are with their creative work.',
  '1. Post A Job': '1. Post A Job',
  '2. Select Creators': '2. Select Creators',
  '3. Approve Content': '3. Approve Content',
};

export default landing;
