import useCreatorJobs from './useCreatorJobs';

export default function useSafeCampaign(campaign?: ICampaignData) {
  const { jobs } = useCreatorJobs();

  const safety =
    jobs.filter((job) => job.campaignID === campaign?.id).length === 0;

  return { safety };
}
