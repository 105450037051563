import { ReactElement } from 'react';
import { Route, Routes } from 'react-router-dom';

import CampaignDetailView from './CampaignDetailView';
import CampaignJobDetailView from './CampaignJobDetailView';

export default function CampaignDetailHome(): ReactElement {
  return (
    <Routes>
      <Route path='/' element={<CampaignDetailView />} />
      <Route path='/job-detail/:jobID' element={<CampaignJobDetailView />} />
    </Routes>
  );
}
