import {
  BanknotesIcon,
  CheckBadgeIcon,
  CloudArrowUpIcon,
  ForwardIcon,
  PaperAirplaneIcon,
  RectangleStackIcon,
} from '@heroicons/react/24/outline';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { RootState } from 'redux/store';

const jobStatus = [
  'waiting-payment',
  'started',
  'product-sent',
  'product-delivered',
  'content-uploaded',
  'completed',
];

interface IJobProgressBar {
  jobStatus: TJobStatus;
  onStepClick: (status: TJobStatus) => void;
}

function getValidStepCursor(
  userRole: 'CREATOR' | 'BRAND' | 'ADMIN',
  status: TJobStatus
): string {
  const pointerClassName = 'cursor-pointer';
  if (userRole === 'BRAND') {
    if (['product-sent', 'completed'].includes(status)) {
      return pointerClassName;
    }
  }
  if (userRole === 'CREATOR') {
    if (['product-delivered', 'content-uploaded'].includes(status)) {
      return pointerClassName;
    }
  }
  return '';
}

const icons: Record<any, any> = {
  'waiting-payment': <BanknotesIcon className='w-5 h-5 md:w-6 md:h-6' />,
  started: <ForwardIcon className='w-5 h-5 md:w-6 md:h-6' />,
  completed: <CheckBadgeIcon className='w-5 h-5 md:w-6 md:h-6' />,
  'product-sent': <PaperAirplaneIcon className='w-5 h-5 md:w-6 md:h-6' />,
  'product-delivered': <RectangleStackIcon className='w-5 h-5 md:w-6 md:h-6' />,
  'content-uploaded': <CloudArrowUpIcon className='w-5 h-5 md:w-6 md:h-6' />,
};

export default function JobPorgressBar({
  jobStatus: currentStatus,
  onStepClick,
}: IJobProgressBar) {
  const { t } = useTranslation('translation', {
    keyPrefix: 'creator.marketplace.jobStatus',
  });

  const currentIndex = jobStatus.findIndex(
    (status) => status === currentStatus
  );

  const userRole = useSelector((root: RootState) => root.user.profile.role);

  return (
    <div className='progress-bar h-36 relative max-sm:mt-6'>
      <div className='w-[92vw] md:w-[660px] lg:w-[900px] absolute top-1/2 -translate-y-1/2 left-1/2 -translate-x-1/2'>
        <div className='px-8 grid grid-cols-5'>
          {new Array(5).fill(true).map((_step, index) => (
            <div
              className={`h-1 rounded-full ${
                currentIndex >= index
                  ? 'bg-theme dark:bg-white/90'
                  : 'bg-dark-theme-200/20 dark:bg-white/20'
              } ${currentIndex === index ? 'animate-pulse' : ''}`}
            ></div>
          ))}
        </div>
      </div>
      <div className='w-[92vw] md:w-[660px] lg:w-[900px] top-1/2 -translate-y-1/2 left-1/2 -translate-x-1/2 absolute flex justify-between'>
        {jobStatus.map((status, index) => (
          <div
            key={status + index}
            onClick={() => onStepClick(status as TJobStatus)}
            className={`group ${
              currentStatus !== 'completed'
                ? getValidStepCursor(userRole, status as TJobStatus)
                : ''
            } flex justify-center items-center w-12 h-12 md:w-16 md:h-16 rounded-full border-[4px] bg-white dark:bg-dark-theme-900 relative ${
              currentIndex >= index
                ? 'border-theme/80 text-theme/90 dark:text-white/80 dark:border-white/90'
                : 'border-dark-theme-200/20 text-dark-theme-200/50 dark:text-white/20 dark:border-white/20'
            }`}
          >
            <hr
              className={`absolute w-3 border-[0.5px] border-dashed rotate-90 group-odd:translate-y-8 group-even:-translate-y-8 sm:hidden ${
                currentIndex >= index
                  ? 'border-gray-600 dark:border-gray-300'
                  : 'border-gray-600/40 dark:border-gray-300/40'
              }`}
            />

            {icons[status]}
            <div
              className={`absolute group-even:border-b-[2px] group-odd:border-t-[2px] border-dashed sm:border-none group-even:bottom-14 md:group-even:top-16 group-odd:top-14 md:group-odd:top-16 left-1/2 -translate-x-1/2 text-center capitalize text-xs md:text-base ${
                currentIndex >= index
                  ? 'dark:text-white/90 border-gray-600 dark:border-gray-300'
                  : 'text-dark-theme-200/80 dark:text-white/40 border-gray-600/40 dark:border-gray-300/40'
              } w-[150px]`}
            >
              {t(status)}
            </div>
          </div>
        ))}
      </div>
    </div>
  );
}
