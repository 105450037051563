import { ReactElement } from 'react';
import { Route, Routes } from 'react-router-dom';

import BrandProductView from './BrandProductView';
import BrandProducDetailView from './BrandProductDetailView';

export default function BrandProducts(): ReactElement {
  window?.scrollTo({ top: 0 });

  return (
    <Routes>
      <Route path='/' element={<BrandProductView />} />
      <Route path='/:productID' element={<BrandProducDetailView />} />
    </Routes>
  );
}
