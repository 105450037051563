import { ReactNode } from 'react';

import Button from 'components/button';

interface ICustomButton {
  onClick?: React.MouseEventHandler<HTMLButtonElement>;
  pending?: boolean;
  children?: ReactNode;
  disabled?: boolean;
  type?: 'submit' | 'button';
}
export default function CustomButton({ pending, ...props }: ICustomButton) {
  return (
    <Button
      {...props}
      className='text-xs h-9 bg-gray-500 dark:bg-dark-theme-200 dark:hover:bg-dark-theme-200/70 dark:active:bg-dark-theme-200/50 border-[1px] dark:border-dark-theme-900 w-[200px]'
    >
      {props.children}
    </Button>
  );
}
