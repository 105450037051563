import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { RootState } from 'redux/store';

import Button from 'components/button';
import Card from 'components/card';
import CardHr from 'components/card/CardHr';

import AmplifyMedia from 'views/components/media/StorageImageRenderer';
import CardTextLabel from 'components/card/CardLabelText';
import CardText from 'components/card/CardText';

type PropsType = {
  jobStatus?: TJobStatus;
  creator?: IUserProfile;
  campaignID?: String;
  invoiceLink?: string | null;
};

export default function JobDeliveryInfoPanel({
  jobStatus,
  creator,
  campaignID,
  invoiceLink,
}: PropsType) {
  const isBrand = useSelector(
    (root: RootState) => root.user.profile.role === 'BRAND'
  );
  const navigate = useNavigate();
  const { t } = useTranslation('translation', { keyPrefix: 'brand.campaigns' });

  return (
    <Card addClassName='p-4 md:p-8 mt-8'>
      {['invited', 'waiting-payment', 'open'].includes(jobStatus ?? '') ? (
        t('You can see the delivery info after the job has started')
      ) : (
        <>
          <div className='flex justify-between items-center'>
            <div className='flex items-center gap-2'>
              <AmplifyMedia
                width={12}
                height={12}
                className='w-12 h-12 rounded-full'
                isUserAvatar
                sourceKey={creator?.profile_picture}
              />
              <p className='font-medium'>
                <span className='text-sm font-medium capitalize'>{`${creator?.firstName} ${creator?.lastName}`}</span>
              </p>
            </div>
            {isBrand && (
              <Button
                onClick={() => navigate(`/brand/campaigns/` + campaignID)}
                variant='outline'
                className='h-10 px-5 text-sm font-medium'
              >
                {t('Go to campaign')}
              </Button>
            )}
          </div>
          <CardHr />
          <div className='pl-4'>
            <CardTextLabel>{t('Delivery Country')}</CardTextLabel>
            <CardText addClassName='mb-2'>{creator?.deliveryCountry}</CardText>
            <CardTextLabel>{t('Delivery State')}</CardTextLabel>
            <CardText addClassName='mb-2'>{creator?.deliveryState}</CardText>
            <CardTextLabel>{t('Delivery City')}</CardTextLabel>
            <CardText addClassName='mb-2'>{creator?.deliveryCity}</CardText>
            <CardTextLabel>{t('Delivery AddressLine1')}</CardTextLabel>
            <CardText addClassName='mb-2'>
              {creator?.deliveryAddressLine1}
            </CardText>
            <CardTextLabel>{t('Delivery Postal Code')}</CardTextLabel>
            <CardText>{creator?.deliveryPostalCode}</CardText>

            {invoiceLink && (
              <>
                <CardHr />
                <CardTextLabel>{t('Invoice')}</CardTextLabel>
                <a
                  href={invoiceLink ?? '#'}
                  rel='noreferrer noopener'
                  className='underline'
                  target='_blank'
                >
                  {jobStatus === 'waiting-payment'
                    ? t('Send payment')
                    : t('View Receipt')}
                </a>
              </>
            )}
          </div>
        </>
      )}
    </Card>
  );
}
