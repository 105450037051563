import { ReactElement, useEffect, useRef } from 'react';
import { NavLink } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import Button from 'components/button/Button';
import Card from 'components/card/Card';
import CardHr from 'components/card/CardHr';

import useChatData from 'hooks/common/useChatData';

import ChatHistoryItem from 'views/components/chat/Chat.HistoryItem';
import MessageInput from 'views/components/chat/MessageInput';
import AmplifyMedia from 'views/components/media/StorageImageRenderer';
import { ChatBubbleLeftRightIcon } from '@heroicons/react/20/solid';

export default function CreatorChatCard({
  buyer,
}: {
  buyer?: IUserProfile;
}): ReactElement {
  const { t } = useTranslation('translation', {
    keyPrefix: 'creator.marketplace',
  });

  const panelRef = useRef<HTMLDivElement>(null);
  const { pending, userID, userName, messages, isNew, setIsNew, shootMessage } =
    useChatData('CREATOR', buyer?.id);

  useEffect(() => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
    return () => {};
  }, []);

  useEffect(() => {
    const height = panelRef.current?.scrollHeight;

    if (height && isNew) {
      panelRef.current.scrollTop = height;
      setIsNew(false);
    }
  }, [messages]);

  return (
    <Card addClassName='p-4 md:p-8'>
      <div className='flex justify-between items-center'>
        <div className='flex gap-2 items-center'>
          <AmplifyMedia
            width={12}
            height={12}
            className='w-12 h-12 rounded-full'
            isUserAvatar
            sourceKey={buyer?.profile_picture}
          />
          <p className='text-sm'>
            <span className='text-xs text-gray-500 block'>
              {t('Chat with')}
            </span>
            <span className='font-semibold capitalize'>
              {buyer?.firstName} {buyer?.lastName}{' '}
            </span>
          </p>
        </div>
        <NavLink to='/influencer/my-jobs'>
          <Button
            variant='outline'
            className='w-fit h-10 px-5 text-sm font-medium'
          >
            {t('Go to my jobs')}
          </Button>
        </NavLink>
      </div>
      <CardHr />
      <div
        ref={panelRef}
        className='mt-8 h-[500px] overflow-auto pr-5 relative'
      >
        {messages.length === 0 && (
          <div className='absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 flex flex-col items-center gap-y-4'>
            <ChatBubbleLeftRightIcon className='w-12 h-12' />
            {t('No messages')}
          </div>
        )}
        {messages.map((message, index) => (
          <ChatHistoryItem
            key={'chat-history' + index}
            align={message.fromUserID === userID ? 'left' : 'right'}
            avatar={message.fromUserID === userID ? userName.at(0) ?? '' : 'J'}
            message={message}
          />
        ))}
      </div>
      <CardHr />
      <div className='flex'>
        <MessageInput pending={pending} onShoot={shootMessage} />
      </div>
    </Card>
  );
}
