import { useEffect, useState } from 'react';
import { getJobContentsByCampaignID } from 'apis/common/jobContent';

export default function useJobContents(campaignID?: string) {
  const [contents, setContents] = useState<Array<any>>([]);
  const [query, setQuery] = useState<string>('');
  const [pending, setPending] = useState<boolean>(false);
  const [error, setError] = useState<boolean>(false);

  useEffect(() => {
    (async () => {
      setPending(true);
      try {
        const response = await getJobContentsByCampaignID(campaignID ?? '');
        setContents(response.data.listJobContents.items);
        setError(false);
      } catch (e) {
        setError(true);
      }
      setPending(false);
    })();
    return () => {};
  }, []);

  const queryChangeHandler = (query: string) => {
    setQuery(query);
  };

  return { pending, error, contents, queryChangeHandler };
}
