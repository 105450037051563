import { useCallback, useEffect } from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { AppDispatch, RootState } from 'redux/store';
import { getProtoTypesAction } from 'redux/common_slices/protoTypeSlice';
import {
  getAllNotificationAction,
  getAllReviewAction,
  getUserDataAction,
} from 'redux/common_slices/userSlice';

import brandPath from 'utils/path/brand';
import influencerPath from 'utils/path/influencer';

import Landing from 'app/Landing';
import BrandApp from 'app/BrandApp';
import AdminApp from 'app/AdminApp';
import InfluencerApp from 'app/InfluencerApp';

import { languages } from 'components/button/LocaleButton';

const routes = [
  {
    path: '/*',
    element: <Landing />,
  },
  {
    path: brandPath.BRAND_APP_PATH,
    element: <BrandApp />,
  },
  {
    path: influencerPath.INFLUENCER_APP_PATH,
    element: <InfluencerApp />,
  },
  {
    path: '/admin/*',
    element: <AdminApp />,
  },
];

export default function App() {
  const { i18n } = useTranslation();

  const dispatch = useDispatch<AppDispatch>();
  const auth = useSelector((root: RootState) => root.authentication.auth);
  const userID = useSelector((root: RootState) => root.user.profile.id);
  useEffect(() => {
    if (!auth) return;
    dispatch(getProtoTypesAction());
  }, [auth]);

  useEffect(() => {
    if (!userID) return;
    getAllUserData();

    return () => {};
  }, [userID]);

  const getAllUserData = useCallback(async () => {
    dispatch(getAllNotificationAction(userID));
    dispatch(getUserDataAction(userID));
    dispatch(getAllReviewAction(userID));
  }, [userID]);

  useEffect(() => {
    if (!localStorage.getItem('reeffo-theme')) {
      document.documentElement.classList.add('dark');
      localStorage.setItem('reeffo-theme', 'dark');
    }

    if (!localStorage.getItem('reeffo-language')) {
      i18n.changeLanguage(languages[0]);
      localStorage.setItem('reeffo-language', languages[0]);
    } else {
      i18n.changeLanguage(
        languages.find((lng) => lng === localStorage.getItem('reeffo-language'))
      );
    }
  }, []);

  return (
    <Router>
      <Routes>
        {routes.map((route, index) => (
          <Route key={'app-route-' + index} {...route} />
        ))}
      </Routes>
    </Router>
  );
}
