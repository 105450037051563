import CardHr from 'components/card/CardHr';
import Rating from 'components/rating/Rating';

export default function ReviewCard(props: IUserReview) {
  return (
    <div className='p-2'>
      <div className='w-[240px] rounded-lg h-[300px] !shadow-card dark:bg-dark-theme-900'>
        <div className='flex justify-between items-center px-4 pt-4 text-sm'>
          <Rating rating={props.rating} mini />
          {new Date(props.createdAt).toLocaleDateString()}
        </div>
        <p className='pt-1 px-4 text-sm'>{props.review}</p>
        <CardHr marginClassName='my-2 mx-4' />
      </div>
    </div>
  );
}
