import { ReactElement } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { RootState } from 'redux/store';

import Card from 'components/card';
import CardHr from 'components/card/CardHr';
import ComboBox from 'components/form/Combobox/Combobox';
import { SkeletonBox } from 'components/skeleton';

import useCampaignStatus from 'hooks/brand/useCampaignStatus';

const liClassName = 'py-1 text-sm';
const spanClassName = 'text-gray-400';

export default function CampaignStatusCard({
  pending,
  campaign,
}: ICampaignStatusCard): ReactElement {
  const { t } = useTranslation('translation', { keyPrefix: 'brand.campaigns' });

  const jobs = useSelector((root: RootState) =>
    root.job.jobs.filter((job) => job.campaignID === campaign?.id)
  );

  const { campaignStatus: currentStatus, changeCampaignStatusHandler } =
    useCampaignStatus(campaign);

  const campaignStatus: IValueWithIndex[] = ['OPEN', 'HIDDEN', 'CLOSED'].map(
    (status) => {
      return {
        index: status,
        displayValue: t(status),
        value: status,
      };
    }
  );

  return (
    <Card addClassName='p-4 md:p-8 mt-8 lg:w-[40%]'>
      <div className='flex text-sm'>{t('Campaign status')}</div>
      {pending ? (
        <SkeletonBox darkMode className='h-8 mt-2 w-32' />
      ) : (
        <span className='text-xl font-semibold pt-2 block capitalize'>
          {t(currentStatus)}
        </span>
      )}

      <CardHr />
      {pending ? (
        <div className='flex flex-col gap-2'>
          {new Array(4).fill(true).map((_elem, index) => (
            <SkeletonBox
              darkMode
              key={index}
              className='w-56 h-5 first:mt-1 last:mb-1'
            />
          ))}
        </div>
      ) : (
        (() => {
          const counts = {
            payment: 0,
            applicants: 0,
            invitations: 0,
            progress: 0,
            completions: 0,
          };

          jobs.forEach((job) => {
            if (job.jobStatus === 'open') {
              counts.applicants++;
            } else if (job.jobStatus === 'invited') {
              counts.invitations++;
            } else if (job.jobStatus === 'completed') {
              counts.completions++;
            } else if (job.jobStatus === 'waiting-payment') {
              counts.payment++;
            } else if (job.jobStatus !== 'rejected') {
              counts.progress++;
            }
          });

          return (
            <ul>
              <li className={liClassName}>
                <span className={spanClassName}>{t('Applicants')}:</span>{' '}
                {counts.applicants} {t('creator(s)')}
              </li>
              <li className={liClassName}>
                <span className={spanClassName}>{t('Invited')}:</span>{' '}
                {counts.invitations} {t('creator(s)')}
              </li>
              <li className={liClassName}>
                <span className={spanClassName}>{t('Waiting payment')}:</span>{' '}
                {counts.payment} {t('job(s)')}
              </li>
              <li className={liClassName}>
                <span className={spanClassName}>{t('In progress')}:</span>{' '}
                {counts.progress} {t('job(s)')}
              </li>
              <li className={liClassName}>
                <span className={spanClassName}>{t('Completed')}:</span>
                {counts.completions} {t('job(s)')}
              </li>
            </ul>
          );
        })()
      )}

      <span className={liClassName + ' block mt-4 mb-2'}>
        {t('Change campaign status')}:
      </span>
      <div className='w-[240px]'>
        <ComboBox
          disabled={pending}
          data={campaignStatus}
          setSelectedData={changeCampaignStatusHandler}
          selectedData={campaignStatus.find(
            (status) => status.value === currentStatus
          )}
        />
      </div>
    </Card>
  );
}
