const notification = {
  'Successfully updated campaign status':
    'Successfully updated campaign status',
  'There was an error while updating campaign status':
    'There was an error while updating campaign status',
  'Invitation sent!': 'Invitation sent!',
  'There was an error while inviting a creator.':
    'There was an error while inviting a creator.',
  'You have an active job with this creator for this campaign':
    'You have an active job with this creator for this campaign',
  'There was an error uploading image.': 'There was an error uploading image.',
  'Successfully picked the content.': 'Successfully picked the content.',
  'Unpicked the content.': 'Unpicked the content.',
  'There was an error while picking this content.':
    'There was an error while picking this content.',
  'You should upload product image.': 'You should upload product image.',
  'Successfully uploaded contents': 'Successfully uploaded contents',
  "You can't upload any contents.": "You can't upload any contents.",
  'There was an error while submitting review.':
    'There was an error while submitting review.',
  'Are you gonna really proceed with this creator?':
    'Are you gonna really proceed with this creator?',
  'Payment is requested, after payment is made job will be started.':
    'Payment is requested, after payment is made job will be started.',
  'There was an error while creating invoice.':
    'There was an error while creating invoice.',
  'There was an error while starting this job.':
    'There was an error while starting this job.',
  'You can only completed job after they upload contents.':
    'You can only completed job after they upload contents.',
  'Are you gonna really complete this job?':
    'Are you gonna really complete this job?',
  'Successfully completed the job.': 'Successfully completed the job.',
  'There was an error while completing the job.':
    'There was an error while completing the job.',
  'Successfully set the job status': 'Successfully set the job status',
  'There was an error while changing the job status':
    'There was an error while changing the job status',
  'You can not close the job.': 'You can not close the job.',
  'Are you really gonna reject this invitation?':
    'Are you really gonna reject this invitation?',
  'Successfully rejected the application':
    'Successfully rejected the application',
  'There was an error while rejecting the application':
    'There was an error while rejecting the application',
  'You can only start job when they invite you.':
    'You can only start job when they invite you.',
  'Are you really gonna accept this invitation and start?':
    'Are you really gonna accept this invitation and start?',
  'There was an error while accepting invitation.':
    'There was an error while accepting invitation.',
  'You can set as delivered after they send product to you.':
    'You can set as delivered after they send product to you.',
  'Successfully update job status': 'Successfully update job status',
  'There was an error while updating the status':
    'There was an error while updating the status',
  'You can only reject when they invited you':
    'You can only reject when they invited you',
  'Successfully rejected the invitation':
    'Successfully rejected the invitation',
  'There was an error while rejecting the invitation':
    'There was an error while rejecting the invitation',
  'There is an issue with this campaign, please try another.':
    'There is an issue with this campaign, please try another.',
  'You already have an active job with this campaign.':
    'You already have an active job with this campaign.',
  'You should complete your deliver address before applying for a job':
    'You should complete your deliver address before applying for a job',
  'Only images and video formats are supported.':
    'Only images and video formats are supported.',
  'Files with unsupported formats are automatically excluded.':
    'Files with unsupported formats are automatically excluded.',
};

export default notification;
