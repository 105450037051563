import { ReactElement, useEffect } from 'react';
import { Route, Routes } from 'react-router-dom';

import BrandLanding from './BrandLanding';
import CreatorLanding from './CreatorLanding';
import Terms from './Terms';
import Privacy from './Privacy';

export default function Landing(): ReactElement {
  // useEffect(() => {
  //   document.documentElement.classList.remove('dark');
  //   localStorage.setItem('theme', 'light');
  // }, []);

  return (
    <Routes>
      <Route path='/' element={<BrandLanding />} />
      <Route path='/for-creators' element={<CreatorLanding />} />
      <Route path='/privacy' element={<Privacy />} />
      <Route path='/terms' element={<Terms />} />
    </Routes>
  );
}
