import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

import { getAllAdminJobs } from "apis/common/job";
import { logoutAction } from "redux/common_slices/authSlice";

export const getAllAdminJobAction = createAsyncThunk("admin/job", async () => {
  const result = await getAllAdminJobs();
  return result;
});

const initialState: IAdminState = {
  pending: false,
  error: null,
  jobs: [],
};

export const brandSlice = createSlice({
  name: "brand",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getAllAdminJobAction.pending, (state: IAdminState) => {
        state.pending = true;
        return state;
      })
      .addCase(getAllAdminJobAction.rejected, (state: IAdminState, action: any) => {
        console.log("failed", action);
        state.pending = false;
        state.error = action.error;
        return state;
      })
      .addCase(getAllAdminJobAction.fulfilled, (state: IAdminState, action: any) => {
        console.log("success: data:", action);
        state.pending = false;
        state.jobs = action.payload.data.listJobs.items;
        return state;
      });
    // set remove redux data when they logout
    builder.addCase(logoutAction.fulfilled, (state: IAdminState) => {
      window.location.href = "/";
      state = initialState;
      return state;
    });
  },
});

// export const {} = brandSlice.actions;

export default brandSlice.reducer;
