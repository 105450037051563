import { ChevronLeftIcon } from '@heroicons/react/24/outline';
import { InformationCircleIcon } from '@heroicons/react/20/solid';

import { ReactElement, useState } from 'react';
import { Helmet } from 'react-helmet';
import { NavLink } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import CreatorAuthedContainer from '../_components/Container.AuthedCreator';
import CreatorJobContentCard from './components/Card.CreatorJobContent';
import CreatorChatCard from './components/Card.CreatorChat';

import { SkeletonBox } from 'components/skeleton';
// import ComboBox from 'components/form/Combobox/Combobox';
import Button from 'components/button';
import Card from 'components/card';
import Tip from 'components/tip/Tip';
import Tab from 'components/tab';

// import { jobStatusWithIndex } from 'views/brand/campaigns/pages/CampaignJobDetailView';
import AmplifyMedia from 'views/components/media/StorageImageRenderer';
import JobPorgressBar from 'views/components/progress';

import useUpdateJobStatus from 'hooks/common/useUpdateJobStatus';
import useFetchedJobData from 'hooks/common/useFetchedJobDetail';
import JobDeliveryInfoPanel from 'views/brand/campaigns/components/Panel.JobDeliveryInfo';
import JobInfoPanel from 'views/brand/campaigns/components/Panel.JobInfo';

export default function JobDetail(): ReactElement {
  const { t } = useTranslation('translation', {
    keyPrefix: 'creator.marketplace',
  });

  const [refresh, setRefersh] = useState<number>(0);

  const { job, isJobStarted, fetching, fetchJobByJobID } =
    useFetchedJobData(refresh);

  const { pending, currentJobStatus, updateJobStatus } =
    useUpdateJobStatus(job);

  window?.scrollTo({ top: 0 });

  return (
    <>
      <Helmet>
        <title>Creator - Job detail</title>
      </Helmet>
      <CreatorAuthedContainer>
        <NavLink
          to={'/influencer/my-jobs'}
          className='flex items-center gap-2 font-medium text-sm my-5'
        >
          <ChevronLeftIcon className='w-5 h-5' />
          {t('Back to my jobs')}
        </NavLink>

        {!!currentJobStatus &&
          currentJobStatus !== 'invited' &&
          currentJobStatus !== 'open' &&
          currentJobStatus !== 'rejected' && (
            <JobPorgressBar
              key={refresh}
              jobStatus={currentJobStatus}
              onStepClick={(status: TJobStatus) => {
                if (currentJobStatus === 'completed') return;

                updateJobStatus(status, fetchJobByJobID);
              }}
            />
          )}

        {job?.jobStatus === 'invited' && (
          <Tip
            icon={<InformationCircleIcon className='w-6 h-6' />}
            tipType={'info'}
            description={
              <div className='flex items-center'>
                {t(
                  'Someone invited you to a job, you can start by accepting the invitation.'
                )}
                <Button
                  onClick={() =>
                    updateJobStatus('waiting-payment', fetchJobByJobID)
                  }
                  variant='outline'
                  className='ml-auto mr-10 px-10 h-10 !text-black'
                  disabled={fetching || pending}
                >
                  {t('Accept')}
                </Button>
                <Button
                  onClick={() => updateJobStatus('rejected', fetchJobByJobID)}
                  className='px-10 h-10 mr-10'
                  disabled={fetching || pending}
                >
                  {t('Decline')}
                </Button>
              </div>
            }
            wrapperClassName='mt-8 shadow-card'
            closable
          />
        )}
        {job?.jobStatus === 'open' && (
          <Tip
            icon={<InformationCircleIcon className='w-6 h-6' />}
            tipType={'info'}
            description={t(
              'You have applied for this job, please wait until clients accept your application.'
            )}
            wrapperClassName='mt-8 shadow-card'
            closable
          />
        )}

        <Card addClassName='py-4 px-8 my-8 flex items-center text-sm leading-[18px] font-semibold gap-3 capitalize'>
          <AmplifyMedia
            sourceKey={job?.Buyer?.profile_picture}
            className='w-8 h-8 !rounded-full'
            height={32}
            width={32}
            isUserAvatar
          />
          {fetching ? (
            <SkeletonBox darkMode className='w-36 h-[18px]' />
          ) : (
            <>{job?.Buyer && job.Buyer.firstName}</>
          )}
          <div className='ml-auto capitalize'>{job?.jobStatus}</div>
        </Card>

        <Tab
          tabs={
            isJobStarted
              ? [t('Delivery info'), t('Job info'), t('Chat'), t('Content')]
              : [t('Delivery info'), t('Job info'), t('Chat')]
          }
          views={[
            <JobDeliveryInfoPanel
              campaignID={job?.campaignID}
              creator={job?.Creator}
            />,
            <JobInfoPanel
              fetching={fetching}
              brand={job?.Brand}
              campaign={job?.Campaign}
              product={job?.Product}
            />,
            <CreatorChatCard buyer={job?.Buyer} />,
            <CreatorJobContentCard job={job} hook={setRefersh} />,
          ]}
          tabBarAddClassName='mb-5'
        />
      </CreatorAuthedContainer>
    </>
  );
}
