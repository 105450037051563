import { ReactElement } from 'react';
import { Navigate, Route, Routes } from 'react-router-dom';

import influencerPath from 'utils/path/influencer';
import CreatorPublicProfile from 'views/CreatorPublicProfile';
import ResendCode from 'views/influencer/auth/ResendCode';
import Signin from 'views/influencer/auth/Signin';
import Signup from 'views/influencer/auth/Signup';
import Campaign from 'views/influencer/campaign';
import Marketplace from 'views/influencer/marketplace/Marketplace';
import MyJobs from 'views/influencer/my_jobs/MyJobs';
import Settings from 'views/influencer/settings/Settings';

export default function InfluencerRoute({
  auth,
  pathName,
}: {
  auth: boolean;
  pathName: string;
}): ReactElement {
  const routes: IRoutesGroup[] = [
    {
      path: '/',
      element: <Navigate to='/influencer/marketplace' replace />,
      authRequired: true,
    },
    {
      path: influencerPath.SIGNIN,
      element: <Signin />,
      authRequired: false,
    },
    {
      path: influencerPath.SIGNUP,
      element: <Signup />,
      authRequired: false,
    },
    // {
    //   path: influencerPath.FORGOT_PASSWORD,
    //   element: <InfluencerForgotPassword />,
    // },
    {
      path: influencerPath.VERIFY_EMAIL,
      element: <ResendCode />,
      authRequired: false,
    },
    {
      path: influencerPath.MARKETPLACE,
      element: <Marketplace />,
      authRequired: true,
    },
    {
      path: influencerPath.CAMPAIGN,
      element: <Campaign />,
      authRequired: true,
    },
    {
      path: influencerPath.MY_JOBS,
      element: <MyJobs />,
      authRequired: true,
    },
    {
      path: influencerPath.SETTINGS,
      element: <Settings />,
      authRequired: true,
    },
    {
      path: influencerPath.CREATOR_PROFILE,
      element: <CreatorPublicProfile />,
      authRequired: true,
    },
  ];

  //fallback when theres no auth
  const fallbackRoute = (
    <Navigate to={`/influencer${influencerPath.SIGNIN}`} replace />
  );

  return (
    <Routes>
      {routes
        .filter((route) =>
          route.authRequired === false && auth ? false : true
        )
        .map(({ authRequired, element, ...route }, index) => (
          <Route
            key={'app-influencer-route-' + index}
            {...route}
            element={authRequired ? (auth ? element : fallbackRoute) : element}
          />
        ))}
      <Route
        path='/*'
        element={
          <Navigate
            to={
              auth
                ? '/influencer/marketplace'
                : `/influencer${influencerPath.SIGNIN}`
            }
          />
        }
      />
    </Routes>
  );
}
