import { ReactElement } from 'react';
import { ArrowTopRightOnSquareIcon } from '@heroicons/react/24/outline';
import { useTranslation } from 'react-i18next';

import Button from 'components/button';
import Card from 'components/card';
import Modal from 'components/modal/Modal';

import DigitalProductModal from './components/Modal.DigitalProduct';
import PhysicalProductModal from './components/Modal.PhysicalProduct';
import NavigateButtonGroup from './components/Navigate.ButtonGroup';

import AmplifyImage from 'views/components/media/StorageImageRenderer';

import useNewCampaign from 'hooks/brand/useNewCampaign';

export default function CampaignDetails(): ReactElement {
  const { t } = useTranslation('translation', { keyPrefix: 'brand.campaigns' });

  const {
    visibility,
    campaign: newCampaign,
    product,
    brand,
    editCurrentProductHandler,
    closeEditModal,
  } = useNewCampaign();
  return (
    <>
      <div className='min-h-[calc(100vh_-_190px)] px-3 md:px-4 xl:px-6 pb-10'>
        <h2 className='text-xl font-semibold text-gray-900 dark:text-gray-100 py-6'>
          {t('New campaign')}:{' '}
          <span className='sm:ml-1 sm:text-gray-800 dark:text-gray-200'>
            {newCampaign?.campaignName}
          </span>
        </h2>
        <Card addClassName='p-4 md:p-8'>
          <div className='delivery-product-information'>
            <div className='flex justify-between items-center xs:mb-10 mb-4'>
              <span className='delivery-product-information-title text-lg font-semibold'>
                {t('Product information')}
              </span>
              <Button
                onClick={editCurrentProductHandler}
                variant='outline'
                className='text-sm px-5 w-fit h-10 font-medium'
              >
                {t('Edit product')}
              </Button>
            </div>
            <div className='flex flex-col md:flex-row gap-8 mt-10 items-center'>
              <div className='flex justify-self-start sm:justify-center'>
                <div className='image-container object-center w-[100px] h-[100px] md:w-[144px] md:h-[144px] rounded-lg overflow-hidden bg-dark-black/20 flex items-center justify-center text-6xl capitalize'>
                  <AmplifyImage sourceKey={product?.productImage} />
                </div>
              </div>
              <div className='flex flex-col w-full md:w-fit'>
                <span className='text-sm text-gray-500 dark:text-gray-300 break-words line-clamp-3 sm:mb-3 mb-1'>
                  {brand?.brandName}
                </span>
                <span className='text-sm sm:text-base text-gray-900 dark:text-gray-100 break-words line-clamp-3'>
                  {product?.productName}
                </span>
                <div className='flex gap-x-3 mt-1 sm:mt-0'>
                  <span className='text-gray-900 dark:text-gray-100 font-semibold'>
                    ${product?.productPrice.toFixed(2)}
                  </span>
                  <a
                    href={product?.productExternalLink}
                    target='_blank'
                    rel='noreferrer'
                    className='text-blue-700 dark:text-blue-300 inline-block link-no-underline product-link'
                  >
                    <span className='flex items-center gap-1'>
                      {t('Product link')}
                      <ArrowTopRightOnSquareIcon className='w-5 h-5 stroke-2' />
                    </span>
                  </a>
                </div>
                <div className='product-type-info pt-4'>
                  <span className='product-type-info-title text-sm font-semibold'>
                    {t(`${product?.productType.toLowerCase()} product`)}
                  </span>
                </div>
              </div>
            </div>

            <hr className='border-gray-200 dark:border-dark-theme-900/70 mt-8 mb-8 hidden sm:block' />
            <div className='digital-product-details mt-6 sm:mt-0'>
              <p className='label-access pb-2 text-sm text-gray-500 dark:text-gray-400'>
                {t('Product access instructions')}
              </p>
              <p className='access-instructions text-base break-words whitespace-pre-line'>
                {product?.productDescription}
              </p>
            </div>
          </div>
        </Card>
        <NavigateButtonGroup next='../settings' back='../create' />
      </div>
      {product && (
        <Modal isOpen={visibility} closeModal={closeEditModal}>
          {product?.productType === 'DIGITAL' && (
            <DigitalProductModal
              data={product}
              modalType={'EDIT'}
              closeModal={closeEditModal}
            />
          )}
          {product?.productType === 'PHYSICAL' && (
            <PhysicalProductModal
              data={product}
              modalType={'EDIT'}
              closeModal={closeEditModal}
            />
          )}
        </Modal>
      )}
    </>
  );
}
