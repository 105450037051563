import { getAllCreators } from 'apis/brand/campaign';
import { useEffect, useState } from 'react';

export default function useCreators() {
  const [creators, setCreators] = useState<Array<any>>([]);
  const [query, setQuery] = useState<string>('');
  const [pending, setPending] = useState<boolean>(false);

  useEffect(() => {
    (async () => {
      setPending(true);
      try {
        const response = await getAllCreators(query.toLowerCase());
        setCreators(response.data.listUsers.items);
      } catch (e) {}
      setPending(false);
    })();
    return () => {};
  }, [query]);

  const queryChangeHandler = (query: string) => {
    setQuery(query);
  };

  return { creators, queryChangeHandler };
}
