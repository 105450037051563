import { ReactElement } from 'react';
import { Helmet } from 'react-helmet';
import { useTranslation } from 'react-i18next';

import AuthedHeading from 'components/typhography/AuthedHeading';

import BrandAccountInfo from '../_components/DashboardAccountInfo';
// import DashboardGetStarted from '../_components/DashboardGetStarted';
import BrandAuthedContainer from '../_components/Container.AuthedBrand';

import NewJobs from './components/NewJobs';
import RecentJobs from './components/RecentJobs';

export default function BrandDashboard(): ReactElement {
  const { t } = useTranslation('translation', { keyPrefix: 'brand.dashboard' });

  window?.scrollTo({ top: 0 });

  return (
    <>
      <Helmet>
        <title>Brand - Dashboard</title>
      </Helmet>
      <BrandAuthedContainer>
        <AuthedHeading>{t('Dashboard')}</AuthedHeading>
        <BrandAccountInfo />
        {/* <DashboardGetStarted /> */}

        <NewJobs />
        <RecentJobs />
      </BrandAuthedContainer>
    </>
  );
}
