export const campaignsByUserID = /* GraphQL */ `
  query CampaignsByUserID(
    $userID: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelCampaignFilterInput
    $limit: Int
    $nextToken: String
  ) {
    campaignsByUserID(
      userID: $userID
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        campaignName
        campaignStatus
        approvedDate
        campaignPrice
        description
        contentType
        contentFormat
        userID
        brandID
        productID
        createdAt
        updatedAt
        Product {
          id
          productType
          productPrice
          productCategory
          productExternalLink
          productImage
          productDescription
          brandID
          userID
          productName
        }
        Brand {
          id
          brandName
          externalLink
          imageUrl
        }
      }
      nextToken
    }
  }
`;

export const productsByUserID = /* GraphQL */ `
  query ProductsByUserID(
    $userID: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelProductFilterInput
    $limit: Int
    $nextToken: String
  ) {
    productsByUserID(
      userID: $userID
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        productType
        productPrice
        productCategory
        productExternalLink
        productImage
        productDescription
        brandID
        userID
        productName
        createdAt
        updatedAt
        Brand {
          id
          brandName
        }
      }
      nextToken
    }
  }
`;

export const listCampaigns = /* GraphQL */ `
  query ListCampaigns($filter: ModelCampaignFilterInput, $limit: Int, $nextToken: String) {
    listCampaigns(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        campaignName
        campaignStatus
        approvedDate
        campaignPrice
        description
        contentType
        contentFormat
        userID
        brandID
        productID
        createdAt
        updatedAt
        Product {
          id
          productType
          productPrice
          productCategory
          productExternalLink
          productImage
          productDescription
          brandID
          userID
          productName
        }
        Brand {
          id
          brandName
          externalLink
          imageUrl
        }
      }
      nextToken
    }
  }
`;

export const listJobs = /* GraphQL */ `
  query ListJobs($filter: ModelJobFilterInput, $limit: Int, $nextToken: String) {
    listJobs(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        jobStatus
        jobStartedDate
        jobEndedDate
        jobPrice
        jobApplicationFee
        invoiceLink
        deliveryTrackingInfo
        deliveryName
        deliveryAddressLine1
        deliveryAddressLine2
        deliveryCity
        deliveryState
        deliveryCountry
        deliveryPostalCode
        campaignID
        creatorID
        buyerID
        brandID
        createdAt
        updatedAt
        productID
        Campaign {
          id
          campaignName
          contentType
          contentFormat
        }
        Brand {
          id
          brandName
          externalLink
          imageUrl
        }
        Creator {
          id
          firstName
          lastName
          deliveryAddressLine1
          deliveryAddressLine2
          deliveryCity
          deliveryState
          deliveryCountry
          profile_picture
          UserReviews {
            nextToken
            items {
              rating
            }
          }
        }
        Product {
          id
          productName
          productPrice
          productType
          productCategory
          productExternalLink
          productImage
          productDescription
          productImage
        }
        Buyer {
          id
          firstName
          lastName
        }
      }
      nextToken
    }
  }
`;

export const jobsByCreatorID = /* GraphQL */ `
  query JobsByCreatorID(
    $creatorID: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelJobFilterInput
    $limit: Int
    $nextToken: String
  ) {
    jobsByCreatorID(
      creatorID: $creatorID
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        jobStatus
        jobStartedDate
        jobEndedDate
        jobPrice
        jobApplicationFee
        invoiceLink
        deliveryTrackingInfo
        deliveryName
        deliveryAddressLine1
        deliveryAddressLine2
        deliveryCity
        deliveryState
        deliveryCountry
        deliveryPostalCode
        campaignID
        creatorID
        buyerID
        brandID
        createdAt
        updatedAt
        productID
        Campaign {
          id
          campaignName
          contentType
          contentFormat
        }
        Brand {
          id
          brandName
          externalLink
          imageUrl
        }
        Creator {
          id
          firstName
          lastName
          deliveryAddressLine1
          deliveryAddressLine2
          deliveryCity
          deliveryState
          deliveryCountry
          profile_picture
          UserReviews {
            nextToken
            items {
              rating
            }
          }
        }
        Product {
          id
          productName
          productPrice
          productType
          productCategory
          productExternalLink
          productImage
          productDescription
          productImage
        }
        Buyer {
          id
          firstName
          lastName
        }
      }
      nextToken
    }
  }
`;

export const jobsByBuyerID = /* GraphQL */ `
  query JobsByBuyerID(
    $buyerID: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelJobFilterInput
    $limit: Int
    $nextToken: String
  ) {
    jobsByBuyerID(
      buyerID: $buyerID
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        jobStatus
        jobStartedDate
        jobEndedDate
        jobPrice
        jobApplicationFee
        invoiceLink
        deliveryTrackingInfo
        deliveryName
        deliveryAddressLine1
        deliveryAddressLine2
        deliveryCity
        deliveryState
        deliveryCountry
        deliveryPostalCode
        campaignID
        creatorID
        buyerID
        brandID
        createdAt
        updatedAt
        productID
        Campaign {
          id
          campaignName
          contentType
          contentFormat
        }
        Brand {
          id
          brandName
          externalLink
          imageUrl
        }
        Creator {
          id
          firstName
          lastName
          deliveryAddressLine1
          deliveryAddressLine2
          deliveryCity
          deliveryState
          deliveryCountry
          profile_picture
          UserReviews {
            nextToken
            items {
              rating
            }
          }
        }
        Product {
          id
          productName
          productPrice
          productType
          productCategory
          productExternalLink
          productImage
          productDescription
          productImage
        }
        Buyer {
          id
          firstName
          lastName
        }
      }
      nextToken
    }
  }
`;

export const getProduct = /* GraphQL */ `
  query GetProduct($id: ID!) {
    getProduct(id: $id) {
      id
      productType
      productPrice
      productCategory
      productExternalLink
      productImage
      productDescription
      brandID
      Brand {
        id
        brandName
        externalLink
        imageUrl
        userID
        createdAt
        updatedAt
      }
      userID
      productName
      Campaigns {
        nextToken
        items {
          id
          campaignName
          campaignStatus
        }
      }
      Jobs {
        items {
          id
          JobContents {
            nextToken
            items {
              id
              contentUrl
              picked
              creatorID
              buyerID
              createdAt
              Creator {
                id
                firstName
                lastName
              }
              Buyer {
                id
                firstName
                lastName
              }
            }
          }
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;

export const listUsers = /* GraphQL */ `
  query ListUsers($filter: ModelUserFilterInput, $limit: Int, $nextToken: String) {
    listUsers(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        email
        firstName
        lastName
        isActive
        approvedDate
        role
        phone
        deliveryAddressLine1
        deliveryAddressLine2
        deliveryCity
        deliveryState
        deliveryCountry
        deliveryPostalCode
        youtube
        instagram
        tiktok
        facebook
        date_of_birth
        gender
        profile_picture
        phone_number
        createdAt
        updatedAt
        paypalUserName
        UserReviews {
          nextToken
          items {
            rating
          }
        }
      }
      nextToken
    }
  }
`;

export const getUser = /* GraphQL */ `
  query GetUser($id: ID!) {
    getUser(id: $id) {
      id
      email
      firstName
      lastName
      isActive
      approvedDate
      role
      phone
      deliveryAddressLine1
      deliveryAddressLine2
      deliveryCity
      deliveryState
      deliveryCountry
      deliveryPostalCode
      youtube
      instagram
      tiktok
      facebook
      date_of_birth
      gender
      phone_number
      UserReviews {
        nextToken
        items {
          id
          rating
          FromUserID
          review
          userID
          jobID
        }
      }
      Notifications {
        nextToken
      }
      Campaigns {
        nextToken
        items {
          id
        }
      }
      Brands {
        nextToken
        items {
          id
          brandName
        }
      }
      Products {
        nextToken
        items {
          id
          productName
        }
      }
      CreatorJobs {
        nextToken
      }
      BuyerJobs {
        nextToken
      }
      PortfolioContents {
        nextToken
        items {
          contentType
          contentUrl
        }
      }
      createdAt
      updatedAt
    }
  }
`;

export const jobContentsByBuyerID = /* GraphQL */ `
  query JobContentsByBuyerID(
    $buyerID: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelJobContentFilterInput
    $limit: Int
    $nextToken: String
  ) {
    jobContentsByBuyerID(
      buyerID: $buyerID
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        contentType
        contentUrl
        jobID
        campaignID
        picked
        creatorID
        buyerID
        createdAt
        updatedAt
        Creator {
          id
          firstName
          lastName
        }
        Buyer {
          id
          firstName
          lastName
        }
      }
      nextToken
    }
  }
`;

export const jobContentsByJobID = /* GraphQL */ `
  query JobContentsByJobID(
    $jobID: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelJobContentFilterInput
    $limit: Int
    $nextToken: String
  ) {
    jobContentsByJobID(
      jobID: $jobID
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        contentType
        contentUrl
        jobID
        campaignID
        picked
        creatorID
        buyerID
        createdAt
        updatedAt
        Creator {
          id
          firstName
          lastName
        }
        Buyer {
          id
          firstName
          lastName
        }
      }
      nextToken
    }
  }
`;

export const listJobContents = /* GraphQL */ `
  query ListJobContents($filter: ModelJobContentFilterInput, $limit: Int, $nextToken: String) {
    listJobContents(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        contentType
        contentUrl
        jobID
        campaignID
        picked
        creatorID
        buyerID
        createdAt
        updatedAt
        Creator {
          id
          firstName
          lastName
        }
        Buyer {
          id
          firstName
          lastName
        }
      }
      nextToken
    }
  }
`;

export const updateUser = /* GraphQL */ `
  mutation UpdateUser($input: UpdateUserInput!, $condition: ModelUserConditionInput) {
    updateUser(input: $input, condition: $condition) {
      id
      email
      firstName
      lastName
      isActive
      approvedDate
      role
      phone
      deliveryAddressLine1
      deliveryAddressLine2
      deliveryCity
      deliveryState
      deliveryCountry
      deliveryPostalCode
      youtube
      instagram
      tiktok
      facebook
      date_of_birth
      gender
      phone_number
      paypalUserName
      UserReviews {
        nextToken
      }
      Notifications {
        nextToken
      }
      Campaigns {
        nextToken
      }
      Brands {
        nextToken
      }
      Products {
        nextToken
      }
      CreatorJobs {
        nextToken
      }
      BuyerJobs {
        nextToken
      }
      PortfolioContents {
        nextToken
      }
      profile_picture
      CreatorJobContents {
        nextToken
      }
      BuyerJobContents {
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;


export const jobsByCampaignID = /* GraphQL */ `
  query JobsByCampaignID(
    $campaignID: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelJobFilterInput
    $limit: Int
    $nextToken: String
  ) {
    jobsByCampaignID(
      campaignID: $campaignID
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        jobStatus
        jobStartedDate
        jobEndedDate
        jobPrice
        jobApplicationFee
        invoiceLink
        deliveryTrackingInfo
        deliveryName
        deliveryAddressLine1
        deliveryAddressLine2
        deliveryCity
        deliveryState
        deliveryCountry
        deliveryPostalCode
        campaignID
        creatorID
        buyerID
        brandID
        createdAt
        updatedAt
        productID
        Campaign {
          id
          campaignName
          contentType
          contentFormat
        }
        Brand {
          id
          brandName
          externalLink
          imageUrl
        }
        Creator {
          id
          firstName
          lastName
          deliveryAddressLine1
          deliveryAddressLine2
          deliveryCity
          deliveryState
          deliveryCountry
          profile_picture
          UserReviews {
            nextToken
            items {
              rating
            }
          }
        }
        Product {
          id
          productName
          productPrice
          productType
          productCategory
          productExternalLink
          productImage
          productDescription
          productImage
        }
        Buyer {
          id
          firstName
          lastName
        }
      }
      nextToken
    }
  }
`;