import { ReactElement } from 'react';

export default function CardTitle({
  title,
  noMargin,
}: {
  title: string;
  noMargin?: boolean;
}): ReactElement {
  return (
    <div className={`card-title ${noMargin ? '' : 'mb-8'}`}>
      <p className='text-base sm:text-xl text-gray-900 dark:text-gray-100 font-semibold'>
        {title}
      </p>
    </div>
  );
}
