import { ReactElement } from 'react';
import { Route, Routes } from 'react-router-dom';

import CampaignDetail from './CampaignDetail';
import JobDetail from './JobDetail';

export default function Campaign(): ReactElement {
  return (
    <Routes>
      <Route path='/' element={<CampaignDetail />} />
      <Route path='/job-detail/:jobID' element={<JobDetail />} />
    </Routes>
  );
}
