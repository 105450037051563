export const isNotEmpty = (value: string): boolean => {
  if (value === '') {
    return false;
  }
  return true;
};

export const hasNotEmptyElem = (value: string[]): boolean => {
  value.forEach((element) => {
    if (!isNotEmpty(element)) {
      return false;
    }
  });
  return true;
};

export const isValidEmail = (value: string): boolean => {
  if (value === '') return false;

  var validRegex =
    /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
  if (value.match(validRegex) && value.match(/^\S+@\S+\.\S+$/)) {
    return true;
  }
  return false;
};

export const shouldBeAtLeast = (limit: number, value: string): boolean => {
  if (value === '') return false;
  return value.length >= limit;
};

export const shouldBeAtLeastOfArray = (
  limits: number[],
  values: string[]
): boolean => {
  values.forEach((value, index) => {
    if (!shouldBeAtLeast(limits[index], value)) {
      return false;
    }
  });
  return true;
};

export const hasOnlyNumbers = (value: string): boolean => {
  var reg = /^\d+$/;
  if (value.match(reg)) {
    return true;
  }
  return false;
};
