import { ReactElement, useState } from 'react';
import { Helmet } from 'react-helmet';
import { useSelector, useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { AppDispatch, RootState } from 'redux/store';

import Button from 'components/button';
import InputBox from 'components/form/InputBox';
import FormContainer from './components/Wrapper.FormContainer';
import ImageSidePanel from './components/Image.SidePanel';
import DecorationNavlink from './components/Link.Decoration';

// custom hooks
import useCredentials from 'hooks/auth/useCredentials';
import { brandResetPasswordAction } from 'redux/common_slices/authSlice';

export default function BrandForgotPassword(): ReactElement {
  const { t } = useTranslation('translation', { keyPrefix: 'auth' });

  const auth = useSelector((root: RootState) => root.authentication);
  const dispatch = useDispatch<AppDispatch>();

  const { credentials, errorMessages, handleInputChange } =
    useCredentials('RESET');

  const [errorMsgShow, setErrorMesgShow] = useState<boolean>(false);

  const blurHandler = () => {
    errorMsgShow === false && setErrorMesgShow(true);
  };

  const submitHandler = async (e: any) => {
    e.preventDefault();
    if (
      errorMessages.email === undefined ||
      errorMessages.email?.message !== undefined
    ) {
      setErrorMesgShow(true);
      return;
    }

    await dispatch(brandResetPasswordAction(credentials.email));
  };

  return (
    <>
      <Helmet>
        <title>Brand - Forgot Password?</title>
      </Helmet>
      <main className='w-full flex-col relative'>
        <div className='w-full mx-auto sm:w-3/5 2xl:w-2/5 py-14 md:py-56'>
          <div className='flex flex-col lg:flex-row rounded-2xl overflow-hidden shadow-none md:shadow-card'>
            <ImageSidePanel />
            <form
              noValidate
              autoComplete='off'
              onSubmit={submitHandler}
              className='shadow-card md:shadow-none bg-white dark:bg-dark-theme-200 m-3 sm:m-0 p-4 md:p-6 sm:p-8 xl:p-10 lg:w-2/3 rounded-lg md:rounded-none'
            >
              <h1 className='text-2xl sm:text-3xl font-bold text-left mb-4 lg:mb-6'>
                {t('Forgot password?')}
              </h1>
              <p className='text-sm md:text-base text-left pb-2 lg:pb-4 text-gray-900 dark:text-gray-100'>
                {t('Already have a reeffo account?')}{' '}
                <DecorationNavlink to={'/brand/signin'}>
                  {t('Sign in')}
                </DecorationNavlink>
              </p>
              <FormContainer
                labelText={t('Email address')}
                errorMessage={
                  errorMessages.email?.show
                    ? errorMessages.email.message
                    : undefined
                }
              >
                <InputBox
                  name='email'
                  value={credentials.email}
                  onChange={handleInputChange}
                  placeholder='email@domain.tld'
                  error={errorMessages.email?.show}
                  onBlur={blurHandler}
                />
              </FormContainer>
              <Button
                pending={auth.pending}
                disabled={
                  errorMessages.email === undefined ||
                  errorMessages.email?.message !== undefined
                }
                addClassName='mt-4 font-medium'
              >
                {t('Reset Password')}
              </Button>
            </form>
          </div>
        </div>
      </main>
    </>
  );
}
