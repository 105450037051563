import { ReactElement } from 'react';
import { Navigate, Route, Routes } from 'react-router-dom';

import BrandSignin from 'views/brand/auth/BrandSignin';
import BrandSignup from 'views/brand/auth/BrandSignup';

import brandPath from 'utils/path/brand';

import BrandForgotPassword from 'views/brand/auth/BrandForgotPassword';
import BrandNewCampaign from 'views/brand/new_campaign/New.Campaign';
import BrandDashboard from 'views/brand/dashboard';
import BrandCampaigns from 'views/brand/campaigns';
import BrandProducts from 'views/brand/products';
import BrandSettings from 'views/brand/settings';
import BrandContent from 'views/brand/content';
import BrandResendCode from 'views/brand/auth/ResendCode';
import CreatorPublicProfile from 'views/CreatorPublicProfile';

export default function BrandRoute({ auth }: { auth: boolean }): ReactElement {
  const routes: IRoutesGroup[] = [
    {
      path: '/',
      element: <BrandDashboard />,
      authRequired: true,
    },
    {
      path: brandPath.SIGNIN,
      element: <BrandSignin />,
      authRequired: false,
    },
    {
      path: brandPath.SIGNUP,
      element: <BrandSignup />,
      authRequired: false,
    },
    {
      path: brandPath.VERIFY_EMAIL,
      element: <BrandResendCode />,
      authRequired: false,
    },
    {
      path: brandPath.FORGOT_PASSWORD,
      element: <BrandForgotPassword />,
    },
    {
      path: brandPath.CAMPAIGNS,
      element: <BrandCampaigns />,
      authRequired: true,
    },
    {
      path: brandPath.NEWCAMPAIGN,
      element: <BrandNewCampaign />,
      authRequired: true,
    },
    {
      path: brandPath.CONTENT,
      element: <BrandContent />,
      authRequired: true,
    },
    {
      path: brandPath.PRODUCTS,
      element: <BrandProducts />,
      authRequired: true,
    },
    {
      path: brandPath.SETTINGS,
      element: <BrandSettings />,
      authRequired: true,
    },
    {
      path: brandPath.CREATOR_PROFILE,
      element: <CreatorPublicProfile />,
      authRequired: true,
    },
  ];
  //fallback when theres no auth
  const fallbackRoute = <Navigate to={`/brand${brandPath.SIGNIN}`} replace />;

  return (
    <Routes>
      {routes
        .filter((route) =>
          route.authRequired === false && auth ? false : true
        )
        .map(({ authRequired, element, ...route }, index) => (
          <Route
            key={'app-influencer-route-' + index}
            {...route}
            element={authRequired ? (auth ? element : fallbackRoute) : element}
          />
        ))}
      <Route
        path='/*'
        element={
          <Navigate to={auth ? '/brand' : `/brand${brandPath.SIGNIN}`} />
        }
      />
    </Routes>
  );
}
