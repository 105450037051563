import { useLocation, useNavigate } from 'react-router-dom';
import { useEffect, useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { ColorRing } from 'react-loader-spinner';
import { AppDispatch, RootState } from 'redux/store';

import { getAllCampaignAction } from 'redux/brand_slices/campaignSlice';
import { getAllProductAction } from 'redux/brand_slices/productSlice';
import { getAllBrandAction } from 'redux/brand_slices/brandSlice';
import { getAllJobAction } from 'redux/brand_slices/jobSlice';

import BrandRoute from 'routes/brand';
import {
  checkExistingSessionAction,
  logoutAction,
} from 'redux/common_slices/authSlice';
import {
  getUserDataAction,
  setAuthedUserProfileData,
} from 'redux/common_slices/userSlice';
import AuthLayout from 'components/layout/AuthLayout';

export default function BrandApp() {
  const location = useLocation().pathname;
  const navigate = useNavigate();

  const dispatch = useDispatch<AppDispatch>();
  const authentication = useSelector((root: RootState) => root.authentication);
  const profile = useSelector((root: RootState) => root.user.profile);
  const path = useLocation().pathname;

  const { id, role, profile_picture } = profile;

  const checkLoggedIn = useCallback(async () => {
    console.log('Brand app check login session...');
    try {
      const session = await dispatch(checkExistingSessionAction());
      if (session.payload.data.profile === 'CREATOR') {
        await dispatch(logoutAction());
      }
      await dispatch(getUserDataAction(session.payload.data.id));
      console.log('before navigating to current path');
      navigate(location);
    } catch (e) {}
  }, []);

  useEffect(() => {
    checkLoggedIn();
    return () => {};
  }, []);

  useEffect(() => {
    if (!authentication.auth || !id || role === 'CREATOR') return;

    dispatch(getAllCampaignAction(id));
    dispatch(getAllProductAction(id));
    dispatch(getAllBrandAction(id));
    dispatch(getAllJobAction(id));

    if (authentication.auth && profile.role === 'ADMIN') {
      navigate('/admin');
      return;
    }

    return () => {};
  }, [authentication.auth, id]);

  if (
    (authentication.action === 'CHECK SESSION' ||
      authentication.action === undefined) &&
    authentication.pending
  ) {
    return (
      <div className='w-screen h-screen dark:bg-dark-theme-200 flex items-center justify-center'>
        <ColorRing
          visible={true}
          height='160'
          width='160'
          ariaLabel='blocks-loading'
          colors={['#FF8134', '#FF5757', '#5F5FFF', '#2F3454', 'transparent']}
        />
      </div>
    );
  }

  return (
    <AuthLayout
      userProfilePicture={profile_picture}
      auth={authentication.auth}
      path={path}
      userType='BRAND'
    >
      <BrandRoute auth={authentication.auth} />
    </AuthLayout>
  );
}
