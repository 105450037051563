import { ReactElement } from "react";
import { PencilSquareIcon } from "@heroicons/react/20/solid";
import { useTranslation } from "react-i18next";

import Card from "components/card";
import CardHr from "components/card/CardHr";
import CardTextLabel from "components/card/CardLabelText";
import CardText from "components/card/CardText";
import CardTitle from "components/card/CardTitle";
import InputBox from "components/form/InputBox";

import useEditCreatorProfile from "hooks/influencer/useEditCreatorProfile";
import Button from "components/button";

export default function AccountCredentials({
  editing,
  setEditing,
}: {
  editing: IProfileEdit;
  setEditing: (value: IProfileEdit) => void;
}): ReactElement {
  const { t } = useTranslation("translation", {
    keyPrefix: "creator.setting.CredentialPage",
  });

  const {
    user,
    editedUser,
    isEditing,
    toEditMode,
    editUserHandler,
    cancelEditHandler,
    updateUserProfileHandler,
  } = useEditCreatorProfile(editing, setEditing);

  return (
    <Card addClassName="p-4 md:p-8 mt-8">
      <CardTitle title={t("Credentials")} />
      <CardHr />
      <div className={`${isEditing ? "hidden" : "block"} relative`}>
        <PencilSquareIcon
          onClick={() => toEditMode("CREDENTIAL")}
          className="cursor-pointer w-6 h-6 absolute top-0 right-2"
        />
        <CardTextLabel>{t("Your password")}</CardTextLabel>
        <CardText addClassName="mb-4">••••••••••••••</CardText>
        <CardTextLabel>{t("Phone number")}</CardTextLabel>
        <CardText addClassName="mb-4">{user.phone ?? t("No phone number")}</CardText>
      </div>

      <div className={`${!isEditing ? "hidden" : "block"}`}>
        <div className="grid grid-cols-1 sm:grid-cols-2 gap-x-5 gap-2">
          <div className="flex flex-col gap-2">
            <CardTextLabel>{t("Password")}</CardTextLabel>
            <InputBox
              name="password"
              value={""}
              placeholder={t("Password") ?? ""}
              onChange={editUserHandler}
            />
          </div>
          <div className="flex flex-col gap-2">
            <CardTextLabel>{t("Phone number")}</CardTextLabel>
            <InputBox
              name="phone"
              value={editedUser.phone ?? ""}
              placeholder={t("Phone number") ?? ""}
              onChange={editUserHandler}
            />
          </div>
        </div>
        <div className="mt-4 flex flex-col sm:flex-row gap-x-5 gap-y-2">
          <Button onClick={cancelEditHandler} variant="outline">
            {t("Cancel")}
          </Button>
          <Button onClick={updateUserProfileHandler}>{t("Save changes")}</Button>
        </div>
      </div>
    </Card>
  );
}
