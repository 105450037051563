import { toast } from 'react-toastify';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { updateCampaignStatus } from 'apis/brand/campaign';

export default function useCampaignStatus(campaign?: ICampaignData) {
  const { t } = useTranslation('translation', {
    keyPrefix: 'notification',
  });

  const [campaignStatus, setCampaignStatus] = useState<
    'OPEN' | 'HIDDEN' | 'CLOSED'
  >(campaign?.campaignStatus ?? 'OPEN');

  const changeCampaignStatusHandler = async (status: {
    index: string;
    value: 'OPEN' | 'HIDDEN' | 'CLOSED';
  }) => {
    if (campaign === undefined || status.value === campaign.campaignStatus)
      return;
    try {
      await updateCampaignStatus(campaign.id, status.value);
      toast.success(t('Successfully updated campaign status'));
      setCampaignStatus(status.value);
    } catch (e) {
      toast.error(t('There was an error while updating campaign status'));
    }
  };

  useEffect(() => {
    if (campaign === undefined) return;

    setCampaignStatus(campaign?.campaignStatus);
    return () => {};
  }, [campaign]);

  return { campaignStatus, changeCampaignStatusHandler };
}
