import { ReactElement, useEffect, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import Card from 'components/card';
import CardHr from 'components/card/CardHr';
import Button from 'components/button';

import MessageInput from 'views/components/chat/MessageInput';
import AmplifyMedia from 'views/components/media/StorageImageRenderer';
import ChatHistoryItem from 'views/components/chat/Chat.HistoryItem';

import useChatData from 'hooks/common/useChatData';
import { ChatBubbleLeftRightIcon } from '@heroicons/react/24/outline';

export default function JobChatPanel({
  creator,
  brand,
}: {
  creator?: IUserProfile;
  brand?: IBrandData;
}): ReactElement {
  const { t } = useTranslation('translation', { keyPrefix: 'brand.campaigns' });

  const panelRef = useRef<HTMLDivElement>(null);

  const navigate = useNavigate();
  const {
    pending,
    userID,
    userName,
    campaignID,
    messages,
    isNew,
    setIsNew,
    shootMessage,
  } = useChatData('BRAND', creator?.id);

  useEffect(() => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
    return () => {};
  }, []);

  useEffect(() => {
    const height = panelRef.current?.scrollHeight;
    if (height && isNew) {
      panelRef.current.scrollTop = height;
      setIsNew(false);
    }
  }, [messages]);

  return (
    <Card addClassName='p-4 md:p-8 mt-8'>
      <div className='flex justify-between items-center'>
        <div className='flex items-center gap-2'>
          <AmplifyMedia
            width={12}
            height={12}
            className='w-12 h-12 rounded-full'
            isUserAvatar
            sourceKey={creator?.profile_picture}
          />
          <p className='font-medium'>
            <span className='text-gray-400 text-xs block'>
              {t('Chat with')}
            </span>
            <span className='text-sm font-medium capitalize'>
              {`${creator?.firstName} ${creator?.lastName}`} @ $
              {brand?.brandName}
            </span>
          </p>
        </div>
        <Button
          onClick={() => navigate('/brand/campaigns/' + campaignID)}
          variant='outline'
          className='h-10 px-5 text-sm font-medium'
        >
          {t('Go to campaign')}
        </Button>
      </div>
      <CardHr />
      <div
        ref={panelRef}
        className='mt-8 h-[460px] overflow-auto pr-5 relative'
      >
        {messages.length === 0 && (
          <div className='absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 flex flex-col items-center gap-y-4'>
            <ChatBubbleLeftRightIcon className='w-12 h-12' />
            {t('No messages')}
          </div>
        )}
        {messages.map((message, index) => (
          <ChatHistoryItem
            key={'chat-history' + index}
            align={message.fromUserID === userID ? 'left' : 'right'}
            avatar={message.fromUserID === userID ? userName.at(0) ?? '' : 'J'}
            message={message}
          />
        ))}
      </div>
      <CardHr />
      <div className='flex'>
        <MessageInput pending={pending} onShoot={shootMessage} />
      </div>
    </Card>
  );
}
