import { API } from 'aws-amplify';

import * as queries from 'graphql/queries';
import * as customQueries from 'graphql_custom/custom_queries';
import * as mutations from 'graphql/mutations';
import { removeDatabaseDates } from 'helpers/object';

export async function getAllMessagesByJobID(jobID: string) {
  const response = await API.graphql({
    query: queries.messagesByJobID,
    variables: { jobID, limit: 1000},
  });

  return response;
}

export async function getAllNotifications(userID: string) {
  const list = (await API.graphql({
    query: queries.notificationsByUserID,
    variables: { userID, limit: 1000 },
  })) as any; //todo is this right to do any?
  const result = { success: true, data: list.data.notificationsByUserID.items }; //todo is this how we replace mock items ?
  return result;
}

export async function setNotificationAsRead(id: string) {
  const response = await API.graphql({
    query: mutations.updateNotifications,
    variables: { input: { id, isRead: true } },
  });
  return response;
}

export async function shootMessage(data: IMessage) {
  const response = await API.graphql({
    query: mutations.createMessage,
    variables: { input: data },
  });

  return response;
}

export async function getUserData(userID: string) {
  const result = await API.graphql({
    query: queries.getUser,
    variables: { id: userID },
  });

  return result;
}

export async function updateUserProfile(data: IUserProfile) {
  const response: any = await API.graphql({
    query: customQueries.updateUser,
    variables: { input: removeDatabaseDates(data) },
  });

  return response;
}

export async function createUserReview({
  id,
  updatedAt,
  createdAt,
  ...review
}: IUserReview) {
  const response: any = await API.graphql({
    query: mutations.createUserReview,
    variables: { input: review },
  });

  return response;
}

export async function getAllUserReviews(userID: string) {
  const response: any = await API.graphql({
    query: queries.userReviewsByUserID,
    variables: { userID, limit: 1000 },
  });

  return response;
}

export async function getAllUsers() {
  console.log('getAllUsers', getAllUsers);
  const response: any = await API.graphql({
    query: customQueries.listUsers,
    variables: {
      limit: 1000
    },
  });

  console.log('response: ', response);

  return response;
}
