const marketplace = {
  // first scene
  'Jobs you may be interested in': '你或许会感兴趣的任务',
  digital: '电子产品',
  physical: '实物产品',
  'Product price': '产品价格',

  //campaign detail - /influencer/campaigns/:campaignID
  'Brand approval required': '需经品牌确认',
  "Apply for this job and we'll let you know if a brand chooses you to create the content":
    '提交申请，品牌选中后平台会推送提醒',
  'Product link': '产品链接',
  product: '产品',
  'Brand will ship directly': '品牌会直接邮寄产品给您',
  'Creator will purchase': '创作者需要直接购买产品',
  Earnings: '账户收入',
  'Apply for this job': '申请承接这项任务',

  'Job details': '任务细节',
  Brand: '品牌',

  // content format
  any: '任意',
  portrait: '纵向视频',
  landscape: '横向视频',
  square: '方形视频',
  'aspect ratio': '视频尺寸比例',
  'All aspect ratio': '全部视频比例',

  'Content description': '内容描述',
  Description: '简述',
  'No description': '无简述',
  'Are you sure?': '是否确认？',

  Cancel: '取消',
  Apply: '申请',

  // job detail
  'Back to my jobs': '回到我的任务',
  'Someone invited you to a job, you can start by accepting the invitation.':
    '有品牌邀请您参加一项任务，如果您想参与合作，请接受。',

  Accept: '接受',
  Decline: '拒绝',

  'You have applied for this job, please wait until clients accept your application.':
    '您已经申请接受这份任务工作，请等待客户接受您的申请。',

  'Delivery info': '内容发送信息',
  'Job info': '任务详情',
  Chat: '聊天对话',
  Content: '内容',

  // chat box
  'Chat with': '聊天对象',
  'Go to my jobs': '进入我的任务',
  Send: '发送',

  // job contents
  'No contents': '无内容',

  // Dropzone component
  'Choose a file or drag it here': '选择一个文件或者拖拽到这里',
  'Remove all': '删除全部',
  'Upload all': '上传全部',

  'Required content': '要求内容',
  'No messages': '暂无消息',

  jobStatus: {
    all: '全部',
    invited: '已邀请的内容创作者',
    open: '在进行中的',
    'waiting-payment': '等待支付',
    started: '已开始的任务',
    'product-sent': '已发出的产品',
    'product-delivered': '已送到的产品',
    'content-uploaded': '已上传的内容',
    completed: '已完成的任务',
    rejected: '被拒绝的任务',
  },

  'Write message...': '写信息...',
  'You can upload contents after job has started':
    '请在任务开始后上传视频和图片内容',
  Product: '产品',
  'Created at': '视频上传时间',  
};

export default marketplace;
