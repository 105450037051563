import { ReactElement } from 'react';
import { useTranslation } from 'react-i18next';

import Card from 'components/card';
import CardHr from 'components/card/CardHr';

import JobContentCard, { SkeletonJobContentCard } from './Card.JobContent';

export default function ContentTableCard({
  contents,
  fetching,
  fetchJobContents,
}: {
  contents: IJobContent[];
  fetching: boolean;
  fetchJobContents: () => void;
}): ReactElement {
  const { t } = useTranslation('translation', { keyPrefix: 'brand.contents' });

  return (
    <Card addClassName='p-4 md:p-8'>
      <CardHr />
      {contents.length === 0 && fetching && (
        <div className='grid lg:grid-cols-4 2xl:grid-cols-5 gap-4'>
          {new Array(5).fill(true).map((_elem, index) => (
            <SkeletonJobContentCard key={'skeleton-job-content' + index} />
          ))}
        </div>
      )}

      {contents.length === 0 && !fetching && (
        <p className='text-gray-500 dark:text-gray-300'>
          {t(
            'Sorry, but nothing matched your search terms. Please change your filtering selections and try again.'
          )}
        </p>
      )}

      {contents.length > 0 && (
        <div className='grid sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 2xl:grid-cols-5 gap-4'>
          {contents.map((content) => (
            <JobContentCard {...content} fetchJobContents={fetchJobContents} />
          ))}
        </div>
      )}
    </Card>
  );
}
