import { ReactElement, useState } from "react";
import { useTranslation } from "react-i18next";

import AuthedHeading from "components/typhography/AuthedHeading";

import AccountCredentials from "../components/Account.Accounts";
import AccountAddress from "../components/Account.Address";
import AccountProfile from "../components/Account.Profile";
import AccountSocials from "../components/Account.Socials";

export default function AccountSetting(): ReactElement {
  const { t } = useTranslation("translation", {
    keyPrefix: "creator.setting",
  });

  const [profileEditing, setProfileEditing] = useState<IProfileEdit>({
    editing: false,
    section: null,
  });

  return (
    <>
      <AuthedHeading addClassName="mt-0">{t("Account")}</AuthedHeading>

      <AccountProfile editing={profileEditing} setEditing={setProfileEditing} />

      <AccountAddress editing={profileEditing} setEditing={setProfileEditing} />

      <AccountSocials editing={profileEditing} setEditing={setProfileEditing} />

      <AccountCredentials editing={profileEditing} setEditing={setProfileEditing} />
    </>
  );
}
