import { ReactElement } from 'react';
import { Helmet } from 'react-helmet';
import { useTranslation } from 'react-i18next';

import Tab from 'components/tab';
import { SkeletonBox } from 'components/skeleton';
import AuthedHeading from 'components/typhography/AuthedHeading';

import CampaignJobCard from '../components/CampaignJobCard';
import CampaignStatusCard from '../components/CampaignStatusCard';
import BrandAuthedContainer from '../../_components/Container.AuthedBrand';

// panel views
import CampaginDetail from '../components/Card.CampaignDetail';
import CampaignJobTableCard from '../components/Card.CampaignJobTable';
import CampaignCreatorTableCard from '../components/Card.CampaignCreatorTable';
import CampaignContentTableCard from '../components/Card.CampaignContentTable';
import CampaginDeliveryTableCard from '../components/Card.CampaignDeliveryTable';
import CampaignCostBreakDownCard from '../components/Card.CampaignCostBreakdown';

import useCampaignDetailFromId from 'hooks/brand/useCampaignDetailFromId';

export default function CampaignDetailView(): ReactElement {
  const { t } = useTranslation('translation', { keyPrefix: 'brand.campaigns' });

  const { campaign, pending } = useCampaignDetailFromId();

  window?.scrollTo({ top: 0 });

  return (
    <>
      <Helmet>
        <title>My campaigns</title>
      </Helmet>
      <BrandAuthedContainer>
        <AuthedHeading>
          {pending && <SkeletonBox className='h-8 w-40' />}
          {!pending && campaign && campaign?.campaignName}
        </AuthedHeading>
        <div className='flex flex-col lg:flex-row justify-around mb-12'>
          <CampaignJobCard
            pending={pending}
            campaign={campaign}
            brand={campaign?.Brand}
          />
          <CampaignStatusCard pending={pending} campaign={campaign} />
        </div>
        <Tab
          pending={pending}
          pendingBody={<SkeletonBox className='w-full h-[240px]' />}
          tabs={[
            t('Jobs'),
            t('Creators'),
            t('Content'),
            t('Campaign details'),
            t('Delivery'),
            t('Cost breakdown'),
          ]}
          tabBarAddClassName='mt-4 mb-6'
          views={[
            <CampaignJobTableCard />,
            <CampaignCreatorTableCard campaign={campaign} />,
            <CampaignContentTableCard campaignID={campaign?.id} />,
            <CampaginDetail
              brand={campaign?.Brand}
              campaign={campaign}
              product={campaign?.Product}
            />,
            <CampaginDeliveryTableCard />,
            <CampaignCostBreakDownCard
              brand={campaign?.Brand}
              campaign={campaign}
              product={campaign?.Product}
            />,
          ]}
        />
      </BrandAuthedContainer>
    </>
  );
}
