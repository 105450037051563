import { ReactElement } from 'react';
import { ArrowTopRightOnSquareIcon } from '@heroicons/react/24/solid';
import {
  ArrowsPointingOutIcon,
  PhotoIcon,
  VideoCameraIcon,
} from '@heroicons/react/24/outline';
import { useTranslation } from 'react-i18next';

import Card from 'components/card';
import CardHr from 'components/card/CardHr';
import CardText from 'components/card/CardText';
import CardTextLabel from 'components/card/CardLabelText';

import AmplifyMedia from 'views/components/media/StorageImageRenderer';

export default function CampaginDetail({
  campaign,
  brand,
  product,
}: {
  brand?: IBrandData;
  campaign?: ICampaignData;
  product?: IProductData;
}): ReactElement {
  const { t } = useTranslation('translation', { keyPrefix: 'brand.campaigns' });

  return (
    <Card addClassName='p-4 md:p-8'>
      <div className='flex gap-2'>
        <div className='w-12 h-12 bg-dark rounded-lg flex items-center justify-center text-white text-xl capitalize'>
          {brand?.imageUrl ? (
            <AmplifyMedia
              width={250}
              height={250}
              className='w-full h-full object-cover'
              sourceKey={brand.imageUrl}
            />
          ) : (
            brand?.brandName.at(0)
          )}
        </div>
        <div className='flex flex-col justify-around'>
          <CardTextLabel>{t('Brand')}</CardTextLabel>
          <CardText addClassName='font-medium'>{brand?.brandName}</CardText>
        </div>
      </div>

      <CardHr />

      <CardTextLabel>{t('Product')}</CardTextLabel>
      <CardText addClassName='mt-1 flex w-full justify-between font-medium'>
        <span>{product?.productName}</span>
        <span>${product?.productPrice.toFixed(2)}</span>
      </CardText>
      <a
        rel="noreferrer"
        target="_blank"      
        href={product?.productExternalLink}
        className='text-theme cursor-pointer mt-2 flex gap-1 items-center'
      >
        {t('Product link')}
        <ArrowTopRightOnSquareIcon className='w-5 h-5' />
      </a>

      <CardHr />

      <CardTextLabel>{t('Campaign name')}</CardTextLabel>
      <CardText addClassName='font-medium mt-1'>
        {campaign?.campaignName}
      </CardText>

      <CardHr />

      <CardTextLabel>{t('Required content')}</CardTextLabel>
      <CardText addClassName='mt-2 flex items-center gap-1 capitalize'>
        {campaign?.contentType === 'photo' ? (
          <PhotoIcon className='w-5 h-5' />
        ) : campaign?.contentType === 'video' ? (
          <VideoCameraIcon className='w-5 h-5' />
        ) : (
          <></>
        )}
        {t(campaign?.contentType ?? '')}
      </CardText>
      <CardText addClassName='mt-1 flex items-center gap-1 capitalize'>
        <ArrowsPointingOutIcon className='w-5 h-5' />
        {t(campaign?.contentFormat ?? '')}
      </CardText>

      <CardHr />

      <CardTextLabel>{t('Description')}</CardTextLabel>
      <CardText addClassName='mt-1'>
        <pre className='whitespace-pre-wrap'>{campaign?.description}</pre>
      </CardText>
    </Card>
  );
}
