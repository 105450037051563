import { ReactElement, ReactNode } from 'react';

export default function AuthedHeading(props: {
  children?: ReactNode;
  addClassName?: string;
}): ReactElement {
  return (
    <h1
      className={`mt-6 text-2xl font-semibold text-dark dark:text-gray-100 ${
        props.addClassName ?? ''
      }`}
    >
      {props.children}
    </h1>
  );
}
