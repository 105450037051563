import { ReactElement } from 'react';
import { NavLink } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import Card from 'components/card';
import Button from 'components/button';
import { SkeletonBox } from 'components/skeleton';

import useFetchedProductDetail from 'hooks/brand/useFetchedProductDetail';

export default function ProductCampaignBar(): ReactElement {
  const { fetching, error, product } = useFetchedProductDetail();

  const { t } = useTranslation('translation', {
    keyPrefix: 'brand.products',
  });

  return (
    <Card addClassName='p-4 md:p-8 mb-6'>
      <div className='flex flex-col lg:flex-row w-full sm:justify-between'>
        <div className='flex flex-col sm:flex-row w-full lg:w-4/5 sm:gap-6 items-center'>
          <div className='flex flex-col items-center sm:items-start border-b sm:border-b-0 sm:border-r border-gray-200 w-full sm:w-1/3 pb-4 sm:pb-0'>
            {fetching ? (
              <SkeletonBox className='h-5 my-[2px] w-10' darkMode />
            ) : (
              <p className='font-semibold text-gray-900 dark:text-gray-200'>
                {product?.Campaigns !== undefined &&
                  product?.Campaigns.items?.filter(
                    (campaign) => campaign.campaignStatus === 'OPEN'
                  ).length}
              </p>
            )}

            <span className='text-sm text-gray-500 dark:text-gray-400'>
              {t('Open campaigns')}
            </span>
          </div>
          <div className='flex flex-col items-center sm:items-start pt-4 sm:pt-0 border-b sm:border-b-0 sm:border-r border-gray-200 w-full sm:w-1/3 pb-4 sm:pb-0'>
            {fetching ? (
              <SkeletonBox className='h-5 my-[2px] w-10' darkMode />
            ) : (
              <p className='font-semibold text-gray-900 dark:text-gray-200'>
                {product?.Campaigns !== undefined &&
                  product?.Campaigns.items?.filter(
                    (campaign) => campaign.campaignStatus === 'HIDDEN'
                  ).length}
              </p>
            )}

            <span className='text-sm text-gray-500 dark:text-gray-400'>
              {t('Hidden campaigns')}
            </span>
          </div>
          <div className='flex flex-col items-center sm:items-start pt-4 sm:pt-0 sm:w-1/3'>
            {fetching ? (
              <SkeletonBox className='h-5 my-[2px] w-10' darkMode />
            ) : (
              <p className='font-semibold text-gray-900 dark:text-gray-200'>
                {product?.Campaigns !== undefined &&
                  product?.Campaigns.items?.filter(
                    (campaign) => campaign.campaignStatus === 'CLOSED'
                  ).length}
              </p>
            )}

            <span className='text-sm text-gray-500 dark:text-gray-400'>
              {t('Closed campaigns')}
            </span>
          </div>
        </div>
        <NavLink to='/brand/campaign/create'>
          <Button addClassName='w-[200px] mt-8 lg:mt-0 font-medium'>
            {t('Create campaign')}
          </Button>
        </NavLink>
      </div>
    </Card>
  );
}
