const content = {
  'Available content': '已制作完成的内容',
  MyContent: '我的内容媒体库({{count}})',
  'Pick content': '内容选择({{count}})',

  // No contents
  'Sorry, but nothing matched your search terms. Please change your filtering selections and try again.':
    '对不起未能找到符合条件的内容，请重新设定筛选条件再次搜索.',

  // pick & unpick
  'Pick this': '选择这里',
  'Unpick this': '不选择这里',

  'Content preview': '内容预览',
  Download: '下载',
};

export default content;
