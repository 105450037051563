import landing from './landing';
import creator from './creator';
import brand from './brand';
import auth from './auth';
import notification from './notification';

import nav from './nav.json';
import brandNav from './nav.json';

const en = {
  translation: {
    landing,
    auth,
    creator,
    brand,
    nav,
    brandNav,
    notification,
  },
};

export default en;
