const setting = {
  // aside bar
  Account: '账户',
  Portfolio: '内容库',
  Reviews: '评论',
  Wallet: '钱包',
  'Payment methods': '支付方式',

  'Member since': '成员自从',
  'Active jobs': '在进行中的任务',

  AccountPage: {
    'Personal information': '个人信息',
    'First name': '名',
    'Last name': '姓',
    'Day of birth': '出生日期',
    'Not provided': '未提供',
    'Email': '邮箱',
    Gender: '性别',
    Male: '男',
    Female: '女',

    // Buttons
    Cancel: '取消',
    'Save changes': '保存修改',
  },

  AddressPage: {
    'Delivery Address': '产品邮寄地址',
    Country: '国家',
    'Address line 1': '地址栏1',
    'City/Town': '所在城市',
    State: '所在州',
    'Zip code': '邮编',
    'Not provided': '未提供',

    // Buttons
    Cancel: '取消',
    'Save changes': '保存修改',
  },

  SocialPage: {
    'Not provided': '未提供',
    Instagram: 'Instagram',
    Tiktok: 'Tiktok',
    Youtube: 'Youtube',
    'Social media links': 'Social media links',
    // Buttons
    Cancel: '取消',
    'Save changes': '保存修改',
  },

  CredentialPage: {
    'Your password': '秘码',
    'Phone number': '电话',
    'No phone number': '无电话',

    Credentials: '证件信息',
    Password: '密码',

    // Buttons
    Cancel: '取消',
    'Save changes': '保存修改',
  },

  PortfolioPage: {
    'My portfolio': '我的内容库',
    'See public portfolio': '查看内容库',
    'Upload new content': '上传新内容',
    'Submit content': '提交内容',
    published: '已公布',
    Content: '内容',
  },

  ReviewPage: {
    'My Reviews': '我的评论',
    'No reviews': '无评论',
    "Looks like you don't have any reviews": '看上去您目前还未收到任何评论',
    'Go to marketplace': '去广场大厅',
  },

  Payment: {
    'Payout method': '支付方式',
    'Your payouts will be sent directly to your PayPal account.':
      '酬金会直接送到您的Paypal账号.',
    'Paypal Username:': 'Paypal账户名或邮箱:',
    Edit: '编辑',
    Cancel: '取消',
    Save: '保存',
  },
};

export default setting;
