import mockWait from 'utils/mock/wait';

const mockProtoType: IMockProtoType = {
  productCategories: [
    {
      id: 'DEFAULT',
      name: 'Select A Category',
    },    
    {
      id: 'ARTS',
      name: 'Arts, Crafts & Sewing',
    },
    {
      id: 'BABY',
      name: 'Baby',
    },
    {
      id: 'BOOKS',
      name: 'Books',
    },       
    {
      id: 'ELECTRONICS',
      name: 'Electronics',
    },    
    {
      id: 'EXERCISE',
      name: 'Exercise',
    },  
    {
      id: 'GROCERY',
      name: 'Grocery & Gourmet',
    },      
    {
      id: 'HOME',
      name: 'Home',
    },
    {
      id: 'KITCHEN',
      name: 'Kitchen',
    },
    {
      id: 'MUSIC',
      name: 'Music',
    },    
    {
      id: 'OFFICE',
      name: 'Office Products',
    },    
    {
      id: 'OUTDOORS',
      name: 'Outdoors',
    },     
    {
      id: 'SPORTINGGOODS',
      name: 'Sporting Goods',
    },    
    {
      id: 'TOOLS',
      name: 'Tools & Home Improvement',
    },      
    {
      id: 'TOYS',
      name: 'Toys & Games',
    },       
    {
      id: 'VIRTUAL',
      name: 'Virtual',
    },

    {
      id: 'VITAMINSANDSUPPLEMENTS',
      name: 'Vitamins & Supplements',
    },    
  ],
};

export async function doGetMockProtoTypes(): Promise<IMockProtoType> {
  await mockWait(1500);

  return mockProtoType;
}
