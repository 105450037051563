import { XCircleIcon } from '@heroicons/react/20/solid';
import { useState } from 'react';

export default function Tip(props: ITip) {
  const [visible, setVisible] = useState<boolean>(true);

  const tipThemes: Record<'info' | 'warning' | 'success' | 'error', string> = {
    info: 'border-l-blue-500 bg-white text-blue-500',
    warning: 'border-l-orange-500 text-orange-500 bg-white',
    success: 'border-l-blue-500',
    error: 'border-l-blue-500',
  };

  if (visible === false) return <></>;

  return (
    <div className={`${props.wrapperClassName ?? ''} relative group`}>
      {props.closable && (
        <XCircleIcon
          onClick={() => setVisible(false)}
          className='cursor-pointer right-2 top-2 w-6 h-6 text-gray-400 absolute opacity-0 group-hover:opacity-100 transition-all duration-150'
        />
      )}
      <div
        className={`tip py-6 pr-[18px] flex justify-between items-center rounded-r-lg border-l-4 ${
          tipThemes[props.tipType]
        }`}
      >
        <div className='w-full flex items-center'>
          {props.icon && (
            <div className='w-[60px] flex items-center justify-center'>
              {props.icon}
            </div>
          )}

          <div className={`w-full flex flex-col ${props.icon ? '' : 'pl-10'}`}>
            <span className='font-semibold text-base text-gray-900 pb-1'>
              {props.title}
            </span>
            <div className='font-normal text-sm text-gray-500 w-full'>
              {props.description}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
