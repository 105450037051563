import { ReactElement } from 'react';
import { useTranslation } from 'react-i18next';
import { PhotoIcon, VideoCameraIcon } from '@heroicons/react/24/solid';

import Card from 'components/card';
import { SkeletonBox } from 'components/skeleton';

import AmplifyImage from 'views/components/media/StorageImageRenderer';

const accounting = require('accounting');

export default function CampaignJobCard({
  pending,
  campaign,
  brand,
}: ICampaignJobCard): ReactElement {
  const { t } = useTranslation('translation', { keyPrefix: 'brand.campaigns' });

  return (
    <Card addClassName='p-4 md:p-8 mt-8 lg:w-[40%]'>
      <div className='flex flex-col gap-10'>
        <div className='mx-auto w-[160px] h-[160px] rounded-lg bg-dark-theme-200/70 dark:bg-dark-theme-900 font-semibold text-5xl flex justify-center items-center text-white capitalize'>
          {(() => {
            if (pending) {
              return <SkeletonBox darkMode className='w-full aspect-square' />;
            } else {
              return (
                <AmplifyImage
                  className='w-full aspect-square rounded-lg'
                  sourceKey={campaign?.Product?.productImage}
                />
              );
            }
          })()}
        </div>
        <div className='flex justify-between'>
          <div className='text-sm font-medium flex flex-col justify-around'>
            {pending ? (
              <>
                <SkeletonBox darkMode className='h-5 w-32' />
                <SkeletonBox darkMode className='h-12 w-32 my-2' />
                <SkeletonBox darkMode className='w-32 h-5' />
              </>
            ) : (
              <>
                {brand?.brandName}
                <p className='mt-2'>
                  {t('Delivery type')}
                  <span className='block text-gray-500 text-xs pt-1'>
                    {campaign?.deliveryType ? t(campaign.deliveryType) : ''}
                  </span>
                </p>
                {campaign && (
                  <p className='flex items-center gap-1 mt-2'>
                    {t('Product review')}
                    {campaign.contentType === 'photo' ? (
                      <PhotoIcon className='w-5 h-5' />
                    ) : (
                      <VideoCameraIcon className='w-5 h-5' />
                    )}
                  </p>
                )}
              </>
            )}
          </div>
          <div className='text-right'>
            <span className='block text-xs text-gray-400'>
              {t('Job price')}
            </span>
            {pending ? (
              <SkeletonBox darkMode className='h-5 w-20' />
            ) : (
              <span className='font-medium'>
                {accounting.formatMoney(campaign?.campaignPrice, '¥')}
              </span>
            )}
          </div>
        </div>
      </div>
    </Card>
  );
}
