import { ReactElement } from 'react';
import {
  ArrowTopRightOnSquareIcon,
  InformationCircleIcon,
} from '@heroicons/react/20/solid';
import { Dialog } from '@headlessui/react';
import {
  ArrowsPointingOutIcon,
  PhotoIcon,
  VideoCameraIcon,
} from '@heroicons/react/24/outline';
import { useTranslation } from 'react-i18next';

import CreatorAuthedContainer from '../_components/Container.AuthedCreator';
import Tip from 'components/tip/Tip';
import Card from 'components/card';
import Modal from 'components/modal/Modal';
import Button from 'components//button';
import CardHr from 'components/card/CardHr';
import CardText from 'components/card/CardText';
import CardTitle from 'components/card/CardTitle';
import CardTextLabel from 'components/card/CardLabelText';
import { SkeletonBox } from 'components/skeleton';

import AmplifyMedia from 'views/components/media/StorageImageRenderer';

import useFetchedCampaignData from 'hooks/influencer/useFetchedCampaignData';

export default function CampaignDetail(): ReactElement {
  const { t } = useTranslation('translation', {
    keyPrefix: 'creator.marketplace',
  });

  window?.scrollTo({ top: 0 });

  const {
    pending,
    fetching,
    safety,
    error,
    visibility: modalVisibility,
    campaign,
    openConfirmModal,
    closeConfirmModal,
    applyJob,
  } = useFetchedCampaignData();

  return (
    <>
      <CreatorAuthedContainer>
        <div className='xl:px-44 2xl:px-52'>
          {safety && (
            <Card>
              <Tip
                icon={
                  <InformationCircleIcon className='w-6 h-6 text-blue-500' />
                }
                tipType={'info'}
                title={t('Brand approval required')}
                description={t(
                  "Apply for this job and we'll let you know if a brand chooses you to create the content"
                )}
                wrapperClassName='mt-6 mb-2'
              />
            </Card>
          )}

          <Card addClassName='p-4 md:p-8 mt-2'>
            <div className='flex flex-col md:flex-row justify-between items-center md:items-stretch gap-10'>
              <div className='min-w-[250px] w-[250px] h-[250px] rounded-lg overflow-hidden'>
                {(() => {
                  if (fetching) {
                    return <SkeletonBox className='w-full h-full' darkMode />;
                  }

                  return (
                    <AmplifyMedia
                      width={250}
                      height={250}
                      className='w-full h-full object-cover'
                      sourceKey={campaign?.Product?.productImage}
                    />
                  );
                })()}
              </div>

              <div className='w-full flex flex-col justify-between md:py-2'>
                <div className='flex w-full justify-between mb-4 md:mb-0'>
                  <div>
                    {fetching ? (
                      <SkeletonBox className='w-32 h-5 my-1' darkMode />
                    ) : (
                      <h5 className='font-semibold'>
                        {campaign?.Product?.productName}
                      </h5>
                    )}

                    <p className='flex items-center gap-2 font-medium'>
                      {(() => {
                        if (fetching) {
                          return (
                            <>
                              <SkeletonBox className='w-12 h-4 my-1' darkMode />
                              <SkeletonBox
                                className='w-[112px] h-4 my-1'
                                darkMode
                              />
                            </>
                          );
                        }

                        return (
                          <>
                            ${campaign?.Product?.productPrice}{' '}
                            <a
                              rel="noreferrer"
                              target="_blank"                            
                              href={
                                campaign?.Product?.productExternalLink ?? '#'
                              }
                              className='flex gap-2 text-sm items-center text-blue-600 dark:text-blue-300'
                            >
                              {t('Product link')}
                              <ArrowTopRightOnSquareIcon className='w-4 h-4' />
                            </a>
                          </>
                        );
                      })()}
                    </p>
                    <p className='mt-6 text-sm'>
                      {fetching ? (
                        <>
                          <SkeletonBox
                            className='w-[100px] h-[14px] my-[2px]'
                            darkMode
                          />
                          <SkeletonBox className='w-[160px] h-4' darkMode />
                        </>
                      ) : (
                        <>
                          <span className='font-medium capitalize'>
                            {t(
                              campaign?.Product?.productType?.toLowerCase() ??
                                ''
                            )}{' '}
                            {t('product')}
                          </span>
                          <span className='block text-xs text-gray-500'>
                            {t(campaign?.deliveryType ?? '')}
                          </span>
                        </>
                      )}
                    </p>
                  </div>
                  {/* <div className='flex flex-col items-end'>
                    <span className='text-sm font-medium'>{t('Earnings')}</span>
                    {fetching ? (
                      <SkeletonBox className='h-6 my-1 w-16' darkMode />
                    ) : (
                      <span className='text-xl font-semibold'>$100</span>
                    )}
                  </div> */}
                </div>

                <Button
                  disabled={fetching || !safety}
                  onClick={openConfirmModal}
                >
                  {safety
                    ? t('Apply for this job')
                    : 'You have an active job with this campaign.'}
                </Button>
              </div>
            </div>
          </Card>
          <Card addClassName='p-4 md:p-8 mt-2'>
            <CardTitle title={t('Job details')} />
            <div className='-mt-2 flex gap-4'>
              <div className='w-16 h-16 rounded-md overflow-hidden'>
                {(() => {
                  if (fetching) {
                    return <SkeletonBox className='w-full h-full' darkMode />;
                  }
                  return (
                    <AmplifyMedia
                      sourceKey={campaign?.Brand?.imageUrl}
                      className='w-full h-full object-cover'
                    />
                  );
                })()}
              </div>
              <p>
                <span className='text-xs text-gray-400'>{t('Brand')}</span>
                {fetching ? (
                  <SkeletonBox className='h-5 my-1 w-32' darkMode />
                ) : (
                  <span className='block'>{campaign?.Brand?.brandName}</span>
                )}
              </p>
            </div>
            <CardHr />
            <CardTextLabel>{t('Required content')}</CardTextLabel>
            <CardText addClassName='mt-1 flex items-center gap-2 text-sm font-medium capitalize'>
              {fetching ? (
                <SkeletonBox className='w-5 h-5' darkMode />
              ) : campaign?.contentType === 'photo' ? (
                <PhotoIcon className='w-5 h-5' />
              ) : (
                <VideoCameraIcon className='w-5 h-5' />
              )}

              {fetching ? (
                <SkeletonBox className='h-4 my-[2px] w-20' darkMode />
              ) : (
                <>
                  {t(campaign?.Product?.productType?.toLowerCase() ?? '')}{' '}
                  {t('product')}
                </>
              )}
            </CardText>
            <CardText addClassName='mt-1 flex items-center gap-2 text-sm font-medium capitalize'>
              {fetching ? (
                <SkeletonBox className='w-5 h-5' darkMode />
              ) : (
                <ArrowsPointingOutIcon className='w-5 h-5' />
              )}

              {fetching ? (
                <SkeletonBox className='h-4 my-[2px] w-48' darkMode />
              ) : (
                <>
                  {t(campaign?.contentFormat ?? '')}
                  <span className='text-gray-500 text-xs'>
                    (
                    {campaign?.contentFormat === 'any'
                      ? t('All aspect ratio')
                      : campaign?.contentFormat === 'landscape'
                      ? '16:9 ' + t('aspect ratio')
                      : campaign?.contentFormat === 'portrait'
                      ? '3:4 ' + t('aspect ratio')
                      : '1:1 ' + t('aspect ratio')}
                    )
                  </span>
                </>
              )}
            </CardText>
          </Card>

          <Card addClassName='p-4 md:p-8 mt-2'>
            <CardTitle noMargin title={t('Content description')} />
            <CardHr marginClassName='my-3' />
            <CardTextLabel>{t('Description')}</CardTextLabel>
            <CardText addClassName='text-sm mt-1'>
              {fetching ? (
                <>
                  <SkeletonBox className='w-full h-[18px] my-[2px]' darkMode />
                  <SkeletonBox className='w-full h-[18px] my-[2px]' darkMode />
                  <SkeletonBox className='w-full h-[18px] my-[2px]' darkMode />
                  <SkeletonBox className='w-full h-[18px] my-[2px]' darkMode />
                </>
              ) : (
                campaign?.description ?? 'No description'
              )}
            </CardText>
          </Card>

          {/* <Card addClassName='p-4 md:p-8 mt-2'>
            <CardTitle noMargin title='Earnings' />
            <CardHr marginClassName='my-4' />
          </Card> */}
        </div>
      </CreatorAuthedContainer>
      <Modal isOpen={modalVisibility} closeModal={closeConfirmModal}>
        <Dialog.Panel className='flex flex-col items-center w-full max-w-[480px] min-w-[480px] transform overflow-hidden rounded-xl bg-white dark:bg-dark-theme-200 shadow-card transition-all'>
          <Dialog.Title
            as='h3'
            className='text-lg font-medium leading-6 text-gray-900 dark:text-gray-100 mx-5'
          >
            <span className='flex justify-between items-center py-5'>
              {t('Apply for this job')}
            </span>
          </Dialog.Title>
          <p className='text-sm'>{t('Are you sure?')}</p>
          <CardHr marginClassName='w-full my-5' />
          <div className='flex justify-between w-full px-5 pb-5 gap-5'>
            <Button
              variant='outline'
              onClick={closeConfirmModal}
              addClassName='w-full'
            >
              {t('Cancel')}
            </Button>
            <Button pending={pending} onClick={applyJob} addClassName='w-full'>
              {t('Apply')}
            </Button>
          </div>
        </Dialog.Panel>
      </Modal>
    </>
  );
}
