import { ReactElement } from 'react';
import { Route, Routes } from 'react-router-dom';

import CampaignHome from './pages/CampaignHome';
import CampaignDetailHome from './pages/CampaignDetailHome';

export default function BrandCampaigns(): ReactElement {
  return (
    <Routes>
      <Route path='/' element={<CampaignHome />} />
      <Route path='/:campaignID/*' element={<CampaignDetailHome />} />
    </Routes>
  );
}
