const brands = {
  'Create brand': 'Create brand',
  'Edit brand': 'Edit brand',
  'Brand name': 'Brand name',
  'Website (optional)': 'Website (optional)',

  // select product type modal
  'Add a product': 'Add a product',
  'Physical product': 'Physical product',
  'A product that requires delivery or a full refund':
    'A product that requires delivery or a full refund',
  'Digital product': 'Digital product',
  "An online service that doesn't require delivery":
    "An online service that doesn't require delivery",

  // action button text
  Cancel: 'Cancel',
  'Add brand': 'Add brand',
  'Save changes': 'Save changes',

  // image upload component
  'Upload an image': 'Upload an image',
  'PNG, JPG upto 50MB': 'PNG, JPG upto 50MB',
  'Change image': 'Change image',
};

export default brands;
