const settings = {
  sidebar: {
    'Member since': 'Member since',
    Account: 'Account',
    Wallet: 'Wallet',
    Billing: 'Billing',
    'Payment methods': 'Payment methods',
    Brands: 'Brands',
    Notifications: 'Notifications',
    'Withdrawal method': 'Withdrawal method',
  },
  brand: {
    Brands: 'Brands',
    'Add brand': 'Add brand',
    Brand: 'Brand',
    Campaigns: 'Campaigns',
    'Submitted content': 'Submitted content',
    Products: 'Products',
    Open: 'Open',
    Hidden: 'Hidden',
    Closed: 'Closed',
    Picture: 'Picture',
    Video: 'Video',
    'Edit brand': 'Edit brand',
  },
  Account: {
    Account: 'Account',
  },
  Notification: {
    Notifications: 'Notifications',
    'Mark as read': 'Mark as read',
  },
};

export default settings;
