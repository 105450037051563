import { ReactElement, ReactNode } from 'react';

export default function NavWrapper({
  children,
}: {
  children?: ReactNode;
}): ReactElement {
  return (
    <nav className='w-full h-16 bg-white dark:bg-dark-theme-200 dark:text-white shadow-card z-20 sticky top-0'>
      <div className='container mx-auto h-full max-w-full flex justify-between items-center px-4 lg:px-8 py-4 relative z-10'>
        {children}
      </div>
    </nav>
  );
}
