import { useState } from 'react';
import { NavLink } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import {
  ArrowLongLeftIcon,
  ArrowLongRightIcon,
} from '@heroicons/react/24/outline';

import UnauthedLayout from 'components/layout/UnauthedLayout';

export default function Privacy() {
  


  return (
    <UnauthedLayout>
      <div className='2xl:container md:mx-auto' >
        <iframe width="100%" height="3000px" src="https://docs.google.com/document/d/e/2PACX-1vTwBAMLYK3jUzYyyAvp5vGMyQmwHxCH3up6lKE0lxDgpgJs-jb2XDDsWEuDa7P7-uIhu4fr2fOeXhQC/pub?embedded=true"></iframe>
      </div>
    </UnauthedLayout>
  );
}
