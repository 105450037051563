import { ReactElement } from 'react';
import { Helmet } from 'react-helmet';
import { Trans, useTranslation } from 'react-i18next';

import Tab from 'components/tab';
import AuthedHeading from 'components/typhography/AuthedHeading';
import JobTableCard from './components/Card.JobTable';

import CreatorAuthedContainer from '../_components/Container.AuthedCreator';

import useCreatorJobs from 'hooks/influencer/useCreatorJobs';

export default function MyJobs(): ReactElement {
  const { t } = useTranslation('translation', {
    keyPrefix: 'creator.job',
  });

  const { jobs } = useCreatorJobs();

  window?.scrollTo({ top: 0 });

  return (
    <>
      <Helmet>
        <title>Creator - My jobs</title>
      </Helmet>
      <CreatorAuthedContainer>
        <AuthedHeading>{t('My jobs')}</AuthedHeading>
        {(() => {
          const openJobs = [];
          const invitedJobs = [];
          const startedJobs = [];
          const completedJobs = [];
          const rejectedJobs = [];

          for (let i = 0; i < jobs.length; i++) {
            if (jobs[i].jobStatus === 'open') {
              openJobs.push(jobs[i]);
            } else if (jobs[i].jobStatus === 'invited') {
              invitedJobs.push(jobs[i]);
            } else if (
              [
                'started',
                'product-sent',
                'product-delivered',
                'content-uploaded',
              ].includes(jobs[i].jobStatus)
            ) {
              startedJobs.push(jobs[i]);
            } else if (jobs[i].jobStatus === 'completed') {
              completedJobs.push(jobs[i]);
            } else if (jobs[i].jobStatus === 'rejected') {
              rejectedJobs.push(jobs[i]);
            }
          }

          return (
            <Tab
              tabs={[
                <Trans
                  i18nKey={'creator.job.All'}
                  values={{ count: jobs.length }}
                />,
                <Trans
                  i18nKey={'creator.job.Open'}
                  values={{ count: openJobs.length }}
                />,
                <Trans
                  i18nKey={'creator.job.Invited'}
                  values={{ count: invitedJobs.length }}
                />,
                <Trans
                  i18nKey={'creator.job.Started'}
                  values={{ count: startedJobs.length }}
                />,
                <Trans
                  i18nKey={'creator.job.Completed'}
                  values={{ count: completedJobs.length }}
                />,
                <Trans
                  i18nKey={'creator.job.Rejected'}
                  values={{ count: rejectedJobs.length }}
                />,
              ]}
              views={[
                <JobTableCard jobs={jobs} />,
                <JobTableCard jobs={openJobs} />,
                <JobTableCard jobs={invitedJobs} />,
                <JobTableCard jobs={startedJobs} />,
                <JobTableCard jobs={completedJobs} />,
                <JobTableCard jobs={rejectedJobs} />,
              ]}
              tabBarAddClassName='mt-6 mb-10'
            />
          );
        })()}
      </CreatorAuthedContainer>
    </>
  );
}
