import dashboard from './dashboard';
import campaigns from './campaigns';
import products from './products';
import contents from './contents';
import brands from './brands';
import navbars from './navbars';
import settings from './settings';
import landing from './landing';

const brand = {
  dashboard,
  campaigns,
  products,
  contents,
  brands,
  navbars,
  settings,
  landing, 
};

export default brand;
