import { useState } from 'react';
import { useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import { RootState } from 'redux/store';
import { updateJob, sendInvoice } from 'apis/common/job';
import { useTranslation } from 'react-i18next';

export default function useUpdateJobStatus(job?: IJobData) {
  const { t } = useTranslation('translation', {
    keyPrefix: 'notification',
  });

  const userProfile = useSelector((root: RootState) => root.user.profile);
  const { role } = userProfile;
  const [pending, setPending] = useState<boolean>(false);

  const createInvoice = async () => {
    const invoice = await sendInvoice({
      unit_amount: 995,
      currency: 'cny',
      brandStripeCustomerId: job?.Buyer?.stripeCustomer,
      brandUserId: job?.buyerID,
      campaignName: job?.Campaign?.campaignName,
      brandUserEmail: job?.Buyer?.email,
      brandFirstName: job?.Buyer?.firstName,
      brandLastName: job?.Buyer?.lastName,
      creatorFirstName: job?.Creator?.firstName,
      creatorLastName: job?.Creator?.lastName,
      campaignID: job?.campaignID,
      jobID: job?.id,
      creatorUserId: job?.creatorID,
    });
    return invoice;
  };

  const updateJobStatus = async (
    newStatus: TJobStatus,
    fetJobByJobID: () => void
  ) => {
    if (job === undefined) return;

    const currentStatus = job?.jobStatus;

    // reject all actions when it's waiting for payment or it's rejected
    if (['waiting-payment', 'rejected'].includes(currentStatus)) {
      return;
    }
    setPending(true);
    // For brands
    if (role === 'BRAND') {
      // start the job
      if (newStatus === 'waiting-payment') {
        // they can only start when creators apply
        if (currentStatus !== 'open') return;
        if (
          window.confirm(
            t('Are you gonna really proceed with this creator?') || ''
          )
        ) {
          try {
            const invoiceResponse = await createInvoice();

            if (invoiceResponse?.hosted_invoice_url) {
              await updateJob({
                id: job.id,
                jobStatus: 'waiting-payment',
                jobStartedDate: new Date(),
                invoiceLink: invoiceResponse?.hosted_invoice_url,
              });
              toast.success(
                t(
                  'Payment is requested, after payment is made job will be started.'
                )
              );
              setTimeout(() => {
                window.location.reload();
              }, 1000);
            } else {
              toast.info(t('There was an error while creating invoice.'));
              return;
            }
          } catch (e) {
            toast.error(t('There was an error while starting this job.'));
          }
        }
      }

      // complete the job
      if (newStatus === 'completed') {
        if (currentStatus !== 'content-uploaded') {
          toast.warn(
            t('You can only completed job after they upload contents.')
          );
          return;
        }
        if (['open', 'invited', 'rejected'].includes(currentStatus)) {
          return;
        }

        if (
          window.confirm(t('Are you gonna really complete this job?') || '')
        ) {
          try {
            await updateJob({
              id: job.id,
              jobStatus: 'completed',
              jobEndedDate: new Date(),
            });
            toast.success(t('Successfully completed the job.'));
          } catch (e) {
            toast.error(t('There was an error while completing the job.'));
          }
        }
      }

      // product sent status
      if (newStatus === 'product-sent') {
        // they can only select when the status is started
        if (currentStatus !== 'started') return;
        try {
          await updateJob({ id: job.id, jobStatus: 'product-sent' });
          toast.success(t('Successfully set the job status'));
        } catch (e) {
          toast.error(t('There was an error while changing the job status'));
        }
      }

      // reject the applications
      if (newStatus === 'rejected') {
        if (currentStatus !== 'open') {
          toast.info(t('You can not close the job.'));
          return;
        }
        if (
          window.confirm(
            t('Are you really gonna reject this invitation?') || ''
          )
        ) {
          try {
            await updateJob({ id: job.id, jobStatus: 'rejected' });
            toast.success(t('Successfully rejected the application'));
          } catch (e) {
            toast.error(
              t('There was an error while rejecting the application')
            );
          }
        }
      }
    }

    // For creators
    if (role === 'CREATOR') {
      if (['open', 'completed', 'rejected'].includes(currentStatus)) return;

      // creators are gonna start job by accepting invitations
      if (newStatus === 'waiting-payment') {
        // they can only start when brands invited
        if (currentStatus !== 'invited') {
          toast.info(t('You can only start job when they invite you.'));
          return;
        }
        if (
          window.confirm(
            t('Are you really gonna accept this invitation and start?') || ''
          )
        ) {
          try {
            const invoiceResponse = await createInvoice();
            if (invoiceResponse?.hosted_invoice_url) {
              await updateJob({
                id: job.id,
                jobStatus: 'waiting-payment',
                jobStartedDate: new Date(),
                invoiceLink: invoiceResponse?.hosted_invoice_url,
              });
              toast.success(
                t(
                  'Payment is requested, after payment is made job will be started.'
                )
              );
            } else {
              toast.info(t('There was an error while creating invoice.'));
              return;
            }
          } catch (e) {
            toast.error(t('There was an error while accepting invitation.'));
          }
        }
      }

      // creators are gonna confirm that they got products
      if (newStatus === 'product-delivered') {
        if (currentStatus !== 'product-sent') {
          toast.info(
            t('You can set as delivered after they send product to you.')
          );
          return;
        }

        try {
          await updateJob({ id: job.id, jobStatus: 'product-delivered' });
          toast.success(t('Successfully update job status'));
        } catch (e) {
          toast.error(t('There was an error while updating the status'));
        }
      }

      // reject the applications only when brands invited
      if (newStatus === 'rejected') {
        if (currentStatus !== 'invited') {
          toast.info(t('You can only reject when they invited you'));
          return;
        }
        if (
          window.confirm(
            t('Are you really gonna reject this invitation?') || ''
          )
        ) {
          try {
            await updateJob({ id: job.id, jobStatus: 'rejected' });
            toast.success(t('Successfully rejected the invitation'));
          } catch (e) {
            toast.error(t('There was an error while rejecting the invitation'));
          }
        }
      }
    }
    setPending(false);
    fetJobByJobID();
  };

  return {
    pending,
    userRole: role,
    currentJobStatus: job?.jobStatus,
    updateJobStatus,
  };
}
