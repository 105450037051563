import { API } from 'aws-amplify';
import * as customQueries from 'graphql_custom/custom_queries';
import * as queries from 'graphql/queries';
import * as mutations from 'graphql/mutations';
import { removeDatabaseDates } from 'helpers/object';

export async function getAllAdminJobs() {
  const response: any = await API.graphql({
    query: customQueries.listJobs,
    variables: {
      limit: 1000
    },    
  });

  console.log('all jobs: ', response);
  return response;
}

export async function getAllJobs(
  forUserType: 'creator' | 'brand',
  userID: string
) {
  let response: any = await API.graphql({
    
    query:
      forUserType === 'creator'
        ? customQueries.jobsByCreatorID
        : customQueries.jobsByBuyerID,
    variables:
      forUserType === 'creator' ? { limit: 1000, creatorID: userID } : { limit: 1000, buyerID: userID },
  });

  return response;
}

export async function getJobByID(jobID: string) {
  const response = await API.graphql({
    query: queries.getJob,
    variables: { id: jobID },
  });

  return response;
}

export async function getJobByCampaignID(campaignID: string) {
  console.log('getJobByCampaignID', campaignID);
  console.log('customQueries.jobsByCampaignID', customQueries.jobsByCampaignID);
  const response: any = await API.graphql({
    query: customQueries.jobsByCampaignID,
    variables: {
      campaignID: campaignID,
    },
  });

  return response;
}


export async function updateJob(job: any) {
  const response: any = await API.graphql({
    query: mutations.updateJob,
    variables: {
      input: job,
    },
  });

  return response;
}

export async function sendInvoice(payload: any) {
  const response = await API.post('invoiceApi', '/invoices', {
    body: payload,
  });
  return response;
}
