import { useState } from 'react';
import { useSelector } from 'react-redux';
import { RootState } from 'redux/store';

export default function useFilteredCampaign(
  view?: 'All' | 'Open' | 'Closed' | 'Hidden'
) {
  const [filterBrandIndex, setFilterBrandIndex] = useState<string>('NaN');
  const [query, setQuery] = useState<string>('');

  const campaigns = useSelector((root: RootState) => root.campaign.campaigns)
    .filter((campaign) =>
      view === 'All'
        ? true
        : campaign.campaignStatus.toLowerCase() === view?.toLowerCase()
    )
    .filter((campaign) =>
      filterBrandIndex === 'NaN'
        ? true
        : campaign.brandID.toLowerCase() === filterBrandIndex.toLowerCase()
    )
    .filter((campaign) =>
      !!query
        ? campaign?.campaignName?.toLowerCase().includes(query.toLowerCase())
        : true
    );

  const brands = useSelector((root: RootState) => root.brand.brands);

  function selectFilterBrand(value: IValueWithIndex) {
    setFilterBrandIndex(value.index);
  }

  function queryChangeHandler(value: string) {
    setQuery(value);
  }

  return { campaigns, brands, selectFilterBrand, queryChangeHandler };
}
