import { ReactElement } from 'react';
import { Dialog } from '@headlessui/react';
import { ChevronLeftIcon } from '@heroicons/react/20/solid';
import { useTranslation } from 'react-i18next';

import Button from 'components/button';
import InputBox from 'components/form/InputBox';
import Textarea from 'components/form/Textarea/Textarea';
import ModalCloseButton from 'components/button/Button.ModalClose';

import { CampaignFormContainer } from './Container.CreateCampaign';

import useEditProductData from 'hooks/brand/useEditProductData';
import useProductModal from 'hooks/brand/useProductModal';

import ImageUpload from 'views/components/upload/ImageUpload';
import BrandComboBox from 'views/components/product_brand/Combo.Brand';
import ProductCategoryCombo from 'views/components/product_brand/Combo.ProductCategory';

export default function DigitalProductModal(
  props: IProductModalPanel
): ReactElement {
  const { t } = useTranslation('translation', { keyPrefix: 'brand.products' });

  const {
    disabled,
    imageURL,
    imageFile,
    product,
    handleInputChange,
    handleManualChange,
    handleImageFileChange,
    handleBrandChange,
  } = useEditProductData(props?.data);

  const { pending, progress, isUploading, submitHandler } = useProductModal(
    product,
    props.modalType,
    props.closeModal,
    imageFile,
    props?.hook
  );

  return (
    <Dialog.Panel className='w-[80vw] md:w-[700px] transform overflow-hidden rounded-xl bg-white dark:bg-dark-theme-200 shadow-card transition-all'>
      <Dialog.Title
        as='h3'
        className='text-lg font-medium leading-6 text-gray-900 dark:text-gray-100 mx-5'
      >
        <div className='flex justify-between items-center py-5 border-b-gray-300 dark:border-b-dark-theme-900/60 border-b-[1px]'>
          <div className='flex items-center'>
            <button
              onClick={() => props.openModal && props.openModal('PRODUCT TYPE')}
              className='mr-4'
            >
              <ChevronLeftIcon className='text-black w-6 h-6 dark:text-gray-100' />
            </button>
            {props.modalType === 'CREATE'
              ? t('Add a digital product')
              : t('Edit product')}
          </div>
          <ModalCloseButton onClick={props.closeModal} />
        </div>
      </Dialog.Title>
      <div className='modal-body p-5 pb-0'>
        <form onSubmit={submitHandler} noValidate autoComplete='off'>
          {props.modalType === 'CREATE' && props.data === undefined && (
            <div className='mb-4'>
              <BrandComboBox onBrandChanage={handleBrandChange} />
            </div>
          )}

          <div className='flex flex-col items-center md:flex-row md:gap-4 relative z-50'>
            <ImageUpload
              progress={progress}
              pending={isUploading}
              imageURL={imageURL}
              onImageChange={handleImageFileChange}
            />
            <div className='flex-auto w-full'>
              <CampaignFormContainer
                addClassName='first:md:pt-0'
                labelText={t('Product name (visible to creators)')}
                inputElement={
                  <InputBox
                    value={product.productName}
                    name='productName'
                    onChange={handleInputChange}
                    placeholder='Product name'
                  />
                }
              />
              <CampaignFormContainer
                labelText={t('Product price')}
                inputElement={
                  <InputBox
                    value={product.productPrice}
                    type='number'
                    min={1}
                    name='productPrice'
                    onChange={handleInputChange}
                  />
                }
              />
              <CampaignFormContainer
                labelText={t('Product category')}
                inputElement={
                  <ProductCategoryCombo
                    onCategoryChange={(category: IProductCategoryData) =>
                      handleManualChange('productCategory', category.id)
                    }
                  />
                }
              />
            </div>
          </div>

          <CampaignFormContainer
            labelText={t('External product link')}
            inputElement={
              <InputBox
                value={product.productExternalLink}
                name='productExternalLink'
                onChange={handleInputChange}
                placeholder='https://website.com'
              />
            }
          />

          <CampaignFormContainer
            labelText={t('Please describe your product')}
            inputElement={
              <Textarea
                value={product.productDescription}
                name='productDescription'
                onChange={handleInputChange}
                placeholder='Description'
              />
            }
          />

          <div className='modal-footer sticky border-t-[1px] border-t-gray-300 dark:border-t-dark-theme-900/60 z-10 mt-5 flex pt-5 bottom-0 pb-5'>
            <div className='grid md:grid-cols-2 w-full gap-5'>
              <Button
                disabled={pending}
                type='button'
                onClick={props.closeModal}
                variant='outline'
                addClassName='md:order-first order-last border-dark-theme-900'
              >
                {t('Cancel')}
              </Button>
              <Button disabled={disabled} pending={pending}>
                {props.modalType === 'CREATE'
                  ? t('Create product')
                  : t('Save changes')}
              </Button>
            </div>
          </div>
        </form>
      </div>
    </Dialog.Panel>
  );
}
