import { useState } from 'react';
import { useSelector } from 'react-redux';
import { RootState } from 'redux/store';

export default function useVisibleModal(props: IVisibleModal) {
  const products = useSelector((root: RootState) => root.product.products);
  const [editProductIndex, setEditProductIndex] = useState<string>();

  const [type, setType] = useState<string>(props.types[0]);
  const [visibility, setVisibility] = useState<boolean>(false);
  const [modalType, setModalType] = useState<'CREATE' | 'EDIT'>('CREATE');

  const openModal = (visibleType: string) => {
    if (!props.types.includes(visibleType)) {
      return;
    }

    setVisibility(true);
    setType(visibleType);
  };

  const closeModal = () => {
    setVisibility(false);
  };

  const productToEdit = products.find(
    (product) => product.id === editProductIndex
  );

  return {
    productToEdit,
    setEditProductIndex,
    modalType,
    type,
    visibility,
    setModalType,
    openModal,
    closeModal,
  };
}
