import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import { createBrand, getAllBrands, editBrand } from 'apis/brand/brand';
import { toast } from 'react-toastify';
import { logoutAction } from 'redux/common_slices/authSlice';

export const getAllBrandAction = createAsyncThunk(
  'brand/brands/get/all',
  async (userID: string) => {
    const result = await getAllBrands(userID);
    return result;
  }
);

export const createBrandAction = createAsyncThunk(
  'brand/create',
  async (data: IBrandData) => {
    const result = await createBrand(data);
    return result;
  }
);

export const editBrandAction = createAsyncThunk(
  'brand/edit',
  async (data: IBrandData) => {
    const result = await editBrand(data);
    return result;
  }
);

const initialState: IBrandState = {
  pending: false,
  error: null,
  brands: [],
};

export const brandSlice = createSlice({
  name: 'brand',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getAllBrandAction.pending, (state: IBrandState, action) => {
        if (!state.pending) {
          state.pending = true;
          state.currentRequestId = action.meta.requestId;
        }
      })
      .addCase(getAllBrandAction.fulfilled, (state: IBrandState, action) => {
        const { requestId } = action.meta;
        if (state.pending && state.currentRequestId === requestId) {
          state.pending = false;
          state.brands = action.payload.data;
          state.currentRequestId = undefined;
        }
        return state;
      })
      .addCase(getAllBrandAction.rejected, (state: IBrandState, action) => {
        const { requestId } = action.meta;
        if (state.pending && state.currentRequestId === requestId) {
          state.pending = false;
          state.error = action.error;
          state.currentRequestId = undefined;
        }
      });

    builder
      .addCase(createBrandAction.pending, (state: IBrandState, action) => {
        if (!state.pending) {
          state.pending = true;
          state.currentRequestId = action.meta.requestId;
        }
      })
      // creating brand will return only the created brand
      .addCase(createBrandAction.fulfilled, (state: IBrandState, action) => {
        const { requestId } = action.meta;
        if (state.pending && state.currentRequestId === requestId) {
          toast.success('Successfully created a new brand');
          state.pending = false;
          state.brands = [...state.brands, action.payload.data];
          state.currentRequestId = undefined;
        }
        return state;
      })
      .addCase(createBrandAction.rejected, (state: IBrandState, action) => {
        const { requestId } = action.meta;
        if (state.pending && state.currentRequestId === requestId) {
          toast.success('There wasn an error while creating a new brand.');
          state.pending = false;
          state.error = action.error;
          state.currentRequestId = undefined;
        }
      });

    builder
      .addCase(editBrandAction.pending, (state: IBrandState, action) => {
        if (!state.pending) {
          state.pending = true;
          state.currentRequestId = action.meta.requestId;
        }
      })
      // editing brand will return only the edited brand
      .addCase(editBrandAction.fulfilled, (state: IBrandState, action) => {
        const { requestId } = action.meta;
        if (state.pending && state.currentRequestId === requestId) {
          toast.success('Successfully updated a brand');
          state.pending = false;
          state.brands = [...state.brands].map((brand) =>
            brand.id === action.payload.data.id ? action.payload.data : brand
          );
          state.currentRequestId = undefined;
        }
        return state;
      })
      .addCase(editBrandAction.rejected, (state: IBrandState, action) => {
        const { requestId } = action.meta;
        if (state.pending && state.currentRequestId === requestId) {
          toast.error('There was an error while saving changes.');
          state.pending = false;
          state.error = action.error;
          state.currentRequestId = undefined;
        }
      });

    // set remove redux data when they logout
    builder.addCase(logoutAction.fulfilled, (state: IBrandState) => {
      state = initialState;
      return state;
    });
  },
});

// export const {} = brandSlice.actions;

export default brandSlice.reducer;
