import { ReactElement } from 'react';

export default function Card(props: ICard): ReactElement {
  return (
    <div
      className={`${
        props?.addClassName ?? ''
      } bg-white dark:bg-[rgb(30,30,30)] w-full rounded-lg shadow-card`}
    >
      {props.children}
    </div>
  );
}
