export const removeUnwantedFieldsFromBrand = ({
  id,
  brandName,
  externalLink,
  imageUrl,
  userID,
  ...obj
}: any) => {
  return { id, brandName, externalLink, imageUrl, userID };
};

export const removeDatabaseDates = ({
  createdAt,
  updatedAt,
  deletedAt,
  ...obj
}: any) => {
  return obj;
};
