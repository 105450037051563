import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import Card from 'components/card';
import CardHr from 'components/card/CardHr';
import ComboBox from 'components/form/Combobox/Combobox';
import CardTitle from 'components/card/CardTitle';

import JobTable from 'views/brand/campaigns/components/Table.Job';

import useFetchedJobDataByBuyerID from 'hooks/common/useFetchedJobDataByBuyerID';
import i18n from 'locale/i18n';

const statusDataTemp = [
  {
    index: '0x00',
    value: 'all',
  },
  {
    index: '0x10',
    value: 'started',
  },
  {
    index: '0x20',
    value: 'product-sent',
  },
  {
    index: '0x30',
    value: 'product-delivered',
  },
  {
    index: '0x40',
    value: 'content-uploaded',
  },
  {
    index: '0x45',
    value: 'waiting-payment',
  },
  {
    index: '0x50',
    value: 'completed',
  },
  {
    index: '0x60',
    value: 'rejected',
  },
];

export default function RecentJobs() {
  const { t } = useTranslation('translation', { keyPrefix: 'brand.dashboard' });

  const { t: transJobStatus } = useTranslation('translation', {
    keyPrefix: 'creator.marketplace.jobStatus',
  });

  const statusData: IValueWithIndex[] = statusDataTemp.map((elem) => {
    const newElem = { ...elem, displayValue: transJobStatus(elem.value) };
    return newElem;
  });

  const { jobs, fetching } = useFetchedJobDataByBuyerID();

  const [option, setOption] = useState<IValueWithIndex>(statusData[0]);

  useEffect(() => {
    setOption(statusData[0]);
  }, [i18n.language]);

  const activeJobs = jobs
    .filter((job) => job.jobStatus !== 'invited' && job.jobStatus !== 'open')
    .filter((job) =>
      option.index === '0x00'
        ? true
        : job.jobStatus.toLowerCase() === option.value.toLowerCase()
    );

  return (
    <Card addClassName='p-4 md:p-8 mt-5'>
      <div className='flex justify-between items-center mb-8'>
        <CardTitle noMargin title={t('Current jobs')} />
        <div>
          <ComboBox
            data={statusData}
            setSelectedData={setOption}
            selectedData={option}
            optionCapitalize
            valueCapitalize
          />
        </div>
      </div>
      <CardHr />
      <JobTable jobs={activeJobs} fetching={fetching} />
    </Card>
  );
}
