import { API } from 'aws-amplify';
import * as queries from 'graphql/queries';
import * as customQueries from 'graphql_custom/custom_queries';
import * as mutations from 'graphql/mutations';

// import {
//   doCreateMockProduct,
//   getMockAllProducts,
// } from 'mock_backend/mock_api_endpoints/products';

export async function getAllProducts(userID: string) {
  const result = (await API.graphql({
    query: customQueries.productsByUserID,
    variables: { userID },
  })) as any; //is this rgiht ?
  // const result = await getMockAllProducts(userID);
  return { success: true, data: result.data.productsByUserID.items }; //todo is this how we replace mock items ?
}

export async function getProductByID(productID: string) {
  const response = await API.graphql({
    query: customQueries.getProduct,
    variables: { id: productID },
  });

  return response;
}

export async function createProduct(data: IProductData) {
  // const result = await doCreateMockProduct(data);
  const result = (await API.graphql({
    query: mutations.createProduct,
    variables: { input: data },
  })) as any; //is this rgiht ?
  return { success: true, data: result.data.createProduct };
}

export async function editProduct({ Brand, ...data }: IProductData) {
  const result = (await API.graphql({
    query: mutations.updateProduct,
    variables: { input: data },
  })) as any;

  return { success: true, data: result.data.updateProduct };
}
