import { ReactElement } from 'react';
import { useTranslation } from 'react-i18next';
import { NavLink } from 'react-router-dom';
// import { NavLink } from 'react-router-dom';

import DecorationNavlink from 'views/brand/auth/components/Link.Decoration';
import Icon from '../icon';

export default function Footer(): ReactElement {
  const { t } = useTranslation('translation', { keyPrefix: 'auth' });

  return (
    <footer className='h-auto w-full  bg-white dark:bg-dark-theme-200 dark:border-dark-theme-200'>
      <div className='grid grid-cols-1 grid-rows-12 grid-flow-row-dense md:grid-cols-5 md:grid-rows-2 xl:container xl:mx-auto'>
        <div className='row-span-4 order-1 border-gray-200 dark:border-dark-theme-900/60 md:row-span-1 md:col-span-3 md:order-1 xl:mx-6'></div>
        <div className='row-span-1 md:col-span-3 order-4 md:order-3'>
          <div className='ml-4 md:ml-6 xl:ml-12 mb-6 md:mb-0 md:my-6'>
            <div className='h-6 flex items-center text-gray-400 font-normal text-sm dark:text-gray-100'>
              © {new Date().getFullYear()} Reeffo.com
            </div>
          </div>
        </div>
        <div className='row-span-2 order-2  border-gray-200 dark:border-dark-theme-900/60 md:row-span-1 md:col-span-2 md:order-2 pr-6 xl:pr-0'>
          <div className='flex flex-wrap gap-8 sm:justify-end ml-4 md:ml-0 my-6 text-gray-500 font-medium dark:text-gray-100'>

          </div>
        </div>
        <div className='row-span-2 order-3 md:row-span-1 md:col-span-2'>
          <div className='flex items-center justify-start sm:justify-end my-6 mr-6 md:ml-0 ml-4 space-x-4'>

            <div className='flex items-center ml-4 md:ml-0 mt-0 text-gray-400 dark:text-gray-50'>


            <NavLink
              to='/privacy'
              className='text-sm mr-4'
            >
              {t('Privacy Policy')}
            </NavLink>

            <NavLink
              to='/terms'
              className='text-sm mr-4'
            >
              {t('Terms & Conditions')}
            </NavLink>


              <p className='text-sm mr-4'>English</p>
              <p className='text-sm'>USD</p>
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
}
