const campaign = {
  'My campaigns': '我的招募活动',

  // tabs with count
  All: '全部({{count}})',
  Open: '招募中({{count}})',
  Closed: '已结束({{count}})',
  Hidden: '隐藏({{count}})',

  // campaign table header array
  Campaign: '招募活动',
  'Content type': '内容种类',
  Creators: '内容创作者',
  Status: '进度',
  'Created at': '活动建立时间',

  'In progress': '正在制作中',
  'Waiting payment': '等待付款',
  Completed: '已完成',
  'See details': '请看详细信息',

  'No campaigns': '无招募活动',

  // campaign status
  OPEN: '公开招募中',
  CLOSED: '已结束',
  HIDDEN: '隐藏',

  // campaign content type
  photo: '图片',
  video: '视频',

  // campaign job card
  'Delivery type': '产品提供方式',
  'Brand will ship directly': '品牌直接邮寄产品给内容创作者',
  'Creator will purchase': '内容创作者会直接购买',

  'Product review': '产品评论',

  'Job price': '任务金额',

  // campaign status card
  'Campaign status': '招募活动状态',
  Applicants: '前来应聘的创作者',
  Invited: '邀请的创作者',
  'creator(s)': '创作者(们)',
  'job(s)': '任务',
  'Change campaign status': '修改招募活动状态',

  // campaign detail table tabs
  Jobs: '任务',
  Content: '内容',
  'Campaign details': '招募活动内容细节',
  Delivery: '内容交付',
  'Cost breakdown': '费用明细',

  // creator table
  Reviews: '评论',
  Invite: '邀请',
  'Pro tip': '提示 ',
  'Here goes the pro tip like you can select more creators...':
    '您可以轻松添加选择更多的网红来加入到您的任务中',

  // campaign delivery table
  Creator: '内容创作者',
  Address: '地址',

  // job status enum
  open: '进行中',
  invited: '被邀请',
  started: '已开始',
  'product-sent': '产品已发出',
  'product-delivered': '产品已送达',
  'content-uploaded': '内容已上传',
  completed: '已完成',
  rejected: '被拒绝',

  'Price per': '每单位价格',
  Total: '总数',

  // job detail page
  'Back to campagin': '回到招募活动大厅',

  loading: 'Loading...',
  NoJobMessage:
    'Thanks for creating your campaign. Creators will soon see your listing and apply for your campaign. You can also invite creators from the invite tab',

  // chat
  'Chat with': '开始您的私聊',
  'Go to campaign': '进入招募活动',

  // new campaign
  'Campaign creation': '招募活动创建',
  'Product information': '产品信息',
  'Content setting': '内容设置',
  Summary: '总结',

  'New campaign': '新建招募活动',
  'Add a new brand': '添加新的品牌',
  Brand: '品牌名字',
  Product: '产品',
  'Add a new product': '添加新产品',
  'Campaign name': '招募活动名称',
  'Required content': '所需内容',
  'Edit product': '修改产品',
  'Product link': '产品链接',
  'Product access instructions': '获取产品方式方法',

  'Content format': '内容格式',
  'Content description': '内容详解',
  Characters: '种类性质',
  'Recommendations on what to mention in this description':
    '推荐建议填写内容要求，请用英文填写，可以使用翻译软件来翻译您对内容的要求，然后复制到这里。',
  'Specify what exactly you want to see':
    '说明您想如何让网红宣传您的产品，列举产品的主要特征，功能，有点，不同之处等',
  'face, hands, etc.': '需不需要露脸还是只需露手部在产品近处拍摄即可',
  'Specify the location in which the content should be filmed':
    '列举您推荐的拍摄地点，网红会尽可能找满足条件的地点',
  'outside, in the kitchen, in the house, etc.':
    '地点可以是室内或室外，厨房，客厅，后院，公园等',

  'Content details': '内容细节',
  'Content type and format': '内容的种类与格式',
  Description: '详情',
  'Job Cost': '任务费用',

  'digital product': '电子产品',
  'physical product': '实物产品',

  // Tips
  NewJobMsg:
    'The creator applied for this job, you can start by accepting the application.',

  // navigation & edit
  Edit: '编辑',
  Back: '后退',
  Cancel: '取消',
  Next: '下一步',
  Create: '创建',

  Accept: 'Accept',
  Decline: 'Decline',

  // tip
  'You will NOT be charged full amount upfront.':
    '费用进入平台，任务完成后，费用会转移给内容制作者.',
  'You will be charged for each creator job individually, as soon as creators accept the job.':
    '费用只会在当您雇佣内容创作者后产生，费用是按照每个单独的创作者来收取的',

  // job detail

  'Delivery info': '产品邮寄地址信息',
  'Job info': '任务详情',
  Chat: '发送站内信息',
  'You can see the delivery info after the job has started':
    '当任务开始以后，您将会看到产品邮寄地址的信息',

  // delivery info panel
  'Delivery Country': '国家',
  'Delivery State': '省份',
  'Delivery City': '城市',
  'Delivery AddressLine1': '邮寄地址栏',
  'Delivery Postal Code': '邮编',

  'Send payment': '付款',
  'View Receipt': '收据',
  Invoice: '发票',

  'No messages': '暂无信息',
  'No contents': '尚无内容',

  // product ratio
  Any: '任意',
  Portrait: '纵向',
  Landscape: '横向',
  Square: '方形',
  any: '任意',
  portrait: '纵向',
  landscape: '横向',
  square: '方形',

  // job card texts
  'Job details': '任务信息',

  'All brands': '所有品牌',
  'All products': '所有产品',

  // inside components
  'Select product': '选择产品',
  'Nothing found': '搜索无结果',

  'Search...': '搜索...',
  'View profile': '查看详情',
  'No active jobs': '目前没有任务在进行',
  'Complete the job': '完成任务',
  'Leave reviews to the creator to complete the job.':
    '请为内容创作者留评论来结束这个任务',
  Complete: '完成',
  'Write message...': '写信息...',
};

export default campaign;
