import { ReactElement } from 'react';
import { InformationCircleIcon } from '@heroicons/react/24/solid';
import { useTranslation } from 'react-i18next';

import Tip from 'components/tip';
import Card from 'components/card';
import CardHr from 'components/card/CardHr';

import SearchInput from 'views/brand/_components/Input.SearchBox';
import CreatorTable from './Table.Creator';

import useCreators from 'hooks/brand/useCreators';

export default function CampaignCreatorTableCard({
  campaign,
}: {
  campaign?: ICampaignData;
}): ReactElement {
  const { t } = useTranslation('translation', { keyPrefix: 'brand.campaigns' });

  const { creators, queryChangeHandler } = useCreators();

  return (
    <div>
      <Tip
        closable
        tipType='info'
        description={t(
          'Here goes the pro tip like you can select more creators...'
        )}
        title={t('Pro tip')}
        icon={<InformationCircleIcon className='w-6 h-6 text-blue-500' />}
      />
      <Card addClassName='p-4 md:p-8 mt-4'>
        <div className='flex justify-between'>
          <SearchInput onQueryChange={queryChangeHandler} />
        </div>
        <CardHr />
        <CreatorTable creators={creators} campaign={campaign} />
      </Card>
    </div>
  );
}
