const auth = {
  // sign in
  'Sign in': '登陆',
  'as a Brand': '品牌方登录',
  'as a Creator': '创作者登陆',
  "Don't have an account?": '尚未创建账户?',
  'Sign up here': '创建账户',
  'Forgot password?': '忘记密码?',
  // sign in form
  'Email address': '邮箱',
  Password: '密码',
  '8 characters minimum': '最短8个字符',
  // error messages
  'The email address is required.': '请填写邮箱地址.',
  'The email address is invalid.': '邮箱地址不正确.',
  'The password is required.': '请填写密码.',
  'The minimum number of characters required is 8.': '最短8个字符.',
  'The last name is required': '阁下贵姓，请填写',
  'The first name is required': '阁下名字，请填写',
  'The phone number is required': '请提供您的电话号码',

  // sign up
  'Sign up': '注册',
  'Terms & Conditions': '条款和条件',
  'Privacy Policy': '隐私条款',
  'First Name': '名字',
  'Last Name': '姓氏',
  'Your password must be minimum 8 characters long.': '秘密最短8个字符.',
  Continue: '下一步',

  // forget password
  'Already have a reeffo account?': '您之前已经注册账户?',
  'Reset Password': '重制密码',

  // resend code
  'Wrong email address?': '邮箱地址不正确?',
  'If you mistyped your email address, you can simply go through the register page again. Do you want to continue?':
    '如果您输入了错误的电子邮件地址，您只需再次浏览注册页面即可。您想继续吗?',
  Cancel: '取消',
  'Please check your inbox': '请查看您的收件箱',
  'If you did not receive the confirmation email, please check your spam folder and/or verify that you entered the correct email address above.':
    '如果您没有收到确认电子邮件，请检查您的垃圾邮件文件夹和/或确认您在上面输入了正确的电子邮件地址。',
  'Resend Code': '重新发送代码',
  Submit: '提交',
};

export default auth;
