import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getAllCampaignAction } from 'redux/brand_slices/campaignSlice';
import { AppDispatch, RootState } from 'redux/store';

export default function useAllCampaignsByUserID() {
  const dispatch = useDispatch<AppDispatch>();
  const userID = useSelector((root: RootState) => root.user.profile.id);
  const campaigns = useSelector((root: RootState) => root.campaign.campaigns);
  const pending = useSelector((root: RootState) => root.campaign.pending);

  const fetchAllCampaigns = async () => {
    try {
      await dispatch(getAllCampaignAction(userID));
    } catch (e) {
      fetchAllCampaigns();
    }
  };

  useEffect(() => {
    fetchAllCampaigns();
  }, []);

  return { campaigns, pending };
}
