import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';

import { getJobByID } from 'apis/common/job';

export function useAdminJobDetail() {
  const [jobData, setJobData] = useState<IJobData[]>([]);
  const [fetching, setFetching] = useState<boolean>(false);

  const { jobID } = useParams();

  async function fetchJobDetail() {
    if (!jobID) return;
    try {
      const response: any = await getJobByID(jobID);
      setJobData([response.data.getJob]);
    } catch (e) {
      // fetchJobDetail();
    }
  }

  useEffect(() => {
    console.log('job ID changing...');
    fetchJobDetail();
  }, [jobID]);

  return {
    jobData: jobData[0],
    fetching,
  };
}
