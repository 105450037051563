import { ReactElement } from 'react';
import { Dialog } from '@headlessui/react';
import { useTranslation } from 'react-i18next';

import Icon from 'components/icon';
import ModalCloseButton from 'components/button/Button.ModalClose';

export default function SelectProductTypeModal(
  props: IModalPanelWithOpenModal
): ReactElement {
  const { t } = useTranslation('translation', { keyPrefix: 'brand.brands' });

  return (
    <Dialog.Panel className='w-[260px] sm:w-[400px] transform overflow-hidden rounded-xl bg-white dark:bg-dark-theme-200 shadow-card transition-all'>
      <Dialog.Title
        as='h3'
        className='text-lg font-medium leading-6 text-gray-900 dark:text-gray-100 mx-5'
      >
        <span className='flex justify-between items-center py-5 border-b-gray-300 dark:border-b-dark-theme-900/70 border-b-[1px]'>
          {t('Add a product')}
          <ModalCloseButton onClick={props.closeModal} />
        </span>
      </Dialog.Title>
      <div className='py-5 grid grid-cols-1 sm:grid-cols-2 gap-5 px-10 sm:px-5'>
        {(() => {
          const buttonClassName =
            'group flex flex-col justify-around items-center w-full aspect-square p-4 border-gray-300 rounded-lg border-[2px] border-dashed transition-all duration-200';
          return (
            <>
              <button
                onClick={() =>
                  props.openModal && props.openModal('PHYSICAL PRODUCT')
                }
                className={buttonClassName}
              >
                <span className='p-3 rounded-full bg-theme'>
                  <Icon
                    iconName={'PHYSICAL-PRODUCT'}
                    iconClassName='w-6 h-6 text-white'
                  />
                </span>
                <p className='py-1 text-sm font-medium'>
                  {t('Physical product')}
                </p>
                <span className='text-xs text-gray-400'>
                  {t('A product that requires delivery or a full refund')}
                </span>
              </button>
              <button
                onClick={() =>
                  props.openModal && props.openModal('DIGITAL PRODUCT')
                }
                className={buttonClassName}
              >
                <span className='p-3 rounded-full bg-green-600'>
                  <Icon
                    iconName={'DIGITAL-PRODUCT'}
                    iconClassName='w-6 h-6 text-white'
                  />
                </span>
                <p className='py-1 text-sm font-medium'>
                  {t('Digital product')}
                </p>
                <span className='text-xs text-gray-400'>
                  {t("An online service that doesn't require delivery")}
                </span>
              </button>
            </>
          );
        })()}
      </div>
    </Dialog.Panel>
  );
}
