import { useState } from 'react';
import { useSelector } from 'react-redux';
import { RootState } from 'redux/store';

export default function useBrandModal() {
  const [modal, setModal] = useState<IBrandModal>({
    visibility: false,
    modalType: 'CREATE',
  });
  const brands = useSelector((root: RootState) => root.brand.brands);
  const campaigns = useSelector((root: RootState) => root.campaign.campaigns);
  const products = useSelector((root: RootState) => root.product.products);

  const closeModal = () => {
    setModal({ ...modal, visibility: false });
  };

  const openCreateModal = () => {
    setModal({ modalType: 'CREATE', visibility: true });
  };

  const openEditModal = (brandId: string) => {
    setModal({
      visibility: true,
      modalType: 'EDIT',
      data: brands.find((brand) => brand.id === brandId),
    });
  };

  return {
    brands,
    products,
    campaigns,
    modal,
    closeModal,
    openCreateModal,
    openEditModal,
  };
}
