import Card from "components/card";
import CardHr from "components/card/CardHr";
import CardTitle from "components/card/CardTitle";
import TableBody from "components/table/TableBody";
import TableCell from "components/table/TableCell";
import TableHeader from "components/table/TableHeader";
import TableRow from "components/table/TableRow";
import TableWrapper from "components/table/TableWrapper";
import { ReactElement } from "react";

export default function UserTable({
  users,
  text,
}: {
  users: IUserProfile[];
  text: string;
}): ReactElement {
  return (
    <Card addClassName="p-4 md:p-8 mt-4">
      <CardTitle title={text} />
      <CardHr />
      <div className="overflow-x-auto">
        <TableWrapper>
          <TableHeader headerArray={["No", "Full Name", "Email address", "Role", "Paypal"]} />
          <TableBody>
            {users.map((user, index) => (
              <TableRow key={user.id}>
                <TableCell>{index + 1}</TableCell>
                <TableCell addClassName="capitalize">
                  {user.firstName} {user.lastName}
                </TableCell>
                <TableCell>{user.email}</TableCell>
                <TableCell>{user.role}</TableCell>
                <TableCell>{user.paypalUserName}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </TableWrapper>
      </div>
    </Card>
  );
}
