import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import {
  createCampaignAction,
  initCampaignData,
  setNewCampaign,
  setNewCampaignElement,
} from 'redux/brand_slices/campaignSlice';
import { AppDispatch, RootState } from 'redux/store';

import useNewCampaignNavigation from './useNewCampaignNavigation';

export default function useNewCampaign() {
  const navigate = useNavigate();
  const dispatch = useDispatch<AppDispatch>();
  const pending = useSelector((root: RootState) => root.campaign.pending);

  const campaign = useSelector((root: RootState) => root.campaign.newCampaign);
  const product = useSelector((root: RootState) => root.product.products).find(
    (product) => product.id === campaign.productID
  );
  const brand = useSelector((root: RootState) => root.brand.brands).find(
    (product) => product.id === campaign.brandID
  );

  const { disabled } = useNewCampaignNavigation();

  const [visibility, setVisibility] = useState<boolean>(false);

  const changeHandler = (name: string, value: string | number) => {
    dispatch(setNewCampaignElement({ name, value }));
  };

  const editCurrentProductHandler = () => {
    setVisibility(true);
  };

  const closeEditModal = () => {
    setVisibility(false);
  };

  const createCampaignHandler = async () => {
    const response = await dispatch(createCampaignAction(campaign));
    dispatch(setNewCampaign(initCampaignData));

    navigate(`/brand/campaigns/${response.payload.data.createCampaign.id}`);
  };

  return {
    pending,
    disabled,
    visibility,
    campaign,
    product,
    brand,
    changeHandler,
    createCampaignHandler,
    editCurrentProductHandler,
    closeEditModal,
  };
}
