const auth = {
  // sign in
  'Sign in': 'Sign in',
  'as a Brand': 'as a Brand',
  'as a Creator': 'as a Creator',
  "Don't have an account?": "Don't have an account?",
  'Sign up here': 'Sign up here',
  'Forgot password?': 'Forgot password?',
  // sign in form
  'Email address': 'Email address',
  Password: 'Password',
  '8 characters minimum': '8 characters minimum',
  // error messages
  'The email address is required.': 'The email address is required.',
  'The email address is invalid.': 'The email address is invalid.',
  'The password is required.': 'The password is required.',
  'The minimum number of characters required is 8.':
    'The minimum number of characters required is 8.',
  'The last name is required': 'The last name is required',
  'The first name is required': 'The first name is required',
  'The phone number is required': 'The phone number is required',

  // sign up
  'Sign up': 'Sign up',
  'Terms & Conditions': 'Terms & Conditions',
  'Privacy Policy': 'Privacy Policy',
  'First Name': 'First Name',
  'Last Name': 'Last Name',
  'Your password must be minimum 8 characters long.':
    'Your password must be minimum 8 characters long.',
  Continue: 'Continue',

  // forget password
  'Already have a reeffo account?': 'Already have a reeffo account?',
  'Reset Password': 'Reset Password',

  // resend code
  'Wrong email address?': 'Wrong email address?',
  'If you mistyped your email address, you can simply go through the register page again. Do you want to continue?':
    'If you mistyped your email address, you can simply go through the register page again. Do you want to continue?',
  Cancel: 'Cancel',
  'Please check your inbox': 'Please check your inbox',
  'If you did not receive the confirmation email, please check your spam folder and/or verify that you entered the correct email address above.':
    'If you did not receive the confirmation email, please check your spam folder and/or verify that you entered the correct email address above.',
  'Resend Code': 'Resend Code',
  Submit: 'Submit',
};

export default auth;
