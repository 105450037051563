import { useState } from 'react';
import { Storage } from 'aws-amplify';
import { toast } from 'react-toastify';
import { useTranslation } from 'react-i18next';

export default function useUpload() {
  const { t } = useTranslation('translation', {
    keyPrefix: 'notification',
  });

  const [isUploading, setIsUploading] = useState<boolean>(false);
  const [progress, setProgress] = useState<IUploadProgress>({
    loaded: 0,
    total: 1,
  });

  const uploadToS3Bucket = async ({
    file,
    noFileMessage,
  }: {
    file?: File;
    noFileMessage: string;
  }) => {
    if (file === undefined) {
      toast.warn(noFileMessage);
      return;
    }

    setIsUploading(true);
    let response;
    try {
      const fileName =
        file.name.split('.')[0] + Date.now() + '.' + file.name.split('.')[1];

      response = await Storage.put(fileName, file, {
        level: 'public',
        progressCallback(progress) {
          setProgress({ loaded: progress.loaded, total: progress.total });
        },
      });
    } catch (error) {
      toast.error(t('There was an error uploading image.'));
    }
    setProgress({ loaded: 0, total: 1 });
    setIsUploading(false);

    return response?.key;
  };

  return { isUploading, progress, uploadToS3Bucket };
}
