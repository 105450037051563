import mockWait from 'utils/mock/wait';

import { mock_authed_brand_user } from '../mock_data/user';

export async function doMockSignUp(
  email: string,
  password: string
): Promise<boolean> {
  await mockWait(1500);

  if (email === '' || password === '') {
    return false;
  }
  return true;
}

export async function doMockSignIn(
  email: string,
  password: string
): Promise<boolean | any> {
  await mockWait(1500);

  if (email === '' || password === '') {
    return { success: false, data: null };
  }
  return { success: true, data: mock_authed_brand_user };
}

export async function doMockResetPassword(email: string) {
  await mockWait(1000);

  if (!!email) return false;
  return true;
}
