import { ReactElement } from 'react';

import Card from 'components/card';
import CardHr from 'components/card/CardHr';

import DeliveryTable from './Table.Delivery';

export default function CampaginDeliveryTableCard(): ReactElement {
  return (
    <Card addClassName='p-4 md:p-8'>
      <div className='flex'></div>
      <CardHr />
      <DeliveryTable />
    </Card>
  );
}
