import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { toast } from 'react-toastify';

import {
  doSignIn,
  doSignUp,
  resetPassword,
  logout,
  getSession,
  confirmSignup,
  resendAuthCode,
} from 'apis/brand/auth';
export const SigninAction = createAsyncThunk(
  'signin',
  async (param: {
    credential: ICredentials;
    type: 'BRAND' | 'CREATOR';
    navigate: any;
  }) => {
    const result = await doSignIn(param.credential);
    return {
      result,
      type: param.type,
      navigate: param.navigate,
      email: param.credential.email,
    };
  }
);

export const checkExistingSessionAction = createAsyncThunk(
  'checkExistingSession/brand',
  async () => {
    const result = await getSession();
    return result;
  }
);

export const SignupAction = createAsyncThunk(
  'signup',
  async (param: {
    credential: ICredentials;
    type: 'BRAND' | 'CREATOR';
    navigate: any;
  }) => {
    const result = await doSignUp(param);
    return {
      result,
      type: param.type,
      navigate: param.navigate,
      email: param.credential.email,
    };
  }
);

export const brandResetPasswordAction = createAsyncThunk(
  'forgot-password/brand',
  async (email: string) => {
    const result = await resetPassword(email);
    return result;
  }
);

export const confirmSignupAction = createAsyncThunk(
  'signup/confirm',
  async (param: {
    emailAddress: string;
    authCode: string;
    type: 'BRAND' | 'CREATOR';
    navigate: any;
  }) => {
    const result = await confirmSignup(param);
    return { result: result, type: param.type, navigate: param.navigate };
  }
);

export const resendAuthCodeAction = createAsyncThunk(
  'signup/resend_code',
  async (emailAddress: string) => {
    const result = await resendAuthCode(emailAddress);
    return result;
  }
);

export const logoutAction = createAsyncThunk('logout', async () => {
  const result = await logout();
  return result;
});

const initialState: IAuthState = {
  pending: false,
  error: null,
  auth: false,
};

export const brandAuthSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(SignupAction.pending, (state: IAuthState) => {
        state.pending = true;
        return state;
      })
      .addCase(SignupAction.fulfilled, (state: IAuthState, action) => {
        state.pending = false;
        toast.success('Successfully created your account');
        if (action.payload.type === 'BRAND') {
          action.payload.navigate('/brand/verify-email', {
            state: { email: action.payload.email },
          });
        } else {
          action.payload.navigate('/influencer/verify-email', {
            state: { email: action.payload.email },
          });
        }
        return state;
      })
      .addCase(SignupAction.rejected, (state: IAuthState, action) => {
        state.pending = false;
        toast.error(action.error.message);
        return state;
      });

    builder
      .addCase(SigninAction.pending, (state: IAuthState) => {
        state.pending = true;
        return state;
      })
      .addCase(SigninAction.fulfilled, (state: IAuthState, action) => {
        state.pending = false;
        state.auth = true;
        return state;
      })
      .addCase(SigninAction.rejected, (state: IAuthState, action: any) => {
        state.pending = false;
        toast.error(action.error?.message);
        state.auth = false;
        return state;
      });

    builder
      .addCase(brandResetPasswordAction.pending, (state: IAuthState) => {
        state.pending = true;
        return state;
      })
      .addCase(
        brandResetPasswordAction.fulfilled,
        (state: IAuthState, action) => {
          state.pending = false;
          return state;
        }
      )
      .addCase(brandResetPasswordAction.rejected, (state: IAuthState) => {
        state.pending = false;
        return state;
      });

    builder
      .addCase(logoutAction.pending, (state: IAuthState, action) => {
        if (!state.pending) {
          state.pending = true;
          state.currentRequestId = action.meta.requestId;
        }
        return state;
      })
      .addCase(logoutAction.fulfilled, (state: IAuthState, action) => {
        const { requestId } = action.meta;
        if (state.pending && state.currentRequestId === requestId) {
          state.pending = false;
          state.auth = false;
          state.currentRequestId = undefined;
        }
        return state;
      })
      .addCase(logoutAction.rejected, (state: IAuthState, action) => {
        const { requestId } = action.meta;
        if (state.pending && state.currentRequestId === requestId) {
          state.pending = false;
          state.error = action.error;
          state.currentRequestId = undefined;
        }
        return state;
      });

    builder
      .addCase(checkExistingSessionAction.pending, (state: IAuthState) => {
        state.pending = true;
        state.action = 'CHECK SESSION';
        return state;
      })
      .addCase(checkExistingSessionAction.fulfilled, (state: IAuthState) => {
        state.pending = false;
        state.auth = true;
        state.action = undefined;
        return state;
      })
      .addCase(checkExistingSessionAction.rejected, (state: IAuthState) => {
        state.pending = false;
        state.auth = false;
        state.action = undefined;
        return state;
      });

    builder
      .addCase(confirmSignupAction.pending, (state: IAuthState) => {
        state.pending = true;
        return state;
      })
      .addCase(confirmSignupAction.fulfilled, (state: IAuthState, action) => {
        state.pending = false;
        toast.success('Successfully confirmed your account.');
        if (action.payload.type === 'BRAND') {
          action.payload.navigate('/brand');
        } else {
          action.payload.navigate('/influencer');
        }
        return state;
      })
      .addCase(confirmSignupAction.rejected, (state: IAuthState, action) => {
        state.pending = false;
        toast.error('There was an error while confirming your account.');
        return state;
      });

    builder
      .addCase(resendAuthCodeAction.pending, (state: IAuthState) => {
        state.pending = true;
        return state;
      })
      .addCase(resendAuthCodeAction.fulfilled, (state: IAuthState, action) => {
        state.pending = false;
        toast.success('Another code sent to your email address.');
        return state;
      })
      .addCase(resendAuthCodeAction.rejected, (state: IAuthState, action) => {
        state.pending = false;
        toast.error('There was an error while resending code.');
        return state;
      });
  },
});

// export const {} = brandAuthSlice.actions;

export default brandAuthSlice.reducer;
