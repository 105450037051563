import { ReactElement } from 'react';
import { useTranslation } from 'react-i18next';

import Card from 'components/card/Card';
import CardHr from 'components/card/CardHr';
import { SkeletonBox } from 'components/skeleton';

import FileDropaone from 'views/components/FileDropzone/FileDropzone';
import AmplifyMedia from 'views/components/media/StorageImageRenderer';

import useJobContent from 'hooks/common/useFetchedJobContent';

export default function CreatorJobContentCard({
  job,
  hook,
}: {
  job?: IJobData;
  hook: (val: number) => void;
}): ReactElement {
  const { t } = useTranslation('translation', {
    keyPrefix: 'creator.marketplace',
  });

  const { fetching, jobContents, uploadContentHandler } = useJobContent(hook);

  return (
    <Card addClassName='p-4 md:p-8'>
      {['open', 'invited', 'waiting-payment', 'product-sent'].includes(
        job?.jobStatus ?? ''
      ) ? (
        t('You can upload contents after job has started')
      ) : (
        <>
          <CardHr />
          <div>
            <div className='min-h-[210px] max-h-[400px] overflow-y-scroll pr-4 relative'>
              {fetching === false && jobContents.length === 0 && (
                <div className='w-full h-[210px] flex justify-center items-center'>
                  {t('No contents')}
                </div>
              )}
              <div className='grid grid-cols-5 gap-5'>
                {fetching ? (
                  <>
                    {new Array(5).fill(true).map((_content, index) => (
                      <SkeletonBox
                        key={'skeleton' + index}
                        className='aspect-square'
                        darkMode
                      />
                    ))}
                  </>
                ) : (
                  <>
                    {jobContents.map((content) => (
                      <AmplifyMedia
                        className='w-full aspect-square'
                        mediaType={content.contentType}
                        key={content.id}
                        sourceKey={content.contentUrl}
                      />
                    ))}
                  </>
                )}
              </div>
            </div>

            <div className='mt-8 w-full bottom-5'>
              <FileDropaone
                onFileUpload={(imageURLs: string[], files: File[]) =>
                  uploadContentHandler(imageURLs, files, job)
                }
                fileType={['video', 'image']}
                pending={fetching}
              />
            </div>
          </div>
        </>
      )}
    </Card>
  );
}
