import { ReactElement } from 'react';
import { useSelector } from 'react-redux';
import { NavLink } from 'react-router-dom';
import { RootState } from 'redux/store';

export default function Logo(): ReactElement {
  const userType = useSelector((root: RootState) => root.user.profile.role);
  const auth = useSelector((root: RootState) => root.authentication.auth);
  return (
    <NavLink
      to={
        !auth
          ? '/'
          : userType === 'BRAND'
          ? '/brand'
          : userType === 'ADMIN'
          ? '/admin'
          : '/influencer'
      }
      className='font-pacifico text-[28px] leading-[42px] bg-clip-text text-transparent bg-gradient-to-r from-landing-theme-4 to-landing-theme-3'
    >
      Reeffo
    </NavLink>
  );
}
