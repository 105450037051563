import { ReactElement, useState } from 'react';

import Card from 'components/card';
import CardHr from 'components/card/CardHr';
import SearchInput from 'views/brand/_components/Input.SearchBox';

import JobTable from './Table.Job';

export default function JobTableCard(props: {
  jobs: IJobData[];
}): ReactElement {
  const [query, setQuery] = useState<string>('');
  const filteredJobs: IJobData[] = props.jobs?.filter(
    (job) =>
      job.Product?.productName.toLowerCase().includes(query.toLowerCase()) ||
      job.Campaign?.campaignName.toLowerCase().includes(query.toLowerCase()) ||
      job.Brand?.brandName.toLowerCase().includes(query.toLowerCase())
  );

  return (
    <Card addClassName='p-4 md:p-8'>
      <div className='flex justify-between'>
        <SearchInput onQueryChange={setQuery} />
      </div>

      <CardHr />
      <div className='overflow-x-auto'>
        <JobTable jobs={filteredJobs} />
      </div>
    </Card>
  );
}
