import { ReactElement } from 'react';
import { Navigate, Route, Routes } from 'react-router-dom';

import SettingAsideBar from './components/SettingAsideBar';
import SettingBrandsCRUD from './pages/SettingBrandsCRUD';
import BrandAccountSetting from './pages/BrandAccountSetting';

export default function BrandSettings(): ReactElement {
  window?.scrollTo({ top: 0 });

  const routes = [
    { path: '/', element: <Navigate to='/account' replace /> },
    { path: '/account', element: <BrandAccountSetting /> },
    // { path: '/wallet', element: <BrandAccountSetting /> },
    // { path: '/billing', element: <BrandAccountSetting /> },
    // { path: '/payment-methods', element: <BrandAccountSetting /> },
    { path: '/brands', element: <SettingBrandsCRUD /> },
    { path: '/withdrawal-method', element: <BrandAccountSetting /> },
  ];

  return (
    <main className='xl:container flex mx-auto'>
      <SettingAsideBar />
      <div className='w-full xl:w-[1016px] 2xl:w-[1272px] relative px-3 md:px-4 xl:px-0'>
        <div className='pt-6 pb-10 sm:p-6 xl:min-h-[calc(100vh-212px)]'>
          <Routes>
            {routes.map((route) => (
              <Route key={route.path} {...route} />
            ))}
          </Routes>
        </div>
      </div>
    </main>
  );
}
