import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';

import { getProductByID } from 'apis/brand/product';

export default function useProductDetail() {
  const { productID } = useParams();
  const [error, setError] = useState<boolean>(false);
  const [fetching, setFetching] = useState<boolean>(false);
  const [product, setProduct] = useState<IProductData>();

  const [visibility, setVisibility] = useState<boolean>(false);

  const closeEditModal = () => {
    setVisibility(!visibility);
  };

  useEffect(() => {
    if (productID == undefined) return;
    (async () => {
      try {
        setFetching(true);
        const response: any = await getProductByID(productID);
        setProduct(response.data.getProduct);
        setFetching(false);
        setError(false);
      } catch (e) {
        setError(true);
      }
    })();
    return () => {};
  }, [visibility]);

  const jobContents: IJobContent[] = product?.Jobs?.items
    ? product?.Jobs.items
        .map((jobItem) => jobItem.JobContents?.items ?? [])
        .flat(2)
    : [];

  return {
    fetching,
    error,
    product,
    jobContents,
    visibility,
    setVisibility,
    closeEditModal,
  };
}
