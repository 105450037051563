import { useEffect, useRef } from 'react';
import { NavLink } from 'react-router-dom';
import { ChatBubbleLeftRightIcon } from '@heroicons/react/24/outline';
import Card from 'components/card';
import CardHr from 'components/card/CardHr';
import ChatHistoryItem from 'views/components/chat/Chat.HistoryItem';
import CreatorAuthedContainer from 'views/influencer/_components/Container.AuthedCreator';

import { useAdminJobDetail } from 'hooks/admin/useAdminJobDetail';
import useChatData from 'hooks/common/useChatData';

export default function JobDetailView() {
  const panelRef = useRef<HTMLDivElement>(null);
  const { fetching, jobData } = useAdminJobDetail();
  const {
    fetching: msgFetching,
    isNew,
    messages,
    setIsNew,
  } = useChatData('BRAND');

  useEffect(() => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
    return () => {};
  }, []);

  useEffect(() => {
    const height = panelRef.current?.scrollHeight;

    if (height && isNew) {
      panelRef.current.scrollTop = height;
      setIsNew(false);
    }
  }, [messages]);

  return (
    <CreatorAuthedContainer>
      <NavLink to='/admin' className='mt-10 underline block text-error'>
        Back
      </NavLink>

      <p className='pt-6'>
        <u>Job</u>
      </p>

      <p className='pt-2'>
        Brand: <b>{jobData?.Buyer?.firstName} {jobData?.Buyer?.lastName}: {jobData?.Buyer?.email}</b>
      </p>
      <p className='pt-2'>
        Creator: <b>{jobData?.Creator?.firstName} {jobData?.Creator?.lastName}:  {jobData?.Creator?.email}</b>
      </p>


      <p className='pt-6'>
        <u>Campaign</u>
      </p>
      <p className='pt-2'>
        Campaign: <b>{jobData?.Campaign?.campaignName}</b>
      </p>
      <p>
        Status: <b className='capitalize'>{jobData?.jobStatus}</b>
      </p>

      <p className='pt-10'>
        <u>Brand</u>
      </p>
      <p className='pt-2'>Brand: {jobData?.Brand?.brandName}</p>
      <p className='pt-2'>
        External link:{' '}
        <a
          className='underline'
          target='_blank'
          rel='noopener noreferrer'
          href={jobData?.Brand?.externalLink || '#'}
        >
          {jobData?.Brand?.externalLink}
        </a>
      </p>

      <Card addClassName='mt-8 p-4 md:p-8'>
        Message history
        <CardHr />
        <div
          ref={panelRef}
          className='mt-8 h-[500px] overflow-auto pr-5 relative'
        >
          {messages.length === 0 && (
            <div className='absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 flex flex-col items-center gap-y-4'>
              {msgFetching ? (
                'Loading...'
              ) : (
                <>
                  <ChatBubbleLeftRightIcon className='w-12 h-12' />
                  No Messages
                </>
              )}
            </div>
          )}
          {jobData && messages.map((message, index) => (
            <ChatHistoryItem
              key={'chat-history' + index}
              align={message.fromUserID === jobData?.buyerID ? 'left' : 'right'}
              avatar={
                (message.fromUserID === jobData.buyerID
                  ? jobData.Buyer?.firstName[0]
                  : jobData.Creator?.firstName[0]) || ''
              }
              message={message}
            />
          ))}
        </div>
      </Card>
    </CreatorAuthedContainer>
  );
}
