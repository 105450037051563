import { ReactElement } from 'react';
import { Helmet } from 'react-helmet';
import { Trans, useTranslation } from 'react-i18next';

import Tab from 'components/tab';
import AuthedHeading from 'components/typhography/AuthedHeading';

import BrandAuthedContainer from '../_components/Container.AuthedBrand';
import ContentTableCard from './components/ContentTableCard';

import useFetchedJobContents from 'hooks/brand/useFetchedJobContents';

export default function BrandContent(): ReactElement {
  const { t } = useTranslation('translation', { keyPrefix: 'brand.contents' });

  const { fetching, contents, fetchJobContents } = useFetchedJobContents();

  window?.scrollTo({ top: 0 });

  return (
    <>
      <Helmet>
        <title>Brands - Products</title>
      </Helmet>
      <BrandAuthedContainer>
        <AuthedHeading>{t('Available content')}</AuthedHeading>
        <Tab
          tabs={[
            <Trans
              i18nKey='brand.contents.MyContent'
              values={{ count: contents.length }}
            />,
          ]}
          views={[
            <ContentTableCard
              fetching={fetching}
              contents={contents}
              fetchJobContents={fetchJobContents}
            />,
          ]}
          tabBarAddClassName='mt-4 mb-6'
        />
      </BrandAuthedContainer>
    </>
  );
}
