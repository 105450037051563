import { getJobContentsByBuyerID } from 'apis/common/jobContent';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { RootState } from 'redux/store';

export default function useFetchedJobContents() {
  const userID = useSelector((root: RootState) => root.user.profile.id);
  const [fetching, setFetching] = useState<boolean>(false);
  const [error, setError] = useState<boolean>(false);
  const [contents, setContents] = useState<IJobContent[]>([]);

  const fetchJobContents = async () => {
    setFetching(true);

    try {
      const response = await getJobContentsByBuyerID(userID);
      setContents(response.data.jobContentsByBuyerID.items);
      setError(false);
    } catch (e) {
      setError(true);
    }

    setFetching(false);
  };

  useEffect(() => {
    fetchJobContents();

    return () => {};
  }, []);

  return { fetching, error, contents, fetchJobContents };
}
