export const helperAddIndexArrayElem = (
  list: string[],
  lowercase?: boolean
) => {
  return list.map((elem, index) => {
    return {
      index: index,
      value: lowercase ? elem?.toLocaleLowerCase() : elem,
    };
  });
};

function getTimeSpan(date?: Date) {
  if (date) {
    return new Date(date).getTime();
  }

  return 0;
}

export const helperSortMessagesByDate = (messages: IMessage[]) => {
  return messages.sort(
    (a, b) => getTimeSpan(a.createdAt) - getTimeSpan(b.createdAt)
  );
};
