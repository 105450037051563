import { ReactElement, MouseEvent, Fragment } from 'react';
import { Menu, Transition } from '@headlessui/react';
import { NavLink } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';

import {
  CreditCardIcon,
  UserCircleIcon,
  ArrowLeftOnRectangleIcon,
  GiftIcon,
  StarIcon,
} from '@heroicons/react/24/outline';

import { AppDispatch } from 'redux/store';
import { logoutAction } from 'redux/common_slices/authSlice';

const optionIconClassName = 'min-w-[20px] w-5 h-5';
export const mainOptions: { text: string; link?: string; icon: any }[] = [
  {
    text: 'Account',
    link: 'account',
    icon: <UserCircleIcon className={optionIconClassName} />,
  },
  {
    text: 'Portfolio',
    link: 'portfolio',
    icon: <GiftIcon className={optionIconClassName} />,
  },
  {
    text: 'Reviews',
    link: 'reviews',
    icon: <StarIcon className={optionIconClassName} />,
  },
  {
    text: 'Payment methods',
    link: 'payment-methods',
    icon: <CreditCardIcon className={optionIconClassName} />,
  },
];

export default function AccountSettings(props: IAccountSetting): ReactElement {
  const { t } = useTranslation('translation', { keyPrefix: 'creator.navbar' });

  const dispatch = useDispatch<AppDispatch>();

  const logOutHandler = () => {
    dispatch(logoutAction());
  };

  return (
    <Menu as='div' className='hidden relative lg:inline-block text-left'>
      <Menu.Button
        onClick={(e: MouseEvent<HTMLButtonElement>) => e.stopPropagation()}
        className={`${
          props.addClassName ?? ''
        } p-2 rounded-full transition-all duration-150`}
      >
        {props.buttonElem}
      </Menu.Button>
      <Transition
        as={Fragment}
        enter='transition ease-out duration-100'
        enterFrom='transform opacity-0 scale-95'
        enterTo='transform opacity-100 scale-100'
        leave='transition ease-in duration-75'
        leaveFrom='transform opacity-100 scale-100'
        leaveTo='transform opacity-0 scale-95'
      >
        <Menu.Items className='absolute right-0 mt-2 w-56 origin-top-right divide-y divide-gray-100 rounded-md bg-white dark:bg-dark-theme-200 shadow-card '>
          <div key={'main-option-group'} className='px-1 py-1'>
            {mainOptions.map((option, index) => {
              return (
                <Menu.Item key={option.link ?? '' + index}>
                  {({ active }) =>
                    option.link ? (
                      <NavLink
                        to={'settings/' + option.link}
                        className={`${
                          active ? 'bg-gray-100 dark:bg-dark-theme-900' : ''
                        } group active:bg-gray-200 flex gap-3 w-full truncate items-center rounded-md px-3 h-10 text-sm transition-all duration-150`}
                      >
                        {option.icon}
                        {t(option.text)}
                      </NavLink>
                    ) : (
                      <button
                        className={`${
                          active ? 'bg-gray-100' : ''
                        } group active:bg-gray-200 flex gap-3 w-full truncate items-center rounded-md px-3 h-10 text-sm transition-all duration-150`}
                      >
                        {option.icon}
                        {t(option.text)}
                      </button>
                    )
                  }
                </Menu.Item>
              );
            })}

            <Menu.Item>
              {({ active }) => (
                <button
                  onClick={logOutHandler}
                  className={`${
                    active ? 'bg-gray-100 dark:bg-dark-theme-900' : ''
                  } group active:bg-gray-200 flex gap-3 w-full truncate items-center rounded-md px-3 h-10 text-sm transition-all duration-150`}
                >
                  <ArrowLeftOnRectangleIcon className='w-5 h-5' />
                  {t('Log out')}
                </button>
              )}
            </Menu.Item>
          </div>
        </Menu.Items>
      </Transition>
    </Menu>
  );
}
