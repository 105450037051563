import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { getJobByID } from 'apis/common/job';

export default function useFetchedJobData(refresh?: number) {
  const { campaignID, jobID } = useParams();
  const [fetching, setFetching] = useState<boolean>(false);
  const [job, setJob] = useState<IJobData | undefined>();

  const fetchJobByJobID = async () => {
    if (jobID === undefined) return;
    try {
      setFetching(true);
      const response: any = await getJobByID(jobID);
      setJob(response.data.getJob);
      setFetching(false);
    } catch (e) {
      fetchJobByJobID();
    }
  };

  useEffect(() => {
    fetchJobByJobID();

    return () => {};
  }, [campaignID, refresh]);

  const isJobStarted =
    job === undefined
      ? false
      : !['open', 'rejected', 'completed', 'invited'].includes(job?.jobStatus);

  return {
    fetching,
    campaignID,
    job,
    isJobStarted,
    fetchJobByJobID,
  };
}
