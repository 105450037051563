const products = {
  'My Products': '我的产品',
  'Active campaigns': '在进行中的招募活动',

  // product modal
  'Add a physical product': '添加实物产品',
  'Edit product': '修改产品',
  'Product name (visible to creators)': '产品的名称（应聘内容制作者可见）',
  'Product price': '产品价格',
  'Product category': '产品门类',
  'External product link': '产品链接',
  'Please describe your product': '产品简介和产品送达方式',
  'Add a digital product': '添加电子非实物产品',

  // Action
  Cancel: '取消',
  'Create product': '创建产品',
  'Save changes': '保存修改',

  Product: '产品',
  Brand: '品牌',
  'My content': '我的内容',
  'Add new product': '添加新产品',

  'No products': '无产品',
  'See details': '查看详情',
  'Remove product': '删除产品',
  'Open campaigns': '在进行中的招募活动',
  'Hidden campaigns': '隐藏的招募活动',
  'Closed campaigns': '已关闭的招募活动',
  'Create campaign': '创建招募活动',
  'Product information': '产品信息',
  'Product link': '产品链接',
  'Product access instructions': '获取产品方法解释',

  'digital product': '电子非实物产品',
  'physical product': '事物产品',
};

export default products;
