// import { getMockAllCampaigns } from 'mock_backend/mock_api_endpoints/campaigns';
import { API } from 'aws-amplify';
import * as queries from 'graphql/queries';
import * as mutations from 'graphql/mutations';
import * as customQueries from 'graphql_custom/custom_queries';

export async function getAllCampaigns(userID: string) {
  const result = (await API.graphql({
    query: customQueries.campaignsByUserID,
    variables: { userID, limit: 1000 },
  })) as any; //todo is this right to do any?
  //const result = await getMockAllBrands(userId);
  return { success: true, data: result.data.campaignsByUserID.items }; //todo is this how we replace mock items ?
}

export async function createCampaign(data: ICampaignData) {
  const result = (await API.graphql({
    query: mutations.createCampaign,
    variables: { input: data },
  })) as any;

  return result;
}

export async function getCampaignByID(campaignID: string) {
  const response: any = await API.graphql({
    query: queries.getCampaign,
    variables: { id: campaignID },
  });

  return response;
}

export async function removeCampaign({ id, ...data }: ICampaignData) {
  const result = (await API.graphql({
    query: mutations.deleteCampaign,
    variables: { input: { id } },
  })) as any;

  return result;
}

export async function getAllCreators(query: string) {
  const result = (await API.graphql({
    query: customQueries.listUsers,
    variables:
      query === ''
        ? {
          
            filter: {
              role: { eq: 'CREATOR' },
            },
            limit: 1000
          }
        : {
            limit: 1000,
            filter: {
              role: { eq: 'CREATOR' },
              or: [
                {
                  firstName: {
                    contains: query,
                  },
                },
                {
                  lastName: {
                    contains: query,
                  },
                },
              ],
            },
          },
  })) as any;

  return result;
}

export async function inviteCreator({ id, ...data }: IJobData) {
  const result = (await API.graphql({
    query: mutations.createJob,
    variables: { input: data },
  })) as any;

  return result;
}

export async function updateCampaignStatus(
  id: string,
  status: 'OPEN' | 'HIDDEN' | 'CLOSED'
) {
  const response: any = await API.graphql({
    query: mutations.updateCampaign,
    variables: {
      input: {
        id: id,
        campaignStatus: status,
      },
    },
  });

  return response;
}
