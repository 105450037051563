/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const getUserReview = /* GraphQL */ `
  query GetUserReview($id: ID!) {
    getUserReview(id: $id) {
      id
      FromUserID
      review
      rating
      userID
      User {
        id
        email
        firstName
        lastName
        isActive
        approvedDate
        role
        phone
        deliveryAddressLine1
        deliveryAddressLine2
        deliveryCity
        deliveryState
        deliveryCountry
        deliveryPostalCode
        youtube
        instagram
        tiktok
        facebook
        date_of_birth
        gender
        phone_number
        paypalUserName
        stripeCustomer
        profile_picture
        createdAt
        updatedAt
      }
      jobID
      Job {
        id
        jobStatus
        jobStartedDate
        jobEndedDate
        jobPrice
        jobApplicationFee
        invoiceLink
        deliveryTrackingInfo
        deliveryName
        deliveryAddressLine1
        deliveryAddressLine2
        deliveryCity
        deliveryState
        deliveryCountry
        deliveryPostalCode
        campaignID
        creatorID
        buyerID
        brandID
        productID
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
    }
  }
`;
export const listUserReviews = /* GraphQL */ `
  query ListUserReviews(
    $filter: ModelUserReviewFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listUserReviews(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        FromUserID
        review
        rating
        userID
        jobID
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const userReviewsByUserID = /* GraphQL */ `
  query UserReviewsByUserID(
    $userID: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelUserReviewFilterInput
    $limit: Int
    $nextToken: String
  ) {
    userReviewsByUserID(
      userID: $userID
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        FromUserID
        review
        rating
        userID
        jobID
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const userReviewsByJobID = /* GraphQL */ `
  query UserReviewsByJobID(
    $jobID: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelUserReviewFilterInput
    $limit: Int
    $nextToken: String
  ) {
    userReviewsByJobID(
      jobID: $jobID
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        FromUserID
        review
        rating
        userID
        jobID
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getNotifications = /* GraphQL */ `
  query GetNotifications($id: ID!) {
    getNotifications(id: $id) {
      id
      notification_type
      message
      link
      isRead
      userID
      User {
        id
        email
        firstName
        lastName
        isActive
        approvedDate
        role
        phone
        deliveryAddressLine1
        deliveryAddressLine2
        deliveryCity
        deliveryState
        deliveryCountry
        deliveryPostalCode
        youtube
        instagram
        tiktok
        facebook
        date_of_birth
        gender
        phone_number
        paypalUserName
        stripeCustomer
        profile_picture
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
    }
  }
`;
export const listNotifications = /* GraphQL */ `
  query ListNotifications(
    $filter: ModelNotificationsFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listNotifications(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        notification_type
        message
        link
        isRead
        userID
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const notificationsByUserID = /* GraphQL */ `
  query NotificationsByUserID(
    $userID: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelNotificationsFilterInput
    $limit: Int
    $nextToken: String
  ) {
    notificationsByUserID(
      userID: $userID
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        notification_type
        message
        link
        isRead
        userID
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getMessage = /* GraphQL */ `
  query GetMessage($id: ID!) {
    getMessage(id: $id) {
      id
      fromUserID
      toUserID
      message
      jobID
      Job {
        id
        jobStatus
        jobStartedDate
        jobEndedDate
        jobPrice
        jobApplicationFee
        invoiceLink
        deliveryTrackingInfo
        deliveryName
        deliveryAddressLine1
        deliveryAddressLine2
        deliveryCity
        deliveryState
        deliveryCountry
        deliveryPostalCode
        campaignID
        creatorID
        buyerID
        brandID
        productID
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
    }
  }
`;
export const listMessages = /* GraphQL */ `
  query ListMessages(
    $filter: ModelMessageFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listMessages(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        fromUserID
        toUserID
        message
        jobID
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const messagesByJobID = /* GraphQL */ `
  query MessagesByJobID(
    $jobID: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelMessageFilterInput
    $limit: Int
    $nextToken: String
  ) {
    messagesByJobID(
      jobID: $jobID
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        fromUserID
        toUserID
        message
        jobID
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getJobContent = /* GraphQL */ `
  query GetJobContent($id: ID!) {
    getJobContent(id: $id) {
      id
      contentType
      contentUrl
      jobID
      Job {
        id
        jobStatus
        jobStartedDate
        jobEndedDate
        jobPrice
        jobApplicationFee
        invoiceLink
        deliveryTrackingInfo
        deliveryName
        deliveryAddressLine1
        deliveryAddressLine2
        deliveryCity
        deliveryState
        deliveryCountry
        deliveryPostalCode
        campaignID
        creatorID
        buyerID
        brandID
        productID
        createdAt
        updatedAt
      }
      campaignID
      picked
      creatorID
      buyerID
      Creator {
        id
        email
        firstName
        lastName
        isActive
        approvedDate
        role
        phone
        deliveryAddressLine1
        deliveryAddressLine2
        deliveryCity
        deliveryState
        deliveryCountry
        deliveryPostalCode
        youtube
        instagram
        tiktok
        facebook
        date_of_birth
        gender
        phone_number
        paypalUserName
        stripeCustomer
        profile_picture
        createdAt
        updatedAt
      }
      Buyer {
        id
        email
        firstName
        lastName
        isActive
        approvedDate
        role
        phone
        deliveryAddressLine1
        deliveryAddressLine2
        deliveryCity
        deliveryState
        deliveryCountry
        deliveryPostalCode
        youtube
        instagram
        tiktok
        facebook
        date_of_birth
        gender
        phone_number
        paypalUserName
        stripeCustomer
        profile_picture
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
    }
  }
`;
export const listJobContents = /* GraphQL */ `
  query ListJobContents(
    $filter: ModelJobContentFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listJobContents(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        contentType
        contentUrl
        jobID
        campaignID
        picked
        creatorID
        buyerID
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const jobContentsByJobID = /* GraphQL */ `
  query JobContentsByJobID(
    $jobID: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelJobContentFilterInput
    $limit: Int
    $nextToken: String
  ) {
    jobContentsByJobID(
      jobID: $jobID
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        contentType
        contentUrl
        jobID
        campaignID
        picked
        creatorID
        buyerID
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const jobContentsByCreatorID = /* GraphQL */ `
  query JobContentsByCreatorID(
    $creatorID: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelJobContentFilterInput
    $limit: Int
    $nextToken: String
  ) {
    jobContentsByCreatorID(
      creatorID: $creatorID
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        contentType
        contentUrl
        jobID
        campaignID
        picked
        creatorID
        buyerID
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const jobContentsByBuyerID = /* GraphQL */ `
  query JobContentsByBuyerID(
    $buyerID: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelJobContentFilterInput
    $limit: Int
    $nextToken: String
  ) {
    jobContentsByBuyerID(
      buyerID: $buyerID
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        contentType
        contentUrl
        jobID
        campaignID
        picked
        creatorID
        buyerID
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getJob = /* GraphQL */ `
  query GetJob($id: ID!) {
    getJob(id: $id) {
      id
      jobStatus
      jobStartedDate
      jobEndedDate
      jobPrice
      jobApplicationFee
      invoiceLink
      deliveryTrackingInfo
      deliveryName
      deliveryAddressLine1
      deliveryAddressLine2
      deliveryCity
      deliveryState
      deliveryCountry
      deliveryPostalCode
      campaignID
      JobContents {
        nextToken
      }
      Campaign {
        id
        campaignName
        campaignStatus
        approvedDate
        campaignPrice
        description
        contentType
        contentFormat
        deliveryType
        userID
        brandID
        productID
        createdAt
        updatedAt
      }
      creatorID
      buyerID
      brandID
      Brand {
        id
        brandName
        externalLink
        imageUrl
        userID
        createdAt
        updatedAt
      }
      Creator {
        id
        email
        firstName
        lastName
        isActive
        approvedDate
        role
        phone
        deliveryAddressLine1
        deliveryAddressLine2
        deliveryCity
        deliveryState
        deliveryCountry
        deliveryPostalCode
        youtube
        instagram
        tiktok
        facebook
        date_of_birth
        gender
        phone_number
        paypalUserName
        stripeCustomer
        profile_picture
        createdAt
        updatedAt
      }
      Buyer {
        id
        email
        firstName
        lastName
        isActive
        approvedDate
        role
        phone
        deliveryAddressLine1
        deliveryAddressLine2
        deliveryCity
        deliveryState
        deliveryCountry
        deliveryPostalCode
        youtube
        instagram
        tiktok
        facebook
        date_of_birth
        gender
        phone_number
        paypalUserName
        stripeCustomer
        profile_picture
        createdAt
        updatedAt
      }
      Messages {
        nextToken
      }
      UserReviews {
        nextToken
      }
      productID
      Product {
        id
        productType
        productPrice
        productCategory
        productExternalLink
        productImage
        productDescription
        brandID
        userID
        productName
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
    }
  }
`;
export const listJobs = /* GraphQL */ `
  query ListJobs(
    $filter: ModelJobFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listJobs(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        jobStatus
        jobStartedDate
        jobEndedDate
        jobPrice
        jobApplicationFee
        invoiceLink
        deliveryTrackingInfo
        deliveryName
        deliveryAddressLine1
        deliveryAddressLine2
        deliveryCity
        deliveryState
        deliveryCountry
        deliveryPostalCode
        campaignID
        creatorID
        buyerID
        brandID
        productID
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const jobsByCampaignID = /* GraphQL */ `
  query JobsByCampaignID(
    $campaignID: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelJobFilterInput
    $limit: Int
    $nextToken: String
  ) {
    jobsByCampaignID(
      campaignID: $campaignID
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        jobStatus
        jobStartedDate
        jobEndedDate
        jobPrice
        jobApplicationFee
        invoiceLink
        deliveryTrackingInfo
        deliveryName
        deliveryAddressLine1
        deliveryAddressLine2
        deliveryCity
        deliveryState
        deliveryCountry
        deliveryPostalCode
        campaignID
        creatorID
        buyerID
        brandID
        productID
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const jobsByCreatorID = /* GraphQL */ `
  query JobsByCreatorID(
    $creatorID: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelJobFilterInput
    $limit: Int
    $nextToken: String
  ) {
    jobsByCreatorID(
      creatorID: $creatorID
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        jobStatus
        jobStartedDate
        jobEndedDate
        jobPrice
        jobApplicationFee
        invoiceLink
        deliveryTrackingInfo
        deliveryName
        deliveryAddressLine1
        deliveryAddressLine2
        deliveryCity
        deliveryState
        deliveryCountry
        deliveryPostalCode
        campaignID
        creatorID
        buyerID
        brandID
        productID
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const jobsByBuyerID = /* GraphQL */ `
  query JobsByBuyerID(
    $buyerID: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelJobFilterInput
    $limit: Int
    $nextToken: String
  ) {
    jobsByBuyerID(
      buyerID: $buyerID
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        jobStatus
        jobStartedDate
        jobEndedDate
        jobPrice
        jobApplicationFee
        invoiceLink
        deliveryTrackingInfo
        deliveryName
        deliveryAddressLine1
        deliveryAddressLine2
        deliveryCity
        deliveryState
        deliveryCountry
        deliveryPostalCode
        campaignID
        creatorID
        buyerID
        brandID
        productID
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const jobsByBrandID = /* GraphQL */ `
  query JobsByBrandID(
    $brandID: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelJobFilterInput
    $limit: Int
    $nextToken: String
  ) {
    jobsByBrandID(
      brandID: $brandID
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        jobStatus
        jobStartedDate
        jobEndedDate
        jobPrice
        jobApplicationFee
        invoiceLink
        deliveryTrackingInfo
        deliveryName
        deliveryAddressLine1
        deliveryAddressLine2
        deliveryCity
        deliveryState
        deliveryCountry
        deliveryPostalCode
        campaignID
        creatorID
        buyerID
        brandID
        productID
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const jobsByProductID = /* GraphQL */ `
  query JobsByProductID(
    $productID: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelJobFilterInput
    $limit: Int
    $nextToken: String
  ) {
    jobsByProductID(
      productID: $productID
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        jobStatus
        jobStartedDate
        jobEndedDate
        jobPrice
        jobApplicationFee
        invoiceLink
        deliveryTrackingInfo
        deliveryName
        deliveryAddressLine1
        deliveryAddressLine2
        deliveryCity
        deliveryState
        deliveryCountry
        deliveryPostalCode
        campaignID
        creatorID
        buyerID
        brandID
        productID
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getCampaign = /* GraphQL */ `
  query GetCampaign($id: ID!) {
    getCampaign(id: $id) {
      id
      campaignName
      campaignStatus
      approvedDate
      campaignPrice
      description
      contentType
      contentFormat
      deliveryType
      userID
      Jobs {
        nextToken
      }
      User {
        id
        email
        firstName
        lastName
        isActive
        approvedDate
        role
        phone
        deliveryAddressLine1
        deliveryAddressLine2
        deliveryCity
        deliveryState
        deliveryCountry
        deliveryPostalCode
        youtube
        instagram
        tiktok
        facebook
        date_of_birth
        gender
        phone_number
        paypalUserName
        stripeCustomer
        profile_picture
        createdAt
        updatedAt
      }
      brandID
      productID
      Product {
        id
        productType
        productPrice
        productCategory
        productExternalLink
        productImage
        productDescription
        brandID
        userID
        productName
        createdAt
        updatedAt
      }
      Brand {
        id
        brandName
        externalLink
        imageUrl
        userID
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
    }
  }
`;
export const listCampaigns = /* GraphQL */ `
  query ListCampaigns(
    $filter: ModelCampaignFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listCampaigns(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        campaignName
        campaignStatus
        approvedDate
        campaignPrice
        description
        contentType
        contentFormat
        deliveryType
        userID
        brandID
        productID
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const campaignsByUserID = /* GraphQL */ `
  query CampaignsByUserID(
    $userID: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelCampaignFilterInput
    $limit: Int
    $nextToken: String
  ) {
    campaignsByUserID(
      userID: $userID
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        campaignName
        campaignStatus
        approvedDate
        campaignPrice
        description
        contentType
        contentFormat
        deliveryType
        userID
        brandID
        productID
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const campaignsByBrandID = /* GraphQL */ `
  query CampaignsByBrandID(
    $brandID: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelCampaignFilterInput
    $limit: Int
    $nextToken: String
  ) {
    campaignsByBrandID(
      brandID: $brandID
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        campaignName
        campaignStatus
        approvedDate
        campaignPrice
        description
        contentType
        contentFormat
        deliveryType
        userID
        brandID
        productID
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const campaignsByProductID = /* GraphQL */ `
  query CampaignsByProductID(
    $productID: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelCampaignFilterInput
    $limit: Int
    $nextToken: String
  ) {
    campaignsByProductID(
      productID: $productID
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        campaignName
        campaignStatus
        approvedDate
        campaignPrice
        description
        contentType
        contentFormat
        deliveryType
        userID
        brandID
        productID
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getProduct = /* GraphQL */ `
  query GetProduct($id: ID!) {
    getProduct(id: $id) {
      id
      productType
      productPrice
      productCategory
      productExternalLink
      productImage
      productDescription
      brandID
      Brand {
        id
        brandName
        externalLink
        imageUrl
        userID
        createdAt
        updatedAt
      }
      userID
      productName
      Campaigns {
        nextToken
      }
      Jobs {
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const listProducts = /* GraphQL */ `
  query ListProducts(
    $filter: ModelProductFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listProducts(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        productType
        productPrice
        productCategory
        productExternalLink
        productImage
        productDescription
        brandID
        userID
        productName
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const productsByBrandID = /* GraphQL */ `
  query ProductsByBrandID(
    $brandID: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelProductFilterInput
    $limit: Int
    $nextToken: String
  ) {
    productsByBrandID(
      brandID: $brandID
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        productType
        productPrice
        productCategory
        productExternalLink
        productImage
        productDescription
        brandID
        userID
        productName
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const productsByUserID = /* GraphQL */ `
  query ProductsByUserID(
    $userID: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelProductFilterInput
    $limit: Int
    $nextToken: String
  ) {
    productsByUserID(
      userID: $userID
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        productType
        productPrice
        productCategory
        productExternalLink
        productImage
        productDescription
        brandID
        userID
        productName
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getBrand = /* GraphQL */ `
  query GetBrand($id: ID!) {
    getBrand(id: $id) {
      id
      brandName
      externalLink
      imageUrl
      userID
      Products {
        nextToken
      }
      User {
        id
        email
        firstName
        lastName
        isActive
        approvedDate
        role
        phone
        deliveryAddressLine1
        deliveryAddressLine2
        deliveryCity
        deliveryState
        deliveryCountry
        deliveryPostalCode
        youtube
        instagram
        tiktok
        facebook
        date_of_birth
        gender
        phone_number
        paypalUserName
        stripeCustomer
        profile_picture
        createdAt
        updatedAt
      }
      Campaigns {
        nextToken
      }
      Jobs {
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const listBrands = /* GraphQL */ `
  query ListBrands(
    $filter: ModelBrandFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listBrands(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        brandName
        externalLink
        imageUrl
        userID
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const brandsByUserID = /* GraphQL */ `
  query BrandsByUserID(
    $userID: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelBrandFilterInput
    $limit: Int
    $nextToken: String
  ) {
    brandsByUserID(
      userID: $userID
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        brandName
        externalLink
        imageUrl
        userID
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getPortfolioContent = /* GraphQL */ `
  query GetPortfolioContent($id: ID!) {
    getPortfolioContent(id: $id) {
      id
      userID
      User {
        id
        email
        firstName
        lastName
        isActive
        approvedDate
        role
        phone
        deliveryAddressLine1
        deliveryAddressLine2
        deliveryCity
        deliveryState
        deliveryCountry
        deliveryPostalCode
        youtube
        instagram
        tiktok
        facebook
        date_of_birth
        gender
        phone_number
        paypalUserName
        stripeCustomer
        profile_picture
        createdAt
        updatedAt
      }
      contentType
      contentUrl
      createdAt
      updatedAt
    }
  }
`;
export const listPortfolioContents = /* GraphQL */ `
  query ListPortfolioContents(
    $filter: ModelPortfolioContentFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listPortfolioContents(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        userID
        contentType
        contentUrl
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const portfolioContentsByUserID = /* GraphQL */ `
  query PortfolioContentsByUserID(
    $userID: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelPortfolioContentFilterInput
    $limit: Int
    $nextToken: String
  ) {
    portfolioContentsByUserID(
      userID: $userID
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        userID
        contentType
        contentUrl
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getUser = /* GraphQL */ `
  query GetUser($id: ID!) {
    getUser(id: $id) {
      id
      email
      firstName
      lastName
      isActive
      approvedDate
      role
      phone
      deliveryAddressLine1
      deliveryAddressLine2
      deliveryCity
      deliveryState
      deliveryCountry
      deliveryPostalCode
      youtube
      instagram
      tiktok
      facebook
      date_of_birth
      gender
      phone_number
      paypalUserName
      stripeCustomer
      UserReviews {
        nextToken
      }
      Notifications {
        nextToken
      }
      Campaigns {
        nextToken
      }
      Brands {
        nextToken
      }
      Products {
        nextToken
      }
      CreatorJobs {
        nextToken
      }
      BuyerJobs {
        nextToken
      }
      PortfolioContents {
        nextToken
      }
      profile_picture
      CreatorJobContents {
        nextToken
      }
      BuyerJobContents {
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const listUsers = /* GraphQL */ `
  query ListUsers(
    $filter: ModelUserFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listUsers(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        email
        firstName
        lastName
        isActive
        approvedDate
        role
        phone
        deliveryAddressLine1
        deliveryAddressLine2
        deliveryCity
        deliveryState
        deliveryCountry
        deliveryPostalCode
        youtube
        instagram
        tiktok
        facebook
        date_of_birth
        gender
        phone_number
        paypalUserName
        stripeCustomer
        profile_picture
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getInvoice = /* GraphQL */ `
  query GetInvoice($id: ID!) {
    getInvoice(id: $id) {
      id
      date
      stripeInvoiceID
      amount_due
      amount_paid
      amount_remaining
      total
      customer
      status
      buyerID
      creatorID
      campaignID
      jobID
      createdAt
      updatedAt
    }
  }
`;
export const listInvoices = /* GraphQL */ `
  query ListInvoices(
    $filter: ModelInvoiceFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listInvoices(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        date
        stripeInvoiceID
        amount_due
        amount_paid
        amount_remaining
        total
        customer
        status
        buyerID
        creatorID
        campaignID
        jobID
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const invoicesByBuyerID = /* GraphQL */ `
  query InvoicesByBuyerID(
    $buyerID: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelInvoiceFilterInput
    $limit: Int
    $nextToken: String
  ) {
    invoicesByBuyerID(
      buyerID: $buyerID
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        date
        stripeInvoiceID
        amount_due
        amount_paid
        amount_remaining
        total
        customer
        status
        buyerID
        creatorID
        campaignID
        jobID
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const invoicesByCreatorID = /* GraphQL */ `
  query InvoicesByCreatorID(
    $creatorID: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelInvoiceFilterInput
    $limit: Int
    $nextToken: String
  ) {
    invoicesByCreatorID(
      creatorID: $creatorID
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        date
        stripeInvoiceID
        amount_due
        amount_paid
        amount_remaining
        total
        customer
        status
        buyerID
        creatorID
        campaignID
        jobID
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const invoicesByCampaignID = /* GraphQL */ `
  query InvoicesByCampaignID(
    $campaignID: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelInvoiceFilterInput
    $limit: Int
    $nextToken: String
  ) {
    invoicesByCampaignID(
      campaignID: $campaignID
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        date
        stripeInvoiceID
        amount_due
        amount_paid
        amount_remaining
        total
        customer
        status
        buyerID
        creatorID
        campaignID
        jobID
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const invoicesByJobID = /* GraphQL */ `
  query InvoicesByJobID(
    $jobID: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelInvoiceFilterInput
    $limit: Int
    $nextToken: String
  ) {
    invoicesByJobID(
      jobID: $jobID
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        date
        stripeInvoiceID
        amount_due
        amount_paid
        amount_remaining
        total
        customer
        status
        buyerID
        creatorID
        campaignID
        jobID
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
