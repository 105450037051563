import { ReactElement } from 'react';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { EyeIcon, PhotoIcon, VideoCameraIcon } from '@heroicons/react/24/solid';

import Button from 'components/button';
import TableRow from 'components/table/TableRow';
import TableBody from 'components/table/TableBody';
import TableCell from 'components/table/TableCell';
import TableHeader from 'components/table/TableHeader';
import TableWrapper from 'components/table/TableWrapper';

import AmplifyImage from 'views/components/media/StorageImageRenderer';

export default function CampaignTable(props: {
  tableInfo?: string;
  campaigns: ICampaignDataResponse[];
  brands: IBrandData[];
}): ReactElement {
  const { t } = useTranslation('translation', { keyPrefix: 'brand.campaigns' });

  const navigate = useNavigate();

  const navigateToDetail = (index: string) => {
    navigate('/brand/campaigns/' + index);
  };

  const headerArray = [
    t('Campaign'),
    t('Content type'),
    t('Creators'),
    t('Status'),
    t('Created at'),
    '',
  ];

  return (
    <TableWrapper addClassName='min-w-[900px]'>
      <TableHeader headerArray={headerArray} />
      <TableBody>
        {props.campaigns.length === 0 && (
          <TableRow>
            <td colSpan={6} className='h-[200px] text-center'>
              {t('No campaigns')}
            </td>
          </TableRow>
        )}
        {props.campaigns.length > 0 &&
          props.campaigns.map((campaign, _rowIndex: number) => {
            return (
              <TableRow key={campaign.id}>
                <TableCell>
                  <div className='flex gap-4'>
                    <div className='w-16 h-16 rounded-lg bg-dark-black flex justify-center items-center capitalize text-2xl font-semibold text-white'>
                      <AmplifyImage
                        className='rounded-lg w-full h-full object-cover'
                        sourceKey={campaign.Product?.productImage}
                        width={16}
                        height={16}
                      />
                    </div>
                    <div className='flex flex-col'>
                      <span>{campaign.campaignName}</span>
                      <span className='text-gray-500 dark:text-gray-400'>
                        {campaign.Product?.productName}
                      </span>
                      <span className='capitalize'>
                        {t(campaign.campaignStatus)}
                      </span>
                    </div>
                  </div>
                </TableCell>
                <TableCell>
                  <div className='flex items-center gap-1 capitalize'>
                    {t(campaign.contentType)}
                    {campaign.contentType === 'photo' ? (
                      <PhotoIcon className='w-6 h-6' />
                    ) : (
                      <VideoCameraIcon className='w-6 h-6' />
                    )}
                  </div>
                </TableCell>
                <TableCell>
                  <div>
                    <p>
                      <span className='text-gray-500 dark:text-gray-300'>
                        {t('In progress')}:
                      </span>{' '}
                      0
                    </p>
                    <p>
                      <span className='text-gray-500 dark:text-gray-300'>
                        {t('Completed')}:
                      </span>{' '}
                      0
                    </p>
                  </div>
                </TableCell>
                <TableCell>
                  <div>
                    <p>
                      <span className='text-gray-500 dark:text-gray-300'>
                        {t(campaign.campaignStatus)}
                      </span>
                    </p>
                  </div>
                </TableCell>
                <TableCell>
                  {new Date(campaign?.createdAt).toLocaleDateString()}
                </TableCell>
                <TableCell addClassName='w-[220px]'>
                  <Button
                    variant='outline'
                    className='px-4 h-10 flex items-center gap-2'
                    icon={<EyeIcon className='w-5 h-5' />}
                    onClick={() => navigateToDetail(campaign.id)}
                  >
                    {t('See details')}
                  </Button>
                </TableCell>
              </TableRow>
            );
          })}
      </TableBody>
    </TableWrapper>
  );
}
