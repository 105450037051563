import { PhotoIcon } from '@heroicons/react/24/outline';
import { ReactElement } from 'react';
import { useTranslation } from 'react-i18next';

import JobContentCard from 'views/brand/content/components/Card.JobContent';

export default function ContentTable(props: {
  contents: IJobContent[];
}): ReactElement {
  const { t } = useTranslation('translation', { keyPrefix: 'brand.campaigns' });

  return (
    <div className='grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-4 2xl:gap-8 mt-6'>
      {props.contents.length === 0 && (
        <div className='col-span-1 sm:col-span-2 md:col-span-3 lg:col-span-4 flex flex-col justify-center items-center h-[300px]'>
          <PhotoIcon className='w-12 h-12' />
          {t('No contents')}
        </div>
      )}
      {props.contents.length > 0 &&
        props.contents.map((content) => <JobContentCard {...content} />)}
    </div>
  );
}
