import { useState } from 'react';
import { NavLink } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import {
  ArrowLongLeftIcon,
  ArrowLongRightIcon,
} from '@heroicons/react/24/outline';

import UnauthedLayout from 'components/layout/UnauthedLayout';

export default function CreatorLanding() {
  const { t } = useTranslation('translation', { keyPrefix: 'creator.landing' });

  const [index, setIndex] = useState<number>(0);

  const steps = ['1. Pick A Job', '2. Create Content', '3. Submit and Earn'];

  const panels = [
    <>
      <h4 className='font-semibold text-3xl text-black'>
        {t('Pick content type')}
      </h4>
      <p className='mt-3 text-xl text-black'>
        {t(
          'Browse our library of jobs from cross-industrial brands, from health suppleements to household items. Select one (or many!) brands that piqued your interest.'
        )}
      </p>
    </>,
    <>
      <h4 className='font-semibold text-3xl text-black'>
        {t('Create Content')}
      </h4>
      <p className='mt-3 text-xl text-black'>
        {t(
          "Have fun taking video and photos of the product. Don't hold back, let your creativity soar and show off your eloquent charm!"
        )}
      </p>
    </>,
    <>
      <h4 className='font-semibold text-3xl text-black'>
        {t('Submmit. Ka-ching!')}
      </h4>
      <p className='mt-3 text-xl text-black'>
        {t('Upload your content and a payment will be deposited instantly.')}
      </p>
    </>,
  ];

  return (
    <UnauthedLayout>
      <div className='container mx-auto pt-14 md:pt-20 2xl:pt-10 text-center capitalize px-5 md:px-0 2xl:px-28'>
        <div className='flex flex-col gap-y-10 md:gap-y-20 xl:flex-row items-center justify-between min-h-[80vh]'>
          <div className='md:w-[650px]'>
            <h2 className='mt-4 text-3xl md:text-4xl lg:text-5xl leading-[2.5rem] md:leading-[3rem] lg:leading-[3.5rem] font-semibold text-left text-black'>
              {t('Get Paid for Doing What You Love.')}
            </h2>
            <p className='pt-8 text-2xl pr-6 text-left text-black'>
              {t(
                'Pick a brand you love. Receive a free product. And get paid for doing one short video clip and shooting three photos.'
              )}
            </p>
            <NavLink
              to='/influencer/signup'
              className='mt-12 w-fit bg-gradient-to-r from-landing-theme-4 to-landing-theme-5 text-white block py-3 px-6 rounded-lg text-xl font-bold'
            >
              {t("Let's get started!")}
            </NavLink>
          </div>
          <img
            src='/assets/images/landing/creator-photo1.png'
            alt='brand-illu-1'
            draggable={false}
          />
        </div>
        <h2 className='mt-10 md:mt-20 text-3xl md:text-4xl lg:text-5xl leading-[2.5rem] md:leading-[3rem] lg:leading-[3.5rem] font-semibold text-center text-black'>
          {t('How it works')}
        </h2>
        <div className='mt-6 px-10 flex flex-wrap justify-center items-center gap-x-10 gap-y-5 text-lg lg:text-xl'>
          {steps.map((step: string, stepIndex: number) => {
            if (stepIndex === index) {
              return (
                <button className='py-2 px-3 rounded-full bg-gradient-to-r from-landing-theme-4 via-landing-theme-4 to-landing-theme-5 font-semibold text-white'>
                  {t(step)}
                </button>
              );
            }
            return (
              <button
                onClick={() => setIndex(stepIndex)}
                className='text-black'
              >
                {t(step)}
              </button>
            );
          })}
        </div>
        <div className='flex items-center justify-center pb-20'>
          <div>
            <div className='mt-10 md:w-[550px] md:h-[340px] rounded-2xl bg-landing-theme-2 text-left p-8 pb-24 md:pb-8 normal-case relative'>
              {panels[index]}
              <div className='absolute bottom-8 left-8 flex gap-4'>
                <button
                  onClick={() => setIndex(Math.max(0, index - 1))}
                  className='rounded-full bg-white p-2 text-black'
                >
                  <ArrowLongLeftIcon className='w-5 h-5 stroke-[2px]' />
                </button>
                <button
                  onClick={() => setIndex(Math.min(2, index + 1))}
                  className='rounded-full bg-white p-2 text-black'
                >
                  <ArrowLongRightIcon className='w-5 h-5 stroke-[2px]' />
                </button>
              </div>
            </div>
            <div className='flex flex-col md:flex-row gap-y-2 items-center gap-6 px-8 mt-2'>
              {t('Ready to get started?')}
              <NavLink
                to='/influencer/signup#'
                className='p-3 rounded-xl bg-gradient-to-r from-landing-theme-4 via-landing-theme-4 to-landing-theme-5 text-white font-medium'
              >
                {t('Sign Me Up Now!')}
              </NavLink>
            </div>
          </div>
        </div>
      </div>
    </UnauthedLayout>
  );
}
