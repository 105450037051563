import { ReactElement } from 'react';
import { NavLink } from 'react-router-dom';
import { Bars3Icon, BellAlertIcon } from '@heroicons/react/24/outline';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch, RootState } from 'redux/store';
import {
  setMobileSidebarVisibility,
  setNotificationSidebarVisibility,
} from 'redux/common_slices/userSlice';

import DarkModeButton from 'components/button/DarkModeButton';
import LocaleButton from 'components/button/LocaleButton';
import Logo from 'components/logo';

import NavWrapper from 'views/components/navbar/NavWrapper';
import AmplifyMedia from 'views/components/media/StorageImageRenderer';

import UnauthedMobileMenu from 'components/layout/UnauthedMobileMenu';
import { useTranslation } from 'react-i18next';
import AdminNavMenu from './AdminNavMenu';

import AccountSetting from './AccountSettings';

export default function AdminNav({
  auth,
  path,
  userProfilePicture,
}: IBrandNav): ReactElement {
  const dispatch = useDispatch<AppDispatch>();
  const notifications = useSelector((root: RootState) =>
    root.user.notifications.filter((noti) => !noti.isRead)
  );

  const { t } = useTranslation('translation', {
    keyPrefix: 'brand.navbars',
  });

  const authPath =
    ['signup', 'signin'].includes(
      path.split('/')[path.split('/').length - 1]
    ) && !auth;

  return (
    <NavWrapper>
      {auth ? (
        <AdminNavMenu>
          <Logo />
        </AdminNavMenu>
      ) : (
        <Logo />
      )}

      <div className='flex items-center gap-6'>
        <DarkModeButton />
        <LocaleButton />

        {auth && (
          <>
            <div className='relative'>
              <BellAlertIcon
                onClick={() => {
                  dispatch(setNotificationSidebarVisibility(true));
                  // blockScroll();
                }}
                className='w-5 h-5 cursor-pointer'
              />
              {notifications.length > 0 && (
                <div className='w-2 h-2 absolute right-0 top-0 rounded-full bg-error'></div>
              )}
            </div>

            {/* Account for desktop for authed user */}
            <AccountSetting
              buttonElem={
                <AmplifyMedia
                  className='w-8 h-8 !rounded-full'
                  height={64}
                  width={64}
                  isUserAvatar
                  sourceKey={userProfilePicture}
                />
              }
            />

            {/* Hamburger for mobile for authed user */}
            <div
              onClick={() => {
                dispatch(setMobileSidebarVisibility(true));
              }}
              className='lg:hidden p-2 cursor-pointer'
            >
              <Bars3Icon className='h-5 w-5' />
            </div>
          </>
        )}

        {authPath && (
          <>
            <NavLink
              to='/influencer/signup'
              className='text-theme font-semibold text-sm max-sm:hidden'
            >
              {t('Register as an Influencer')}
            </NavLink>
            <span className='text-gray-500 text-sm max-sm:hidden'>
              {t('Already have an account?')}
            </span>
            <NavLink to='/brand/signin' className='font-semibold max-sm:hidden'>
              {t('Sign In')}
            </NavLink>

            {/* Mobile hamburger for unauthed user*/}
            <UnauthedMobileMenu />
          </>
        )}
      </div>
    </NavWrapper>
  );
}
