import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { RootState } from 'redux/store';

export default function useProductCategoryCombo(
  onCategoryChange: (data: IProductCategoryData) => void
) {
  const categories: IProductCategoryData[] = useSelector(
    (root: RootState) => root.protoType.productCategories
  );

  const categoryObjects: IValueWithIndex[] = categories.map((category) => {
    return {
      index: category.id,
      value: category.name,
      displayValue: category.name,
    };
  });

  const [selectedCategory, setSelectedCategory] = useState<IValueWithIndex>(
    categoryObjects[0]
  );

  useEffect(() => {
    const data = categories.find(
      (category) => category.id === selectedCategory.index
    );
    data && onCategoryChange(data);
  }, [selectedCategory]);

  return { categoryObjects, selectedCategory, setSelectedCategory };
}
