import React from 'react';
import { Amplify, API } from 'aws-amplify';

import ReactDOM from 'react-dom/client';
import App from './App';

import { ToastContainer } from 'react-toastify';

import { Provider } from 'react-redux';
import { store } from 'redux/store';

// locales for multi-language
import './locale/i18n';

import './styles/loader.scss';
import './index.scss';
import 'react-toastify/dist/ReactToastify.css';

import config from './aws-exports';

import reportWebVitals from './reportWebVitals';

Amplify.configure(config);
API.configure(config);

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);
root.render(
  <React.StrictMode>
    <Provider store={store}>
      <App />
      <ToastContainer />
    </Provider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
