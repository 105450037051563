/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "us-east-1",
    "aws_appsync_graphqlEndpoint": "https://n3z7dh2utfdb5jkxsxjg5gmqqm.appsync-api.us-east-1.amazonaws.com/graphql",
    "aws_appsync_region": "us-east-1",
    "aws_appsync_authenticationType": "AMAZON_COGNITO_USER_POOLS",
    "aws_appsync_apiKey": "da2-ycakhi2rujflvj63jnyai2tewq",
    "aws_cloud_logic_custom": [
        {
            "name": "invoiceApi",
            "endpoint": "https://uvsiqd4w3a.execute-api.us-east-1.amazonaws.com/production",
            "region": "us-east-1"
        },
        {
            "name": "stripeApi",
            "endpoint": "https://qf5edyg758.execute-api.us-east-1.amazonaws.com/production",
            "region": "us-east-1"
        }
    ],
    "aws_cognito_identity_pool_id": "us-east-1:8b56bc8e-e247-4046-9d78-b4467a11f836",
    "aws_cognito_region": "us-east-1",
    "aws_user_pools_id": "us-east-1_YiRskfDWM",
    "aws_user_pools_web_client_id": "4mmg5j1v14ctb3afccq76jkmkj",
    "oauth": {},
    "aws_cognito_username_attributes": [],
    "aws_cognito_social_providers": [],
    "aws_cognito_signup_attributes": [
        "EMAIL",
        "GIVEN_NAME",
        "FAMILY_NAME",
        "PROFILE"
    ],
    "aws_cognito_mfa_configuration": "OFF",
    "aws_cognito_mfa_types": [
        "SMS"
    ],
    "aws_cognito_password_protection_settings": {
        "passwordPolicyMinLength": 8,
        "passwordPolicyCharacters": [
            "REQUIRES_LOWERCASE",
            "REQUIRES_NUMBERS",
            "REQUIRES_UPPERCASE"
        ]
    },
    "aws_cognito_verification_mechanisms": [
        "EMAIL"
    ],
    "aws_user_files_s3_bucket": "influencer-platform-storage-7183c05001635-production",
    "aws_user_files_s3_bucket_region": "us-east-1"
};


export default awsmobile;
