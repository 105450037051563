import { useTranslation } from 'react-i18next';

import Card from 'components/card';
import Rating from 'components/rating/Rating';
import AmplifyMedia from './components/media/StorageImageRenderer';
import ReviewCard from './influencer/settings/components/Card.Review';
import CreatorAuthedContainer from './influencer/_components/Container.AuthedCreator';

import useCreatorProfile from 'hooks/common/useCreatorProfile';

export default function CreatorPublicProfile() {
  const { fetching, portfolios, reviews, creator, completedJobCount } =
    useCreatorProfile();

  const { t } = useTranslation('translation', {
    keyPrefix: 'creator.publicProfile',
  });

  if (fetching) {
    return <></>;
  }

  return (
    <CreatorAuthedContainer>
      <div className='flex justify-between pt-10'>
        <div className='flex items-center gap-4'>
          <AmplifyMedia
            isUserAvatar
            className='w-20 h-20 rounded-full min-w-[80px] overflow-hidden'
            sourceKey={creator?.profile_picture}
          />
          <div>
            <h3 className='font-medium text-base capitalize'>{`${creator?.firstName} ${creator?.lastName}`}</h3>
            <p className='text-xs pt-1'>
              Member since{' '}
              {`${
                creator?.approvedDate
                  ? new Date(creator?.approvedDate).toLocaleDateString()
                  : ''
              } Completed ${completedJobCount} jobs`}
            </p>
            {(() => {
              let rating = 0;
              reviews.forEach((review) => {
                rating += review.rating;
              });
              if (reviews.length > 0)
                return (
                  <div className='mt-1 flex items-center gap-2 text-sm'>
                    <Rating rating={rating / reviews.length} mini />
                    {(rating / reviews.length).toFixed(2)}
                    {` (${reviews.length} ratings)`}
                  </div>
                );
            })()}
          </div>
        </div>
      </div>

      {/* Portfolios */}

      {portfolios.length > 0 && (
        <>
          <h2 className='font-semibold text-xl capitalize mt-10'>{`${creator?.firstName} ${creator?.lastName}' portfolio`}</h2>
          <div className='mt-5 grid sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 xl:grid-cols-5 2xl:grid-cols-6 gap-6'>
            {portfolios.map((portfolio) => (
              <AmplifyMedia
                key={portfolio.id}
                className='w-full aspect-square'
                mediaType={portfolio.contentType}
                sourceKey={portfolio.contentUrl}
              />
            ))}
          </div>
        </>
      )}

      {/* Reviews */}
      <h2 className='font-semibold text-xl mt-10'>
        {t('Latest brand reviews')}
      </h2>
      <Card addClassName='mt-5 p-4 flex flex-wrap gap-2'>
        {reviews.map((review) => (
          <ReviewCard key={review.id} {...review} />
        ))}
      </Card>
    </CreatorAuthedContainer>
  );
}
