import { useDispatch, useSelector } from 'react-redux';
import { Storage } from 'aws-amplify';
import {
  createProductAction,
  updateProductAction,
} from 'redux/brand_slices/productSlice';

import { AppDispatch, RootState } from 'redux/store';
import { useState } from 'react';
import { toast } from 'react-toastify';
import { useTranslation } from 'react-i18next';

export default function useProductModal(
  product: IProductData,
  modalType: 'CREATE' | 'EDIT',
  closeModal: () => void,
  imageFile?: File,
  hook?: (productItem: IValueWithIndex) => void
) {
  const { t } = useTranslation('translation', {
    keyPrefix: 'notification',
  });

  const dispatch = useDispatch<AppDispatch>();
  const pending = useSelector((root: RootState) => root.product.pending);
  const [isUploading, setIsUploading] = useState<boolean>(false);
  const [progress, setProgress] = useState<IUploadProgress>({
    loaded: 0,
    total: 1,
  });

  const submitHandler = async (e: any) => {
    e.preventDefault();
    if (modalType === 'CREATE') {
      if (imageFile === undefined) {
        toast.warn(t('You should upload product image.'));
        return;
      }

      setIsUploading(true);
      let response;
      try {
        const fileName =
          imageFile.name.split('.')[0] +
          Date.now() +
          '.' +
          imageFile.name.split('.')[1];

        response = await Storage.put(fileName, imageFile, {
          level: 'public',
          progressCallback(progress) {
            setProgress({ loaded: progress.loaded, total: progress.total });
          },
        });
      } catch (error) {
        toast.error(t('There was an error uploading image.'));
      }
      setProgress({ loaded: 0, total: 1 });
      setIsUploading(false);

      if (response?.key) {
        const returnURL = response.key;
        if (modalType === 'CREATE') {
          await dispatch(
            createProductAction({
              ...product,
              productImage: returnURL,
            })
          );

          // trigger hook to automatically selecte created one
          hook &&
            hook({
              index: product.id,
              value: product.productName,
              displayValue: product.productName,
            });
        }
      }

      closeModal();
      return;
    }
    if (imageFile === undefined && product.productImage === '') return;

    if (imageFile) {
      setIsUploading(true);
      let response;
      try {
        const fileName =
          imageFile.name.split('.')[0] +
          Date.now() +
          '.' +
          imageFile.name.split('.')[1];

        response = await Storage.put(fileName, imageFile, {
          level: 'public',
          progressCallback(progress) {
            setProgress({ loaded: progress.loaded, total: progress.total });
          },
        });
      } catch (error) {
        toast.error(t('There was an error uploading image.'));
      }
      setProgress({ loaded: 0, total: 1 });
      setIsUploading(false);

      if (response?.key) {
        const returnURL = response.key;
        await dispatch(
          updateProductAction({
            id: product.id,
            brandID: product.brandID,
            productCategory: product.productCategory,
            productDescription: product.productDescription,
            productExternalLink: product.productExternalLink,
            productName: product.productName,
            productPrice: product.productPrice,
            productType: product.productType,
            userID: product.userID,
            productImage: returnURL,
          })
        );
      }
    } else {
      await dispatch(
        updateProductAction({
          id: product.id,
          brandID: product.brandID,
          productCategory: product.productCategory,
          productDescription: product.productDescription,
          productExternalLink: product.productExternalLink,
          productName: product.productName,
          productPrice: product.productPrice,
          productType: product.productType,
          userID: product.userID,
          productImage: product.productImage,
        })
      );
    }
    closeModal();
  };

  return { pending, progress, isUploading, submitHandler };
}
