import {
  ChevronLeftIcon,
  InformationCircleIcon,
} from '@heroicons/react/24/outline';
import { ReactElement } from 'react';
import { Helmet } from 'react-helmet';
import { NavLink } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Dialog } from '@headlessui/react';

import Card from 'components/card';
import Tab from 'components/tab';

import { SkeletonBox } from 'components/skeleton';

import BrandAuthedContainer from 'views/brand/_components/Container.AuthedBrand';
import AmplifyMedia from 'views/components/media/StorageImageRenderer';

import JobChatPanel from '../components/Panel.JobChat';
import JobContentInfoPanel from '../components/Panel.JobContentInfo';

import Tip from 'components/tip';
import Modal from 'components/modal/Modal';
import CardHr from 'components/card/CardHr';
import Button from 'components/button/Button';
import Textarea from 'components/form/Textarea/Textarea';
import CardTextLabel from 'components/card/CardLabelText';

import JobPorgressBar from 'views/components/progress/JobProgressBar';

import RatingInput from '../components/Input.Rating';
import JobInfoPanel from '../components/Panel.JobInfo';
import ProductInfoCard from '../components/Card.ProductInfo';
import JobDeliveryInfoPanel from '../components/Panel.JobDeliveryInfo';

// hooks
import useReviews from 'hooks/common/useReviews';
import useUpdateJobStatus from 'hooks/common/useUpdateJobStatus';
import useFetchedJobDetail from 'hooks/common/useFetchedJobDetail';

export const jobStatusWithIndex: IValueWithIndex[] = [
  'open',
  'invited',
  'waiting-payment',
  'started',
  'product-sent',
  'product-delivered',
  'content-uploaded',
  'completed',
  'rejected',
].map((status) => {
  return {
    index: status,
    value: status,
    displayValue: status,
  };
});

export default function CampaignJobDetailView(): ReactElement {
  const { t } = useTranslation('translation', { keyPrefix: 'brand.campaigns' });
  const { t: transJobStatus } = useTranslation('translation', {
    keyPrefix: 'creator.marketplace.jobStatus',
  });

  const { campaignID, job, fetching, fetchJobByJobID } = useFetchedJobDetail();
  const { pending, currentJobStatus, updateJobStatus } =
    useUpdateJobStatus(job);

  const {
    disabled,
    review,
    visibility,
    setVisiblity,
    closeReviewModal,
    changeReviewHandler,
    changeRatingHandler,
    submitReviewHandler,
  } = useReviews(job);

  window?.scrollTo({ top: 0 });

  return (
    <>
      <Helmet>
        <title>My campaigns</title>
      </Helmet>
      <BrandAuthedContainer>
        <NavLink
          className='mt-8 flex items-center gap-2 text-sm'
          to={'/brand/campaigns/' + campaignID}
        >
          <ChevronLeftIcon className='w-5 h-5' />
          {t('Back to campagin')}{' '}
          <>
            {job?.Campaign?.campaignName
              ? `"${job?.Campaign?.campaignName}"`
              : ''}
          </>
        </NavLink>

        {job?.jobStatus === 'open' && (
          <Tip
            icon={<InformationCircleIcon className='w-6 h-6' />}
            tipType={'info'}
            description={
              <div className='flex items-center'>
                {t('NewJobMsg')}
                <Button
                  onClick={() =>
                    updateJobStatus('waiting-payment', fetchJobByJobID)
                  }
                  variant='outline'
                  className='ml-auto mr-10 px-10 h-10 !text-black'
                  disabled={fetching || pending}
                >
                  {t('Accept')}
                </Button>
                <Button
                  onClick={() => updateJobStatus('rejected', fetchJobByJobID)}
                  className='px-10 h-10 mr-10'
                  disabled={fetching || pending}
                >
                  {t('Decline')}
                </Button>
              </div>
            }
            wrapperClassName='mt-8 shadow-card'
            closable
          />
        )}

        {/* Product info */}

        <ProductInfoCard
          fetching={fetching}
          campaign={job?.Campaign}
          product={job?.Product}
        />

        {!!currentJobStatus &&
          currentJobStatus !== 'invited' &&
          currentJobStatus !== 'open' &&
          currentJobStatus !== 'rejected' && (
            <JobPorgressBar
              jobStatus={currentJobStatus}
              onStepClick={(status: TJobStatus) => {
                if (
                  ['completed', 'waiting-payment', 'rejected'].includes(
                    currentJobStatus
                  )
                )
                  return;

                if (status === 'completed') {
                  if (currentJobStatus !== 'content-uploaded') return;
                  setVisiblity(true);
                } else {
                  updateJobStatus(status, fetchJobByJobID);
                }
              }}
            />
          )}

        <Card addClassName='p-4 mt-8 flex flex-col md:flex-row md:items-center text-sm leading-[18px] font-semibold gap-3'>
          <div className='flex items-center gap-x-3'>
            <AmplifyMedia
              sourceKey={job?.Creator?.profile_picture}
              className='w-8 h-8 !rounded-full'
              height={32}
              width={32}
              isUserAvatar
            />
            {fetching ? (
              <SkeletonBox darkMode className='w-36 h-[18px]' />
            ) : (
              <span className='first-letter:uppercase'>
                {job?.Creator && job.Creator.firstName + `' job`}
              </span>
            )}
          </div>
          <div className='md:ml-auto capitalize'>
            {job?.jobStatus === 'waiting-payment' && (
              <a
                href={job?.invoiceLink ?? '#'}
                rel='noreferrer noopener'
                className='mr-8 px-6 py-3 text-error animate-pulse hover:animate-none hover:text-white transition-all duration-200 hover:bg-theme/20 rounded'
                target='_blank'
              >
                {t('Send payment')}
              </a>
            )}
            <span className='max-md:pl-11 sm:pr-5'>
              {job && transJobStatus(job.jobStatus)}
            </span>
          </div>
        </Card>
        <Tab
          tabs={[t('Delivery info'), t('Job info'), t('Chat'), t('Content')]}
          views={[
            <JobDeliveryInfoPanel
              invoiceLink={job?.invoiceLink}
              creator={job?.Creator}
              campaignID={job?.campaignID}
              jobStatus={job?.jobStatus}
            />,
            <JobInfoPanel
              fetching={fetching}
              campaign={job?.Campaign}
              product={job?.Product}
              brand={job?.Brand}
            />,
            <JobChatPanel creator={job?.Creator} />,
            <JobContentInfoPanel />,
          ]}
          tabBarAddClassName='mt-8'
        />
      </BrandAuthedContainer>
      <Modal isOpen={visibility} closeModal={closeReviewModal}>
        <Dialog.Panel className='w-[80vw] md:w-[700px] transform overflow-hidden rounded-xl bg-white dark:bg-dark-theme-200 shadow-card transition-all'>
          <Dialog.Title
            as='h3'
            className='text-lg font-medium leading-6 text-gray-900 mx-5 dark:text-gray-200 pt-4 flex justify-between items-center'
          >
            {t('Complete the job')}
          </Dialog.Title>
          <CardHr marginClassName='my-4' />
          <div className='px-5 pb-4'>
            <CardTextLabel>
              {t('Leave reviews to the creator to complete the job.')}
            </CardTextLabel>
            <Textarea
              className='mt-3 mb-2'
              value={review?.review}
              onChange={changeReviewHandler}
            />
            <RatingInput
              value={review?.rating}
              onChange={changeRatingHandler}
            />
            <div className='flex justify-end gap-2 mt-2'>
              <Button
                onClick={() =>
                  submitReviewHandler(updateJobStatus, fetchJobByJobID)
                }
                className='w-[220px] h-10'
                disabled={disabled}
              >
                {t('Complete')}
              </Button>
              <Button
                onClick={closeReviewModal}
                className='w-[220px] h-10'
                variant='outline'
              >
                {t('Cancel')}
              </Button>
            </div>
          </div>
        </Dialog.Panel>
      </Modal>
    </>
  );
}
