import { Transition } from '@headlessui/react';
import { NavLink, useNavigate } from 'react-router-dom';
// import { useScroll } from 'hooks/common/useScroll';
import { Fragment, useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  ArrowLeftOnRectangleIcon,
  XMarkIcon,
} from '@heroicons/react/24/outline';

import { setMobileSidebarVisibility } from 'redux/common_slices/userSlice';
import { AppDispatch, RootState } from 'redux/store';

import useNotifications from 'hooks/common/useNotifications';
import AmplifyMedia from '../media/StorageImageRenderer';

import creatorMenu from 'utils/constants/creator_menu';
import brandMenu from 'utils/constants/brand_menu';

import { mainOptions as creatorAccountOptions } from 'views/influencer/_components/AccountSettings';

import { mainOptions as brandAccountOptions } from 'views/brand/_components/AccountSetting';
import { logoutAction } from 'redux/common_slices/authSlice';

const menu: Record<any, any> = {
  CREATOR: creatorMenu,
  BRAND: brandMenu,
};

const accountOptions: Record<any, any> = {
  CREATOR: creatorAccountOptions,
  BRAND: brandAccountOptions,
};

export default function MobileSidebar() {
  const navigate = useNavigate();
  const dispatch = useDispatch<AppDispatch>();
  const asidebarRef = useRef<HTMLDivElement>(null);
  const visibility = useSelector(
    (root: RootState) => root.user.showMobileSidebar
  );
  const userProfile = useSelector((root: RootState) => root.user.profile);

  const { notifications, markAsReadOne } = useNotifications();

  // const [_, allowScroll] = useScroll();

  useEffect(() => {
    function handleClickOutside(event: any) {
      if (asidebarRef.current && !asidebarRef.current.contains(event.target)) {
        dispatch(setMobileSidebarVisibility(false));
      }
    }

    document.addEventListener('click', handleClickOutside);

    return () => {
      document.removeEventListener('click', handleClickOutside);
    };
  }, [asidebarRef, notifications]);

  useEffect(() => {
    document.body.style.overflow = visibility ? 'hidden' : 'auto';

    return () => {
      document.body.style.overflow = 'auto';
    };
  }, [visibility]);

  return (
    <Transition
      enter='ease-out duration-300'
      enterFrom='opacity-80 translate-x-full'
      enterTo='opacity-100 translate-x-0'
      leave='ease-in duration-300'
      leaveFrom='opacity-100 translate-x-0'
      leaveTo='opacity-80 translate-x-full'
      show={visibility}
      as={Fragment}
    >
      <aside
        ref={asidebarRef}
        className='lg:hidden w-full sm:w-[360px] h-screen fixed top-0 right-0 bg-white dark:bg-dark-theme-900 z-20'
      >
        <div className='pt-6'>
          <div className='px-6 border-b border-gray-200 dark:border-dark-theme-200 pb-4'>
            <div className='flex items-start justify-between'>
              <div className='avatar avatar-circle avatar-md'>
                <div className='image-container w-full'>
                  <AmplifyMedia
                    isUserAvatar
                    height={48}
                    width={48}
                    sourceKey={userProfile.profile_picture}
                    className='w-12 h-12 !rounded-full'
                  />
                </div>
              </div>
              <div className='ml-3 flex h-7 items-center'>
                <XMarkIcon
                  onClick={() => dispatch(setMobileSidebarVisibility(false))}
                  className='cursor-pointer w-8 h-8'
                />
              </div>
            </div>
            <p className='text-lg font-medium pt-2 capitalize'>
              {userProfile.firstName} {userProfile.lastName}
            </p>
            <div className='text-gray-500 text-sm font-normal'>
              {userProfile.email}
            </div>
          </div>
        </div>

        <div className='relative py-6 flex-1 overflow-y-auto'>
          <ul>
            {Array.isArray(menu[userProfile.role]) &&
              menu[userProfile.role]?.map(
                (menuItem: IMenuData, index: number) => (
                  <li
                    key={userProfile.role + index}
                    className='space-y-2 flex flex-col px-4'
                  >
                    <NavLink
                      to={menuItem.path}
                      className={({ isActive }) =>
                        `p-2 rounded capitalize ${
                          isActive
                            ? 'text-black dark:text-white bg-dark/10 dark:bg-[rgb(12,12,12)]'
                            : 'text-dark/70 dark:text-white/80'
                        }`
                      }
                    >
                      {menuItem.text}
                    </NavLink>
                  </li>
                )
              )}
          </ul>
          <ul className='border-t border-gray-200 dark:border-dark-theme-200 mt-4 pt-4'>
            {Array.isArray(accountOptions[userProfile.role]) &&
              accountOptions[userProfile.role].map(
                (option: { text: string; link?: string; icon: any }) => (
                  <li
                    key={option.text}
                    className='space-y-2 flex flex-col px-4'
                  >
                    <NavLink
                      to={`/${
                        userProfile.role === 'BRAND' ? 'brand' : 'influencer'
                      }/settings/${option.link}`}
                      className={({ isActive }) =>
                        `${
                          isActive
                            ? 'text-black dark:text-white bg-dark/10 dark:bg-[rgb(12,12,12)]'
                            : 'text-dark/70 dark:text-white/80'
                        } p-2 rounded flex items-center gap-3`
                      }
                    >
                      {option.icon}
                      {option.text}
                    </NavLink>
                  </li>
                )
              )}
            <li className='space-y-2 flex flex-col px-4'>
              <button
                onClick={() => dispatch(logoutAction())}
                className={`group active:bg-gray-200 flex gap-3 w-full truncate items-center rounded-md p-2 h-10 text-sm transition-all duration-150`}
              >
                <ArrowLeftOnRectangleIcon className='w-5 h-5' />
                Log out
              </button>
            </li>
          </ul>
        </div>
      </aside>
    </Transition>
  );
}
