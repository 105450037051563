const content = {
  'Available content': 'Available content',
  MyContent: 'My content({{count}})',
  'Pick content': 'Pick content({{count}})',

  // No contents
  'Sorry, but nothing matched your search terms. Please change your filtering selections and try again.':
    'Sorry, but nothing matched your search terms. Please change your filtering selections and try again.',

  // pick & unpick
  'Pick this': 'Pick this',
  'Unpick this': 'Unpick this',

  'Content preview': 'Content preview',
  Download: 'Download',
};

export default content;
