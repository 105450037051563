import { ReactElement } from 'react';
import UnauthenticatedNav from '../../views/components/navbar/UnauthenticatedNav';
import Footer from './Footer';

export default function UnauthedLayout(props: IUnauthedLayout): ReactElement {
  return (
    <>
      <UnauthenticatedNav />
      <main className='min-h-[calc(100vh-64px)] h-[calc(100vh-64px)] overflow-auto'>
        {props.children}
        <Footer />
      </main>

    </>
  );
}
