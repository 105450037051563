const data: IMenuData[] = [
  {
    text: 'dashboard',
    path: '/brand/',
  },
  {
    text: 'campaigns',
    path: '/brand/campaigns',
  },
  {
    text: 'products',
    path: '/brand/products',
  },
  {
    text: 'content',
    path: '/brand/content',
  },
];

export default data;
