const job = {
  'My jobs': 'My jobs',

  // table tabs
  All: 'All({{count}})',
  Open: 'Open({{count}})',
  Invited: 'Invited({{count}})',
  Started: 'Started({{count}})',
  Completed: 'Completed({{count}})',
  Rejected: 'Rejected({{count}})',

  // job table tabs

  Product: 'Product',
  'Content type': 'Content type',
  'Job info': 'Job info',

  // content type
  video: 'video',
  photo: 'photo',

  // content format
  Any: 'Any',
  'aspect ratio': 'aspect ratio',

  'Created at': 'Created at',
  'No jobs': 'No jobs',
};

export default job;
