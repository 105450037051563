import { ReactElement, ReactNode } from 'react';
import { NavLink } from 'react-router-dom';

const menu: IMenuData[] = [
  {
    path: 'users',
    text: 'Users',
  },
  {
    path: 'job',
    text: 'Jobs',
  },
];

export default function AdminNavMenu(props: {
  children?: ReactNode;
}): ReactElement {
  return (
    <ul className='flex items-center gap-x-4 text-sm'>
      {props.children}
      {menu.map((elem: IMenuData) => (
        <li
          key={elem.path}
          className='hidden lg:block capitalize first-of-type:ml-5'
        >
          <NavLink
            className={({ isActive }) =>
              `${
                isActive
                  ? 'text-black dark:text-white bg-dark/10 dark:bg-[rgb(12,12,12)]'
                  : 'text-dark/70 dark:text-white/80'
              } py-3 px-5 rounded-3xl block hover:bg-dark/10 dark:hover:bg-[rgb(18,18,18)]`
            }
            to={elem.path}
          >
            {elem.text}
          </NavLink>
        </li>
      ))}
    </ul>
  );
}
