import { SunIcon } from '@heroicons/react/20/solid';
import { MoonIcon } from '@heroicons/react/24/outline';
import { ReactElement, useState } from 'react';

export default function DarkModeButton(): ReactElement {
  const [theme, setTheme] = useState<'light' | 'dark'>(
    (localStorage?.getItem('reeffo-theme') as 'light' | 'dark') ?? 'dark'
  );

  const toggleDarkModeHandler = () => {
    if (localStorage.getItem('reeffo-theme')) {
      if (localStorage.getItem('reeffo-theme') === 'light') {
        document.documentElement.classList.add('dark');
        localStorage.setItem('reeffo-theme', 'dark');
        setTheme('dark');
      } else {
        document.documentElement.classList.remove('dark');
        localStorage.setItem('reeffo-theme', 'light');
        setTheme('light');
      }

      // if NOT set via local storage previously
    } else {
      if (document.documentElement.classList.contains('dark')) {
        document.documentElement.classList.remove('dark');
        localStorage.setItem('reeffo-theme', 'light');
        setTheme('light');
      } else {
        document.documentElement.classList.add('dark');
        localStorage.setItem('reeffo-theme', 'dark');
        setTheme('dark');
      }
    }
  };

  return (
    <button
      onClick={toggleDarkModeHandler}
      type='button'
      className='flex justify-center p-2 text-gray-500 transition duration-150 ease-in-out border border-transparent rounded-full dark:text-gray-200  hover:text-gray-700 focus:outline-none hover:bg-gray-100 active:bg-gray-200 dark:hover:bg-dark-theme-900/80 dark:active:bg-dark-theme-900'
    >
      {theme === 'light' ? (
        <SunIcon className='w-5 h-5' />
      ) : (
        <MoonIcon className='w-5 h-5' />
      )}
    </button>
  );
}
