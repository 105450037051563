import { Fragment, ReactElement, MouseEvent } from 'react';
import { EllipsisVerticalIcon } from '@heroicons/react/24/solid';
import { Menu, Transition } from '@headlessui/react';

export default function EllipsisVerticalButton(
  props: IEllipsisVerticalButton
): ReactElement {
  return (
    <Menu as='div' className='relative text-left'>
      <Menu.Button
        onClick={(e: MouseEvent<HTMLButtonElement>) => e.stopPropagation()}
        className={`${
          props.className ?? ''
        } p-2 hover:bg-gray-200 active:bg-gray-300 dark:hover:bg-dark-theme-900/80 dark:active:bg-dark-theme-900 rounded-full transition-all duration-150`}
      >
        <EllipsisVerticalIcon className='w-6 h-6 text-black dark:text-gray-100' />
      </Menu.Button>
      <Transition
        as={Fragment}
        enter='transition ease-out duration-100'
        enterFrom='transform opacity-0 scale-95'
        enterTo='transform opacity-100 scale-100'
        leave='transition ease-in duration-75'
        leaveFrom='transform opacity-100 scale-100'
        leaveTo='transform opacity-0 scale-95'
      >
        <Menu.Items className='absolute right-0 mt-2 w-56 origin-top-right divide-y divide-gray-100 dark:divide-dark-theme-900 rounded-md bg-white dark:bg-dark-theme-200 shadow-card z-10'>
          {props.optionLists.map((optionGroup, groupIndex) => {
            return (
              <div key={'option-group' + groupIndex} className='px-1 py-1'>
                {optionGroup.map((option, optionIndex) => {
                  return (
                    <Menu.Item key={option + optionIndex}>
                      {({ active }) => (
                        <button
                          onClick={(e: MouseEvent<HTMLButtonElement>) => {
                            e.stopPropagation();
                            props.onOptionClick(
                              props.optionLists.slice(0, groupIndex - 2).flat(2)
                                .length + optionIndex
                            );
                          }}
                          className={`${
                            active
                              ? 'bg-gray-100 dark:bg-dark-theme-900/80'
                              : ''
                          } group active:bg-gray-200 flex gap-3 w-full truncate items-center rounded-md px-3 h-10 text-sm transition-all duration-150`}
                        >
                          {option}
                        </button>
                      )}
                    </Menu.Item>
                  );
                })}
              </div>
            );
          })}
        </Menu.Items>
      </Transition>
    </Menu>
  );
}
