import { ReactElement } from 'react';
import { useTranslation } from 'react-i18next';

import Card from 'components/card/Card';
import CardHr from 'components/card/CardHr';
import Button from 'components/button/Button';
import CardTitle from 'components/card/CardTitle';
import AuthedHeading from 'components/typhography/AuthedHeading';

import FileDropzone from 'views/components/FileDropzone';

// hook
import useCreatorPortfolio from 'hooks/influencer/useCreatorPortfolio';
import AmplifyMedia from 'views/components/media/StorageImageRenderer';
import { NavLink } from 'react-router-dom';

export default function CreatorPortfolio(): ReactElement {
  const { t } = useTranslation('translation', {
    keyPrefix: 'creator.setting.PortfolioPage',
  });

  const { portfolios, creatorID, uploadPortfolioHandler } =
    useCreatorPortfolio();

  return (
    <>
      <AuthedHeading>{t('My portfolio')}</AuthedHeading>
      <Card addClassName='p-4 md:p-8 mt-8'>
        <div className='flex items-center justify-between'>
          <div>
            <CardTitle noMargin title={t('Content')} />
            <p className='text-sm text-gray-500 dark:text-gray-400'>
              {portfolios.length} {t('published')}
            </p>
          </div>
          <NavLink to={`/influencer/creator/${creatorID}`}>
            <Button variant='outline' className='w-fit px-10 h-10'>
              {t('See public portfolio')}
            </Button>
          </NavLink>
        </div>
        <CardHr />
        <div className='grid grid-cols-4 gap-4'>
          {portfolios.length > 0 &&
            portfolios?.map((portfolio) => (
              <AmplifyMedia
                mediaType={portfolio.contentType}
                sourceKey={portfolio.contentUrl}
                key={portfolio.id}
                className='w-full aspect-square rounded-lg overflow-hidden'
              />
            ))}
        </div>
      </Card>
      <Card addClassName='p-4 md:p-8 mt-8 flex flex-col'>
        <CardTitle noMargin title={t('Upload new content')} />
        <CardHr marginClassName='my-6' />
        <FileDropzone
          fileType={['video', 'image']}
          onFileUpload={uploadPortfolioHandler}
        />
        <CardHr marginClassName='my-6' />
        <Button variant='outline' className='w-fit px-10 h-10 ml-auto'>
          {t('Submit content')}
        </Button>
      </Card>
    </>
  );
}
