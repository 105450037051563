import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';

import { getCampaignByID } from 'apis/brand/campaign';

export default function useCampaignDetailFromId() {
  const { campaignID } = useParams();
  const [error, setError] = useState<boolean>(false);
  const [pending, setPending] = useState<boolean>(false);
  const [campaign, setCampaign] = useState<ICampaignData>();

  useEffect(() => {
    if (campaignID === undefined) return;

    (async () => {
      try {
        setPending(true);
        const response = await getCampaignByID(campaignID);
        setCampaign(response.data.getCampaign);
        setError(false);
        setPending(false);
      } catch (e) {
        setError(true);
      }
    })();
    return () => {};
  }, [campaignID]);

  return { campaign, pending, error };
}
