import { ReactElement, ReactNode } from 'react';

export default function SettingHeading(props: {
  children?: ReactNode;
}): ReactElement {
  return (
    <h1 className='text-2xl font-semibold text-gray-900 dark:text-gray-100 mt-6 xl:mt-0'>
      {props.children}
    </h1>
  );
}
