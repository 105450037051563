import { ReactElement } from 'react';

import Card from 'components/card';
import CardHr from 'components/card/CardHr';

import ContentTable from 'views/brand/campaigns/components/Table.Content';

export default function ContentViewForProduct(props: {
  contents: IJobContent[];
  fetching: boolean;
}): ReactElement {
  return (
    <Card addClassName='p-4 md:p-8 min-h-[300px]'>
      <CardHr />
      <ContentTable contents={props.contents} />
    </Card>
  );
}
