import { ReactElement } from 'react';
import { useTranslation } from 'react-i18next';

import Card from 'components/card';

import useFetchedJobContent from 'hooks/common/useFetchedJobContent';
import JobContentCard from 'views/brand/content/components/Card.JobContent';
import { PhotoIcon } from '@heroicons/react/24/outline';

export default function JobContentInfoPanel(): ReactElement {
  const { t } = useTranslation('translation', { keyPrefix: 'brand.campaigns' });

  const { fetching, error, jobContents } = useFetchedJobContent();

  return (
    <Card addClassName='p-4 md:p-8 mt-8'>
      <div className='mt-12 grid sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 2xl:grid-cols-5 gap-5 relative min-h-[500px]'>
        {jobContents.length === 0 && (
          <div className='flex flex-col items-center absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2'>
            <PhotoIcon className='w-12 h-12' />
            {t('No contents')}
          </div>
        )}
        {jobContents.map((content) => (
          <JobContentCard key={content.id} {...content} />
        ))}
      </div>
    </Card>
  );
}
