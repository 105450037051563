import { Storage } from 'aws-amplify';
import { ChangeEvent, useState, useEffect, useCallback } from 'react';
import { useSelector } from 'react-redux';
import { RootState } from 'redux/store';
import hashID from 'helpers/hashID';

export default function useEditProductData(data?: IProductData) {
  const userID = useSelector((root: RootState) => root.user.profile.id);

  const newProduct: IProductData = {
    id: hashID(8),
    brandID: 'NaN',
    productCategory: -100,
    productDescription: '',
    productExternalLink: '',
    productImage: '',
    productName: '',
    productPrice: 0,
    productType: 'DIGITAL',
    userID: userID,
  };

  const [product, setProduct] = useState<IProductData>(data ?? newProduct);
  const [imageURL, setImageURL] = useState<string>();
  const [imageFile, setImageFile] = useState<File>();

  const getImage = useCallback(async (key?: string) => {
    if (key) {
      const image = await Storage.get(key, { expires: 120 });
      setImageURL(image);
    }
  }, []);

  useEffect(() => {
    getImage(product?.productImage);
    return () => {};
  }, []);

  const handleInputChange = (
    e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    setProduct({ ...product, [e.target.name]: e.target.value });
  };

  const handleManualChange = (name: string, value: string | number) => {
    setProduct({ ...product, [name]: value });
  };

  const handleImageFileChange = (image: File) => {
    setImageURL(URL.createObjectURL(image));
    setImageFile(image);
  };

  const handleBrandChange = (brand: IValueWithIndex) => {
    setProduct({ ...product, brandID: brand.index });
  };

  const enabled =
    product.productName !== '' &&
    product.productPrice !== 0 &&
    product.productExternalLink !== '' &&
    ((product.productExternalLink.includes('https://') &&
      product.productExternalLink !== 'https://') ||
      (product.productExternalLink.includes('http://') &&
        product.productExternalLink !== 'http://')) &&
    product.brandID !== 'NaN' &&
    product.userID !== '';

  return {
    disabled: !enabled,
    product,
    imageURL,
    imageFile,
    handleInputChange,
    handleManualChange,
    handleImageFileChange,
    handleBrandChange,
  };
}
