export default function ImageSidePanel() {
  return (
    <div
      className='hidden sm:block lg:w-1/3 bg-cover bg-center bg-origin-border sm:rounded-t-2xl rounded-t-2xl lg:rounded-t-none lg:rounded-l-2xl w-full h-28 lg:ml-0 lg:h-auto'
      style={{
        backgroundImage: 'url("../assets/images/auth/account.jpg")',
      }}
    ></div>
  );
}
