import { ReactElement, ReactNode } from 'react';

export default function BrandAuthedContainer(props: {
  children?: ReactNode;
}): ReactElement {
  return (
    <div className='container mx-auto px-4 lg:px-8 pb-16 lg:pb-32'>
      {props.children}
    </div>
  );
}
