export default function helperGetAverageRating(reviews?: IUserReview[]) {
  if (reviews === undefined) return 0;
  const sumRating = reviews
    .map((review) => review.rating)
    .reduce((partialSum, a) => partialSum + a, 0);
  const reviewCount = reviews.length;
  const avgRating = reviewCount && sumRating ? sumRating / reviewCount : 0;

  return avgRating;
}
