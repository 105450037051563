import { API } from 'aws-amplify';

import * as customQueries from 'graphql_custom/custom_queries';
import * as queries from 'graphql/queries';
import * as mutations from 'graphql/mutations';

export async function getAllFilteredPost(query: string) {
  const result = await API.graphql({
    query: customQueries.listCampaigns,
    variables:
      query === ''
        ? {
            filter: {
              campaignStatus: { eq: 'OPEN' },
            },
          }
        : {
            filter: {
              campaignName: {
                contains: query,
              },
              campaignStatus: { eq: 'OPEN' },
            },
          },
  });
  return result;
}

export async function applyJob({ id, ...data }: IJobData) {
  const result = (await API.graphql({
    query: mutations.createJob,
    variables: { input: data },
  })) as any;

  return result;
}

export async function getCreatorPortfolio(userID: string) {
  const response = await API.graphql({
    query: queries.portfolioContentsByUserID,
    variables: { userID },
  });

  return response;
}

export async function createCreatorPortfolioContent(
  userID: string,
  contentType: 'image' | 'video',
  contentUrl: string
) {
  const response = await API.graphql({
    query: mutations.createPortfolioContent,
    variables: {
      input: {
        userID,
        contentType,
        contentUrl,
      },
    },
  });

  return response;
}
