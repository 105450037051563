import { useState } from 'react';
import { useSelector } from 'react-redux';
import { RootState } from 'redux/store';

export default function useFilteredProduct() {
  const [query, setQuery] = useState<string>('');
  const [brandIndex, setBrandIndex] = useState<string>('NaN');

  const brands = useSelector((root: RootState) => root.brand.brands);
  const campaigns = useSelector((root: RootState) => root.campaign.campaigns);
  const products = useSelector((root: RootState) => root.product.products)
    .filter((product) =>
      brandIndex === 'NaN' ? true : product.brandID === brandIndex
    )
    .filter((product) =>
      !!query
        ? product.productName.toLowerCase().includes(query.toLowerCase())
        : true
    );

  function queryChangeHandler(value: string) {
    setQuery(value);
  }

  function brandChangeHandler(elem: IValueWithIndex) {
    setBrandIndex(elem.index);
  }

  return {
    products,
    brands,
    campaigns,
    brandChangeHandler,
    queryChangeHandler,
  };
}
