const navbar = {
  marketplace: 'marketplace',
  'my jobs': 'my jobs',

  // account settings
  Account: 'Account',
  Portfolio: 'Portfolio',
  Reviews: 'Reviews',
  Wallet: 'Wallet',
  'Payment methods': 'Payment methods',
  'Log out': 'Log out',
  'Register as an Brand': 'Register as an Brand',
  'Already have an account?': 'Already have an account?',
  'Sign In': 'Sign In',
};

export default navbar;
