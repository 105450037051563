import { useTranslation } from 'react-i18next';

import Card from 'components/card';
import CardHr from 'components/card/CardHr';
import CardTitle from 'components/card/CardTitle';
import JobTable from 'views/brand/campaigns/components/Table.Job';

import useFetchedJobDataByBuyerID from 'hooks/common/useFetchedJobDataByBuyerID';

export default function NewJobs() {
  const { t } = useTranslation('translation', { keyPrefix: 'brand.dashboard' });

  const { jobs, fetching } = useFetchedJobDataByBuyerID();

  const newJobs = jobs.filter(
    (job) => job.jobStatus === 'invited' || job.jobStatus === 'open'
  );

  if (newJobs.length > 0) {
    return (
      <Card addClassName='p-4 md:p-8 mt-5'>
        <CardTitle title={t('Jobs Awaiting Approval')} />
        <CardHr />
        <JobTable jobs={newJobs} fetching={fetching} />
      </Card>
    );
  }

  return <></>;
}
