import { Transition } from '@headlessui/react';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
// import { useScroll } from 'hooks/common/useScroll';
import { Fragment, useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { BellAlertIcon, XMarkIcon } from '@heroicons/react/24/outline';

import { setNotificationSidebarVisibility } from 'redux/common_slices/userSlice';
import { AppDispatch, RootState } from 'redux/store';

import useNotifications from 'hooks/common/useNotifications';

export default function Notifications() {
  const navigate = useNavigate();
  const dispatch = useDispatch<AppDispatch>();
  const asidebarRef = useRef<HTMLDivElement>(null);
  const visibility = useSelector(
    (root: RootState) => root.user.showNotification
  );

  const { t } = useTranslation('translation', {
    keyPrefix: 'brand.settings.Notification',
  });
  const { notifications, markAsReadOne } = useNotifications();

  // const [_, allowScroll] = useScroll();

  useEffect(() => {
    function handleClickOutside(event: any) {
      if (asidebarRef.current && !asidebarRef.current.contains(event.target)) {
        dispatch(setNotificationSidebarVisibility(false));
      }
    }

    document.addEventListener('click', handleClickOutside);

    return () => {
      document.removeEventListener('click', handleClickOutside);
    };
  }, [asidebarRef, notifications]);

  useEffect(() => {
    document.body.style.overflow = visibility ? 'hidden' : 'auto';

    return () => {
      document.body.style.overflow = 'auto';
    };
  }, [visibility]);

  return (
    <Transition
      enter='ease-out duration-300'
      enterFrom='opacity-80 translate-x-full'
      enterTo='opacity-100 translate-x-0'
      leave='ease-in duration-300'
      leaveFrom='opacity-100 translate-x-0'
      leaveTo='opacity-80 translate-x-full'
      show={visibility}
      as={Fragment}
    >
      <aside
        ref={asidebarRef}
        className='w-full sm:w-[360px] h-screen fixed top-0 right-0 bg-gray-100 dark:bg-dark-theme-900 z-20'
      >
        <div className='p-5 flex items-center justify-between border-b-[1px] border-b-gray-300 dark:border-b-dark-theme-200'>
          <h4 className='font-semibold flex items-center gap-2 min-w-[200px]'>
            <BellAlertIcon className='w-5 h-5' /> {t('Notifications')}
          </h4>
          <XMarkIcon
            onClick={() => dispatch(setNotificationSidebarVisibility(false))}
            className='w-5 h-5 cursor-pointer'
          />
        </div>
        <div className='h-[calc(100vh_-_65px)] overflow-auto'>
          {notifications.map((notification) => (
            <div
              key={notification.id}
              onClick={() => {
                if (notification.link === '') return;
                navigate(notification.link);
                dispatch(setNotificationSidebarVisibility(false));
              }}
              className={`p-5 border-b-[1px] border-b-gray-300 dark:border-b-dark-theme-200 text-sm ${
                notification.link !== '' ? 'cursor-pointer' : ''
              }`}
            >
              <div className='flex gap-5 justify-between'>
                <span className='font-medium'>
                  {notification.notification_type}
                </span>
                <span className='text-xs text-gray-500 dark:text-gray-300'>
                  {new Date(notification.createdAt).toLocaleDateString()}
                </span>
              </div>
              <p
                className={`mt-1 text-sm text-gray-600 dark:text-gray-300 ${
                  notification.link !== '' ? 'underline' : ''
                }`}
              >
                {notification.message}
              </p>
              <p className='mt-2 text-right'>
                <button
                  onClick={(e) => {
                    e.stopPropagation();
                    markAsReadOne(notification);
                  }}
                >
                  {t('Mark as read')}
                </button>
              </p>
            </div>
          ))}
        </div>
      </aside>
    </Transition>
  );
}
