import { ChangeEvent, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { isNotEmpty, isValidEmail, shouldBeAtLeast } from 'helpers/validation';

const useCredentials = (type: 'SIGNIN' | 'SIGNUP' | 'RESET') => {
  const { t } = useTranslation('translation', { keyPrefix: 'auth' });

  const [credentials, setCredentials] = useState<ICredentials>({
    email: '',
    password: '',
    first_name: '',
    last_name: '',
    phone_number: '',
  });

  const [errorMessages, setErrorMessages] = useState<ICredentialErrMessages>(
    {}
  );

  let isValid =
    isNotEmpty(credentials.email) && isValidEmail(credentials.email);

  isValid = ['SIGNUP', 'SIGNIN'].includes(type)
    ? isValid &&
      isNotEmpty(credentials.password) &&
      shouldBeAtLeast(8, credentials.password)
    : isValid;
  isValid =
    type === 'SIGNUP'
      ? isValid &&
        isNotEmpty(credentials.first_name) &&
        isNotEmpty(credentials.last_name)
      : isValid;

  const MESSAGES_FACTORY = {
    emailRequired: t('The email address is required.'),
    emailInvalid: t('The email address is invalid.'),
    passwordRequired: t('The password is required.'),
    passwordMinLimit: t('The minimum number of characters required is 8.'),
    lastNameRequired: t('The last name is required'),
    firstNameRequired: t('The first name is required'),
    phoneNumberRequired: t('The phone number is required'),
  };

  const getErrorMessages = (
    name: string,
    value: string
  ): string | undefined => {
    if (name === 'email') {
      return isValidEmail(value) && isNotEmpty(value)
        ? undefined
        : !isNotEmpty(value)
        ? MESSAGES_FACTORY.emailRequired
        : MESSAGES_FACTORY.emailInvalid;
    }
    if (name === 'first_name') {
      return isNotEmpty(value) ? undefined : MESSAGES_FACTORY.firstNameRequired;
    }
    if (name === 'last_name') {
      return isNotEmpty(value) ? undefined : MESSAGES_FACTORY.lastNameRequired;
    }
    if (name === 'phone_number') {
      return isNotEmpty(value)
        ? undefined
        : MESSAGES_FACTORY.phoneNumberRequired;
    }
    if (name === 'password') {
      return shouldBeAtLeast(8, value) && isNotEmpty(value)
        ? undefined
        : !isNotEmpty(value)
        ? MESSAGES_FACTORY.passwordRequired
        : MESSAGES_FACTORY.passwordMinLimit;
    }
  };

  const handleInputChange = (
    e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    const { name, value } = e.target;
    setCredentials({ ...credentials, [name]: value });

    const errMsg = getErrorMessages(name, value);
    setErrorMessages({
      ...errorMessages,
      [name]: {
        show: errMsg !== undefined,
        message: errMsg,
      },
    });
  };

  return {
    credentials,
    hasError: !isValid,
    errorMessages,
    handleInputChange,
  };
};

export default useCredentials;
