import { ReactElement } from 'react';
import { useTranslation } from 'react-i18next';
import { NavLink } from 'react-router-dom';

import Button from 'components/button';
import Card from 'components/card';

import useNewCampaignNavigation from 'hooks/brand/useNewCampaignNavigation';

export default function NavigateButtonGroup(props: {
  back?: string;
  required?: 'description';
  next: string;
}): ReactElement {
  const { t } = useTranslation('translation', { keyPrefix: 'brand.campaigns' });

  const { disabled, cancelNewCampaignHandler } = useNewCampaignNavigation();

  return (
    <Card addClassName='p-4 md:p-8 mt-5 flex flex-col md:flex-row justify-between gap-4'>
      <Button
        onClick={cancelNewCampaignHandler}
        variant='outline'
        addClassName='w-full md:w-[150px] px-10 dark:!text-white dark:border-dark-theme-900'
      >
        {t('Cancel')}
      </Button>
      <div className='flex flex-col md:flex-row gap-4'>
        {props.back && (
          <NavLink to={props.back}>
            <Button
              variant='outline'
              addClassName='font-medium w-full md:w-[150px] dark:!text-white dark:border-dark-theme-900'
            >
              {t('Back')}
            </Button>
          </NavLink>
        )}

        {(() => {
          const buttonElem = (
            <Button
              disabled={disabled}
              addClassName='w-full min-w-[150px] font-medium'
            >
              {t('Next')}
            </Button>
          );
          return disabled ? (
            buttonElem
          ) : (
            <NavLink to={props.next}>{buttonElem}</NavLink>
          );
        })()}
      </div>
    </Card>
  );
}
