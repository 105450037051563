import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';

import { shootMessageAction } from 'redux/common_slices/userSlice';
import { AppDispatch, RootState } from 'redux/store';

import { getAllMessagesByJobID } from 'apis/common/userAPI';

import { helperSortMessagesByDate } from 'helpers/array';
import mockWait from 'utils/mock/wait';

export default function useChatData(
  userType: 'BRAND' | 'CREATOR',
  toUserID?: string
) {
  const dispatch = useDispatch<AppDispatch>();

  const { campaignID, jobID } = useParams();

  const [messages, setMessages] = useState<IMessage[]>([]);
  const [fetching, setFetching] = useState<boolean>(false);
  const [isFirstFetch, setIsFirstFetch] = useState<boolean>(true);
  const [isNew, setIsNew] = useState<boolean>(false);
  const [error, setError] = useState<boolean>(false);

  const user = useSelector((root: RootState) => root.user);
  const { pending, profile: userProfile } = user;
  const { id: userID, firstName: userName } = userProfile;

  const shootMessage = async (msg: string) => {
    if (toUserID === undefined || toUserID === '' || !jobID) return;

    const messageID = `${user.profile.id} ${userType} ${Date.now()}`;

    if (userType === 'BRAND') {
      await dispatch(
        shootMessageAction({
          id: messageID,
          jobID: jobID,
          fromUserID: userID,
          toUserID: toUserID,
          message: msg,
        })
      );

      fetchMessages(0);
    } else {
      await dispatch(
        shootMessageAction({
          id: messageID,
          jobID: jobID,
          fromUserID: userID,
          toUserID: toUserID,
          message: msg,
        })
      );
      fetchMessages(0);
    }
  };

  const fetchMessages = async (offset: 0 | 6000) => {
    if (jobID === undefined) return;

    await mockWait(offset);
    isFirstFetch && setIsFirstFetch(false);

    setFetching(true);
    try {
      const response: any = await getAllMessagesByJobID(jobID);

      if (response.data.messagesByJobID.items.length !== messages.length) {
        setIsNew(true);
      }

      setMessages(
        helperSortMessagesByDate(response.data.messagesByJobID.items)
      );
      setError(false);
    } catch (e) {
      setError(true);
    }
    setFetching(false);
  };

  useEffect(() => {
    fetchMessages(isFirstFetch ? 0 : 6000);
  }, [messages]);

  return {
    fetching,
    pending,
    userID,
    userName,
    campaignID,
    jobID,
    messages,
    isNew,
    setIsNew,
    shootMessage,
  };
}
