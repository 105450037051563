import { ReactElement } from 'react';
import { XMarkIcon } from '@heroicons/react/20/solid';

export default function ModalCloseButton(
  props: IModalCloseButton
): ReactElement {
  return (
    <button onClick={props.onClick}>
      <XMarkIcon className='w-6 h-6 hover:scale-110 transition-all duration-150' />
    </button>
  );
}
