import { Menu, Transition } from '@headlessui/react';
import { Bars3Icon } from '@heroicons/react/20/solid';
import { Fragment, MouseEvent } from 'react';
import { useTranslation } from 'react-i18next';
import { NavLink } from 'react-router-dom';

export default function UnauthedMobileMenu() {
  const { t } = useTranslation('translation', { keyPrefix: 'landing' });

  const landings = [
    {
      text: 'For creators',
      link: 'for-creators',
    },
    {
      text: 'For brands',
      link: '',
    },
  ];
  const options = [
    {
      text: 'Sign in as Brand',
      link: 'brand',
    },
    {
      text: 'Sign in as Creator',
      link: 'influencer',
    },
  ];

  return (
    <Menu as='div' className='max-md:block hidden'>
      <Menu.Button
        onClick={(e: MouseEvent<HTMLButtonElement>) => e.stopPropagation()}
        className={`p-2 rounded-full transition-all duration-150 capitalize`}
      >
        <Bars3Icon className='text-white w-6 h-6' />
      </Menu.Button>
      <Transition
        as={Fragment}
        enter='transition ease-out duration-100'
        enterFrom='transform opacity-0 scale-95'
        enterTo='transform opacity-100 scale-100'
        leave='transition ease-in duration-75'
        leaveFrom='transform opacity-100 scale-100'
        leaveTo='transform opacity-0 scale-95'
      >
        <Menu.Items className='absolute right-0 mt-2 w-56 origin-top-right divide-y divide-gray-100 rounded-md bg-white dark:bg-dark-theme-200 shadow-card '>
          <div key={'main-option-group'} className='px-1 py-1'>
            {landings.map((landing, index) => {
              return (
                <Menu.Item key={landing.link ?? '' + index}>
                  {({ active }) => (
                    <NavLink
                      to={'/' + landing.link}
                      className={`${
                        active ? 'bg-gray-100 dark:bg-dark-theme-900' : ''
                      } group active:bg-gray-200 flex gap-3 w-full truncate items-center rounded-md px-3 h-10 text-sm transition-all duration-150`}
                    >
                      {t(landing.text)}
                    </NavLink>
                  )}
                </Menu.Item>
              );
            })}
            <hr className='mx-2 opacity-60 my-2' />
            {options.map((option, index) => {
              return (
                <Menu.Item key={option.link ?? '' + index}>
                  {({ active }) => (
                    <NavLink
                      to={'/' + option.link}
                      className={`${
                        active ? 'bg-gray-100 dark:bg-dark-theme-900' : ''
                      } group active:bg-gray-200 flex gap-3 w-full truncate items-center rounded-md px-3 h-10 text-sm transition-all duration-150`}
                    >
                      {t(option.text)}
                    </NavLink>
                  )}
                </Menu.Item>
              );
            })}
          </div>
        </Menu.Items>
      </Transition>
    </Menu>
  );
}
