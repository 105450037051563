import { ReactElement } from 'react';
import { Navigate, Route, Routes } from 'react-router-dom';

import PaymentMethodSetting from './pages/PaymentMethodSetting';
import CreatorPortfolio from './pages/CreatorPortfolio';
import SettingAsideBar from './components/SettingAsideBar';
import AccountSetting from './pages/AccountSetting';
import CreatorReview from './pages/CreatorReview';
import WalletSetting from './pages/WalletSetting';

export default function Settings(): ReactElement {
  window?.scrollTo({ top: 0 });

  const routes = [
    { path: '/', element: <Navigate to='/account' replace /> },
    { path: '/account', element: <AccountSetting /> },
    { path: '/portfolio', element: <CreatorPortfolio /> },
    { path: '/reviews', element: <CreatorReview /> },
    // { path: '/wallet', element: <WalletSetting /> },
    { path: '/payment-methods', element: <PaymentMethodSetting /> },
  ];

  return (
    <main className='xl:container flex mx-auto'>
      <SettingAsideBar />
      <div className='w-full xl:w-[752px] 2xl:w-[1008px] border-r-[1px] dark:border-r-dark-theme-200 relative px-3 md:px-4 xl:px-0'>
        <div className='pt-6 pb-10 sm:p-6 xl:min-h-[calc(100vh-212px)]'>
          <Routes>
            {routes.map((route) => (
              <Route key={route.path} {...route} />
            ))}
          </Routes>
        </div>
      </div>
    </main>
  );
}
