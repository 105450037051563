import { getAllJobs } from 'apis/common/job';
import { getAllUserReviews, getUserData } from 'apis/common/userAPI';
import { getCreatorPortfolio } from 'apis/influencer/creator';
import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';

export default function useCreatorProfile() {
  const { creatorID } = useParams();
  const [fetching, setFetching] = useState<boolean>(false);
  const [portfolios, setPortfolios] = useState<IPortfolioContent[]>([]);
  const [reviews, setReviews] = useState<IUserReview[]>([]);
  const [creator, setCreator] = useState<IUserProfile>();
  const [jobs, setJobs] = useState<IJobData[]>([]);

  const fetchCreatorProfile = async () => {
    if (creatorID === undefined) return;

    try {
      setFetching(true);

      await Promise.all([
        getCreatorPortfolio(creatorID),
        getAllUserReviews(creatorID),
        getUserData(creatorID),
        getAllJobs('creator', creatorID),
      ]).then(
        ([
          portfolioResponse,
          reviewResponse,
          creatorResponse,
          jobsResponse,
        ]: any) => {
          setPortfolios(portfolioResponse.data.portfolioContentsByUserID.items);
          setReviews(reviewResponse.data.userReviewsByUserID.items);
          setCreator(creatorResponse.data.getUser);
          setJobs(jobsResponse.data.jobsByCreatorID.items);
        }
      );
      setFetching(false);
    } catch (e) {
      fetchCreatorProfile();
    }
  };

  useEffect(() => {
    fetchCreatorProfile();
    return () => {};
  }, [creatorID]);

  return {
    fetching,
    portfolios,
    reviews,
    creator,
    completedJobCount: jobs.filter((job) => job.jobStatus === 'completed')
      .length,
  };
}
