import { ReactElement } from 'react';
import { useTranslation } from 'react-i18next';

import Card from 'components/card';
import CardHr from 'components/card/CardHr';
import CardTitle from 'components/card/CardTitle';
import CardTextLabel from 'components/card/CardLabelText';

const accounting = require('accounting');

export default function CampaignCostBreakDownCard({
  campaign,
  brand,
  product,
  ...props
}: {
  brand?: IBrandData;
  campaign?: ICampaignData;
  product?: IProductData;
}): ReactElement {
  const { t } = useTranslation('translation', { keyPrefix: 'brand.campaigns' });

  return (
    <Card addClassName='p-4 md:p-8'>
      <CardTitle title={t('Campaign')} />
      <div className='flex justify-between'>
        <CardTextLabel>
          {t('Price per')} {t(campaign?.contentType ?? 'photo')}
        </CardTextLabel>
        <CardTextLabel>
          {accounting.formatMoney(campaign?.campaignPrice, '¥')}
        </CardTextLabel>
      </div>
      <CardHr />
      <div className='flex justify-between'>
        <CardTitle title={t('Total')} noMargin />
        <CardTitle
          title={accounting.formatMoney(campaign?.campaignPrice, '¥')}
          noMargin
        />
      </div>
    </Card>
  );
}
