const navbars = {
  'Account Settings': {
    Account: '账户',
    Wallet: '钱包',
    Billing: '账单',
    'Payment methods': '付款方式',
    Brands: '品牌',
    'Log out': '退出',
  },
  Menu: {
    dashboard: '控制界面',
    campaigns: '招募活动',
    products: '产品信息',
    content: '网红内容',
  },
  'Register as an Influencer': '以内容创作者的身份注册',
  'Already have an account?': '已有账户',
  'Sign In': '登陆',
};

export default navbars;
