// BRAND APP ROUTE PATHS

const BRAND_APP_PATH = '/brand/*';

const SIGNIN = '/signin';
const SIGNUP = '/signup';
const VERIFY_EMAIL = '/verify-email';
const ONBOARDING = '/onboarding';
const FORGOT_PASSWORD = '/forgot-password';
const CAMPAIGNS = '/campaigns/*';
const NEWCAMPAIGN = '/campaign/*';
const PRODUCTS = '/products/*';
const CONTENT = '/content/*';
const SETTINGS = '/settings/*';
const CREATOR_PROFILE = '/creator/:creatorID';

const BRAND_PATH = {
  // BRAND
  BRAND_APP_PATH,
  SIGNIN,
  SIGNUP,
  VERIFY_EMAIL,
  ONBOARDING,
  FORGOT_PASSWORD,
  CAMPAIGNS,
  NEWCAMPAIGN,
  PRODUCTS,
  CONTENT,
  SETTINGS,
  CREATOR_PROFILE,
};

export default BRAND_PATH;
