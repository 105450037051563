import { createUserReview } from 'apis/common/userAPI';
import { ChangeEvent, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';

export default function useReviews(job?: IJobData) {
  const { t } = useTranslation('translation', {
    keyPrefix: 'notification',
  });

  // review modal visibility
  const [visibility, setVisiblity] = useState<boolean>(false);
  const [review, setReview] = useState<IUserReview>({
    id: 'WILL BE IGNORED',
    FromUserID: job?.buyerID ?? '',
    jobID: job?.id ?? '',
    rating: 1,
    review: '',
    userID: job?.creatorID ?? '',
    createdAt: new Date(),
    updatedAt: new Date(),
  });

  const changeReviewHandler = (e: ChangeEvent<HTMLTextAreaElement>) => {
    if (review === undefined) return;

    setReview({ ...review, review: e.target.value });
  };

  const changeRatingHandler = (rating: number) => {
    if (review === undefined) return;
    if (
      rating === 1 ||
      rating === 2 ||
      rating === 3 ||
      rating === 4 ||
      rating === 5
    ) {
      setReview({ ...review, rating: rating });
    }
  };

  const closeReviewModal = () => {
    setVisiblity(false);
    setReview({
      id: 'WILL BE IGNORED',
      FromUserID: job?.buyerID ?? '',
      jobID: job?.id ?? '',
      rating: 1,
      review: '',
      userID: job?.creatorID ?? '',
      createdAt: new Date(),
      updatedAt: new Date(),
    });
  };

  const submitReviewHandler = async (
    updateJobStatus: any,
    fetchJobByJobID: any
  ) => {
    try {
      await createUserReview(review);
      await updateJobStatus('completed', fetchJobByJobID);
      setVisiblity(false);
    } catch (e) {
      toast.error(t('There was an error while submitting review.'));
    }
  };

  const enabled = review.review !== '';

  useEffect(() => {
    setReview({
      id: 'WILL BE IGNORED',
      FromUserID: job?.buyerID ?? '',
      jobID: job?.id ?? '',
      rating: 1,
      review: '',
      userID: job?.creatorID ?? '',
      createdAt: new Date(),
      updatedAt: new Date(),
    });
    return () => {};
  }, [job]);

  return {
    disabled: !enabled,
    review,
    visibility,
    setVisiblity,
    closeReviewModal,
    changeReviewHandler,
    changeRatingHandler,
    submitReviewHandler,
  };
}
