import { Storage } from 'aws-amplify';
import {
  ChangeEvent,
  SyntheticEvent,
  useCallback,
  useEffect,
  useState,
} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch, RootState } from 'redux/store';

import hashID from 'helpers/hashID';
import {
  createBrandAction,
  editBrandAction,
} from 'redux/brand_slices/brandSlice';
import { removeUnwantedFieldsFromBrand } from 'helpers/object';
import { toast } from 'react-toastify';
import { useTranslation } from 'react-i18next';

export default function useEditBrandInfo({
  closeModal,
  hook,
  modalType,
  data,
}: IBrandModalPanel) {
  const { t } = useTranslation('translation', {
    keyPrefix: 'notification',
  });

  const dispatch = useDispatch<AppDispatch>();

  const userID = useSelector((root: RootState) => root.user.profile.id);
  const pending = useSelector((root: RootState) => root.brand.pending);
  const [isUploading, setIsUploading] = useState<boolean>(false);
  const [progress, setProgress] = useState<IUploadProgress>({
    loaded: 0,
    total: 1,
  });

  const initEmptyBrand: IBrandData = {
    id: hashID(8),
    userID: userID,
    brandName: '',
    externalLink: '',
    imageUrl: '',
  };

  const [brandData, setBrandData] = useState<IBrandData>(
    data ?? initEmptyBrand
  );
  const [imageURL, setImageURL] = useState<string>();
  const [imageFile, setImageFile] = useState<File>();

  const inputChangeHandler = (e: ChangeEvent<HTMLInputElement>) => {
    setBrandData({ ...brandData, [e.target.name]: e.target.value });
  };

  const otherChangeHandler = (name: string, value: string | number) => {
    setBrandData({ ...brandData, [name]: value });
  };

  const handleImageFileChange = (image: File) => {
    setImageURL(URL.createObjectURL(image));
    setImageFile(image);
  };

  const getImage = useCallback(async (key?: string) => {
    if (key) {
      const image = await Storage.get(key, { expires: 120 });
      setImageURL(image);
    }
  }, []);

  useEffect(() => {
    getImage(data?.imageUrl);
    return () => {};
  }, []);

  const submitHandler = async (e: SyntheticEvent) => {
    e.preventDefault();
    if (imageFile === undefined) return;

    // add
    if (modalType === 'CREATE') {
      setIsUploading(true);
      let response;
      try {
        const fileName =
          imageFile.name.split('.')[0] +
          Date.now() +
          '.' +
          imageFile.name.split('.')[1];

        response = await Storage.put(fileName, imageFile, {
          level: 'public',
          progressCallback(progress) {
            setProgress({ loaded: progress.loaded, total: progress.total });
          },
        });
      } catch (error) {
        toast.error(t('There was an error uploading image.'));
      }
      setProgress({ loaded: 0, total: 1 });
      setIsUploading(false);

      if (response?.key) {
        await dispatch(
          createBrandAction({ ...brandData, imageUrl: response.key })
        );
        // trigger hook after create or edit brand
        hook &&
          hook({
            index: brandData.id,
            value: brandData.brandName,
            displayValue: brandData.brandName,
          });
      }
    }

    //save
    if (modalType === 'EDIT') {
      if (imageFile === undefined && brandData.imageUrl === '') return;

      setIsUploading(true);
      let response;
      try {
        const fileName =
          imageFile.name.split('.')[0] +
          Date.now() +
          '.' +
          imageFile.name.split('.')[1];

        response = await Storage.put(fileName, imageFile, {
          level: 'public',
          progressCallback(progress) {
            setProgress({ loaded: progress.loaded, total: progress.total });
          },
        });
      } catch (error) {
        toast.error(t('There was an error uploading image.'));
      }
      setProgress({ loaded: 0, total: 1 });
      setIsUploading(false);

      if (response?.key) {
        await dispatch(
          editBrandAction(
            removeUnwantedFieldsFromBrand({
              ...brandData,
              imageUrl: response.key,
            })
          )
        );
      }
    }

    closeModal();
  };

  let enabled =
    brandData.brandName !== '' &&
    brandData.externalLink !== '' &&
    !!brandData.userID;

  if (modalType === 'CREATE') {
    enabled = enabled && imageFile !== undefined;
  } else if (modalType === 'EDIT') {
    enabled = enabled && (imageFile !== undefined || brandData.imageUrl !== '');
  }

  return {
    disabled: !enabled,
    imageURL,
    imageFile,
    pending,
    brandData,
    isUploading,
    progress,
    submitHandler,
    inputChangeHandler,
    otherChangeHandler,
    handleImageFileChange,
  };
}
