import { useLocation, useNavigate } from "react-router-dom";
import { useEffect, useCallback } from "react";
import { useSelector, useDispatch } from "react-redux";
import { ColorRing } from "react-loader-spinner";
import { AppDispatch, RootState } from "redux/store";

import { checkExistingSessionAction, logoutAction } from "redux/common_slices/authSlice";
import { getUserDataAction } from "redux/common_slices/userSlice";
import AuthLayout from "components/layout/AuthLayout";
import AdminRoute from "routes/admin";

export default function AdminApp() {
  const location = useLocation().pathname;
  const navigate = useNavigate();

  const dispatch = useDispatch<AppDispatch>();
  const authentication = useSelector((root: RootState) => root.authentication);
  const profile = useSelector((root: RootState) => root.user.profile);
  const path = useLocation().pathname;

  const { id, role, profile_picture } = profile;

  const checkLoggedIn = useCallback(async () => {
    try {
      const session = await dispatch(checkExistingSessionAction());
      console.log("session: ", session);
      if (!session.payload) {
        throw new Error("No current user");
      }
      if (session.payload.data.profile === "CREATOR") {
        await dispatch(logoutAction());
      }
      await dispatch(getUserDataAction(session.payload.data.id));
      navigate(location);
    } catch (e) {
      navigate("/");
    }
  }, []);

  useEffect(() => {
    checkLoggedIn();
    return () => {};
  }, []);

  useEffect(() => {
    if (!authentication.auth || !id || role !== "ADMIN") {
      navigate("/");
    }

    return () => {};
  }, [authentication.auth, id]);

  if (
    (authentication.action === "CHECK SESSION" || authentication.action === undefined) &&
    authentication.pending
  ) {
    return (
      <div className="w-screen h-screen dark:bg-dark-theme-200 flex items-center justify-center">
        <ColorRing
          visible={true}
          height="160"
          width="160"
          ariaLabel="blocks-loading"
          colors={["#FF8134", "#FF5757", "#5F5FFF", "#2F3454", "transparent"]}
        />
      </div>
    );
  }

  return (
    <AuthLayout
      userProfilePicture={profile_picture}
      auth={authentication.auth}
      path={path}
      userType="ADMIN"
    >
      <AdminRoute auth={authentication.auth} />
    </AuthLayout>
  );
}
