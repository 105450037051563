import { ChangeEvent, ReactElement, useEffect, useState } from 'react';

export default function DebouncedInputBox({
  error,
  className,
  addClassName,
  icon,
  onQueryChange,
  ...props
}: IDebounceInput): ReactElement {
  const [query, setQuery] = useState<string>('');

  useEffect(() => {
    const timerID = setTimeout(() => {
      onQueryChange && onQueryChange(query);
    }, 500);
    return () => {
      clearTimeout(timerID);
    };
  }, [query]);

  return (
    <div className='relative w-full'>
      <input
        {...props}
        className={`border-[1px] outline-none ${
          className ?? 'w-full h-11 text-base'
        } ${addClassName ?? ''} ${
          error
            ? props.errorClassName ??
              'border-error/100 hover:border-error/100 bg-error/10 placeholder:text-error placeholder:font-medium'
            : props.successClassName ??
              ' border-dark/40 hover:border-dark/60 focus:border-dark/90'
        } ${icon ? 'pl-11' : ''} dark:bg-[#343434] px-4 rounded-lg text-xs`}
        value={query}
        onChange={(e: ChangeEvent<HTMLInputElement>) =>
          setQuery(e.target.value)
        }
      />
      {icon && (
        <span className='absolute top-1/2 left-[6px] -translate-y-1/2'>
          {icon}
        </span>
      )}
    </div>
  );
}
