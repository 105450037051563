import { ReactElement } from 'react';
import Loader from '../loader/Loader';

export default function Button({
  pending,
  variant,
  addClassName,
  className,
  icon,
  ...props
}: IButton): ReactElement {
  return (
    <button
      {...props}
      className={`${
        className ?? 'w-full h-11 text-base flex justify-center items-center'
      } ${addClassName ?? ''} ${
        variant === 'outline'
          ? 'border-[1px] border-dark/30 text-dark-black dark:border-dark-theme-900 dark:text-white'
          : 'bg-dark-theme-900 hover:bg-dark-theme-900/80 active:bg-dark-theme-900/90 text-white'
      } disabled:bg-dark-theme-900/60 disabled:cursor-not-allowed rounded-lg overflow-hidden relative disabled:text-opacity-70`}
    >
      {pending ? (
        <Loader scale={1} />
      ) : (
        <>
          {icon}
          {props.children}
        </>
      )}
    </button>
  );
}
