const job = {
  'My jobs': '我的任务',

  // table tabs
  All: '全部({{count}})',
  Open: '进行中的({{count}})',
  Invited: '被邀请的({{count}})',
  Started: '已开始的({{count}})',
  Completed: '已完成的({{count}})',
  Rejected: '被拒绝的({{count}})',

  // job table tabs

  Product: '产品',
  'Content type': '内容类型',
  'Job info': '任务详情',

  // content type
  video: '视频',
  photo: '图片',

  // content format
  Any: '任何',
  'aspect ratio': '视频比例',
  'Created at': '视频上传时间',

  'No jobs': '无任务',
};

export default job;
