import { ReactElement } from 'react';
import { useTranslation } from 'react-i18next';

import Card from 'components/card/Card';
import AmplifyMedia from 'views/components/media/StorageImageRenderer';
import { SkeletonBox } from 'components/skeleton';

export default function JobCard(campaign: ICampaignDataResponse): ReactElement {
  const { t } = useTranslation('translation', {
    keyPrefix: 'creator.marketplace',
  });

  return (
    <Card addClassName='p-6 w-[300px] sm:w-full'>
      <h5 className='font-medium text-sm'>{campaign?.Product?.productName}</h5>
      <p className='text-sm text-gray-500 mb-2'>{campaign?.Brand?.brandName}</p>
      <AmplifyMedia
        height={220}
        sourceKey={campaign?.Product?.productImage}
        className='w-full aspect-square rounded-lg'
      />
      <p className='font-semibold text-sm mt-4 first-letter:capitalize'>
        {campaign?.Product?.productType &&
          t(campaign.Product.productType?.toLowerCase())}
      </p>
      <p className='flex justify-between mt-2 text-sm'>
        <span className='text-gray-500'>{t('Product price')}</span>
        <span className='font-medium'>${campaign.Product?.productPrice}</span>
      </p>

      <p className='flex justify-between mt-2 text-sm'>
        <span className='text-gray-500'>{t('Created at')}</span>
        <span className='font-medium'>{new Date(campaign?.createdAt).toLocaleDateString()}</span>
      </p>      
    </Card>
  );
}

export function JobCardSkeleton(): ReactElement {
  return (
    <Card addClassName='p-6 w-[300px] sm:w-full'>
      <SkeletonBox darkMode className='h-5 w-24 mb-2' />
      <SkeletonBox darkMode className='h-4 w-16 mb-2 opacity-75' />
      <SkeletonBox darkMode className='w-full aspect-square' />
      <SkeletonBox darkMode className='mt-4 w-12 h-5' />
      <div className='mt-2 flex justify-between'>
        <SkeletonBox darkMode className='h-4 w-40 opacity-75' />
        <SkeletonBox darkMode className='h-4 w-12' />
      </div>
    </Card>
  );
}
