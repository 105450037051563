import { ReactElement } from 'react';
import { Helmet } from 'react-helmet';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import { SigninAction } from 'redux/common_slices/authSlice';
import { AppDispatch, RootState } from 'redux/store';

import ImageSidePanel from './components/Image.SidePanel';
import BrandSigninForm from './components/Form.BrandSignin';
import DecorationNavlink from './components/Link.Decoration';

import { setAuthedUserProfileData } from 'redux/common_slices/userSlice';

export default function BrandSignin(): ReactElement {
  const { t } = useTranslation('translation', { keyPrefix: 'auth' });

  const dispatch = useDispatch<AppDispatch>();
  const navigate = useNavigate();

  const auth = useSelector((root: RootState) => root.authentication);

  async function submitHandler(data: ICredentials) {
    const response = (await dispatch(
      SigninAction({ credential: data, navigate, type: 'BRAND' })
    )) as any;

    if (response.error) {
      const error = response.error;
      if (error.name === 'UserNotConfirmedException') {
        //user has a login but not yet confirmed with one tim epassword
        localStorage.setItem('email_address', data.email);
        navigate('/brand/verify-email');
      }
    } else {
      // successfully signin
      dispatch(setAuthedUserProfileData(response.payload.result));
    }
  }

  return (
    <>
      <Helmet>
        <title>Brand - Sign in</title>
      </Helmet>
      <main className='w-full flex-col relative'>
        <div className='w-full mx-auto sm:w-3/5 2xl:w-2/5 py-14 md:py-24'>
          <div className='flex flex-col lg:flex-row rounded-2xl overflow-hidden shadow-none md:shadow-card'>
            <ImageSidePanel />
            <div className='shadow-card md:shadow-none bg-white dark:bg-dark-theme-200 m-3 sm:m-0 p-4 md:p-6 sm:p-8 xl:p-10 lg:w-2/3 rounded-lg md:rounded-none'>
              <h1 className='text-2xl sm:text-3xl font-bold text-left mb-4 lg:mb-6'>
                <span>{t('Sign in')}</span>
                <span className='text-theme'> {t('as a Brand')}</span>
              </h1>
              <BrandSigninForm
                pending={auth.pending}
                onSubmit={submitHandler}
              />
              <p className='pt-4 lg:pt-6 text-gray-600 dark:text-gray-200 text-sm md:text-base'>
                {t("Don't have an account?")}{' '}
                <DecorationNavlink to='/brand/signup'>
                  {t('Sign up here')}
                </DecorationNavlink>
              </p>
              <p className='py-4 lg:py-6 text-sm md:text-base text-gray-600 dark:text-gray-200'>
                <DecorationNavlink to='/brand/forgot-password'>
                  {t('Forgot password?')}
                </DecorationNavlink>
              </p>
            </div>
          </div>
        </div>
      </main>
    </>
  );
}
