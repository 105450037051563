import { ReactElement, useEffect, useState, ChangeEvent } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { NavLink } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import {
  confirmSignupAction,
  resendAuthCodeAction,
} from 'redux/common_slices/authSlice';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { AppDispatch, RootState } from 'redux/store';

import Button from 'components/button/Button';
import InputBox from 'components/form/InputBox';
import ImageSidePanel from './components/Image.SidePanel';

export default function BrandResendCode(): ReactElement {
  const { t } = useTranslation('translation', { keyPrefix: 'auth' });

  const location = useLocation();
  const navigate = useNavigate();
  const dispatch = useDispatch<AppDispatch>();
  const pending = useSelector((root: RootState) => root.authentication.pending);

  const [wrongEmail, setWrongEmail] = useState<boolean>(false);

  const [emailAddress, setEmailAddress] = useState<string>('');
  const [authenticationCode, setAuthenticationCode] = useState<string>('');

  const checkAuthenticationCode = () => {
    dispatch(
      confirmSignupAction({
        emailAddress: emailAddress,
        authCode: authenticationCode,
        type: 'BRAND',
        navigate,
      })
    );
  };

  const resendAuthenticationCode = () => {
    dispatch(resendAuthCodeAction(emailAddress));
  };

  const inputChangeHandler = (e: ChangeEvent<HTMLInputElement>) => {
    setAuthenticationCode(e.target.value);
  };

  useEffect(() => {
    if (
      location?.state?.email === undefined &&
      localStorage.getItem('email_address') === null
    ) {
      navigate('/brand');
    }

    if (location?.state?.email) {
      localStorage.setItem('email_address', location.state.email);
      setEmailAddress(location.state.email);
      return;
    }
    setEmailAddress(localStorage.getItem('email_address') ?? '');
  }, []);

  return (
    <div className='min-h-[calc(100vh-192px)] md:min-h-[calc(100vh-128px)] flex justify-center items-center'>
      <div className='w-full sm:w-3/5 2xl:w-2/5 sm:py-12'>
        <div className='flex flex-col lg:flex-row sm:rounded-2xl shadow-none md:shadow-card overflow-hidden'>
          <ImageSidePanel />
          <div className='bg-white dark:bg-dark-theme-200 rounded-2xl sm:rounded-none m-3 sm:m-0 p-4 md:p-6 sm:p-8 xl:p-10 lg:w-2/3 shadow-card md:shadow-none'>
            {wrongEmail && (
              <div className='transition-all'>
                <h1 className='text-2xl sm:text-3xl font-bold text-gray-900 text-left mb-4 lg:mb-6'>
                  {t('Wrong email address?')}
                </h1>
                <p className='text-sm md:text-base xl:text-md text-left text-gray-600'>
                  {t(
                    'If you mistyped your email address, you can simply go through the register page again. Do you want to continue?'
                  )}
                </p>
                <div className='grid grid-cols-1 xl:grid-cols-2 gap-6 pt-4 lg:pt-6'>
                  <Button
                    pending={pending}
                    onClick={() => setWrongEmail(false)}
                    addClassName='font-medium'
                    variant='outline'
                  >
                    {t('Cancel')}
                  </Button>
                  <NavLink to='/brand/signup'>
                    <Button addClassName='font-medium'>{t('Continue')}</Button>
                  </NavLink>
                </div>
              </div>
            )}
            {!wrongEmail && (
              <div className='transition-all'>
                <h1 className='text-2xl sm:text-3xl font-bold text-gray-900 dark:text-gray-100 text-left mb-4 lg:mb-6'>
                  {t('Please check your inbox')}
                </h1>
                <p className='text-sm md:text-base text-left text-gray-600 dark:text-gray-200 mb-4'>
                  We sent an email to{' '}
                  <span className='text-sm md:text-base text-blue-500'>
                    {emailAddress}
                  </span>
                  . In order to continue the sign-up process, please type in the
                  verification code.
                </p>
                <p className='text-sm md:text-base xl:text-md text-left text-gray-600 dark:text-gray-200'>
                  {t(
                    'If you did not receive the confirmation email, please check your spam folder and/or verify that you entered the correct email address above.'
                  )}
                </p>
                <InputBox
                  addClassName='mt-4 lg:mt-6'
                  name='authenticationCode'
                  value={authenticationCode}
                  onChange={inputChangeHandler}
                  placeholder={'Authentication Code'}
                />
                <div className='mt-4 lg:mt-6 flex flex-col md:flex-row gap-x-6 gap-y-4 md:gap-y-0'>
                  <Button
                    variant='outline'
                    disabled={pending}
                    onClick={() => resendAuthenticationCode()}
                    addClassName='font-medium'
                  >
                    {t('Resend Code')}
                  </Button>
                  <Button
                    pending={pending}
                    addClassName='font-medium'
                    onClick={() => checkAuthenticationCode()}
                  >
                    {t('Submit')}
                  </Button>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}
