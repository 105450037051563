import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import { getProtoTypes } from 'apis/common/protoAPI';
import { logoutAction } from './authSlice';

export const getProtoTypesAction = createAsyncThunk(
  'prototype/product-categories',
  async () => {
    const result = await getProtoTypes();
    return result;
  }
);

const initialState: IProtoTypeState = {
  pending: false,
  error: null,
  productCategories: [],
};

export const protoTypeSlice = createSlice({
  name: 'protoType',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(
        getProtoTypesAction.pending,
        (state: IProtoTypeState, action) => {
          if (!state.pending) {
            state.pending = true;
            state.currentRequestId = action.meta.requestId;
          }
        }
      )
      .addCase(
        getProtoTypesAction.fulfilled,
        (state: IProtoTypeState, action) => {
          const { requestId } = action.meta;
          if (state.pending && state.currentRequestId === requestId) {
            state.pending = false;
            state.productCategories = action.payload.productCategories;
            state.currentRequestId = undefined;
          }
          return state;
        }
      )
      .addCase(
        getProtoTypesAction.rejected,
        (state: IProtoTypeState, action) => {
          const { requestId } = action.meta;
          if (state.pending && state.currentRequestId === requestId) {
            state.pending = false;
            state.error = action.error;
            state.currentRequestId = undefined;
          }
          return state;
        }
      );

    builder.addCase(logoutAction.fulfilled, (state: IProtoTypeState) => {
      state = initialState;
      return state;
    });
  },
});

// export const {} = protoTypeSlice.actions;

export default protoTypeSlice.reducer;
