import { ReactElement } from 'react';
import { NavLink } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import AuthedHeading from 'components/typhography/AuthedHeading';
// import SearchInput from 'views/brand/_components/Input.SearchBox';
import CreatorAuthedContainer from '../_components/Container.AuthedCreator';

import JobCard, { JobCardSkeleton } from './components/Card.Job';

import useFilteredJobPosts from 'hooks/influencer/useFilteredJobPosts';

export default function Marketplace(): ReactElement {
  const { t } = useTranslation('translation', {
    keyPrefix: 'creator.marketplace',
  });

  const { pending, campaigns } = useFilteredJobPosts();
  window?.scrollTo({ top: 0 });

  return (
    <CreatorAuthedContainer>
      <AuthedHeading addClassName='text-center mt-14'>
        {t('Jobs you may be interested in')}
      </AuthedHeading>
      {/* <div className='flex justify-center mt-5'>
        <SearchInput onQueryChange={changeQueryHandler} />
      </div> */}

      <div className='mt-14 flex flex-col items-center flex-wrap justify-center sm:grid sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 2xl:grid-cols-5 gap-4'>
        {pending &&
          new Array(10).fill(true).map((_elem) => <JobCardSkeleton />)}
        {!pending &&
          campaigns.map((campaign) => (
            <NavLink
              key={campaign.id}
              to={'/influencer/campaigns/' + campaign.id}
            >
              <JobCard {...campaign} />
            </NavLink>
          ))}
      </div>
    </CreatorAuthedContainer>
  );
}
