import { StarIcon } from '@heroicons/react/20/solid';
import { StarIcon as StarOutlineIcon } from '@heroicons/react/24/outline';

export default function Rating({
  rating,
  mini,
}: {
  rating: number;
  mini?: boolean;
}) {
  const ignoredRating = rating - Math.floor(rating);

  return (
    <div className='flex gap-1'>
      {new Array(Math.floor(rating)).fill(true).map((_rating, index) => (
        <StarIcon
          key={'rating' + index}
          className={`${mini ? 'w-4 h-4' : 'w-5 h-5'} text-yellow-400`}
        />
      ))}

      {(() => {
        if (rating !== 5) {
          return (
            <>
              {Number(rating) !== 5 && ignoredRating === 0 ? (
                <StarOutlineIcon
                  className={`${mini ? 'w-4 h-4' : 'w-5 h-5'} text-yellow-400`}
                />
              ) : (
                <StarIcon
                  className={`${mini ? 'w-4 h-4' : 'w-5 h-5'} text-yellow-400`}
                  style={{
                    opacity: ignoredRating.toString(),
                  }}
                />
              )}

              {new Array(5 - Math.floor(rating) - 1)
                .fill(true)
                .map((_rating, index) => (
                  <StarOutlineIcon
                    key={'empty-rating' + index}
                    className={`${
                      mini ? 'w-4 h-4' : 'w-5 h-5'
                    } text-yellow-400`}
                  />
                ))}
            </>
          );
        }
      })()}
    </div>
  );
}
