import marketplace from './marketplace';
import job from './job';
import navbar from './navbar';
import setting from './setting';
import landing from './landing';
import publicProfile from './public';

const creator = { marketplace, job, navbar, setting, landing, publicProfile };

export default creator;
