import { ReactElement } from 'react';

import ComboBox from 'components/form/Combobox/Combobox';

import useProductBrand from 'hooks/brand/useProductBrand';

export default function BrandComboBox(props: IBrandCombo): ReactElement {
  const { brandObjects, currentBrand, handleSelectBrand } = useProductBrand(
    props.currentBrand
  );

  return (
    <ComboBox
      data={brandObjects}
      selectedData={currentBrand}
      setSelectedData={(value: IValueWithIndex) => {
        props.onBrandChanage(value);
        handleSelectBrand(value);
      }}
      placeholder='Select product'
    />
  );
}
