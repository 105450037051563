import { ReactElement } from 'react';

export default function ChatHistoryItem(props: IChatHistoryItem): ReactElement {
  const months = [
    'January',
    'February',
    'March',
    'April',
    'May',
    'June',
    'July',
    'August',
    'September',
    'October',
    'November',
    'December',
  ];

  if (props.message.createdAt === undefined) return <></>;

  const date = new Date(props.message.createdAt);

  return (
    <div
      className={`mb-4 last:mb-0 flex ${
        props.align === 'left' ? 'justify-start' : 'justify-end'
      }`}
    >
      <div
        className={`w-fit flex gap-2 ${
          props.align === 'left' ? '' : 'flex-row-reverse'
        }`}
      >
        <div
          className={`w-12 h-12 rounded-lg ${
            props.align === 'right'
              ? 'text-white bg-dark-black'
              : 'text-white bg-theme'
          } flex items-center justify-center font-medium capitalize`}
        >
          {props.avatar}
        </div>
        <div
          className={`flex flex-col ${
            props.align === 'left' ? 'items-start' : 'items-end'
          } gap-y-1`}
        >
          <p
            className={`p-3 w-fit rounded-2xl text-xs max-w-[500px] ${
              props.align === 'left'
                ? 'bg-blue-100 dark:bg-blue-50 text-dark-black rounded-tl-none'
                : 'bg-blue-600 text-white rounded-tr-none'
            }`}
          >
            {props.message.message}
          </p>
          <time className='flex justify-end text-xs text-gray-400'>
            {(() => {
              const today = new Date();

              return today.getDate() === date.getDate() &&
                today.getMonth() === date.getMonth() &&
                today.getFullYear() === date.getFullYear()
                ? `Today`
                : `${months[date.getMonth()]} ${date.getDate()}`;
            })()}
            {` at ${date.getHours().toString().padStart(2, '0')}:${date
              .getMinutes()
              .toString()
              .padStart(2, '0')}`}
          </time>
        </div>
      </div>
    </div>
  );
}
