import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { getAllJobs } from 'apis/common/job';

import { logoutAction } from 'redux/common_slices/authSlice';

export const getAllJobAction = createAsyncThunk(
  'brand/jobs/get/all',
  async (userID: string) => {
    const result = await getAllJobs('brand', userID);
    return result;
  }
);

const initialState: IJobState = {
  pending: false,
  error: null,
  jobs: [],
};

export const jobSlice = createSlice({
  name: 'brand',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getAllJobAction.pending, (state: IJobState, action) => {
        if (!state.pending) {
          state.pending = true;
          state.currentRequestId = action.meta.requestId;
        }
      })
      .addCase(getAllJobAction.fulfilled, (state: IJobState, action: any) => {
        const { requestId } = action.meta;
        if (state.pending && state.currentRequestId === requestId) {
          state.pending = false;
          state.jobs = action.payload.data.jobsByBuyerID.items;
          state.currentRequestId = undefined;
        }
        return state;
      })
      .addCase(getAllJobAction.rejected, (state: IJobState, action) => {
        const { requestId } = action.meta;
        if (state.pending && state.currentRequestId === requestId) {
          state.pending = false;
          state.error = action.error;
          state.currentRequestId = undefined;
        }
      });

    // set remove redux data when they logout
    builder.addCase(logoutAction.fulfilled, (state: IJobState) => {
      state = initialState;
      return state;
    });
  },
});

// export const {} = jobSlice.actions;

export default jobSlice.reducer;
