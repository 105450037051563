import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { RootState } from 'redux/store';

import {
  createCreatorPortfolioContent,
  getCreatorPortfolio,
} from 'apis/influencer/creator';

export default function useCreatorPortfolio() {
  const creatorID: string = useSelector(
    (root: RootState) => root.user.profile.id
  );

  const [portfolios, setPortfolios] = useState<IPortfolioContent[]>([]);
  const [fetching, setFetching] = useState<boolean>(false);

  const fetchCreatorPortfolio = async () => {
    setFetching(true);
    try {
      const response: any = await getCreatorPortfolio(creatorID);
      setPortfolios(response.data.portfolioContentsByUserID.items);
    } catch (e) {
      fetchCreatorPortfolio();
    }
    setFetching(false);
  };

  useEffect(() => {
    fetchCreatorPortfolio();
    return () => {};
  }, [creatorID]);

  const createPortfolioContent = async (
    userID: string,
    contentType: 'image' | 'video',
    contentUrl: string
  ) => {
    try {
      await createCreatorPortfolioContent(userID, contentType, contentUrl);
    } catch (e) {
      createPortfolioContent(userID, contentType, contentUrl);
    }
  };

  const uploadPortfolioHandler = async (imageUrls: string[], files: File[]) => {
    imageUrls.forEach((url: string, index: number) => {
      createPortfolioContent(
        creatorID,
        files[index].type.includes('image') ? 'image' : 'video',
        url
      );
    });
    fetchCreatorPortfolio();
  };

  return { fetching, creatorID, portfolios, uploadPortfolioHandler };
}
