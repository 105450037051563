import { getAllUsers } from 'apis/common/userAPI';
import { useEffect, useState } from 'react';

export default function useAdminBrands() {
  const [users, setUsers] = useState<IUserProfile[]>([]);
  const [creators, setCreators] = useState<IUserProfile[]>([]);
  const [brands, setBrands] = useState<IUserProfile[]>([]);

  const [fetching, setFetching] = useState<boolean>(false);

  useEffect(() => {
    (async () => {
      setFetching(true);
      const response = await getAllUsers();
      console.log('responseresponse', response);

      const userList = [];
      const brandList = [];
      const creatorList = [];

      for (let i = 0; i < response.data.listUsers.items.length; i++) {
        const item: IUserProfile = response.data.listUsers.items[i];
        item.role !== 'ADMIN' && userList.push(item);
        item.role === 'BRAND' && brandList.push(item);
        item.role === 'CREATOR' && creatorList.push(item);
      }

      setUsers(userList);
      setBrands(brandList);
      setCreators(creatorList);

      setFetching(false);
    })();
  }, []);

  return { fetching, users, creators, brands };
}
