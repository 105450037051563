import { ReactElement } from 'react';
import { useTranslation } from 'react-i18next';

import TableWrapper from 'components/table/TableWrapper';
import TableHeader from 'components/table/TableHeader';
import TableBody from 'components/table/TableBody';
import TableCell from 'components/table/TableCell';
import TableRow from 'components/table/TableRow';

import AmplifyMedia from 'views/components/media/StorageImageRenderer';

import useFetchedJobDataByCampaignID from 'hooks/common/useFetchedJobDataByCampaignID';

export default function DeliveryTable(): ReactElement {
  const { t } = useTranslation('translation', { keyPrefix: 'brand.campaigns' });

  const headerArray = [t('Creator'), t('Address'), t('Status')];

  const { jobs, fetching } = useFetchedJobDataByCampaignID();

  const validJobs = jobs.filter(
    (job) => !['open', 'invited', 'waiting-payment'].includes(job.jobStatus)
  );
  if (validJobs.length === 0) {
    return (
      <div className='w-full h-[300px] flex justify-center items-center'>
        {t('No active jobs')}
      </div>
    );
  }

  return (
    <TableWrapper>
      <TableHeader headerArray={headerArray} />
      <TableBody>
        {validJobs.map((job) => (
          <TableRow key={job.id}>
            <TableCell>
              <div className='flex flex-row-reverse justify-end items-center gap-2 capitalize'>
                {`${job.Creator?.firstName} ${job.Creator?.lastName}`}
                <AmplifyMedia
                  sourceKey={job.Creator?.profile_picture}
                  className='w-10 h-10 !rounded-full'
                  isUserAvatar
                />
              </div>
            </TableCell>
            <TableCell>
              {job.Creator?.deliveryAddressLine1 ??
                job.Creator?.deliveryAddressLine2 ??
                'Delivery address not confirmed'}
            </TableCell>
            <TableCell>
              <span className='capitalize'>{t(job.jobStatus)}</span>
            </TableCell>
          </TableRow>
        ))}
      </TableBody>
    </TableWrapper>
  );
}
