import {
  ArrowsPointingOutIcon,
  ArrowTopRightOnSquareIcon,
  PhotoIcon,
  VideoCameraIcon,
} from '@heroicons/react/20/solid';
import { useTranslation } from 'react-i18next';

import Card from 'components/card';
import CardHr from 'components/card/CardHr';
import CardTextLabel from 'components/card/CardLabelText';
import CardText from 'components/card/CardText';
import CardTitle from 'components/card/CardTitle';
import { SkeletonBox } from 'components/skeleton';
import AmplifyMedia from 'views/components/media/StorageImageRenderer';

type PropsType = {
  fetching: boolean;
  campaign?: ICampaignData;
  product?: IProductData;
  brand?: IBrandData;
};

export default function JobInfoPanel({
  fetching,
  campaign,
  product,
  brand,
}: PropsType) {
  const { t } = useTranslation('translation', {
    keyPrefix: 'creator.marketplace',
  });

  return (
    <>
      <Card addClassName='p-4 md:p-8 mt-2'>
        <CardTitle title={t('Job details')} />
        <div className='-mt-2 flex gap-4'>
          <div className='w-16 h-16 rounded-md overflow-hidden'>
            {(() => {
              if (fetching) {
                return <SkeletonBox className='w-full h-full' darkMode />;
              }
              return (
                <AmplifyMedia
                  sourceKey={brand?.imageUrl}
                  className='w-full h-full object-cover'
                />
              );
            })()}
          </div>
          <p>
            <span className='text-xs text-gray-400'>{t('Brand')}</span>
            {fetching ? (
              <SkeletonBox className='h-5 my-1 w-32' darkMode />
            ) : (
              <span className='block'>{brand?.brandName}</span>
            )}
          </p>
        </div>
        <CardHr />

        <div className='-mt-2 flex gap-4'>
          <div className='w-16 h-16 rounded-md overflow-hidden'>
            {(() => {
              if (fetching) {
                return <SkeletonBox className='w-full h-full' darkMode />;
              }
              return (
                <AmplifyMedia
                  sourceKey={product?.productImage}
                  className='w-full h-full object-cover'
                />
              );
            })()}
          </div>
          <p>
            <span className='text-xs text-gray-400'>{t('Product')}</span>
            {fetching ? (
              <SkeletonBox className='h-5 my-1 w-32' darkMode />
            ) : (
              <>
                <span className='block'>{product?.productName}</span>
                <CardTextLabel>{t('Description')}</CardTextLabel>
                <CardText addClassName='text-sm mt-1'>
                  {fetching ? (
                    <>
                      <SkeletonBox className='w-full h-[18px] my-[2px]' darkMode />
                      <SkeletonBox className='w-full h-[18px] my-[2px]' darkMode />
                      <SkeletonBox className='w-full h-[18px] my-[2px]' darkMode />
                      <SkeletonBox className='w-full h-[18px] my-[2px]' darkMode />
                    </>
                  ) : (
                    product?.productDescription || 'No description'
                  )}
                </CardText>    

               

                <a
                  rel="noreferrer"
                  target="_blank"
                  href={product?.productExternalLink}
                  className='text-theme cursor-pointer mt-2 flex gap-1 items-center'
                >
                  {t('Product link')}
                  <ArrowTopRightOnSquareIcon className='w-5 h-5' />
                </a>  
                <span className='block'>{product?.productDescription}</span>     
              </>       
              
            )}
          </p>
          
        </div>

        
      

        <CardHr />

        <CardTextLabel>{t('Required content')}</CardTextLabel>
        <CardText addClassName='mt-1 flex items-center gap-2 text-sm font-medium capitalize'>
          {fetching ? (
            <SkeletonBox className='w-5 h-5' darkMode />
          ) : campaign?.contentType === 'photo' ? (
            <PhotoIcon className='w-5 h-5' />
          ) : (
            <VideoCameraIcon className='w-5 h-5' />
          )}

          {fetching ? (
            <SkeletonBox className='h-4 my-[2px] w-20' darkMode />
          ) : (
            <>
              {t(product?.productType?.toLowerCase() || '')} {t('product')}
            </>
          )}
        </CardText>
        <CardText addClassName='mt-1 flex items-center gap-2 text-sm font-medium capitalize'>
          {fetching ? (
            <SkeletonBox className='w-5 h-5' darkMode />
          ) : (
            <ArrowsPointingOutIcon className='w-5 h-5' />
          )}

          {fetching ? (
            <SkeletonBox className='h-4 my-[2px] w-48' darkMode />
          ) : (
            <>
              {t(campaign?.contentFormat ?? '')}
              <span className='text-gray-500 text-xs'>
                (
                {campaign?.contentFormat === 'any'
                  ? t('All aspect ratio')
                  : campaign?.contentFormat === 'landscape'
                  ? '16:9 ' + t('aspect ratio')
                  : campaign?.contentFormat === 'portrait'
                  ? '3:4 ' + t('aspect ratio')
                  : '1:1 ' + t('aspect ratio')}
                )
              </span>
            </>
          )}
        </CardText>
      </Card>

      <Card addClassName='p-4 md:p-8 mt-2'>
        <CardTitle noMargin title={t('Content description')} />
        <CardHr marginClassName='my-3' />
        <CardTextLabel>{t('Description')}</CardTextLabel>
        <CardText addClassName='text-sm mt-1'>
          {fetching ? (
            <>
              <SkeletonBox className='w-full h-[18px] my-[2px]' darkMode />
              <SkeletonBox className='w-full h-[18px] my-[2px]' darkMode />
              <SkeletonBox className='w-full h-[18px] my-[2px]' darkMode />
              <SkeletonBox className='w-full h-[18px] my-[2px]' darkMode />
            </>
          ) : (
            campaign?.description || 'No description'
          )}
        </CardText>
      </Card>
    </>
  );
}
