import { ReactElement } from 'react';

import Card from 'components/card';
import SearchInput from 'views/brand/_components/Input.SearchBox';
import ContentTable from './Table.Content';

import useJobContents from 'hooks/brand/useJobContents';

export default function CampaignContentTableCard({
  campaignID,
}: {
  campaignID?: string;
}): ReactElement {
  const { contents, queryChangeHandler } = useJobContents(campaignID);
  return (
    <Card addClassName='p-4 md:p-8'>
      <div className='flex justify-between'>
        <SearchInput onQueryChange={queryChangeHandler} />
      </div>
      <ContentTable contents={contents} />
    </Card>
  );
}
