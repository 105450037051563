import { setNotificationAsRead } from 'apis/common/userAPI';
import { useDispatch } from 'react-redux';
import { useSelector } from 'react-redux';
import { getAllNotificationAction } from 'redux/common_slices/userSlice';

import { AppDispatch, RootState } from 'redux/store';

export default function useNotifications() {
  const dispatch = useDispatch<AppDispatch>();
  const userID = useSelector((root: RootState) => root.user.profile.id);

  const notifications = useSelector(
    (root: RootState) => root.user.notifications
  ).filter((notification) => notification.isRead === false);

  const markAsReadOne = async (notification: INotification) => {
    try {
      await setNotificationAsRead(notification.id);
      dispatch(getAllNotificationAction(userID));
    } catch (e) {}
  };

  return { notifications, markAsReadOne };
}
