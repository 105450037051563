import { ReactElement } from 'react';
import { ArrowTopRightOnSquareIcon } from '@heroicons/react/20/solid';

import Card from 'components/card';
import Modal from 'components/modal';
import Button from 'components/button';
import { SkeletonBox } from 'components/skeleton';

import useFetchedProductDetail from 'hooks/brand/useFetchedProductDetail';

import AmplifyImage from 'views/components/media/StorageImageRenderer';
import DigitalProductModal from 'views/brand/new_campaign/components/Modal.DigitalProduct';
import PhysicalProductModal from 'views/brand/new_campaign/components/Modal.PhysicalProduct';
import { useTranslation } from 'react-i18next';

export default function ProductDetailCard(): ReactElement {
  const {
    fetching,
    error,
    product,
    visibility,
    closeEditModal,
    setVisibility,
  } = useFetchedProductDetail();

  const { t } = useTranslation('translation', {
    keyPrefix: 'brand.products',
  });

  return (
    <>
      <Card addClassName='p-4 md:p-8'>
        <div className='delivery-product-information'>
          <div className='flex justify-between xs:mb-10 mb-4'>
            <span className='delivery-product-information-title text-lg font-semibold'>
              {t('Product information')}
            </span>
            <Button
              disabled={fetching}
              onClick={() => setVisibility(true)}
              variant='outline'
              className='text-sm px-5 w-fit h-10 font-medium'
            >
              {t('Edit product')}
            </Button>
          </div>
          <div className='flex flex-col md:flex-row gap-8 mt-10 items-center'>
            <div className='flex justify-self-start sm:justify-center'>
              <div className='image-container object-center w-[100px] h-[100px] md:w-[144px] md:h-[144px] rounded-lg overflow-hidden bg-dark-black/20 dark:bg-dark-theme-900 flex items-center justify-center text-6xl capitalize'>
                <AmplifyImage sourceKey={product?.productImage} />
              </div>
            </div>
            <div className='flex flex-col w-full md:w-fit'>
              <div className='text-sm text-gray-500 dark:text-gray-400 break-words line-clamp-3 sm:mb-3 mb-1'>
                {fetching ? (
                  <SkeletonBox darkMode className='h-5 w-24' />
                ) : (
                  product?.Brand?.brandName
                )}
              </div>
              {fetching ? (
                <SkeletonBox darkMode className='h-12 w-44' />
              ) : (
                <>
                  <span className='text-sm sm:text-base text-gray-900 dark:text-gray-200 break-words line-clamp-3'>
                    {product?.productName}
                  </span>
                  <div className='flex gap-x-3 mt-1 sm:mt-0'>
                    <span className='text-gray-900 dark:text-gray-200 font-semibold'>
                      ${product?.productPrice.toFixed(2)}
                    </span>
                    <a
                      href={product?.productExternalLink}
                      target='_blank'
                      rel='noreferrer'
                      className='text-blue-700 dark:text-blue-400 inline-block link-no-underline product-link'
                    >
                      <span className='flex items-center gap-1'>
                        {t('Product link')}
                        <ArrowTopRightOnSquareIcon className='w-5 h-5 stroke-2' />
                      </span>
                    </a>
                  </div>
                </>
              )}

              <div className='product-type-info pt-4'>
                {fetching ? (
                  <SkeletonBox darkMode className='h-8 mt-4 w-48' />
                ) : (
                  <>
                    <span className='product-type-info-title text-sm font-semibold capitalize'>
                      {t(`${product?.productType.toLowerCase()} product`)}
                    </span>
                    <p className='product-type-info-legend text-xs'>
                      {/* Online service without delivery(NOT MAKE SENSE) */}
                    </p>
                  </>
                )}
              </div>
            </div>
          </div>

          <hr className='border-gray-200 dark:border-dark-theme-900/70 mt-8 mb-8 hidden sm:block' />
          <div className='digital-product-details mt-6 sm:mt-0'>
            <p className='label-access pb-2 text-sm text-gray-500 dark:text-gray-400'>
              {t('Product access instructions')}
            </p>
            <div className='access-instructions text-base break-words whitespace-pre-line'>
              {fetching ? (
                <SkeletonBox darkMode className='h-6 w-36' />
              ) : (
                product?.productDescription
              )}
            </div>
          </div>
        </div>
      </Card>
      <Modal isOpen={visibility} closeModal={closeEditModal}>
        {product?.productType === 'DIGITAL' && (
          <DigitalProductModal
            data={product}
            modalType={'EDIT'}
            closeModal={closeEditModal}
          />
        )}
        {product?.productType === 'PHYSICAL' && (
          <PhysicalProductModal
            data={product}
            modalType={'EDIT'}
            closeModal={closeEditModal}
          />
        )}
      </Modal>
    </>
  );
}
