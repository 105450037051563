import { ReactElement } from 'react';
import { Helmet } from 'react-helmet';
import { useTranslation } from 'react-i18next';

import Card from 'components/card';
import Modal from 'components/modal';
import CardHr from 'components/card/CardHr';
import AuthedHeading from 'components/typhography/AuthedHeading';

import ProductTable from './components/ProductTable';
import ProductTableActions from './components/Actions.ProductTable';
import BrandAuthedContainer from '../_components/Container.AuthedBrand';

// hooks
import useVisibleModal from 'hooks/common/useVisibleModal';
import useFilteredProduct from 'hooks/brand/useFilteredProduct';
import useFetchedJobContents from 'hooks/brand/useFetchedJobContents';

import SelectProductTypeModal from '../new_campaign/components/Modal.SelectProductType';
import PhysicalProductModal from '../new_campaign/components/Modal.PhysicalProduct';
import DigitalProductModal from '../new_campaign/components/Modal.DigitalProduct';

export default function BrandProductView(): ReactElement {
  const { t } = useTranslation('translation', { keyPrefix: 'brand.products' });

  const {
    productToEdit,
    modalType,
    type,
    visibility,
    setEditProductIndex,
    setModalType,
    closeModal,
    openModal,
  } = useVisibleModal({
    types: ['PRODUCT TYPE', 'DIGITAL PRODUCT', 'PHYSICAL PRODUCT'],
  });

  const { contents } = useFetchedJobContents();

  const {
    brands,
    campaigns,
    products,
    queryChangeHandler,
    brandChangeHandler,
  } = useFilteredProduct();

  return (
    <>
      <Helmet>
        <title>Brands - Products</title>
      </Helmet>
      <BrandAuthedContainer>
        <AuthedHeading>{t('My Products')}</AuthedHeading>
        <Card addClassName='mt-6 p-8'>
          <ProductTableActions
            brandChange={brandChangeHandler}
            queryChange={queryChangeHandler}
            createProductHandler={() => {
              setModalType('CREATE');
              openModal('PRODUCT TYPE');
            }}
          />
          <CardHr />
          <div className='overflow-auto'>
            <ProductTable
              campaigns={campaigns}
              products={products}
              brands={brands}
              contents={contents}
              onProductEdit={(
                productIndex: string,
                productType: 'DIGITAL' | 'PHYSICAL'
              ) => {
                setEditProductIndex(productIndex);
                setModalType('EDIT');
                openModal(
                  productType === 'DIGITAL'
                    ? 'DIGITAL PRODUCT'
                    : 'PHYSICAL PRODUCT'
                );
              }}
            />
          </div>
        </Card>
      </BrandAuthedContainer>

      <Modal closeModal={closeModal} isOpen={visibility}>
        {type === 'PRODUCT TYPE' && (
          <SelectProductTypeModal
            openModal={openModal}
            closeModal={closeModal}
          />
        )}
        {type === 'PHYSICAL PRODUCT' && (
          <PhysicalProductModal
            modalType={modalType}
            data={
              modalType === 'CREATE' ? undefined : productToEdit ?? undefined
            }
            openModal={openModal}
            closeModal={closeModal}
          />
        )}
        {type === 'DIGITAL PRODUCT' && (
          <DigitalProductModal
            modalType={modalType}
            data={
              modalType === 'CREATE' ? undefined : productToEdit ?? undefined
            }
            openModal={openModal}
            closeModal={closeModal}
          />
        )}
      </Modal>
    </>
  );
}
