import { useTranslation } from 'react-i18next';
import { NavLink } from 'react-router-dom';
import { Fragment, useState } from 'react';

import UnauthedLayout from 'components/layout/UnauthedLayout';
import {
  ArrowLongLeftIcon,
  ArrowLongRightIcon,
  XCircleIcon,
} from '@heroicons/react/24/outline';
import { PlayCircleIcon } from '@heroicons/react/20/solid';
import { Transition } from '@headlessui/react';
import i18next from 'i18next';

export default function BrandLanding() {
  const { t } = useTranslation('translation', { keyPrefix: 'brand.landing' });
  const [index, setIndex] = useState<number>(0);
  const [videoPlay, setVideoPlay] = useState<boolean>(false);

  const steps = ['1. Post A Job', '2. Select Creators', '3. Approve Content'];

  const panelImages = [
    '/assets/images/landing/photo3.png',
    '/assets/images/landing/photo2.png',
    '/assets/images/landing/photo4.png',
  ];

  const panels = [
    <>
      <h4 className='font-semibold text-3xl text-black'>{t('Post A Job')}</h4>
      <p className='mt-3 text-xl text-black'>
        {t(
          'Add pictures of your brand and your products. Share somande cool facts about your brand. Describe your content requirements. Creators will start applying in minutes.'
        )}
      </p>
    </>,
    <>
      <h4 className='font-semibold text-3xl text-black'>
        {t('Select creators that best represent your brand')}
      </h4>
      <p className='mt-3 text-xl text-black'>
        {t(
          'As creators apply to your job, their portfolio will become accessible for you to review. Browse through their profiles and pick the ones that best represent your brand.'
        )}
      </p>
    </>,
    <>
      <h4 className='font-semibold text-3xl text-black'>
        {t('Approve Content')}
      </h4>
      <p className='mt-3 text-xl text-black'>
        {t(
          'You will receive a notification once the selected creator delivers the content. Chat with them for any editing requests or simply just to let them know how happy you are with their creative work.'
        )}
      </p>
    </>,
  ];

  return (
    <UnauthedLayout>
      <Transition
        enter='transition ease-out duration-200'
        enterFrom='opacity-0 translate-y-1'
        enterTo='opacity-100 translate-y-0'
        leave='transition ease-in duration-150'
        leaveFrom='opacity-100 translate-y-0'
        leaveTo='opacity-0 translate-y-1'
        show={videoPlay}
        className='z-20 fixed top-0 left-0 w-screen h-screen bg-black/40'
        onClick={() => setVideoPlay(false)}
      >
        <div className='absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 p-2 md:p-4 rounded-lg min-w-[300px] sm:min-w-[560px] md:min-w-[700px] lg:min-w-[900px] xl:min-w-[1000px] 2xl:min-w-[1200px] shadow-card bg-white z-20'>
          <div className='relative'>
            <video src='https://res.cloudinary.com/mattkim/video/upload/v1682534304/reeffo/hero-video.mp4' controls muted autoPlay></video>
            <XCircleIcon
              className='w-12 h-12 absolute top-5 right-5 text-white cursor-pointer'
              onClick={() => setVideoPlay(false)}
            />
          </div>
        </div>
      </Transition>
      <div className='container mx-auto pt-14 md:pt-20 2xl:pt-10 text-center capitalize px-5 md:px-0 2xl:px-28'>
        <div className='flex flex-col gap-y-10 md:gap-y-20 xl:flex-row items-center justify-between min-h-[80vh]'>
          <div className='md:w-[650px]'>
            <div className='bg-gradient-to-r from-landing-theme-4 to-landing-theme-5 via-landing-theme-3 w-fit p-2 rounded-full'>
              <div className='rounded-full py-2 px-6 bg-white text-black font-bold text-lg lg:text-xl'>
                {i18next.language === 'cn' ? (
                  <>
                    {
                    <span className='text-landing-theme-4'>内容为王</span>
                    /* <span className='text-landing-theme-4'>一</span>
                    个视频加<span className='text-landing-theme-4'>三</span>
                    张图片只需<span className='text-landing-theme-4'>995</span>
                    元 */}
                  </>
                ) : (
                  <>
                  
                    {
                    
                    <span className='text-landing-theme-4'>CONTENT IS KING</span>
                    /* <span className='text-landing-theme-4'>1</span> Video &{' '}
                    <span className='text-landing-theme-4'>3</span> Images for{' '}
                    <span className='text-landing-theme-4'>995</span> */}
                  </>
                )}
              </div>
            </div>
            <h2 className='mt-4 text-3xl md:text-4xl lg:text-5xl leading-[2.5rem] md:leading-[3rem] lg:leading-[3.5rem] font-semibold text-left text-black'>
              {t('Quality UGC Package for A Fraction of the Price!')}
            </h2>
            <p className='pt-4 md:pt-8 text-lg md:text-xl lg:text-2xl pr-6 text-left text-black'>
              {i18next.language === 'cn' ? (
                <>
                  真实来自国外百姓的产品使用视频，用原生态的视角来丰富产品的维度，增加消费者对品牌的信心！网红自选，价格公道
                </>
              ) : (
                <>
                  Agency-produced UGC costs at least 2000 with lengthy
                  turnaround time. Reeffo allows brands to connect directly with
                  10,000+{' '}
                  <span className='text-landing-theme-4 font-semibold'>
                    US-based
                  </span>{' '}
                  content creators. Save on Time & Money!
                </>
              )}
            </p>
            <NavLink
              to='/brand/signup'
              className='mt-4 w-fit bg-theme hover:bg-theme/90 active:bg-theme/80 transition-all duration-200 block py-3 px-6 rounded-lg text-sm md:text-lg lg:text-xl font-bold text-white'
            >
              {t("Let's get started!")}
            </NavLink>
          </div>
          <div className='relative'>
            <img src='/assets/images/landing/photo1.jpg' alt='brand-illu-1' />
            <PlayCircleIcon
              onClick={() => setVideoPlay(true)}
              className='hover:scale-125 transition-all duration-150 cursor-pointer w-20 h-20 text-landing-theme-5 absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2'
            />
          </div>
        </div>

        <h2 className='mt-10 md:mt-20 text-3xl md:text-4xl lg:text-5xl leading-[2.5rem] md:leading-[3rem] lg:leading-[3.5rem] font-semibold text-center text-black'>
          {t('How it works')}
        </h2>
        <div className='mt-6 px-10 flex flex-wrap justify-center items-center gap-x-10 gap-y-5 text-lg lg:text-xl'>
          {steps.map((step: string, stepIndex: number) => {
            if (stepIndex === index) {
              return (
                <button
                  key={stepIndex}
                  className='p-[6px] rounded-full bg-gradient-to-r from-landing-theme-4 via-landing-theme-4 to-landing-theme-5'
                >
                  <span className='py-2 px-3 block bg-white rounded-full text-landing-theme-5 font-semibold'>
                    {t(step)}
                  </span>
                </button>
              );
            }
            return (
              <button
                key={stepIndex}
                onClick={() => setIndex(stepIndex)}
                className='text-black'
              >
                {t(step)}
              </button>
            );
          })}
        </div>
        <div className='flex flex-col-reverse xl:flex-row items-center justify-between pb-20'>
          <div>
            <div className='mt-10 md:w-[550px] md:h-[340px] rounded-2xl bg-landing-theme-2 text-left p-8 pb-24 md:pb-8 normal-case relative'>
              {panels[index]}
              <div className='absolute bottom-8 left-8 flex gap-4'>
                <button
                  onClick={() => setIndex(Math.max(0, index - 1))}
                  className='rounded-full bg-white p-2 text-black'
                >
                  <ArrowLongLeftIcon className='w-5 h-5 stroke-[2px]' />
                </button>
                <button
                  onClick={() => setIndex(Math.min(3, index + 1))}
                  className='rounded-full bg-white p-2 text-black'
                >
                  <ArrowLongRightIcon className='w-5 h-5 stroke-[2px]' />
                </button>
              </div>
            </div>
            <div className='flex flex-col md:flex-row gap-y-2 items-center gap-6 px-8 mt-2 text-black'>
              {t('Ready to get started?')}
              <NavLink
                to='/brand/signup'
                className='p-3 rounded-xl bg-gradient-to-r from-landing-theme-4 via-landing-theme-4 to-landing-theme-5 text-white font-medium'
              >
                {t('Sign Me Up Now!')}
              </NavLink>
            </div>
          </div>
          <img
            src={panelImages[index]}
            alt={'brand-illu' + index}
            width={500}
            className='mt-10 xl:mt-0'
            draggable='false'
          />
        </div>
      </div>
    </UnauthedLayout>
  );
}
