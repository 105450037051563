const brands = {
  'Create brand': '新建品牌',
  'Edit brand': '编辑品牌',
  'Brand name': '品牌名称',
  'Website (optional)': '公司网站 (选填)',

  // select product type modal
  'Add a product': '添加产品',
  'Physical product': '实物产品',
  'A product that requires delivery or a full refund':
    '产品是需要邮寄实物的',
  'Digital product': '电子非实物产品',
  "An online service that doesn't require delivery":
    "线上产品不需要实物邮寄的",

  // action button text
  Cancel: '取消',
  'Add brand': '添加品牌',
  'Save changes': '保存修改',

  // image upload component
  'Upload an image': '上传图片',
  'PNG, JPG upto 50MB': 'PNG, JPG格式，最高上传文件不超过50MB',
  'Change image': '修改图片',
};

export default brands;
