import { ReactElement, useState } from 'react';
import { Helmet } from 'react-helmet';
import { Trans, useTranslation } from 'react-i18next';

import Tab from 'components/tab';
import AuthedHeading from 'components/typhography/AuthedHeading';

import BrandAuthedContainer from '../../_components/Container.AuthedBrand';
import BrandAccountInfo from '../../_components/DashboardAccountInfo';
import ICampaignTableCard from '../components/CampaignTableCard';

import useAllCampaignsByUserID from 'hooks/brand/useAllCampaignsByUserID';

const status: Array<'All' | 'Open' | 'Closed' | 'Hidden'> = [
  'All',
  'Open',
  'Closed',
  'Hidden',
];

export default function CampaignHome(): ReactElement {
  const { t } = useTranslation('translation', { keyPrefix: 'brand.campaigns' });

  const { campaigns, pending } = useAllCampaignsByUserID();
  const [tabIndex, setTabIndex] = useState<number>(0);

  window?.scrollTo({ top: 0 });

  return (
    <>
      <Helmet>
        <title>My campaigns</title>
      </Helmet>
      <BrandAuthedContainer>
        <AuthedHeading>{t('My campaigns')}</AuthedHeading>
        <BrandAccountInfo />
        <div className='mt-4'>
          <Tab
            tabs={[
              <Trans
                i18nKey='brand.campaigns.All'
                values={{ count: campaigns.length }}
              />,
              <Trans
                i18nKey='brand.campaigns.Open'
                values={{
                  count: campaigns.filter((c) => c.campaignStatus === 'OPEN')
                    .length,
                }}
              />,
              <Trans
                i18nKey='brand.campaigns.Closed'
                values={{
                  count: campaigns.filter((c) => c.campaignStatus === 'CLOSED')
                    .length,
                }}
              />,
              <Trans
                i18nKey='brand.campaigns.Hidden'
                values={{
                  count: campaigns.filter((c) => c.campaignStatus === 'HIDDEN')
                    .length,
                }}
              />,
            ]}
            tabBarAddClassName='mb-6'
            views={[<ICampaignTableCard view={status[tabIndex]} />]}
            onTabChange={(index: number) => setTabIndex(index)}
          />
        </div>
      </BrandAuthedContainer>
    </>
  );
}
