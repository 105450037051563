const notification = {
  'Successfully updated campaign status':
    '招募活动状态已更新 ',
  'There was an error while updating campaign status':
    '在更行招募活动状态时出现了一个问题',
  'Invitation sent!': '邀请已发送！ ',
  'There was an error while inviting a creator.':
    '再邀请内容创作者时出现了一个问题',
  'You have an active job with this creator for this campaign':
    '在这个招募活动中，您和这位内容创作者有一个正在进行中的任务',
  'There was an error uploading image.':
    '在上传图片时出现了一个问题 ',
  'Successfully picked the content.':
    '内容选择成功',
  'Unpicked the content.': '不选择这个内容 ',
  'There was an error while picking this content.':
    '在选择这个内容时出现了一个问题',
  'You should upload product image.':
    '请上传一个产品图片',
  'Successfully uploaded contents': '已成功上传了内容',
  "You can't upload any contents.": "您不可以上传内容。",
  'There was an error while submitting review.':
    '在留评论时出现了一个问题',
  'Are you gonna really proceed with this creator?':
    '您确认要选择这个内容创作者么？',
  'Payment is requested, after payment is made job will be started.':
    '请付款，任务会在付款后自动开启。',
  'There was an error while creating invoice.':
    '在账单生成时出现了一个问题。',
  'There was an error while starting this job.':
    '任务开启时出现了一个问题',
  'You can only completed job after they upload contents.':
    '您只能在创作者上传内容后来结束任务',
  'Are you gonna really complete this job?':
    '您确认要结束这个任务吗？',
  'Successfully completed the job.':
    '成功结束任务。',
  'There was an error while completing the job.':
    '在结束任务时出现了一个问题。 ',
  'Successfully set the job status':
    '成功设定任务状态',
  'There was an error while changing the job status':
    '在改变任务状态时出现了一个问题',
  'You can not close the job.': '您目前无法结束这个任务。',
  'Are you really gonna reject this invitation?':
    '您确认要拒绝这个邀请么？',
  'Successfully rejected the application':
    '成功拒绝邀请',
  'There was an error while rejecting the application':
    '在拒绝邀请时出现了一个问题',
  'You can only start job when they invite you.':
    '您只有在对方邀请时才能开始做这个任务。',
  'Are you really gonna accept this invitation and start?':
    '您确认要接受邀请并开始任务么？',
  'There was an error while accepting invitation.':
    '在接受邀请时出现了一个问题。',
  'You can set as delivered after they send product to you.':
    '当您收到产品时，您可以把状态调整为产品已送达。',
  'Successfully update job status': '已成功更新任务状态',
  'There was an error while updating the status':
    '在更新任务状态时出现了一个问题',
  'You can only reject when they invited you':
    '您只能在对方邀请时拒绝',
  'Successfully rejected the invitation':
    '成功拒绝邀请',
  'There was an error while rejecting the invitation':
    '在拒绝邀请时出现了一个问题',
  'There is an issue with this campaign, please try another.':
    '这个招募活动出现了问题，请选择另一个来尝试。',
  'You already have an active job with this campaign.':
    '在这个招募活动中您已经有一个在进行中的任务',
  'You should complete your deliver address before applying for a job':
    '在申请任务前，您需要提供送货信息',
  'Only images and video formats are supported.':
    '只有图片和视频的文件可以被系统接受。',
  'Files with unsupported formats are automatically excluded.':
    '系统会自动剔除无法接受的文件格式。',
};

export default notification;
