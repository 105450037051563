import { ReactElement } from 'react';

export function SkeletonBox({
  darkMode,
  className,
}: {
  darkMode?: boolean;
  className?: string;
}): ReactElement {
  return (
    <div
      className={`${
        darkMode
          ? 'dark:bg-dark-black bg-gray-300'
          : 'dark:bg-dark-theme-200 bg-gray-300'
      }  rounded-lg animate-pulse ${className ?? 'w-full h-full'}`}
    />
  );
}

export function SkeletonCircle({
  darkMode,
  className,
}: {
  darkMode?: boolean;
  className?: string;
}): ReactElement {
  return (
    <div
      className={`${
        darkMode
          ? 'dark:bg-dark-theme-900 bg-gray-300'
          : 'dark:bg-dark-theme-200 bg-gray-300'
      }  rounded-full animate-pulse ${className ?? 'w-full h-full'} `}
    />
  );
}
