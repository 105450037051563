import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch, RootState } from 'redux/store';

import { getAllJobForCreatorAction } from 'redux/influencer_slices/creatorSlice';

export default function useCreatorJobs() {
  const dispatch = useDispatch<AppDispatch>();
  const [jobs, setJobs] = useState<IJobData[]>([]);
  const userID = useSelector((root: RootState) => root.user.profile.id);

  useEffect(() => {
    if (!userID) return;

    (async () => {
      const response = await dispatch(getAllJobForCreatorAction(userID));
      setJobs(response.payload.data.jobsByCreatorID.items);
    })();

    return () => {};
  }, [userID]);

  return { jobs };
}
